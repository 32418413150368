import { Bindable } from "./common";
import { Country } from "./country";
import { Forward } from "./forward";

const SAVE_PROPS = ['address1','address2','address3','city','state','zip','country_id','phone','email','contact_name'];

export class Address extends Bindable {
    id: number;
    address1: string;
    address2: string;
    address3: string;
    city: string;
    state: string;
    zip: string;
    country_id: number;
    country: Country;
    phone: string;
    email: string;
    contact_name: string;
    forward: Forward;
    
    constructor(data: any) {
        super(data);
        this.id = this.id || (+(new Date())) * -1;
        if (this.country){
            this.country = new Country(this.country);
        }
        if(data.forward){
            this.forward = new Forward(data.forward);
        }         
    }

    get saveProp(): string[] {
        return super.saveProp.concat(SAVE_PROPS);
    }

    get full_address(): string {
        return `${this.address1 || ''}, ${this.address2 || ''}, ${this.city || ''} ${ (this.country || { key: '' }).key }`;
    }

    get location(): string {
        return `${this.country.key}, ${this.state} ${this.city}, ${this.zip}`;
    }
}