import { Component, HostListener, OnInit } from '@angular/core';
import * as actions from 'wms-lib';
import { AuthenticationService, DeviceInfo } from 'wms-lib';
import { Store } from '@ngxs/store';
import { DocumentViewerBaseComponent } from './document-viewer.component.base';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { ArrivalsState, Arrival, ArrivePackingList } from 'wms-lib';
import { combineLatest } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'app-document-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss']
})
export class DocumentViewerComponent extends DocumentViewerBaseComponent implements OnInit {
  _arrival: Arrival;
  apl: ArrivePackingList;

  @HostListener('window:popstate')
  onPopState() {
    this.store.dispatch(new actions.SetWorkingPackingAction({packing_id: null, attach_doc: true}));
  }

  constructor(protected store : Store,
              private location: Location,
              route: ActivatedRoute,
              private router : Router,
              protected authenticationService : AuthenticationService
             ) {
    super(store,route, authenticationService);
  }

  ngOnInit() {
    if (!this.document) {
      const val1$ = this.store.select(ArrivalsState.getDocumentFromWorkingPacking());
      const val2$ = this.store.select((state) => state.arrivalsState.document);
      this.addSub(combineLatest(val1$, val2$).subscribe(results => {
        const docFromPacking = results[0];
        const docSelected = results[1];
        this.document = docFromPacking || docSelected;
        if (!this.document) {
          this.goToDocuments();
        }
      }));
      this.addSub(this.store.select(state => state.arrivalsState.open_arrival).subscribe(a => {
        this._arrival = a;
      }));

	  this.store.select(state => state.arrivalsState.working_packing_id).subscribe(wpl_id => {
	  	  if (wpl_id && this._arrival) {
	  	  	  this.apl = this._arrival.arrive_packing_lists[wpl_id];
	  	  }
	  });
    }
  }

  releaseDocument(){
    this.store.dispatch(new actions.AssignDocumentToWorkingPackingAction({
      document: null,
      keep_working_packing_id: DeviceInfo.isPhone ? true : false
    }));
    this.location.back();
  }

  get loaded(): boolean {
    return !!this.document && (!!this.document.arrive_packing_list || this.only_file);
  }

  get showPartNumberResume(): boolean {
	return (this.loaded && this._arrival && this._arrival.isFreight && this._arrival.usePartNumber && this.apl && this.apl.usePartNumber);
  }

  goToTabletCarousel() {
    if (this._arrival && !this.read_only)
      this.router.navigate(['receivings', this._arrival.type, 'edit', this._arrival.id], { replaceUrl: true });
  }
  goToPacks() {
    if (this._arrival && !this.read_only)
      this.router.navigate(['receivings', this._arrival.type, 'edit', this._arrival.id]);
  }
}
