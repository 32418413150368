import { OnInit, NgZone, Directive } from '@angular/core';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import { CodebarReaderService, AuthenticationService } from 'wms-lib';
import { PackingListsBaseComponent } from '../../../shared/components/base-components/packing-lists.component.base'; 

@Directive({})
export class PackingListsComponent extends PackingListsBaseComponent implements OnInit {
    constructor(store: Store,codebar_service: CodebarReaderService,ng_zone: NgZone,
                authenticationService: AuthenticationService) {
        super(store, codebar_service, ng_zone, authenticationService);
    }
}
