import { Action, Selector, State, StateContext, createSelector } from "@ngxs/store";
import * as actions from '../actions';
import { Address, Asn, Forward, Bundle, DeliveryOrder, Document, RemoteAsset } from "../../models";
import { Injectable } from "@angular/core";
import { ExposService } from "../../services";
import * as _ from 'lodash';
import { NotificationService } from '../../platform-services/notification.service';

export interface IExposState {
  forwards: {[id: string]: Forward};
  addresses: {[id: string]: Address};
  asns: {[id: string]: Asn};
  asn_template: Asn;
  pending_asns: { [id:string]: Asn };
  current_asn_bundles: {[id:number]: Bundle};
  current_asn: Asn;
  deliveries: {[id: string]: DeliveryOrder};
  delivery_template: DeliveryOrder;
  total_asns: {[status: string]: number};
  total_deliveries: {[status: string]: number};
  pops: { [id: string]: Document };
  pop: Document;
  working_delivery_id: number;
  delivery_images_preview: RemoteAsset[];
  delivery_damages_preview: RemoteAsset[];
}

const defaultStateValue = {
  forwards: null,
  addresses: null,
  asns: null,
  asn_template: null,
  pending_asns: null,
  current_asn_bundles: null,
  current_asn: null,
  deliveries: null,
  delivery_template: null,
  total_asns: null,
  total_deliveries: null,
  pops: {},
  pop: null,
  working_delivery_id: null,
  delivery_images_preview: [],
  delivery_damages_preview: []
};

@State<IExposState>({
  name: 'exposState',
  defaults: {...defaultStateValue}
})
@Injectable()
export class ExposState {

  @Selector()
  static getBundle(state: IExposState) {
    return (bundle_id: number) =>  {
      return state.current_asn_bundles[bundle_id];
    };
  }

  static getAsn(id: number) {
    return createSelector([ExposState], (state: IExposState) => {
      return state.asns[id] || new Asn({id: id});
    });
  }

  static getAsnsByStatus(status: string) {
    return createSelector([ExposState], (state: IExposState) => {
      return _.filter(state.asns, (asn: Asn) => asn.status == status);
    });
  }

  constructor(private expos_service: ExposService, private notification_service: NotificationService) { }

  @Action(actions.LoadForwardsAction)
  loadForwards(ctx: StateContext<IExposState>, {}: actions.LoadForwardsAction) {
    global.log("State", "Getting forwards");
    this.expos_service.getForwards().subscribe(data => ctx.patchState({forwards: data}));
  }

  @Action(actions.loadAddressesAction)
  loadAddresses(ctx: StateContext<IExposState>, {}: actions.loadAddressesAction) {
    global.log("State", "Getting addresses");
    this.expos_service.getAddresses().subscribe(data => ctx.patchState({addresses: data}));
  }

  @Action(actions.SaveForwardAction)
  saveForward(ctx: StateContext<IExposState>, {payload}: actions.SaveForwardAction) {
    global.log("State", "Saving forward");
    this.expos_service.saveForward(payload.forward).subscribe(data => {
	  this.notification_service.toast('Forward and address saved successfully' , false, {severity: 'success',summary: 'Success'});
      let forwards = _.cloneDeep(ctx.getState().forwards);
      forwards[data.id] = data;
      ctx.patchState({forwards: forwards});
    });
  }

  @Action(actions.SaveAddressAction)
  saveAddress(ctx: StateContext<IExposState>, {payload}: actions.SaveAddressAction) {
    global.log("State", "Saving address");
    this.expos_service.saveAddress(payload.address).subscribe(data => {
      const state = ctx.getState();

      let addresses = _.cloneDeep(state.addresses);
      let forwards = _.cloneDeep(state.forwards);
      addresses[data.id] = data;
      ctx.patchState({addresses: addresses});
    });
  }

  @Action(actions.DeleteForwardAction)
  deleteForward(ctx: StateContext<IExposState>, {payload}: actions.DeleteForwardAction) {
    global.log("State", "Deleting forward");
    this.expos_service.deleteForward(payload.id).subscribe(data => {
      const state = ctx.getState();
      let forwards = _.cloneDeep(state.forwards);
      let addresses = _.cloneDeep(state.addresses);
      if (forwards[payload.id].address_id) {
        delete addresses[forwards[payload.id].address_id];
      }
      delete forwards[payload.id];
      ctx.patchState({forwards: forwards, addresses: addresses});
    });
  }

  @Action(actions.ImportForwardsAction)
  importForwards(ctx: StateContext<IExposState>, {payload}: actions.ImportForwardsAction) {
    global.log("State", "Importing forwards");
    this.expos_service.importForwards(payload.forwards).subscribe((data: { [id: number]: Forward }) => {
      let forwards_state = _.cloneDeep(ctx.getState().forwards) || {};
      let addresses_state = _.cloneDeep(ctx.getState().addresses) || {};
      _.forEach(data, (forward: Forward) => {
        if (forward.address) {
          addresses_state[forward.address.id+''] = forward.address;
        }
        forwards_state[forward.id] = forward;
      });
      ctx.patchState({forwards: forwards_state, addresses: addresses_state});
    });
  }

  @Action(actions.LoadAsnsAction)
  loadAsns(ctx: StateContext<IExposState>, {}: actions.LoadAsnsAction) {
    global.log("State", "Getting asns");
    this.expos_service.getAsns().subscribe(data => {
      let asns = _.cloneDeep(ctx.getState().asns);
      asns = asns ? asns : {};
      asns = data;
      ctx.patchState({asns: asns});
    });
  }

  @Action(actions.LoadAsnsByStatusAction)
  loadAsnsByStatus(ctx: StateContext<IExposState>, {payload}: actions.LoadAsnsByStatusAction) {
    global.log("State", "Getting asns by status " + payload.status + " and page " + payload.page);
    this.expos_service.getAsnsByStatus(payload.status, payload.page).subscribe( (response: {totalRecords: number, data: {[id: number]: Asn}}) => {
      let asns = _.cloneDeep(ctx.getState().asns) || {};
      let total_asns = _.cloneDeep(ctx.getState().total_asns) || {};
      let response_asns = response.data;
      total_asns[payload.status] = response.totalRecords;
      asns = _.omitBy(asns, (asn: Asn) => asn.status == payload.status);
      _.forEach(response_asns, (asn: Asn) => {
        asns[asn.id] = asn;
      });
      ctx.patchState({asns: asns, total_asns: total_asns});
    });
  }

  @Action(actions.LoadAsnAction)
  loadAsn(ctx: StateContext<IExposState>, {payload}: actions.LoadAsnAction) {
    global.log("State", "Getting asn");
    let asn_template = null;
    if (payload > 0){
      this.expos_service.getAsn(payload).subscribe(data => {
        asn_template = data as Asn;
        ctx.patchState({asn_template: asn_template});
      } );
    } else {
      asn_template = new Asn({});
    }
    ctx.patchState({asn_template: asn_template});
  }

  @Action(actions.SaveAsnAction)
  saveAsn(ctx: StateContext<IExposState>, {payload}: actions.SaveAsnAction) {
    global.log("State", "Saving asn");
    this.expos_service.saveAsn(payload.asn).subscribe(data => {
      let asns = _.cloneDeep(ctx.getState().asns);
      asns = asns || {};
      asns[data.id] = data;
      ctx.patchState({asns: asns, asn_template: null});
    });
  }

  @Action(actions.DeleteAsnAction)
  deleteAsn(ctx: StateContext<IExposState>, {payload}: actions.DeleteAsnAction) {
    global.log("State", "Deleting asn");
    this.expos_service.deleteAsn(payload).subscribe(data => {
      let asns = _.cloneDeep(ctx.getState().asns);
      delete asns[payload];
      ctx.patchState({asns: asns});
    });
  }

  @Action(actions.LoadNotReceivedAsnsAction)
  LoadNotReceivedAsns(ctx: StateContext<IExposState>,{payload}: actions.LoadNotReceivedAsnsAction) {
    global.log("State", "Loading not received ASNs");
    this.expos_service.getNotReceivedAsns(payload.type).subscribe(data => {
      if (!data['error']) {
        ctx.patchState({pending_asns: data});
      }
    });
  }

  @Action(actions.LoadAnsBundlesAction)
  LoadAsnBundles(ctx: StateContext<IExposState>,{payload}: actions.LoadAnsBundlesAction) {
    global.log("State", `Loading and bundles ${payload.asn_id}`);
    this.expos_service.getAnsBundles(payload.asn_id).subscribe(data => {
      ctx.patchState({current_asn_bundles: data})
    });

  }


  @Action(actions.ClearCurrentAsnBundlesAction)
  ClearCurrentAsnBundles(ctx: StateContext<IExposState>) {
    global.log("State", `Cleaning current asn bundles`);
    ctx.patchState({current_asn_bundles: null});
  }

  @Action(actions.SetCurrentAsnAction)
  SetCurrentAsn(ctx: StateContext<IExposState>,{payload}: actions.SetCurrentAsnAction) {
    global.log("State", `Setting current asn ${payload.asn.id}`);
    ctx.patchState({current_asn: _.cloneDeep(payload.asn) });
  }

  @Action(actions.ToggleAsnBundlePartReceivedAction)
  ToggleAsnBundlePartReceived(ctx: StateContext<IExposState>,{payload}: actions.ToggleAsnBundlePartReceivedAction) {
    global.log("State", `Mark part as received ${payload.part.id}`);

    this.expos_service.toggleBundlePartReceived(payload.part).subscribe(part => {
      if(!part['error']) {
        var currentState = ctx.getState();
        currentState.current_asn_bundles[payload.part.bundle_id].parts[part.id] = part;

        ctx.patchState({current_asn_bundles: _.cloneDeep(currentState.current_asn_bundles)});
      }
    });
  }

  @Action(actions.CloseAsnAction)
  CloseAsn(ctx: StateContext<IExposState>,{payload}: actions.CloseAsnAction) {
    global.log("State", `Closing ASN ${payload.id}`);
    this.expos_service.closeAsn(payload.id).subscribe(asn => {
      if(!asn['error']) {
        var currentState = ctx.getState();
        if (currentState.current_asn && currentState.current_asn.id === payload.id)
          delete currentState.pending_asns[payload.id];
        ctx.patchState({pending_asns: _.cloneDeep(currentState.pending_asns), current_asn: null});
      }
    });
  }

  @Action(actions.ResetAsnScanAction)
  ResetAsnScan(ctx: StateContext<IExposState>,{payload}: actions.ResetAsnScanAction) {
    global.log("State", `Resetting Asn scan ${payload.id}`);
    this.expos_service.resetAsnScan(payload.id).subscribe(asn => {
      var currentState = ctx.getState();
      if (currentState.current_asn && currentState.current_asn.id === payload.id)
        currentState.current_asn = asn;
      currentState.pending_asns[payload.id] = asn;
      ctx.patchState({pending_asns: _.cloneDeep(currentState.pending_asns), current_asn: currentState.current_asn});
      ctx.dispatch(new actions.LoadAnsBundlesAction({asn_id: payload.id}));
    });
  }

  @Action(actions.AddPartToBundleAction)
  AddPartToBundle(ctx: StateContext<IExposState>,{payload}: actions.AddPartToBundleAction){
    global.log("State", `Adding serial to ASN, serial: ${payload.serial}`);
    this.expos_service.addPartToAsn(payload.asn_id, payload.serial,payload.bundle_id).subscribe(bundle => {
      if (!bundle['error']) {
        var currentState = ctx.getState();
        currentState.current_asn_bundles[bundle.id] = bundle;
        ctx.patchState({current_asn_bundles: _.cloneDeep(currentState.current_asn_bundles)})
      }
    })
  }

  @Action(actions.UpdateBundleAction)
  UpdateBundleAction(ctx: StateContext<IExposState>,{payload}: actions.UpdateBundleAction){
    global.log("State", `Update bundle #${payload.bundle.id}`);
    this.expos_service.updateBundle(payload.bundle).subscribe(bundle => {
      if (!bundle['errors'])
        var state = ctx.getState();
        state.current_asn_bundles[bundle.id] = _.cloneDeep(bundle);
        ctx.patchState({current_asn_bundles: state.current_asn_bundles});
    });
  }

  @Action(actions.LoadPagedDeliveryOrdersAction)
  loadPagedDeliveryOrders(ctx: StateContext<IExposState>, {payload}: actions.LoadPagedDeliveryOrdersAction) {
    global.log("State", "Getting paged delivery orders with status " + payload.status + " and page " + ''+payload.page || '0');
    this.expos_service.getPagedDeliveryOrders(payload.status, payload.page).subscribe((response: {totalRecords: number, data: {[id: number]: DeliveryOrder}}) => {
      let deliveries = _.cloneDeep(ctx.getState().deliveries);
      let total_deliveries = _.cloneDeep(ctx.getState().total_deliveries) || {};
      deliveries = deliveries || {};
      deliveries = _.omitBy(deliveries, (delivery: DeliveryOrder) => delivery.status == payload.status);
      total_deliveries[payload.status] = response.totalRecords;
      _.forEach(response.data, (delivery: DeliveryOrder) => {
        deliveries[delivery.id] = delivery;
      });
      ctx.patchState({deliveries: deliveries, total_deliveries: total_deliveries});
    });
  }

  @Action(actions.SetWorkingDeliveryOrderAction)
  setWorkingDeliveryOrder(ctx: StateContext<IExposState>, {payload}: actions.SetWorkingDeliveryOrderAction) {
    global.log("State", "Setting working delivery order with id " + payload.id);
    const state = ctx.getState();
    if (payload.id){
      let deliveries = _.cloneDeep(state.deliveries);
      let delivery = deliveries[payload.id];
      ctx.patchState({
        delivery_template: delivery,
        working_delivery_id: state.working_delivery_id == delivery.id ? null : delivery.id
      });
      this.expos_service.getDeliveryOrder(payload.id).subscribe((delivery: DeliveryOrder) => {
        ctx.patchState({delivery_template: delivery});
      });
    } else {
      ctx.patchState({delivery_template: new DeliveryOrder({})});
    }
  }

  @Action(actions.SaveDeliveryOrderAction)
  saveDeliveryOrder(ctx: StateContext<IExposState>, {payload}: actions.SaveDeliveryOrderAction) {
    global.log("State", "Saving delivery order");
    this.expos_service.saveDeliveryOrder(payload).subscribe(data => {
      let deliveries = _.cloneDeep(ctx.getState().deliveries);
      deliveries = deliveries || {};
      deliveries[data.id] = data;
      ctx.patchState({deliveries: deliveries});
      let delivery_template = _.cloneDeep(ctx.getState().delivery_template);
      if (delivery_template && delivery_template.id == data.id){
        ctx.patchState({delivery_template: data});
      }
    });
  }

  @Action(actions.LoadDeliveryImagesAction)
  loadDeliveryImages(ctx: StateContext<IExposState>, {payload}: actions.LoadDeliveryImagesAction) {
    global.log("State", "Loading delivery " + payload.type);
    this.expos_service.getDeliveryImages(payload.id, payload.type).subscribe(data => {
      let store = _.cloneDeep(ctx.getState());
      let deliveries = _.get(store, 'deliveries', {});
      let asns = _.get(store, 'asns', {});
      let asn = _.find(asns, (asn: Asn) => _.has(asn.delivery_orders, payload.id));
      let delivery = _.get(deliveries, payload.id);
      let delivery_images_preview = _.get(store, 'delivery_images_preview', []);
      let delivery_damages_preview = _.get(store, 'delivery_damages_preview', []);
      if(asn) {
      	  if(payload.type == 'damages') {
      	  	  asn.delivery_orders[payload.id].setDamages(data);
      	  	  delivery_damages_preview = asn.delivery_orders[payload.id].damages;
      	  } else {
			  asn.delivery_orders[payload.id].setImages(data);
			  delivery_images_preview = asn.delivery_orders[payload.id].images;
      	  }
		  asns[asn.id] = asn;
      }
      if(delivery) {
      	  if(payload.type == 'damages') {
	  	  	  delivery.setDamages(data);
			  deliveries[payload.id].setDamages(data);
	  	  	  delivery_damages_preview = delivery.damages;
	  	  } else {
			  delivery.setImages(data);
			  deliveries[payload.id].setImages(data);
			  delivery_images_preview = delivery.images;
		  }
      }
      ctx.patchState({deliveries: deliveries, asns: asns, delivery_images_preview: delivery_images_preview, delivery_damages_preview: delivery_damages_preview});
    });
  } 

  @Action(actions.GetPopsAction)
  getPops(ctx: StateContext<IExposState>) {
    global.log("State",`Get documents`)
    this.expos_service.getPops().subscribe((response) => {
      const docs = this.assignNewPopToSelectedDeliveryIfAny(ctx,response);
      ctx.patchState({ pops: docs });
    });
  }

  private assignNewPopToSelectedDeliveryIfAny(ctx: StateContext<IExposState>, docs: any) {
    let state = ctx.getState();
    if (state.working_delivery_id) {
      const new_doc = _(docs).find((f: Document) => !state.pops[f.id] );
      if (new_doc && !state.deliveries[state.working_delivery_id].pop) {
        ctx.dispatch(new actions.AssignPopToWorkingDeliveryAction({ document: new_doc }))
        delete docs[new_doc.id];
      }
    }
    return docs;
  }

  @Action(actions.DeletePopAction)
  deletePop(ctx: StateContext<IExposState>, {payload} : actions.DeletePopAction) {
    global.log("State",`Delete documents`)
    let documents = ctx.getState().pops;
    delete documents[payload];
    ctx.patchState({ pops: {...documents} });
    this.expos_service.deletePop(payload);
  }

  @Action(actions.AssignPopToWorkingDeliveryAction)
  AssignPopToWorkingDelivery(ctx: StateContext<IExposState>, action: actions.AssignPopToWorkingDeliveryAction) {
    global.log("State",`Assign Document To working Packing`)
    if (!action.payload.document)
      ctx.dispatch(new actions.SetWorkingDeliveryOrderAction({id: null}));
    let state = ctx.getState();
    if (state.working_delivery_id) {
      let working_delivery = state.deliveries[state.working_delivery_id];
      let document = action.payload.document != null && !(action.payload.document instanceof Document) ? new Document(action.payload.document) : action.payload.document;
      working_delivery.pop = document;
      ctx.patchState({ working_delivery_id: null });
      ctx.dispatch(new actions.SaveDeliveryOrderAction(working_delivery));
    }
  }

  @Action(actions.SetWorkingPopAction)
  SetWorkingPop(ctx: StateContext<IExposState>, { payload }: actions.SetWorkingPopAction) {
    ctx.patchState({ pop: payload.document });
  }

  static getPopFromWorkingDelivery() {
    return createSelector([ExposState], (state: IExposState) => {
      if (state.deliveries[state.working_delivery_id]) {
        return state.deliveries[state.working_delivery_id].pop;
      }
      return null;
    });
  }
}
