
import { PoSelectionDialogComponent } from '../../receiving/components/po-selection-dialog/po-selection-dialog.component';
import { ModalDialogService, ModalDialogOptions, Package } from 'wms-lib';
import { PurchaseOrderModalBaseService } from './purchase-order-modal.service.base'
import { Subject } from "rxjs";

export class PurchaseOrderModalService extends PurchaseOrderModalBaseService {
    onPOUpdated: Subject<void> = new Subject<void>();
    package: Package;

    constructor(private modal_service: ModalDialogService) {
        super();
    }

    displayCustomDialog() {
        let options: ModalDialogOptions = {
            params: {
                suggestions: this._suggestions,
                selected: this._selected
            },
            ng_options : {
                centered : true,
                size: 'lg',
                backdrop : 'static'
            }
        }

        this.modal_service.
            showModal(PoSelectionDialogComponent, options).
            then(dialog_result => {
                this.dialog_result = dialog_result;
            });
    }

    set value(val: string) {
        this.package.po = val;
        if(this.apply_all) this.package.copy_po();
        this.onPOUpdated.next();
    }

    get value() {
        return this.package.po;
    }
}
