import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { CatalogsService, Validable } from 'wms-lib';
import { Observable } from 'rxjs';

@Component({
    selector: 'app-history',
    templateUrl: './history.component.html',
    styleUrls: ['./history.component.scss']
})
export class HistoryComponent implements OnInit, OnChanges {
    @Input() element: Validable;
    records$: Observable<any[]>;
    display: boolean = false;
    constructor(private catalogsService: CatalogsService) {
    }

    ngOnInit() {

    }

    ngOnChanges() {
        this.records$ = this.catalogsService.getHistory(this.element.validationKey, this.element.id);
    }

    stringify(data: any) {
        return JSON.stringify(data);
    }

}
