import { GenericService } from "./generic.service";
import { Injectable, Inject, NgZone } from "@angular/core";
import { Store } from "@ngxs/store";
import { Carrier, Package, ArrivePackingList } from "../models";
import * as _ from 'lodash';
import { BehaviorSubject } from "rxjs";
import { HttpClient } from "@angular/common/http";
import { map, catchError } from "rxjs/operators";
import { NotificationService } from "../platform-services/notification.service";

const REGEXP_PATH = "regexps/codes";

@Injectable({
    providedIn: 'root'
})
export class CodeParserService extends GenericService {
    carriers: {[id: number]: Carrier} = {};
    codes$: BehaviorSubject<{[id: string]: RegExp}> = new BehaviorSubject<{[id: string]: RegExp}>({});

    constructor(@Inject('env') env, 
                http: HttpClient, 
                protected store: Store, 
                ng_zone: NgZone, 
                notificationService: NotificationService) {
        super(env, http, ng_zone,notificationService);
        this.store.select((state) => state.catalogsState.carriers).subscribe((a) => {
            this.carriers = _(a).values().keyBy("carrier_code").value()
        });
        this.http.get<any>(this.serverUrl + REGEXP_PATH, this.httpOptions).pipe(
            map((data: any) => {
                return _.mapValues(data, (v: string) => new RegExp(v, "i"));
            }),
            catchError(this.handleError<any>(' Getting Reg Exps', {}))
        ).subscribe((regs: {[id: string]: RegExp}) => {
            this.codes$.next(regs);
            if (this.codes["weight"])
                Package.WEIGHT_REGEXP = this.codes["weight"];
            if (this.codes["location"])
                ArrivePackingList.LOC_REGEXP = this.codes["location"];
        });
    }

    get codes(): {[id: number]: RegExp} {
        return this.codes$.value;
    }

    parseLocation(location_tag: string): any {
        try { return _.merge(this.codes["location_filter"].exec(location_tag).groups, {location_tag: location_tag}); }
        catch (e) {
            global.log("Error", e.message);
            return {};
        }
    }

    parseCode(code: string): any {
        try {
            let parsedLabel = { code: code, dense: false };
            let cc = this.codes["carrier"].exec(code);
            if (cc && cc.groups) {
                parsedLabel["dense"] = true;
                parsedLabel["carrier_code"] = _.findKey(cc.groups, (v: string) => v);
                let carrier_regex = this.carriers[parsedLabel["carrier_code"]].regexps;
                Object.keys(carrier_regex).forEach(key => {
                    let result = carrier_regex[key].exec(code);
                    if (key == "trk" && parsedLabel["carrier_code"] == "UPSN" && result && result[2]) 
                        parsedLabel[key] = result[2]+result[4]+result[1]+result[3];
                    else
                        parsedLabel[key] = ((result && result.find((f:string,i:number) => i > 0 && f))  || '').replace(//g," ");
                });
            } else {
                cc = this.codes["simple"].exec(code);
                parsedLabel["trk"] = code;
                if (cc && cc.groups && cc.groups['FDX'] && cc.groups['trk']) 
                    parsedLabel["simple_trk"] = cc.groups['trk'];
            }
            return parsedLabel;
        } catch (e){
            global.log("Error", e.message);
            return {};
        }
    }
}
