import { Component, OnInit} from '@angular/core';
import { Store } from '@ngxs/store';
import * as store_actions from 'wms-lib';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { Shipment, ReportService, Client, User, AuthenticationService } from 'wms-lib';
import { ActivatedRoute } from '@angular/router';
import { ConfirmationService } from 'primeng/api';
import { StateListenerComponent } from '../scan-listener/state-listener.component';
import { map } from 'rxjs/operators';
import {MenuItem} from 'primeng/api';


@Component({
  selector: 'app-shipments',
  templateUrl: './shipments.component.html',
  styleUrls: ['./shipments.component.scss']
})
export class ShipmentsComponent extends StateListenerComponent implements OnInit {
  items: {[id: number]: any};
  open: boolean;
  client_id: number = 0;
  images: number = null;
  totalRecords$: Observable<number>;
  paginating: boolean = false;
  usePartNumber$: Observable<boolean>;
  itemsMenu: MenuItem[];
  selectedChoice: Shipment;

  constructor(
    private router: Router,
    private store: Store,
    private authenticationService : AuthenticationService,
    protected activated_route: ActivatedRoute,
    private confirmationService: ConfirmationService,
    private reportService: ReportService) {
    super();
  }

  user : User;
  ngOnInit() {
    this.addSub(this.authenticationService.currentUser.subscribe((u: User) => {
      this.user = u;
    }));
    this.addSub(this.activated_route.data.subscribe((data: any) => {
      this.paginating = true;
      this.open = data.open;
      if (this.open) {
        this.store.dispatch(new store_actions.GetOpenShipmentsAction());
        this.addSub(this.store.select((state) => state.catalogsState.active_client).subscribe((client: Client) => this.client_id = client.id ));
      } else {
        this.addSub(this.store.select((state) => state.catalogsState.active_client).subscribe((client: Client) => {
          if (client) {
            this.client_id = client.id;
            this.store.dispatch(new store_actions.GetPagedShipmentsAction({client_id: this.client_id, page: 1, append: false}));
          }
        }));
      }
    }));
    this.addSub(this.store.select((state) => state.shipmentsState.shipments).subscribe((shipments: {[id: number]: Shipment}) => {
      this.items = shipments;
      this.paginating = false;
    }));
    this.totalRecords$ = this.store.select((state) => state.shipmentsState.totalRecords);
    this.usePartNumber$ = this.store.select((state) => state.catalogsState.active_client).pipe(
      map((u: Client) => u && u.part_number)
    );
    this.itemsMenu = [];        
  }

  buildMenu(shipment: Shipment): Observable<MenuItem[]> {
    return this.usePartNumber$.pipe(map(
      (usePartNumber: boolean)=> { 
        return [
          { label: 'Packings', icon: 'fas fa-file-download', url: '/shipments/' + shipment.id + '.pdf', target: '_blank' },
          { label: 'Picklist', icon: 'fas fa-clipboard-list', command: () => this.picklistRpt(shipment)},        
          { label: 'Shipment', icon: 'fas fa-truck', command: () => this.shipmentRpt(shipment), visible: shipment.haveComplementary },
          { label: 'Complementary', icon: 'fas fa-file-archive', command: () => this.complementaryRpt(shipment), visible: shipment.haveComplementary },  
          { label: 'Trans Report', icon: 'fas fa-passport', command: () => this.jzTransRpt(shipment), visible: usePartNumber },  
          { label: 'Manifest', icon: 'fas fa-file-invoice', command: () => this.manifestRpt(shipment), visible: shipment.haveInbond },
          { label: 'Images', icon: 'fa fa-camera', command: () => this.showImages(shipment), visible: !!shipment.shipped_id },
          { label: 'Edit', icon: 'pi pi-pencil', command: () => this.edit(shipment), visible: this.canEditShipped(shipment) },
          { label: 'Delete', icon: 'pi pi-trash', command: () => this.confirmAndDeleteArrive(shipment), visible: this.canDelete(shipment) }
        ]
      }
    ));
  }  

  showMenu(event: any, shipment: Shipment, menu: any) {    
    this.buildMenu(shipment).subscribe((m: MenuItem[])=>{
      this.itemsMenu = m;
      menu.toggle(event);
    });   
  }

  get readOnly(): boolean{
    return this.user.isReadOnly;
  }

  edit(shipment?: Shipment) {
    this.router.navigate(['shipments', shipment ? shipment.id : 0]);
  }

  packings(s: Shipment) {
    window.open(`/shipments/${s.id}.pdf`, '_blank');
  }

  picklistRpt(s: Shipment) {
    this.reportService.openReport("OrderFulfillmentReport", {id: s.id, "__parameterpage": false});
  }

  complementaryRpt(s: Shipment) {
    this.reportService.openReport("ComplementaryReport", {id: s.id, "__parameterpage": false});
  }

  shipmentRpt(s: Shipment) {
    this.reportService.openReport("ShippingReport", {id: s.id, "__parameterpage": false});
  }

  jzTransRpt(s: Shipment) {
    this.reportService.openReport("jz_trans", {id: s.id, "__parameterpage": false});
  }

  manifestRpt(s: Shipment) {
    this.reportService.openReport("inbond_manifest", {id: s.id, "__parameterpage": false});
  }

  showImages(s: Shipment){
    if (!s.images || s.images.length == 0)
      this.store.dispatch(new store_actions.LoadImagesAction(s.id));
    this.images = s.id;
  }

  canEditShipped(s: Shipment): boolean {
    return !s.isShipped || (s.isShipped && this.authenticationService.currentUserValue.isSupervisor);
  }

  lazyLoad(event: any) {
    let page = (event.first/event.rows)+1;
    this.paginating = true;
    this.store.dispatch(new store_actions.GetPagedShipmentsAction({client_id: this.client_id, page: page, append: false}));
  }

  canDelete(s: Shipment): boolean {
    return this.authenticationService.currentUserValue.isSupervisor;
  }

  confirmAndDeleteArrive(a: Shipment) {
    this.confirmationService.confirm({
      message: 'Are you sure that you want to perform this action?',
      accept: () => this.delete(a)
    });
  }

  delete(s: Shipment) {
    this.store.dispatch(new store_actions.DeleteShipmentAction(s));
  }
}
