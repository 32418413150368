import { Component } from '@angular/core';
import { GoodCategory, Client, Unit } from 'wms-lib';
import { CatalogComponent } from '../../base-components/catalog.component';
import * as actions from 'wms-lib';

@Component({
    selector: 'app-units',
    templateUrl: './units.component.html'
})
export class UnitsComponent extends CatalogComponent<Unit> {
    name_catalog = 'units';
    active_client: Client;

    ngOnInit(): void {
        super.ngOnInit();
        this.store.select(state => state.catalogsState.active_client).subscribe(client => this.active_client = client );
    }

    addNew(_type?: string) {
        let c = this.newItem({name_es: '', name_en: '', type: _type});
        this.items[c.id] = c;
        this.items = {...this.items};
    }

    newItem(data: any) {
        return new Unit(data);
    }
}

