import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngxs/store';
import { Client, ModalDialogState } from 'wms-lib';
import { ClientSelectionDialogBaseComponent } from './client-selection-dialog.component.base'
@Component({
  selector: 'app-client-selection-dialog',
  templateUrl: './client-selection-dialog.component.html',
  styleUrls: ['./client-selection-dialog.component.scss']
})
export class ClientSelectionDialogComponent extends ClientSelectionDialogBaseComponent implements OnInit {
  constructor(protected store: Store,
      protected readonly change_detector_ref: ChangeDetectorRef,
      private modalState: ModalDialogState) {
      super(store,change_detector_ref);
  }

  ngOnInit() {        
      this.params_context = this.modalState.params;
      super.ngOnInit();
  }

  select(client: Client) {
      if (this.params_context.pl && this.params_context.pl.client_id) 
          super.select(client);
      else 
        this.modalState.closeCallBack(client);    
  }

  save() {
      super.save();
      this.modalState.closeCallBack(this.selected_client);
  }

  cancel() {
      this.modalState.closeCallBack(null);
  }

  accept() {
      this.modalState.closeCallBack(this.selected_client);
  }

}