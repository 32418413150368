import { Component, OnInit, NgZone, Output, EventEmitter, Input, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { Warehouse, Location, CyclicalInventory, Client, User, GetCyclicalInventoriesAction, Tag, AuthenticationService, OnStockStatus, PackageStatuses, ReportService } from 'wms-lib';
import * as actions from 'wms-lib';
import * as _ from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { ConfirmationService } from 'primeng/api';
import { Table } from 'primeng/table'
import {MenuItem} from 'primeng/api';

@Component({
	selector: 'app-inventory-cyclic',
	templateUrl: './inventory-cyclic.component.html',
	styleUrls: ['./inventory-cyclic.component.scss']
})
export class InventoryCyclicComponent implements OnInit {
	@ViewChild('dt', { static: false }) table: Table
	clients: {[id: number]: Client};
	users: {[id: number]: User};
	locations: {[id: number]: Location};
	warehouses: {[id: number]: Warehouse};
    isLoading$: Observable<{ [action: string]: boolean }>;
	statuses: any[] = [];
	typeOptions: any[] = [];
    cyclical_inventories: {[id: number]: CyclicalInventory} = {};
    displayTags: boolean = false;
    selectedCyclicalInventory: CyclicalInventory;
	itemsMenu: MenuItem[];
    totalRecords$: Observable<number>;

	constructor(private store: Store, protected authService: AuthenticationService, private confirmationService: ConfirmationService, private reportService: ReportService
) { }

	ngOnInit(): void {
		this.isLoading$ = this.store.select(state => state.inventoryState.isLoading);
		this.store.dispatch(new actions.GetCatalogAction<Client>({name_catalog: 'clients', live: false}));
		this.store.dispatch(new actions.GetCatalogAction<Location>({name_catalog: "locations", live: false}));
		this.store.dispatch(new GetCyclicalInventoriesAction({query: {page: '1'}}));
		this.store.dispatch(new actions.GetUsersAction());
		this.store.select(state => state.catalogsState.locations).subscribe(data => this.locations = data);
		this.store.select(state => state.catalogsState.warehouses).subscribe(data => this.warehouses = data);
		this.store.select(state => state.catalogsState.users).subscribe(data =>  this.users = _.keyBy(data, 'id') );
		this.store.select(state => state.inventoryState.cyclicals).subscribe(data => {
			if(data) {
				this.cyclical_inventories = _.cloneDeep(data);
			}
			if(this.selectedCyclicalInventory) {
				this.selectedCyclicalInventory = this.cyclical_inventories[this.selectedCyclicalInventory.id];
			}
		});
		this.totalRecords$ = this.store.select(state => state.inventoryState.totalRecords['cyclical'] || 0);

		combineLatest( this.store.select(state => state.catalogsState.clients), this.authService.currentUser)
		.subscribe(([clients, user]) => {
			this.clients = _.pick(clients, user.client_ids);
		});
		this.buildOptionItems();
	}

	new(cyclic?: CyclicalInventory) {
		let item = cyclic || new CyclicalInventory({});
		this.cyclical_inventories[item.id] = item;
		this.cyclical_inventories = {...this.cyclical_inventories};
	}

	save(item: CyclicalInventory) {
		this.store.dispatch(new actions.SaveCyclicalInventoryAction(item));
	}

	delete(item: CyclicalInventory) {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to delete this cyclical inventory?',
            accept: () => {
				if(item.isNew) {
					delete this.cyclical_inventories[item.id];
					this.cyclical_inventories = {...this.cyclical_inventories};
				} else { 
					this.store.dispatch(new actions.DeleteCyclicalInventoryAction(item));
				}
            }
        });
	}

	close(item: CyclicalInventory) {
		const isOpen = item.closed_by_id === null;
		this.confirmationService.confirm({
			message: `Are you sure you want to ${isOpen ? 'close' : 'reopen'} this cyclical inventory?`,
			accept: () => {
				this.store.dispatch(new actions.OpenCloseCyclicalInventoryAction({cyclical: item, open: !isOpen}));
			}
		});
	}

	toggleActive(item: CyclicalInventory) {
		this.confirmationService.confirm({
			message: `Are you sure you want to ${item.active ? 'deactivate' : 'activate'} this cyclical inventory?`,
			accept: () => {
				item.active = !item.active;
				this.store.dispatch(new actions.ToggleActiveCyclicalInventoryAction(item));
			}
		});
	}

	showTags(item: CyclicalInventory) {
		if(item.total_tags > 0) {
			this.selectedCyclicalInventory = item;
			this.displayTags = true;
			this.store.dispatch(new actions.GetCyclicalInventoryTagsAction(item));
		}
	}

	buildOptionItems() {
		this.statuses = _.chain(PackageStatuses)
		.pickBy((value) => OnStockStatus.includes(value))
		.map((value, key) => ({ label: _.startCase(key), value: value }))
		.value();
		this.typeOptions = [
			{ label: 'All', value: null },
			{ label: 'Parcel', value: 'ParcelArrival' },
			{ label: 'Freight', value: 'FreightArrival'},
		];
	}

	viewReport(item: CyclicalInventory) {
		this.reportService.openReport("inventory_cyclic_report", {id: item.id, "__parameterpage": false});
	}

	initTableEdit(item: CyclicalInventory) {
		item.isEditing = true;
		this.table.initRowEdit(item);
	}

	cancelTableEdit(item: CyclicalInventory, htre: any) {
		item.isEditing = false;
		this.table.saveRowEdit(item, htre);
	}


    get canEdit(): boolean {
        return this.authService.currentUserValue.isSupervisor;
    }

	buildMenu(item: CyclicalInventory, htre):MenuItem[]{
		return [
			{ label: 'Edit', icon: 'pi pi-pencil', command: () => this.initTableEdit(item) , visible: item.isNew && !item.isEditing },
				{ label: 'Save', icon: 'pi pi-check', command: () => this.save(item), visible: item.isNew && item.isEditing, disabled: !(item.warehouse_id && item.size && item.size <= 1) },
				{ label: 'Cancel', icon: 'pi pi-times', command: () => this.cancelTableEdit(item, htre), visible: item.isNew && item.isEditing },
				{ label: 'View report', icon: 'fas fa-file-alt', command: () => this.viewReport(item), visible: !item.isNew },
				{ label: item.active ? 'Deactivate' : 'Activate', icon: item.active ? 'fas fa-toggle-on' : 'fas fa-toggle-off', command: () => this.toggleActive(item), visible: (!item.isNew && this.canEdit) },
				{ label: item.isOpen ? 'Close' : 'Reopen', icon: item.isOpen ? 'fas fa-lock' : 'fas fa-lock-open', command: () => this.close(item), visible: (!item.isNew && this.canEdit) },
				{ label: 'Delete', icon: 'pi pi-trash', command: () => this.delete(item), visible: this.canEdit },
		]
	}  

	showMenu(event: any, cyclical: CyclicalInventory, menu: any, htmlTableRowElement: any) {    
		this.itemsMenu = this.buildMenu(cyclical, htmlTableRowElement);
		menu.toggle(event);
	}

    lazyLoad(event: any) {
        let page = (event.first/event.rows)+1;
		this.store.dispatch(new GetCyclicalInventoriesAction({query: {page}}));
    }
  
}

