import { Component, OnInit, NgZone, Output, EventEmitter, Input} from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { NotificationService, CodebarReaderService, ModalDialogService, AuthenticationService } from 'wms-lib';
import { PackageComponent } from '../../../shared/components/base-components/package.component'

@Component({
    selector: 'app-inventory-package',
    templateUrl: './inventory-package.component.html',
    styleUrls: ['../../../shared/components/base-components/package.component.scss'],
})
export class InventoryPackageComponent extends PackageComponent implements OnInit {
    constructor(
        protected store: Store,
        public notification_service: NotificationService,
        protected codebar_service: CodebarReaderService,
        protected ng_zone: NgZone,
        protected modal_service: ModalDialogService,
        protected _router: Router,
        protected authenticationService : AuthenticationService
    ) {
        super(store,notification_service,codebar_service,ng_zone,modal_service,_router, authenticationService);       
    }  
    
}
