import { CatalogComponent } from './catalog.component';
import { ICatalogLogo } from 'wms-lib';

export abstract class CatalogLogoComponent<T extends ICatalogLogo> extends CatalogComponent<T>  {
    attachIcon(event: any, carrier: T) {
        let reader = new FileReader();
        reader.onloadend = () => carrier.logo_url = <string>reader.result;
        reader.readAsDataURL(event.srcElement.files[0]);
    }

    attachTemplate(event: any, carrier: T) {
        let reader = new FileReader();
        reader.onloadend = () => carrier.pop_url = <string>reader.result;
        reader.readAsDataURL(event.srcElement.files[0]); 
    }
}
