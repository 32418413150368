import { DeliveryOrderAttachmentsComponent } from '../../../../shared/components/base-components/delivery-order-attachments.component';
import { Component, OnInit } from '@angular/core';
import { Forward, NotificationService, Catalog, Asn, Address, DeliveryOrder, Bundle, Part, CodebarReaderService, AuthenticationService, ExposService } from 'wms-lib';
import { Store } from '@ngxs/store';
import { Observable, combineLatest } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import * as actions from 'wms-lib';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-asn-detail',
  templateUrl: './asn-detail.component.html',
  styleUrls: ['./asn-detail.component.scss']
})
export class AsnDetailComponent extends DeliveryOrderAttachmentsComponent implements OnInit {
  bundle_types$: Observable<{[id: number]: Catalog }>;
  priorities$: Observable<{[id: number]: Catalog }>;
  countries$: Observable<{[id: number]: Catalog }>;
  carriers$: Observable<{[id: number]: Catalog }>;
  forwards: {[id: number]: Forward };
  addresses: {[id: number]: Address };
  selectedBundle: Bundle = null;
  editedDeliveries: {[id: number]: DeliveryOrder } = {};
  editedBundles: {[id: number]: Bundle } = {};
  editedParts: {[id: number]: Part } = {};
  asn: Asn;
  client_id: number = 0;
  forward?: Forward;
  address?: Address;
  selectedDeliveryOrder: DeliveryOrder;
  address_cols = [
    { field: 'address1', header: 'Address 1' },
    { field: 'address2', header: 'Address 2' },
    { field: 'address3', header: 'Address 3' },
    { field: 'contact_name', header: 'Contact Name' },
    { field: 'phone', header: 'Phone' },
    { field: 'email', header: 'Email' },
    { field: 'city', header: 'City' },
    { field: 'state', header: 'State' },
    { field: 'zip', header: 'Zip' },
    { field: 'country_id', header: 'Country' }
  ];

  constructor(
    private router: Router,
    protected store: Store,
    protected notificationService: NotificationService,
    private activated_route: ActivatedRoute,
    protected codebar_service: CodebarReaderService,
    protected authenticationService: AuthenticationService
  ) {
    super();
  }

  ngOnInit() {
    this.store.dispatch(new actions.LoadForwardsAction());
    this.bundle_types$ = this.store.select(state => state.catalogsState.bundle_types);
    this.priorities$ = this.store.select(state => state.catalogsState.priorities);
    this.carriers$ = this.store.select(state => state.catalogsState.carriers).pipe(
      map( (carriers: {[id: number]: Catalog }) => _(carriers).filter((carrier) => carrier.type == 'ForwardCarrier').keyBy('id').value()
    ));
    this.countries$ = this.store.select(state => state.catalogsState.countries);
    this.activated_route.params.subscribe((params: any) =>{
      this.asn = null;
      this.store.dispatch(new actions.LoadAsnAction(params.id));
    });

    combineLatest(
      this.authenticationService.currentUser,
      this.store.select((state) => state.catalogsState.active_client),
      this.store.select(state => state.exposState.forwards),
      this.store.select(state => state.exposState.asn_template)).subscribe(([currentUser,client, forwards, asn]) => {
        this.client_id = client.id;
        this.forwards = _.filter(forwards, (forward) => forward.client_id == this.client_id);
        this.addresses = _(this.forwards).map((forward) => forward.address).keyBy('id').value();
        if(asn) {
          if(asn.id < 0){
            asn.client_id = this.client_id;
            asn.warehouse_id = currentUser.warehouse_id;
          }
          this.asn = asn;
          this.asn.takeSnapshoot();
        }
      });
  }

  onSubmit() {

      this.store.dispatch(new actions.SaveAsnAction({asn: this.asn}))
      this.addSub(this.store.select(state => state.exposState.asn_template).subscribe(asn => {
        if(!asn) {
          this.onCancel();
        }
      }
      ));

  }

  onCancel() {
    this.router.navigate(['exports','asns']);
  }

  viewBundleParts(bundle: Bundle) {
    this.selectedBundle = bundle;
  }

  pickForwardByAddress(address_id): Forward {
    return _.find(this.forwards, (forward) => (!!forward.address_id && forward.address_id == address_id));
  }

  addDelivery(delivery?: DeliveryOrder) {
    this.selectedDeliveryOrder = null;
    delivery = delivery || new DeliveryOrder({});
    this.asn.addOrUpdateDeliveryOrder(delivery);
  }

  addBundle(delivery: DeliveryOrder) {
    let bundle = new Bundle({});
    delivery.addOrUpdateBundle(bundle);
  }

  addPart(bundle: Bundle) {
    let part = new Part({});
    bundle.addOrUpdatePart(part);
  }

  onRowEditDeliveryInit(delivery: DeliveryOrder) {
    this.selectedDeliveryOrder = null;
    this.editedDeliveries[delivery.id] = _.cloneDeep(delivery);
  }

  onRowEditBundleInit(bundle: Bundle) {
    this.editedBundles[bundle.id] = _.cloneDeep(bundle);
  }

  onRowEditPartInit(part: Part) {
    this.editedParts[part.id] = _.cloneDeep(part);
  }

  onRowEditDeliverySave(delivery: DeliveryOrder) {
    let del = this.editedDeliveries[delivery.id];
    this.asn.addOrUpdateDeliveryOrder(del);
    delete this.editedDeliveries[delivery.id];
  }

  onRowEditDeliveryCancel(delivery: DeliveryOrder) {
    delete this.editedDeliveries[delivery.id];
  }

  onRowEditBundleSave(bundle: Bundle) {
    let bun = this.editedBundles[bundle.id];
    this.asn.delivery_orders[bundle.delivery_order_id].addOrUpdateBundle(bun);
    delete this.editedBundles[bundle.id];
  }

  onRowEditBundleCancel(bundle: Bundle) {
    delete this.editedBundles[bundle.id];
  }

  onRowEditPartSave(part: Part) {
    let par = this.editedParts[part.id];
    this.selectedBundle.addOrUpdatePart(par);
    delete this.editedParts[part.id];
  }

  onRowEditDeliveryDelete(delivery: DeliveryOrder) {
    this.notificationService.showFeedback(
      'Are you sure you want to delete ' + delivery.customer_order + '?',
      'Confirm',
      false,
      null,
      () => this.asn.deleteDeliveryOrder(delivery.id)
    );
  }

  onRowEditBundleDelete(bundle: Bundle) {
    this.notificationService.showFeedback(
      'Are you sure you want to delete ' + bundle.bundle_number + '?',
      'Confirm',
      false,
      null,
      () => this.asn.delivery_orders[bundle.delivery_order_id].deleteBundle(bundle.id)
    );
  }

  onRowEditPartDelete(part: Part) {
    this.notificationService.showFeedback(
      'Are you sure you want to delete ' + part.serial_number + '?',
      'Confirm',
      false,
      null,
      () => this.selectedBundle.deletePart(part.id)
    );
  }

  onRowEditPartCancel(part: Part) {
    delete this.editedParts[part.id];
  }

  partsOverlayClose(){
    this.selectedBundle = null;
    this.editedParts = {};
  }

  previewAddress(address_id: number, overlay: any, event: any) {
    this.address = this.addresses[address_id];
    overlay.toggle(event);
  }

  onRowSelectDelivery(event: any) {
    if(event.data.id > 0 && !this.editedDeliveries[event.data.id]) {
      this.selectedDeliveryOrder = event.data;
    } else {
      this.selectedDeliveryOrder = null;
    }
  }

  onRowUnselectDelivery(event: any) {
    this.selectedDeliveryOrder = null;
  }

  print(bundle: Bundle) {
    try {
      this.codebar_service.sendToPrinter([bundle.zpl])
    } catch (e) {
      this.notificationService.toast(e.name + ': ' + e.message);
    }
  }

  deliveryColor(delivery: DeliveryOrder): string {
    switch (this.asn.status) {
      case 'transit':
        return '';
      case 'working':
        return delivery.colorSituation;
      case 'ready':
        return delivery.colorSituation;
      case 'closed':
        return delivery.colorSituation;
      default:
        return '';
    }
  }

  getDelivery(delivery_id: number): DeliveryOrder {
    return _.find(this.asn.delivery_orders, (delivery) => delivery.id == delivery_id);
  }

  getDeliveryFromBundle(bundle_id: number): DeliveryOrder {
    return _.find(this.asn.delivery_orders, (delivery) => _.find(delivery.bundles, (bundle) => bundle.id == bundle_id));
  }

  getDeliveryFromPart(part_id: number): DeliveryOrder {
    return _.find(this.asn.delivery_orders, (delivery) => _.find(delivery.bundles, (bundle) => _.find(bundle.parts, (part) => part.id == part_id)));
  }

  isEditingDelivery(id: number): boolean {
    return !this.candEditDelivery(id) || _.size(this.editedDeliveries) > 0 && !!this.editedDeliveries[id];
  }

  isEditingSomeBundle(delivery_id: number): boolean {
    return !this.candEditDelivery(delivery_id) || _.size(this.editedBundles) > 0 && _.some(this.editedBundles, (bundle) => bundle.delivery_order_id == delivery_id);
  }

  isEditingSomePart(bundle_id: number): boolean {
    let delivery = this.getDeliveryFromBundle(bundle_id);
    return !this.candEditDelivery(delivery.id) || _.size(this.editedParts) > 0 && _.some(this.editedParts, (part) => part.bundle_id == bundle_id);
  }

  isEditingBundle(id: number): boolean {
    let delivery = this.getDeliveryFromBundle(id);
    return !this.candEditDelivery(delivery.id) || _.size(this.editedBundles) > 0 && !!this.editedBundles[id] || this.isEditingDelivery(delivery.id);
  }

  candEditDelivery(delivery_id: number): boolean {
    let delivery = this.getDelivery(delivery_id);
    return !delivery.ready_to_ship || (delivery.ready_to_ship && this.authenticationService.currentUserValue.isSupervisor)
  }

}
