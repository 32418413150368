import { Address, Asn, Forward, Bundle, Part, DeliveryOrder } from "../../models";

export class LoadForwardsAction {
  public static readonly type = '[Forwards] Load Forwards';
  constructor() { }
}

export class SaveForwardAction {
  public static readonly type = '[Forwards] Save Forward';
  constructor(public payload: { forward: Forward}){}
}

export class DeleteForwardAction {
  public static readonly type = '[Forwards] Delete Forward';
  constructor(public payload: { id: number}){}
}

export class loadAddressesAction {
  public static readonly type = '[Addresses] Load Addresses';
  constructor() { }
}

export class SaveAddressAction {
  public static readonly type = '[Addresses] Save Address';
  constructor(public payload: { address: Address}){}
}

export class ImportForwardsAction {
  public static readonly type = '[Forwards] Import Forwards';
  constructor(public payload: { forwards: Forward[]}){}
}

// ASNS
export class LoadAsnsAction {
  public static readonly type = '[Asns] Load Asns';
  constructor() { }
}

export class LoadAsnsByStatusAction {
  public static readonly type = '[Asns] Load Asns by Status';
  constructor(public payload: { status: string, page?: number}) { }
}

export class LoadAsnAction {
  public static readonly type = '[Asns] Load Asn by ID';
  constructor(public payload: number) { }
}

export class SaveAsnAction {
  public static readonly type = '[Asns] Save Asn';
  constructor(public payload: { asn: Asn }){}
}

export class DeleteAsnAction {
  public static readonly type = '[Asns] Delete Asn';
  constructor(public payload: number){}
}

export class LoadNotReceivedAsnsAction {
  public static readonly type = '[Exports] Load Not Received ASNS';
  constructor(public payload: { type: string }) { }
}

export class LoadAnsBundlesAction {
  public static readonly type = '[Exports] Load ANS Bundles';
  constructor(public payload: { asn_id: number}) { }
}

export class SetCurrentAsnAction {
  public static readonly type = '[Exports] Set current ASN';
  constructor(public payload: { asn: Asn}) { }
}

export class ToggleAsnBundlePartReceivedAction {
  public static readonly type = '[Exports] Set bundle part as received';
  constructor(public payload: {part: Part}) { }
}

export class LoadDeliveryImagesAction {
  public static readonly type = '[Exports] Load Delivery Images';
  constructor(public payload: {id: number, type: string}) { }
}


export class CloseAsnAction {
  public static readonly type = '[Exports] Close ASN';
  constructor(public payload: {id: number}) { }
}

export class ResetAsnScanAction{
  public static readonly type = '[Exports] Reset ASN scan';
  constructor(public payload: {id: number}) { }
}

export class LoadPagedDeliveryOrdersAction {
    public static readonly type = '[DeliveryOrders] Load Paged Delivery Orders';
    constructor(public payload: { status: string, page?: number}){}
}

export class SetWorkingDeliveryOrderAction {
    public static readonly type = '[DeliveryOrders] Set Working Delivery Order';
    constructor(public payload: { id: number}){}
}

export class SaveDeliveryOrderAction {
    public static readonly type = '[DeliveryOrders] Save Delivery Order';
    constructor(public payload: DeliveryOrder){}
}

export class AddPartToBundleAction {
  public static readonly type = '[Exports] Add serial part to a bundle';
  constructor(public payload: { serial: string, asn_id: number, bundle_id?: number }) {

  }
}


export class UpdateBundleAction {
  public static readonly type = '[Exports] Update bundle';
  constructor(public payload: { bundle: Bundle }) { }
}

export class GetPopsAction {
  public static readonly type = '[Exports] Get pops';
  constructor() { }
}

export class DeletePopAction {
  public static readonly type = '[Exports] Delete pop';
  constructor(public payload: number) { }
}

export class AssignPopToWorkingDeliveryAction {
  public static readonly type = '[Exports] Set Selected pop';
  constructor(public payload: { document?: Document | any , keep_working_delivery_id?: boolean} = { keep_working_delivery_id: false }) { }
}

export class SetWorkingPopAction {
  public static readonly type = '[Exports] Set working pop';
  constructor(public payload: { document: Document | any }) { }
}

export class ClearCurrentAsnBundlesAction {
  public static readonly type = '[Exports] Clear current asn bundles';
  constructor() { }
}

export type ExposActions = LoadForwardsAction |
  SaveForwardAction |
  DeleteForwardAction |
  loadAddressesAction |
  SaveAddressAction |
  LoadAsnsAction |
  LoadAsnAction |
  SaveAsnAction |
  DeleteAsnAction |
  LoadNotReceivedAsnsAction |
  LoadAnsBundlesAction |
  SetCurrentAsnAction |
  ToggleAsnBundlePartReceivedAction |
  CloseAsnAction |
  ResetAsnScanAction |
  LoadPagedDeliveryOrdersAction |
  SaveDeliveryOrderAction |
  AddPartToBundleAction |
  UpdateBundleAction |
  GetPopsAction |
  DeletePopAction |
  AssignPopToWorkingDeliveryAction |
  SetWorkingPopAction |
  ClearCurrentAsnBundlesAction;
