import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-palletize',
  templateUrl: './palletize.component.html',
  styleUrls: ['./palletize.component.scss']
})
export class PalletizeComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
