import { Bindable, Client } from '../models';

export class InventoryFilter extends Bindable {
    location_id: number;
    private _location_tag: string;
    scope: string;
    location_code: string;
    column: number;
    level: number;
    regexp: RegExp;
    client: Client;

    constructor(data: any) {
        super(data);
        if (this.client && !(this.client instanceof Client)) {this.client = new Client(this.client); }
        //TODO: Creo que se puede refactorizar a usar la expresion que viene del backend
        this.regexp = new RegExp(
            '^' +
                (this.scope ? this.scope : '(?<scope>\\w)') +
                (this.location_code ? this.location_code : '(?<location_code>.+)') + '-' +
                (this.level ? this.level : '(?<level>.+)') + '-' +
                (this.column ? this.column : '(?<column>.+)') + '$', "i");
    }

    get client_id() {
        return this.client && this.client.id;
    }

    get isAtomic(): boolean {
        return !!this.location_code && !!this.column && !!this.level;
    }

    get location_tag(): string {
        if (this._location_tag)
            return this._location_tag;
        else if (this.scope && this.location_code && this.level && this.column)
            return `${this.scope}${this.location_code}-${this.level}-${this.column}`
        else
            return null;
    }

    set location_tag(value: string) {
        this._location_tag = value;
    }
}
