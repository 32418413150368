import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { GetPackingsAction, Document, ArrivePackingList, Shipment, PackingsFilter, DamageReport, Client } from 'wms-lib';
import { ActivatedRoute } from '@angular/router';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { ReceiptsPackingListsComponent } from './receipts-packing-lists/receipts-packing-lists.component';
import * as _ from 'lodash';
import { StateListenerComponent } from '../../../shared/components/scan-listener/state-listener.component';

@Component({
  selector: 'app-receipts',
  templateUrl: './receipts.component.html',
  styleUrls: ['./receipts.component.scss']
})
export class ReceiptsComponent extends StateListenerComponent implements OnInit {
  private _filter: PackingsFilter;
  private _client_sub: Subscription
  damage_report: DamageReport;
  @ViewChild('apls', { static: false }) apls: ReceiptsPackingListsComponent;
  @Input() selectable: boolean = false;
  @Input() title: string;
  @Input() group_by: string;
  @Input() scrollable: boolean = false;
  @Input() set filter(value: PackingsFilter) {
    this._filter = value;
    if (this._client_sub) this._client_sub.unsubscribe();
    this._client_sub = this.addSub(this.store.select((state) => state.catalogsState.active_client).subscribe((client: Client) => {
      if (client) this._filter.client_id = client.id;
      if (this._filter.page && this.apls) this.apls.reset();
      this.store.dispatch(new GetPackingsAction(this._filter));
    }));
  }
  get filter(): PackingsFilter {
    return this._filter;
  }
  @Input() needShipment: boolean = false;
  @Input() canOrderToShipment: boolean | null = null;

  pls: ArrivePackingList[];
  totalRecords$: Observable<number>;
  selectedDoc: Document;
  todo: boolean = false;
  clasify: boolean = false;

  constructor(private store: Store, private activated_route: ActivatedRoute) { super(); }

  ngOnInit() {
    this.addSub(this.activated_route.data.subscribe((data: any) => {
      if (data.filter) {
        data.filter.client_id = null;
        this.filter = data.filter;
      }
      if (data.title) this.title = data.title;
      if (data.todo) this.todo = data.todo;
      if (data.clasify) this.clasify = data.clasify;
    }));
    this.addSub(combineLatest(
      this.store.select(state => state.shipmentsState.packing_lists),
      this.store.select(state => state.shipmentsState.shipment_template)
    ).subscribe(([pls,ship]: [ArrivePackingList[], Shipment]) => {
      if (pls && ship)
        this.pls = _(ship.arrive_packing_lists).values().unionBy(pls, 'id').value();
      else if (pls && !this.needShipment)
        this.pls = pls;
    }));
    this.totalRecords$ = this.store.select(state => state.shipmentsState.total_packings);
  }

  loadLazyPLs(event: any){
    this._filter.page = (event.first/event.rows)+1;
    this.store.dispatch(new GetPackingsAction(this._filter));
  }

  ngOnDestroy() {
    if (this._client_sub) this._client_sub.unsubscribe();
  }

  get filter_errors() : string[] {
    if (this.pls && this.filter) {
      let pls_id = this.pls.map((pl: ArrivePackingList) => pl.receipt_id);
      return this.filter.receipts_array.filter((f: string) => !pls_id.includes(f) );
    } else
      return []
  }

}
