import { Component, OnInit, Input, ViewChild, OnChanges} from '@angular/core';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import { ArrivePackingList, Client, CodebarReaderService, ContainerType, NotificationService, Package, Pallet } from 'wms-lib';


@Component({
    selector: 'pallet-dialog-list',
    templateUrl: './pallets-dialog-list.component.html',
    styleUrls: ['../../../../shared/components/base-components/packing-lists.component.scss']
})
export class PalletDialogListComponent implements OnInit, OnChanges {
    @Input() apl: ArrivePackingList = undefined;
    container_types: {[id: number]: ContainerType}
    pallet_list: Pallet[] = [];
    clients: {[id: number]: Client} = {};
    constructor(private store: Store, protected codebar_service: CodebarReaderService, protected notification_service: NotificationService,) {}

    ngOnInit(): void {
        this.store.select(state => state.catalogsState.container_types).subscribe((data: {[id: number]: ContainerType}) => { 
            this.container_types = data;
        });
        this.pallet_list = this.palletList();

        this.store.select(state => state.catalogsState.clients).subscribe((data: {[id: number]: Client}) => {
            this.clients = data;
        });

    }

    ngOnChanges(changes: any) {
        this.apl = changes.apl.currentValue;
        this.pallet_list = this.palletList();
    }

    palletList(): Pallet[] {
        if (!this.apl.packagesWithPallets.length) return [];
        let pallets = _(this.apl.packagesWithPallets).groupBy((p: Package) => p.pallet_id).map((p: Package[]) => {
            return _.first(p).pallet
        }).value();
        return pallets;
    }

    print(pallet: Pallet) {
        let client_id = _.first(pallet.packages).client_id;
        let client_name = this.clients[client_id].name;
        try {
            this.codebar_service.sendToPrinter([pallet.zpl_arrival(client_name)]);
        } catch (e) {
            this.notification_service.toast(e.name + ': ' + e.message);
        }
    }
}
