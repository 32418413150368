import { Injectable } from '@angular/core';
import { NgbModal, NgbModalRef, NgbModalOptions } from '@ng-bootstrap/ng-bootstrap';

@Injectable({
  providedIn: 'root'
}) 
export class ModalDialogState {
  params: any;
  modal: NgbModalRef;

  closeCallBack(result:any){
    this.modal.close(result);
  }
}

export class ModalDialogOptions{
  ng_options?: NgbModalOptions;
  params:any;
}

@Injectable({
  providedIn: 'root'
}) 
export class ModalDialogService  {
  DEFAULT_NG_MODAL_OPTIONS : NgbModalOptions ={
    centered : true,
    size: 'sm',
    backdrop : 'static'
  }

  constructor(private ngModalService: NgbModal, private modalState:ModalDialogState) {
  }

  showModal(content: any, modal_options: ModalDialogOptions): Promise<any>{
    this.modalState.params = modal_options.params;
    this.modalState.modal = this.ngModalService.open(content, modal_options.ng_options || this.DEFAULT_NG_MODAL_OPTIONS);
    return this.modalState.modal.result;
  }
}

