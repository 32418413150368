import { ActivatedRoute } from "@angular/router";
import { Store } from "@ngxs/store";
import { DamagesBaseComponent } from "../../../shared/components/damages/damages.component.base";
import { Arrival, CodebarReaderService, Damage, Package } from "wms-lib";
import { Observable, combineLatest } from "rxjs";
import { Directive, OnInit } from "@angular/core";
import * as store_actions from 'wms-lib';
import { map } from "rxjs/operators";

@Directive({ selector: '[packageDamagesBase]' })
export class PackageDamagesComponent extends DamagesBaseComponent implements OnInit {

    package: Package;
    package$: Observable<Package>;
    private package_id: number;
    constructor(_route: ActivatedRoute, store: Store, codebar_service: CodebarReaderService) {
        super(_route, store, codebar_service);

    }

    ngOnInit(): void {
        super.ngOnInit();
        this.package_id = +this._route.snapshot.params['id'];
        this.package$ = this.store.select((state) => state.arrivalsState.open_arrival).pipe(map((a: Arrival) => a.findPackage(this.package_id)));
        this.addSub(combineLatest(this.damage_types$, this.package$).
            subscribe(([dt, pkg]: [Map<number, string>, Package]) => {
                if (dt && pkg && !pkg.damage_report.damage_type_id)
                    pkg.damage_report.damage_type_id = dt.keys().next().value;
                this.package = pkg;
            }));
    }

    takeDamage() {
        this.takeDamageImage().subscribe((d: Damage) => {
            this.codebar_service.switchToProfile();
            if (d) {
                this.package.damage_report.addDamage(d);
                this.updateDamageReport();
            }
        });
    }

    updateDamageReport() {
      this.store.dispatch(new store_actions.UpdateDamageReportAction(this.package.damage_report));
    }
}
