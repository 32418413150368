import { Component, OnInit, NgZone, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { CodebarReaderService, Bindable, ArrivePackingList, Package, Arrival, AuthenticationService, PartNumber, CatalogsState, GoodCategory, Unit } from 'wms-lib';
import { PackingListsComponent } from '../../../shared/components/base-components/packing-lists.component';
import * as actions from 'wms-lib';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { Table } from 'primeng/table';

@Component({
    selector: 'app-arrive-packing-lists',
    templateUrl: './arrive-packing-lists.component.html',
    styleUrls: ['../../../shared/components/base-components/packing-lists.component.scss']
})
export class ArrivePackingListsComponent extends PackingListsComponent implements OnInit {
    @ViewChild('dt',{static: false}) partNumberTable: Table;
    ptap?: Package | ArrivePackingList = undefined;

    constructor(private store: Store,private router: Router, codebar_service: CodebarReaderService,ng_zone: NgZone,
                authenticationService: AuthenticationService) {
        super(store, codebar_service, ng_zone, authenticationService);
    }

    ngOnInit(): void {
        super.ngOnInit();
    }

    toggle_arrival() {
        this.arrival.open = !this.arrival.open;
        this._store.dispatch(new actions.UpdateArriveAction({arrival: <Arrival>this.arrival, package: null})).subscribe(()=> {
            this.router.navigate(['/receivings', this.arrival.type ]);
        });
    }

    onEditInit(event: any) {
        (<Bindable>event.data).takeSnapshoot()
        this.togglePackageState(event, true);
    }

    onEditComplete(event:any){
        if(!event.originalEvent)
            this.onEditCancel(event);
        else
            this.editSave(event.data);
    }

    editSave(e: Bindable) {
        if (e && e.updateCallback && e.haveChanged()) e.updateCallback();
    }

    onEditCancel(event: any) {
        event.data[event.field] = (<Bindable>event.data)._backup[event.field];
        this.togglePackageState(event, false);
    }

    togglePackageState(event: any, editing: boolean) {
        if (event.field == 'weight' && event.data instanceof Package)
            event.data.editing = editing;
    }

    select_all(apl: ArrivePackingList, value: boolean) {
        _(apl.packages).each((p: Package) => this.store.dispatch(new actions.ToggleUndocPkgAction({ package: p, selected: value})));
    }

    get show_list(): boolean {
        return this.arrival && this.arrival.arrive_packing_lists &&
            (this.display_valids || this.display_deflected || !this.arrival.can_be_closed);
    }

    get caption_label(): string {
        if (!this.display_deflected && !this.display_valids)
            return `Active Packings: ${this.arrival.active_packings}, Active Packages: ${this.arrival.active_packages}`;
        if (!this.display_deflected && this.display_valids)
            return `Completed Packings: ${this.arrival.completed_packings}, Completed Packages: ${this.arrival.completed_packages}`;
        if (this.display_deflected)
            return `Deflected Packings: ${this.arrival.deflected_packings}, Deflected Packages: ${this.arrival.deflected_packages}`;
        return '';
    }

    assignDialog(pkg: Package | ArrivePackingList) {
    	this.ptap = undefined;
        setTimeout(()=> { this.ptap = pkg; }, 300);
    }
}
