import { Validable } from "./common";
import * as _ from 'lodash';

export interface ICatalog {
    validationKey: string;
    id: number;
    name: string;
    errors: any;
    sequence: number;
    default?:string;
    notify?: boolean;
    toSaveData(): any;
}

export interface ICatalogLogo extends ICatalog {
    logo_url: string;
    pop_url?: string;
}


const SAVE_PROPS = ['name_en','name_es','notify'];

export class Catalog extends Validable implements ICatalog {
    validationKey: string = "catalog";
    id: number;
    key: string;
    sequence: number;
    name_es:string;
    name_en:string;
    container: any;
    notify: boolean;

    constructor(data:any) {
        super(data);
    }

    get name() :string {
        return this['name_en'];
    }

    get label() :string {
        return this['name_en'];
    }

    get value(): number {
        return this.id;
    }

    protected get saveProp(): string[] {
        return  super.saveProp.concat(SAVE_PROPS);
    }

    toString() {
        return this.name;
    }
}
