import { Component, OnInit, OnDestroy } from '@angular/core';
import { Store } from '@ngxs/store';
import { Shipment } from 'wms-lib';

@Component({
  selector: 'app-shipment-review',
  templateUrl: './shipment-review.component.html',
  styleUrls: ['./shipment-review.component.scss']
})
export class ShipmentReviewComponent implements OnInit, OnDestroy {
  shipment: Shipment = new Shipment({packages: {}, id: 0});
  selectedDoc: Document;

  constructor(private store: Store) {
  }

  ngOnInit() {
    this.store.select(state => state.shipmentsState.shipment_template).subscribe(shipment => {
      this.shipment = shipment;
    });
  }

  ngOnDestroy() {
  }

  onSelDoc(doc: Document) {
    this.selectedDoc = doc;
  }

}
