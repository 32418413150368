import { Injectable } from '@angular/core';
import { Observable, Subject, BehaviorSubject, Subscription, combineLatest } from 'rxjs';

import { Store } from '@ngxs/store';

@Injectable()
export class DocumentService {
    working_packing_id: string = null;

    constructor(
        private store: Store
    ){
        this.getWorkingPackingList();
    }

    getWorkingPackingList(){
        this.store.select(state => state.arrivalsState.working_packing_id).subscribe(wpl_id => {
            this.working_packing_id = wpl_id;
        });
    }

    loadDocuments(): Observable<{ [name: string]: Document }> {
        return this.store.select((state) => state.arrivalsState.documents);
    }

}
