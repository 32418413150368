import { Component } from '@angular/core';
import { ArrivalsBaseComponent } from './arrivals.component.base';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Arrival, AuthenticationService } from 'wms-lib';
import * as actions from 'wms-lib';
import { ConfirmationService } from 'primeng/api';

@Component({
    selector: 'app-arrivals',
    templateUrl: './arrivals.component.html',
    styleUrls: ['./arrivals.component.scss']
})
export class ArrivalsComponent extends ArrivalsBaseComponent {

    constructor(private _router: Router,
                _route: ActivatedRoute,
                store: Store,
                private confirmationService: ConfirmationService,
                protected authenticationService : AuthenticationService
               ) {
        super(_route, store);
    }

    editArrive(a?: Arrival) {
        this.store.dispatch(new actions.ClearArrivalStateAction());
        this._router.navigate(['/receivings', this.type, "edit", a ? a.id : 0]);
    }

    lazyLoad(event: any) {
        let page = (event.first/event.rows)+1;
        this.loading = true;
        this.idToSeach = null;
        this.store.dispatch(new actions.GetPagedArrivalsAction({type: this.type, page: page}));
    }

    searchByID() {
    	if(this.idToSeach) {
			this.loading = true;
			this.store.dispatch(new actions.ClearArrivalStateAction());
			this.store.dispatch(new actions.GetPagedArrivalsAction({type: this.type, page: 1, search: this.idToSeach}));
		}
    }

    clearSearch() {
    	this.loading = true;
    	this.idToSeach = null;
    	this.store.dispatch(new actions.ClearArrivalStateAction());
    	this.store.dispatch(new actions.GetPagedArrivalsAction({type: this.type, page: 1}));
    }

    confirmAndDeleteArrive(a: Arrival) {
        this.confirmationService.confirm({
            message: 'Are you sure that you want to perform this action?',
            accept: () => this.deleteArrive(a)
        });
    }

    get canDelete(): boolean {
        return this.authenticationService.currentUserValue.isSupervisor || this.authenticationService.currentUserValue.isParcelSup; 
    }
}
