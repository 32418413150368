import { Component } from '@angular/core';
import { AuthenticationService, Carrier, Client, Shipment, User } from 'wms-lib';
import * as actions from 'wms-lib';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { AppMainComponent } from '../../../../../../src/app/app.main.component';
import { Store } from '@ngxs/store';
import { KpisComponent } from '../kpis.component';

@Component({
  selector: 'app-kpis-shipment',
  templateUrl: './kpis-shipment.component.html',
  styleUrls: ['./kpis-shipment.component.scss']
})
export class KpisShipmentComponent extends KpisComponent {
  items: Shipment[] = [];
  carriers$: Observable<{ [id: number]: Carrier }> = new Observable<{ [id: number]: Carrier }>();
  loading = false;
  type = 'ParcelArrival';
  clients: { [id: number]: Client } = {};
  users: { [id: number]: User } = {};
  rows = 15;
  first = 0;
  carrouselStarted = false;

  constructor(protected app: AppMainComponent,
              protected store: Store,
              protected authenticationService : AuthenticationService) {
    super(app, store);
    this.store.dispatch(new actions.GetOpenAndClosedShipmentsAction({user_id: this.authenticationService.currentUserValue.id}));
    this.store.dispatch(new actions.GetUsersAction());
    this.store.dispatch(new actions.GetRolesAction());
    this.store.select((state) => state.catalogsState.clients).subscribe((clients: {[id: number]: Client}) => this.clients = clients);
    this.store.select((state) => state.catalogsState.users).subscribe((users: {[id: number]: User}) => {
      this.users = users
    });
    this.store.select((state) => state.shipmentsState.kpis).subscribe((shipments: {[id: number]: Shipment}) => {
      let data = _(shipments).values().value();
      data = _.orderBy(data, ['shipping_date'], ['desc']);
      this.items = data;
      if( _.size(this.items) > 0 && !this.carrouselStarted) {
        this.carrouselStarted = true;
        this.startCarrousel();
      }
    });
  }

  get dataTable(): Shipment[] {
    return this.items;
  }

  get pendingShipments(): number {
    return _(this.items).filter((s: Shipment) => !s.isPicked).size();
  }

  get closedShipments(): number {
    return _(this.items).filter((s: Shipment) => s.closed).size();
  }

  get workingShipments(): number {
    return _(this.items).filter((s: Shipment) => s.isPicked && !s.closed).size();
  }

  shipmentStatus(shipment: Shipment): { text: string, color: string } {
    if (shipment.closed) {
      return { text: 'Closed', color: '#EAEAEA' };
    }
    if (shipment.isPicked) {
      return { text: 'Working', color: '#FFFDD0' };
    }
    if (!shipment.isPicked) {
      return { text: 'Pending', color: '#D1FBEB' };
    } else {
      return { text: '', color: '' };
    }
  }

  startCarrousel() {
    setInterval(() => {
      if (this.isLastPage() === true) {
        this.resetPage();
      } else {
        this.nextPage();
      }
    }, 15000);
  }

  nextPage() {
    this.first = this.first + this.rows;
  }

  resetPage() {
    this.first = 0;
  }

  isLastPage(): boolean {
    return (this.first + this.rows) >= this.items.length;
  }

  isFirstPage(): boolean {
    return this.first === 0;
  }

  shipmentAssignedTo(shipment: Shipment): string {
	  if(!shipment.user_working_id) return '';

	  let user = _.find(this.users, (u: User) => u.id == shipment.user_working_id);
	  if (user) {
		  return user.username;
	  }
	  return '';
  }

  totalBundles(shipment: Shipment, type?: string): number {
	  let packages = _.unionBy(shipment.all_packages, _.values(shipment.packages), 'id');
	  if(type === 'parcel') {
	  	  return _.filter(packages, (p: any) => p.isParcel && !p.part_number_id).length;
	  } else if(type === 'freight') {
	  	  return _.filter(packages, (p: any) => p.isFreight || p.part_number_id).length;
	  } else {
		  return _.size(packages);
	  }
  }

}
