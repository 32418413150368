import { User } from "./user";
import * as _ from 'lodash';
import { TicketComment } from "./ticket-comment";
import { TicketStatusLog } from "./ticket-status-log";
import { Validable } from "./common";
import { Attachment } from "./attachment";
import { Client } from "./client";
import * as moment from 'moment'

const SAVE_PROPS = ['title','description','assign_to_id','client_id', 'status_id','ticket_comments_attributes', 'new_status_id','attachments','receipt_id'];



export class Ticket extends Validable{
    validationKey = 'ticket';
    title: string;
    description: string;
    assign_to_id: number;
    created_by_id: number;
    private _status_id: number;
    client_id: number;
    duration: number;
    receipt_id: string;

    attachments: Attachment[]; // Links to downloads are contained in the objects
    client: Client;

    ticket_comments: {[id:number]: TicketComment};

    ticket_status_logs: TicketStatusLog[];

    private _assign_to: User;

    created_by: User;

    constructor(data) {
        super(data);
        this.setup_comments(data.ticket_comments);
        this.setup_defaults();
    }


    get ticket_comments_attributes(): TicketComment[] {
        return _.values(this.ticket_comments);
    }

    private setup_defaults() {
        

        if (!this.ticket_comments) {
            this.ticket_comments = [];
        }

        if (!this.attachments) {
            this.attachments = [];
        } else {
            this.attachments = this.attachments.map(a => new Attachment(a));
        }

        if (this.ticket_status_logs){
            this.ticket_status_logs = this.ticket_status_logs.map(s => new TicketStatusLog(s));
        }

        if (this.created_by && !(this.created_by instanceof User)) {
            this.created_by = new User(this.created_by);
        }

        if (this.client && !(this.client instanceof Client)) {
            this.client = new Client(this.client);
        }

        if (this.assign_to && !(this.assign_to instanceof User)) {
            this.assign_to = new User(this.assign_to);
        }

        this.ticket_comments = this.ticket_comments || {};
    }

    private setup_comments(comments) {
        this.ticket_comments = _(comments).map((c) => new TicketComment(c)).keyBy('id').value();
    }


    get status_id(){
        if (!this._status_id){
            this._status_id = this.ticket_status  ? this.ticket_status.id : null;
        }
        return this._status_id;
    }

    set status_id(value: number) {
        this._status_id = value;
    }

    
    get ticket_status() {
        if (this.ticket_status_logs)
            return this.ticket_status_logs.filter(s => s.active === true)[0].ticket_status;
        return null;
    }

    get status_key() {
        if (this.ticket_status)
            return this.ticket_status.key;
        return '';
    }

    set assign_to(value) {
        if (value instanceof Object) {
            this.assign_to_id = value.id;
            this._assign_to = value;
        }
    }

    get assign_to() {
        return this._assign_to;
    }

    get saveProp(): string[] {
        return super.saveProp.concat(SAVE_PROPS);
    }

    toSaveData() {
        const data = super.toSaveData();
        if (_(this.attachments).size() > 0 && _.every(this.attachments,(a) => a.file != null && a.file != undefined)) {
            let formData = new FormData();
            let i = 1;
            this.attachments.forEach(a => {
                if (!a.stored){
                    formData.append(`ticket[attachments][][file]`,a.file,a.filename);
                    formData.append(`ticket[attachments][][filename]`,a.filename);
                } else if (a._destroy) {
                    formData.append(`ticket[attachments][][id]`,a.id.toString());
                    formData.append(`ticket[attachments][][_destroy]`,a._destroy.toString());
                }
                i++;
            });

            _(data).forEach((v:any,k: string) => {
                if (v !== undefined && v !== null && k !== 'attachments'){
                    formData.append(`ticket[${k}]`, v);
                }
            });

            return formData;
        }
        return {'ticket': data};
    }

    get has_attachments(): boolean {
        return _.size(this.attachments) > 0;
    }

    get duration_human(): string {
        return moment.duration(this.duration, 'seconds').humanize();
    }


    removeNotPersistedAttachments() {
        this.attachments = this.attachments.filter(e => e.stored === false);
    }

    appendAttachment(a: Attachment) {
        this.attachments.push(a);
    }

    appendAttachmentFromFile(file: File) {
        this.attachments.push(new Attachment({
            file: file,
            filename: file.name
        }));
    }


}

