import { Shipment } from '../../models/shipment';
import { State, StateContext, Action, createSelector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import * as actions from '../actions';
import { PackagesService, ShipmentsService } from '../../services';
import * as _ from 'lodash';
import { ArrivePackingListService } from '../../services/arrive-packing-list.service';
import { Package } from '../../models/package';
import { ArrivePackingList } from '../../models/arrive-packing-list';
import { Pallet } from '../../models/pallet';
import { RemoteAsset } from '../../models/remote-asset';
import { Arrival } from '../../models/arrival';
import { PartNumber } from '../../models';
import { tap } from 'rxjs/operators';

export interface IShipmentsState {
  shipment_template: Shipment;
  shipments: {[id:string]: Shipment};
  kpis: {[id:string]: Shipment};
  packing_lists: ArrivePackingList[];
  total_packings: number;
  all_loaded: boolean;
  working_shipment_id: number;
  new_pallet: Pallet;
  page: number;
  totalRecords: number;
  tag_location_package: Package;
}

const defaultStateValue ={
  shipment_template: null,
  shipments: null,
  kpis: null,
  packing_lists: null,
  total_packings: null,
  all_loaded: false,
  working_shipment_id: null,
  new_pallet: null,
  page: null,
  totalRecords: null,
  tag_location_package: null
};

@State<IShipmentsState>({
  name: 'shipmentsState',
  defaults: {...defaultStateValue}
})

@Injectable()
export class ShipmentsState {

  constructor(private shipment_service: ShipmentsService,
              private pl_service: ArrivePackingListService,
              private package_service: PackagesService,
              private arrive_packing_list_service: ArrivePackingListService
             ){}


  static getWorkingShipment() {
    return createSelector([ShipmentsState], (state: IShipmentsState) => {
      return state.shipment_template;
    });
  }

  static getPackingLists() {
    return createSelector([ShipmentsState], (state: IShipmentsState) => {
      return state.packing_lists;
    });
  }

  static getPartNumbers() {
    return createSelector([ShipmentsState.getPackingLists()], (apls: ArrivePackingList[]) => {
      return !!apls ?
        PartNumber.fromPackages(
          _(apls).
            filter((pl: ArrivePackingList) => pl.isFreight && pl.havePartNumbers).
            map((pl: ArrivePackingList) => _.values(pl.packages)).
            flatten().
            filter((p: Package) => p && !!p.part_number)
        ) : null;
    });
  }

  @Action(actions.UpdatePackingCategoryAction)
  updatePackingCategory(ctx: StateContext<IShipmentsState>, { payload }: actions.UpdatePackingCategoryAction){
    global.log("State",`Update packing list `)
    let pls = ctx.getState().packing_lists;
    if (payload.flush) pls = pls.filter((apl: ArrivePackingList) => apl.id != payload.pl.id)
    this.pl_service.update(payload.pl).subscribe((success: boolean) => {
      if (!success) {
        let pl = pls.find((apl: ArrivePackingList) => apl.id == payload.pl.id);
        pl.good_category_id = payload.old_cat;
        if (payload.flush) pls.push(pl);
        ctx.patchState({ packing_lists: [...pls] });
      }
    });
    ctx.patchState({ packing_lists: [...pls] });
  }

  @Action(actions.UpdatePackingCategoryInBatchAction)
  updatePackingCategoryInBatch(ctx: StateContext<IShipmentsState>, { payload }: actions.UpdatePackingCategoryInBatchAction){
    global.log("State",`Update packing list `)
    let ids = payload.map((apl: ArrivePackingList) => apl.id);
    let [upd,not_upd] = ctx.getState().packing_lists.reduce(
      (m: any[], i: ArrivePackingList) => {m[ids.includes(i.id) ? 0 : 1].push(i); return m}, [[],[]]);
    this.pl_service.updateBatch(payload).subscribe((success: boolean) => {
      if (!success) {
        upd.forEach((pl: ArrivePackingList) => pl.good_category_id = null);
        ctx.patchState({ packing_lists: [].concat(upd,not_upd) });
      }
    });
    ctx.patchState({ packing_lists: not_upd });
  }

  @Action(actions.UpdateShipmentPackageAction)
  updateShipmentPackage(ctx: StateContext<IShipmentsState>, { payload }: actions.UpdateShipmentPackageAction){
    global.log("State",`Update package `);
    let pk = _.cloneDeep(payload);
    this.updateShipmentTemplate(ctx,payload);
    this.package_service.update(payload).subscribe((pkg: Package)=> {
      	if(pkg && pkg.errors && pkg.errors['tag_location']){
	  		ctx.patchState({tag_location_package: pk});
	  	} else {
	  		ctx.patchState({tag_location_package: null});
		}
      this.updateShipmentTemplate(ctx,pkg);
    });
  }

  private updateShipmentTemplate(ctx: StateContext<IShipmentsState>,pkg: Package) {
    pkg.arrive_packing_list.packages = {...pkg.arrive_packing_list.packages};
    if (pkg.arrive_packing_list.arrival)
      pkg.arrive_packing_list.arrival.arrive_packing_lists = {...pkg.arrive_packing_list.arrival.arrive_packing_lists};
    const state = ctx.getState();
    if (!state.shipment_template) return;
    state.shipment_template.arrive_packing_lists[pkg.arrive_packing_list_id].packages[pkg.id] = pkg;
    ctx.patchState({shipment_template: state.shipment_template});
  }


  @Action(actions.GetPackingsAction)
  getPackings(ctx: StateContext<IShipmentsState>, { payload}: actions.GetPackingsAction) {
    global.log("State",`Get packings for categorization `)
    ctx.patchState({packing_lists: null, total_packings: null});
    this.pl_service.getPackings(payload).subscribe((response: {totalRecords: number, pls: ArrivePackingList[]}) => {
      if (response)
        ctx.patchState({packing_lists: response.pls, total_packings: response.totalRecords});
    });
  }

  @Action(actions.AddPackingListToShipmentAction)
  addOrDeletePackingList(ctx: StateContext<IShipmentsState>, { payload }: actions.AddPackingListToShipmentAction) {
    global.log("State",`Add or delete packing list from shipment`)
    const current_state = ctx.getState();
    let ship = current_state.shipment_template || new Shipment({});
    let packing_lists = this.togglePackingList(ship,current_state.packing_lists,payload);
    ship.arrive_packing_lists = { ...ship.arrive_packing_lists };
    ctx.patchState({shipment_template: null, packing_lists: packing_lists });
    ctx.patchState({shipment_template: ship, packing_lists: packing_lists });
  }

  @Action(actions.AddPackingListsToShipmentAction)
  addOrDeletePackingsList(ctx: StateContext<IShipmentsState>, { payload }: actions.AddPackingListsToShipmentAction) {
    global.log("State",`Add or delete packing lists from shipment`)
    const current_state = ctx.getState();
    let ship = current_state.shipment_template || new Shipment({});
    let packing_lists = current_state.packing_lists;
    payload.forEach((apl: ArrivePackingList) => {
      packing_lists = this.togglePackingList(ship,packing_lists,apl)
    })
    ship.arrive_packing_lists = { ...ship.arrive_packing_lists };
    ctx.patchState({shipment_template: null, packing_lists: packing_lists });
    ctx.patchState({shipment_template: ship, packing_lists: packing_lists });
  }

  togglePackingList(ship: Shipment, packing_lists: ArrivePackingList[],  apl: ArrivePackingList): ArrivePackingList[] {
    if (ship.arrive_packing_lists[apl.id]){
      apl.unassignShipment(ship);
      packing_lists.push(apl);
    } else {
      apl.assignShipment(ship);
      packing_lists = packing_lists.filter((fa: ArrivePackingList) => fa.id != apl.id)
    }
    return packing_lists;
  }

  @Action(actions.AddPackageToShipmentAction)
  addOrDeletePackage(ctx: StateContext<IShipmentsState>, { payload }: actions.AddPackageToShipmentAction) {
    global.log("State",`Add or delete packing list from shipment`)
    const current_state = ctx.getState();
    let ship = current_state.shipment_template || new Shipment({});
    if (ship.packages[payload.id]) {
      payload.shipment = undefined;
      ship.packages = _(ship.packages).filter((p: Package) => p.id != payload.id).keyBy('id').value();
    }
    else {
      // Revisar si ya se seleccionaron todos lo paquetes de recibo y des seleccionar los paquetes y seleccionar el recibo completo
      if (payload.arrive_packing_list.contained(_.values(ship.packages).concat([payload]))) {
        ship.togglePackagesToReceipt(payload.arrive_packing_list);
      } else {
        let pkgs = {...ship.packages};
        pkgs[payload.id] = payload;
        ship.packages = pkgs;
      }
      if (!payload.arrive_packing_list)
        ctx.dispatch(new actions.LoadPackingListAction(payload));
    }
    ship.packages = { ...ship.packages };
    ctx.patchState({shipment_template: null });
    ctx.patchState({shipment_template: ship });
  }

  @Action(actions.AddPartToShipmentAction)
  addOrDeletePart(ctx: StateContext<IShipmentsState>, { payload }: actions.AddPartToShipmentAction) {
    global.log("State",`Add or delete part list from shipment`)
    const current_state = ctx.getState();
    let ship = current_state.shipment_template || new Shipment({});
    if (ship.packages[payload.id])
      ship.packages = _(ship.packages).filter((p: Package) => p.id != payload.id).keyBy('id').value();
    else {
      let pkgs = {...ship.packages};
      pkgs[payload.id] = payload;
      ship.packages = pkgs;
      if (!payload.arrive_packing_list)
        ctx.dispatch(new actions.LoadPackingListAction(payload));
    }
    ship.packages = { ...ship.packages };
    ctx.patchState({shipment_template: null });
    ctx.patchState({shipment_template: ship });
  }

  @Action(actions.SaveShipmentAction)
  save(ctx: StateContext<IShipmentsState>, {payload}: actions.SaveShipmentAction){
    global.log("State",`Saving Shipment`)
    return this.shipment_service.saveOrUpdate(payload.shipment).pipe(
      tap(response => {
        if (!response['error'] || Object.keys(response['error']).length == 0){
          if (payload.keep_working) {
            let shipment_template = ctx.getState().shipment_template;
            if (shipment_template && shipment_template.id == payload.shipment.id) {
              shipment_template = response;
              ctx.patchState({ shipment_template: shipment_template , working_shipment_id: shipment_template.id});
            }
          } else {
            ctx.patchState({ shipment_template: null, working_shipment_id: null});
          }
        }
      })
    );
  }

  @Action(actions.DeleteShipmentAction)
  delete(ctx: StateContext<IShipmentsState>, {payload}: actions.DeleteShipmentAction){
    global.log("State",`Deleting Shipment`)
    let shipments = ctx.getState().shipments;
    let sToDel = shipments[payload.id];
    delete shipments[payload.id];
    ctx.patchState({ shipments: {...shipments} });
    return this.shipment_service.delete(payload).subscribe((result: boolean) => {
      if (!result) {
        shipments[payload.id] = sToDel;
        ctx.patchState({ shipments: {...shipments} });
      }
    });
  }

  @Action(actions.SetWorkingShipmentAction)
  setWorkingShipment(ctx: StateContext<IShipmentsState>, {payload}: actions.SetWorkingShipmentAction) {
    global.log("State", `Setting working shipment id to ${payload}`);
    const state = ctx.getState();
    if (payload > 0) {
      this.shipment_service.getActiveShipment(payload).subscribe((shipment: Shipment) => {
        ctx.patchState({working_shipment_id: payload, shipment_template: shipment});
      });

      if(!state.shipments){
        this.getOpenShipments(ctx, payload);
      }
    } else
      ctx.patchState({shipment_template: new Shipment({})});
  }

  @Action(actions.GetOpenShipmentsAction)
  getOpenShipments(ctx: StateContext<IShipmentsState>, id?: number) {
    global.log("State",`Get open shipments`)
    ctx.patchState({ shipments: null });
    this.shipment_service
      .getOpenShipments()
      .subscribe((response: { [id: number]: Shipment }) => {
        global.log("State",`${_.size(response)} open shipments received`);
        ctx.patchState({ shipments: response, page: 0 });
        let ws = ctx.getState().working_shipment_id;
        if (response && ws) {
          if (response[ws])
            ctx.patchState({shipment_template: response[ws]});
          else
            if (id && typeof(id) == 'string') this.getShipment(ctx, id);
        }
      });
  }

  @Action(actions.GetOpenAndClosedShipmentsAction)
  getOpenAndClosedShipmentsAction(ctx: StateContext<IShipmentsState>, {payload}: actions.GetOpenAndClosedShipmentsAction) {
    global.log("State",`Get open and closed shipments`)
    ctx.patchState({ kpis: null });
    this.shipment_service.getOpenAndClosedShipments(payload.user_id)
      .subscribe((response: { [id: number]: Shipment }) => {
        global.log("State",`${_.size(response)} Open and closed shipments received`);
        ctx.patchState({ kpis: response });
      });
  }

  private getShipment(ctx: StateContext<IShipmentsState>, id: number) {
    this.shipment_service.getShipment(id).subscribe((response: Shipment) => {
      if (!_.isEmpty(response))
        ctx.patchState({shipment_template: response});
    });
  }

  @Action(actions.GetPagedShipmentsAction)
  getPagedShipments(ctx: StateContext<IShipmentsState>, action: actions.GetPagedShipmentsAction) {
    global.log("State",`Get page ${action.payload.page} of shipments`);
    ctx.patchState({ page: action.payload.page, shipments: null });
    this.shipment_service
      .getPagedShipments(action.payload.client_id, action.payload.page)
      .subscribe((response: {totalRecords: number, ships: {[id: number]: Shipment}}) => {
        const currentState = ctx.getState();
        if (_.isEmpty(response)){
          ctx.patchState({ all_loaded: true });
        } else {
          ctx.patchState({
            shipments: action.payload.append && action.payload.page > 1 ? _.merge(response.ships, { ...currentState.shipments }) : response.ships,
            totalRecords: response.totalRecords
          });
        }
      });
  }

  @Action(actions.SetShipmentPalletIdAction)
  setPalletId(ctx: StateContext<IShipmentsState>, action: actions.SetShipmentPalletIdAction) {
    global.log("State",`Set pallet id`)
    let pkgs = action.payload.shipment.parcel_packages.
      filter((p: Package) => p.palletizable(action.payload.good_category.id));
    let plt = new Pallet({
      shipment: action.payload.shipment,
      packages: pkgs,
      container_type_id: action.payload.good_category.container.id
    });

    this.shipment_service.palletize(plt).subscribe((pallet: Pallet) => {
      if (pallet && pallet.id > 0) {
        let ship = this.togglePallet(ctx.getState().shipment_template, pallet, pallet);
        ctx.patchState({ shipment_template: null });
        ctx.patchState({ shipment_template: ship, new_pallet: pallet });
      }
    });
  }


  private togglePallet(ship: Shipment, target: Pallet | Package, pallet: Pallet): Shipment {
    ship.arrive_packing_lists = {...ship.arrive_packing_lists};
    Object.keys(ship.arrive_packing_lists).forEach((k: string) => ship.arrive_packing_lists[k].packages = { ...ship.arrive_packing_lists[k].packages } )
    let pkgs = ship.getPackageByType(Arrival.ParcelArrival).filter((p: Package) =>
      (target instanceof Pallet && target.havePackage(p)) ||
      (target instanceof Package && p.id == target.id));
    pkgs.forEach((p: Package) => {
      p.takeSnapshoot();
      p.pallet = pallet;
      p.isPalletized = !!p.pallet;
    })
    ship._parcel_pallets = null;
    ship._parcel_packges = null;
    ship._loose_packages = null;
    return ship;
  }

  @Action(actions.UnPalletAction)
  unPallet(ctx: StateContext<IShipmentsState>, action: actions.UnPalletAction) {
    global.log("State",`Un pallet ${action.payload.id}`);
    if (action.payload instanceof Package)
      this.unPalletPackage(ctx, action.payload);
    else {
      let ship = this.togglePallet(ctx.getState().shipment_template, action.payload, null);
      ctx.patchState({ shipment_template: ship });
      this.shipment_service.unpalletize(action.payload).subscribe((pallet: Pallet) => {
        ship = this.togglePallet(ship, action.payload, pallet);
        ctx.patchState({ shipment_template: ship });
      });
    }
  }

  private unPalletPackage(ctx: StateContext<IShipmentsState>, pkg: Package) {
    let pallet = pkg.pallet;
    let ship = this.togglePallet(ctx.getState().shipment_template, pkg, null);
    this.package_service.update(pkg, ["status", "pallet_id"]).subscribe((pkg: Package) => {
      if (pkg.pallet_id) {
        ship = this.togglePallet(ship, pkg, pallet);
        ctx.patchState({ shipment_template: ship });
      }
    })
  }


  @Action(actions.UpdateImageAction)
  updateImage(ctx: StateContext<IShipmentsState>, action: actions.UpdateImageAction) {
    global.log("State",`Add image to shipment`)
    const shipment = ctx.getState().shipment_template;
    shipment.takeSnapshoot();
    if (Array.isArray(action.payload)){
      let ids = action.payload.map((i: RemoteAsset) => i.id);
      shipment.images.forEach((d: RemoteAsset) => { if (ids.includes(d.id)) d._destroy = true; } );
    }
    else
      shipment.images.push(action.payload);
    shipment.images = [...shipment.images];
    ctx.patchState({ shipment_template:  shipment });
    this.shipment_service.update(shipment).subscribe((s: Shipment) => {
      if (!Array.isArray(action.payload)) {
        let idx = s.images.findIndex((r: RemoteAsset) => r.id == (action.payload as RemoteAsset).id);
        if (s.images[idx]) {
          s.images[idx].stored = Object.keys(s.errors).length == 0;
          if (s.images[idx].stored)
            global.log("State",`Image stored`);
          else
            global.log("State",`Image not stored`);
        }
      }
      if (!s || !s.images || s.images.length == 0) {
        global.log("State",`Images removed on the service`);
        let i = shipment.images.findIndex((r: RemoteAsset) => r.id == (action.payload as RemoteAsset).id);
        shipment.images[i].stored = false;
        ctx.patchState({ shipment_template:  shipment });
      }
      else
        ctx.patchState({ shipment_template:  s });
    })
  }

  @Action(actions.ClearShipmentsStateAction)
  clearState(ctx: StateContext<IShipmentsState>){
    global.log("State",`Clear shipments state`);
    ctx.setState({...defaultStateValue});
  }

  @Action(actions.ReloadShipmentsPageAction)
  realodShipmentsPage(ctx: StateContext<IShipmentsState>, action: actions.ReloadShipmentsPageAction) {
    const page = ctx.getState().page;
    if (page == 0)
      ctx.dispatch(new actions.GetOpenShipmentsAction());
    if (page > 0)
      ctx.dispatch(new actions.GetPagedShipmentsAction({client_id: action.payload, page: page, append: false}));
  }

  @Action(actions.LoadImagesAction)
  LoadImages(ctx: StateContext<IShipmentsState>, action: actions.LoadImagesAction) {
    const shipments = ctx.getState().shipments;
    this.shipment_service.getShipmentImages(action.payload).subscribe((data:any[]) => {
      shipments[action.payload].setImages(data);
    });
    ctx.patchState({ shipments:  shipments });
  }

  @Action(actions.LoadPackingListAction)
  LoadPackingList(ctx: StateContext<IShipmentsState>, { payload }: actions.LoadPackingListAction) {
    global.log("State",`Loading packing list of package: ${payload.id}`);
    const ship = ctx.getState().shipment_template;
    this.arrive_packing_list_service.get(payload.arrive_packing_list_id).subscribe((apl: ArrivePackingList) => {
      if (apl){
        payload.arrive_packing_list = apl;
        apl.packages = apl.packages || {};
        apl.packages[payload.id] = payload
        ship.packages[payload.id] = payload;
        ship.packages = {...ship.packages};
        ctx.patchState({shipment_template: ship });
      }
    });
  }

  @Action(actions.ReLoadPackingListAction)
  ReLoadPackingList(ctx: StateContext<IShipmentsState>, { payload }: actions.ReLoadPackingListAction) {
    global.log("State",`Loading packing list of package: ${payload.id}`);
    this.arrive_packing_list_service.get(payload.id).subscribe((apl: ArrivePackingList) => {
      if (apl) {
        const pls = [...ctx.getState().packing_lists];
        let idx = pls.findIndex((f: ArrivePackingList) => f.id == apl.id);
        if (idx >= 0) {
          pls[idx] = apl;
          ctx.patchState({packing_lists: pls });
        }
        const ship = ctx.getState().shipment_template;
        if (ship.arrive_packing_lists[apl.id]) {
          ctx.patchState({shipment_template: null });
          ship.arrive_packing_lists[apl.id] = apl;
          ship.arrive_packing_lists = {...ship.arrive_packing_lists}
          ctx.patchState({shipment_template: ship });
        }
      }

    });
  }
}
