import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Address, Carrier, Catalog, DeliveryOrder, ExposService, Forward, RemoteAsset } from 'wms-lib';
import { Observable, combineLatest } from 'rxjs';
import * as _ from 'lodash';
import * as actions from 'wms-lib';
import { DeliveryOrderAttachmentsComponent } from '../base-components/delivery-order-attachments.component';
import { map } from 'rxjs/operators';
import { MenuItem } from 'primeng/api';

@Component({
  selector: 'app-delivery-list-table',
  templateUrl: './delivery-list-table.component.html',
  styleUrls: ['./delivery-list-table.component.scss']
})
export class DeliveryListTableComponent extends DeliveryOrderAttachmentsComponent implements OnInit, OnChanges {
  @Input() status: string;
  @Input() pagination: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() showStatus: boolean = false;
  @Input() set deliveries(deliveries: { [id: number]: DeliveryOrder }) {
    this.readOnly = true;
    this.items = deliveries;
    this.paginating = false;
  }
  items: { [id: number]: DeliveryOrder };
  bundle_types$: Observable<{ [id: number]: Catalog }>;
  priorities$: Observable<{ [id: number]: Catalog }>;
  countries$: Observable<{ [id: number]: Catalog }>;
  forwards: { [id: number]: Forward };
  addresses: { [id: number]: Address };
  client_id: number = 0;
  paginating: boolean = false;
  totalRecords$: Observable<number>;
  working_delivery_id: string = null;
  carriers: { [id: number]: Carrier };
  editedDeliveries: { [id: number]: DeliveryOrder } = {};
  deliveryOptions?: DeliveryOrder;
  menus: MenuItem[] = [];
  images: number = 0;
  images_list: RemoteAsset[] = [];
  damages_list: RemoteAsset[] = [];

  constructor(_route: ActivatedRoute, protected store: Store, private router: Router, private exposService: ExposService) {
    super();
    this.store.select(state => state.exposState.working_delivery_id).subscribe(wd_id => {
      this.working_delivery_id = wd_id;
    });
	 this.store.select((state) => state.exposState.delivery_images_preview).subscribe((images) => {
		 if( !_.isEqual(this.images_list, images) ){
			 this.images_list = images;
		 }
	 });
	 this.store.select((state) => state.exposState.delivery_damages_preview).subscribe((damages) => {
		 if( !_.isEqual(this.damages_list, damages) ){
			 this.damages_list = damages;
		 }
	 });

  }

  ngOnChanges(changes: SimpleChanges): void {
    let page = changes.pagination && changes.pagination.currentValue ? 1 : null;
    if (changes.status && changes.status.currentValue && !this.showStatus) {
      this.store.dispatch(new actions.LoadPagedDeliveryOrdersAction({ status: changes.status.currentValue, page: page }));
      this.totalRecords$ = this.store.select((state) => state.exposState.total_deliveries).pipe(
        map((total_deliveries: { [status: string]: number }) => _.get(total_deliveries, this.status, 0))
      );
    }
  }

  ngOnInit() {
    this.store.dispatch(new actions.LoadForwardsAction());
    if (this.status == "ready") this.store.dispatch(new actions.GetPopsAction());
    this.bundle_types$ = this.store.select(state => state.catalogsState.bundle_types);
    this.priorities$ = this.store.select(state => state.catalogsState.priorities);
    this.countries$ = this.store.select(state => state.catalogsState.countries);
    this.store.select(state => state.catalogsState.carriers).subscribe(carriers => {
      this.carriers = _(carriers).filter((carrier) => carrier.type == 'ForwardCarrier').keyBy('id').value()
    });

    combineLatest(
      this.store.select((state) => state.catalogsState.active_client),
      this.store.select(state => state.exposState.forwards),
      this.store.select((state) => state.exposState.deliveries)).subscribe(([client, forwards, deliveries]) => {
        this.client_id = client.id;
        this.forwards = _.filter(forwards, (forward) => forward.client_id == this.client_id);
        this.addresses = _(this.forwards).map((forward) => forward.address).keyBy('id').value();
        if (deliveries && !this.readOnly) {
          this.items = _(deliveries).filter((delivery) => delivery.asn.client_id == this.client_id).keyBy('id').value();
          this.paginating = false;
        }
      });
  }

  onRowEditInit(delivery: DeliveryOrder) {
    this.editedDeliveries[delivery.id] = _.cloneDeep(delivery);
  }

  onRowEditSave(delivery: DeliveryOrder) {
    let deli = _.cloneDeep(this.editedDeliveries[delivery.id]);
    this.items[delivery.id] = deli;
    this.save(deli);
    delete this.editedDeliveries[delivery.id];
  }

  onRowEditCancel(delivery: DeliveryOrder) {
    delete this.editedDeliveries[delivery.id];
  }

  deliveryColor(delivery: DeliveryOrder) {
    return delivery.damages_count > 0 ? '#ffcccc' : '';
  }

  lazyLoad(event: any) {
    let page = (event.first / event.rows) + 1;
    this.paginating = true;
    this.store.dispatch(new actions.LoadPagedDeliveryOrdersAction({ status: this.status, page: page }));
  }

  pickForwardByAddress(address_id): Forward {
    return _.find(this.forwards, (forward) => forward.address_id == address_id);
  }

  toggle(delivery: DeliveryOrder) {
    this.store.dispatch(new actions.SetWorkingDeliveryOrderAction({ id: delivery.id }));
  }

  printPop(delivery: DeliveryOrder) {
    this.exposService.getDeliveryOrderPop(delivery.id).subscribe((data: any) => {
      let url = URL.createObjectURL(data);
      window.open(url, '_blank');
    });
  }

  canPrintPop(delivery: DeliveryOrder) {
    return !!delivery.carrier_id && !!this.pickCarrier(delivery.carrier_id)?.pop_url;
  }

  pickCarrier(carrier_id: number): Carrier {
    return this.carriers[carrier_id];
  }


  save(delivery: DeliveryOrder) {
    this.store.dispatch(new actions.SaveDeliveryOrderAction(delivery));
  }

  openOptions(event: any, tiered: any, delivery: DeliveryOrder) {
    this.deliveryOptions = delivery;
    this.menus = [
      {
        label: 'View Invoice',
        icon: 'pi pi-fw pi-file-pdf',
        disabled: !this.deliveryOptions.invoice_url,
        command: () => {
          this.viewFiles(this.deliveryOptions.invoice_url);
        }
      }, {
        label: 'View ITN',
        icon: 'pi pi-fw pi-file-pdf',
        disabled: !this.deliveryOptions.itn_url,
        command: () => {
          this.viewFiles(this.deliveryOptions.itn_url);
        }
      }, {
        label: 'View DGD',
        icon: 'pi pi-fw pi-file-pdf',
        disabled: !this.deliveryOptions.dgd_url,
        command: () => {
          this.viewFiles(this.deliveryOptions.dgd_url);
        }
      }, {
        label: 'View TRK',
        icon: 'pi pi-fw pi-file-pdf',
        disabled: !this.deliveryOptions.shipping_url,
        command: () => {
          this.viewFiles(this.deliveryOptions.shipping_url);
        }
      }
    ];

    if(this.deliveryOptions.status !== 'ready'){
      this.menus.push({
      	  label: 'View POP',
      	  icon: 'pi pi-fw pi-image',
      	  disabled: !this.deliveryOptions.pop?.id,
      	  command: () => {
            let url = this.deliveryOptions.pop?.src as string;
      	  	  this.viewFiles(url);
      	  }
	  });
      this.menus.push({
        label: 'View Images',
        icon: 'pi pi-fw pi-image',
        disabled: this.deliveryOptions.images_count ? false : true,
        command: () => {
          this.showImages(this.deliveryOptions, 'images');
        }
      });
    }

    this.menus.push({
    	label: 'View Damages',
    	icon: 'pi pi-fw pi-image',
    	disabled: !this.deliveryOptions.damages_count,
    	command: () => {
			this.showImages(this.deliveryOptions, 'damages');
		}
	});

    tiered.toggle(event);
  }

  viewFiles(url: string) {
    window.open(url, '_blank');
  }

  showImages(delivery: DeliveryOrder, type: string ) {
	  type = type ? type : 'images';
    this.images_list = [];
    this.damages_list = [];
    this.store.dispatch(new actions.LoadDeliveryImagesAction({id: delivery.id, type: type}));
  }

}
