import { Injectable, Inject, NgZone } from "@angular/core";
import { GenericService } from "./generic.service";
import * as _ from 'lodash';
import { Address, Asn, AsnStatus, DeliveryOrder, Forward, Document, User, Bundle, Part } from "../models";
import { BehaviorSubject, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { NotificationService } from "../platform-services/notification.service";
import { AuthenticationService } from './authentication.service';

const FORWARDS_URL = 'forwards';
const ADDRESSES_URL = 'addresses';
const ASNS = 'asns';
const DELIVERIES_URL = 'deliveries';
const USERS_PATH = "users";
const POPS_PATH = "pops";
const POPS_LIST_PATH = `${USERS_PATH}/${POPS_PATH}`;
const POPS_CHANNEL = "PopsChannel";
const UPLOAD_POP_PATH = "users/pops";
const BUNDLES_URL = 'bundles';
const PARTS_URL = 'parts';
const IMAGES_URL = 'images';
const DAMAGES_URL = 'damages';

@Injectable({
  providedIn: 'root'
})
export class ExposService extends GenericService {
  private _pops: BehaviorSubject<{ [name: number]: Document }>;

  constructor(@Inject('env') env: any,
              http: HttpClient,
              ng_zone: NgZone,
              notificationService: NotificationService,
              private authenticationService: AuthenticationService
             ) {
    super(env, http, ng_zone, notificationService);
  }

  getForwards(): Observable<{[id: number]: Forward}> {
    return this.http.get<Forward[]>(this.serverUrl + FORWARDS_URL, this.httpOptions).pipe(
      map((data: any[]) => this.buildForwards(data)),
      catchError(this.handleError<Forward[]>('RetrievingForwards',[]))
    );
  }

  getAddresses(): Observable<{[id: number]: Address}> {
    return this.http.get<Address[]>(this.serverUrl + ADDRESSES_URL, this.httpOptions).pipe(
      map((data: any[]) => this.buildAddresses(data)),
      catchError(this.handleError<Address[]>('RetrievingAddresses',[]))
    );
  }

  saveForward(forward: Forward): Observable<Forward> {
    let data = forward.toSaveData();
    return (forward.id > 0 ? this.http.put<Forward>(this.serverUrl + FORWARDS_URL + '/' + forward.id, data, this.httpOptions) :
      this.http.post<Forward>(this.serverUrl + FORWARDS_URL, data, this.httpOptions)).pipe(
      map((data: any) => forward.id > 0 ? forward : new Forward(data)),
      catchError(this.handleError<Forward>('SavingForward', null))
    );
  }

  saveAddress(address: Address): Observable<Address> {
    return (address.id > 0 ? this.http.put<Address>(this.serverUrl + ADDRESSES_URL + '/' + address.id, address, this.httpOptions) :
      this.http.post<Address>(this.serverUrl + ADDRESSES_URL, address, this.httpOptions)).pipe(
      map((data: any) => {
        this.notificationService.toast('Address saved successfully', true, {severity: 'success', summary: 'Success'});
        return address.id > 0 ? address : new Address(data);
      }),
      catchError(this.handleError<Address>('SavingAddress', null))
    );
  }

  deleteForward(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.serverUrl + FORWARDS_URL + '/' + id, this.httpOptions).pipe(
      map((data: any) => {
        this.notificationService.toast('Forward deleted successfully', true, {severity: 'success', summary: 'Success'});
        return true;
      }),
      catchError(this.handleError<boolean>('DeletingForward', false))
    );
  }

  importForwards(forwards: Forward[]): Observable<{[id: number]: Forward}> {
    let data = _.map(forwards, (f: Forward) => f.toSaveData());
    return this.http.post<Forward[]>(this.serverUrl + FORWARDS_URL + '/import', {forwards: data}, this.httpMultipartOptions).pipe(
      map((data: any) => {
        this.notificationService.toast('Forwards imported successfully', true, {severity: 'success', summary: 'Success'});
        return this.buildForwards(data);
      }),
      catchError(this.handleError<Forward[]>('ImportingForwards', []))
    );
  }

  getAsns(id?: number): Observable<{[id: number]: Asn} | Asn> {
    const url = id ? this.serverUrl + ASNS + '/' + id : this.serverUrl + ASNS;
    return this.http.get<any[]>(url, this.httpOptions).pipe(
      map((data: any[]) => id ? new Asn(data) : this.buildAsns(data)),
      catchError(this.handleError<any[]>('RetrievingAsns',[]))
    );
  }

  getAsn(id: number): Observable<Asn> {
    return this.http.get<Asn>(this.serverUrl + ASNS + '/show/' + id, this.httpOptions).pipe(
      map((data: any) => new Asn(data)),
      catchError(this.handleError<Asn>('RetrievingAsn',null))
    );
  }

  getAsnsByStatus(status: string, page?: number): Observable<{totalRecords: number, data: {[id: number]: Asn}} | any[]> {
    global.log("Service",`Get ASNs by status ${status} and page ${page ? page : 'all'}`);
    return this.http.get<any[]>(this.serverUrl + ASNS + '/' + status, {
      params: page ? { 'page': page.toString()} : {},
      headers: this.httpOptions.headers,
      observe: 'response'
    }).pipe(
      map((data: any) => {
        let totalRecords = +data.headers.get('totalrecords');
        return {totalRecords: totalRecords, data: this.buildAsns(data.body)};
      }),
      catchError(this.handleError<any[]>('RetrievingAsns',[]))
    );
  }

  saveAsn(asn: Asn): Observable<Asn> {
    let data = asn.toSaveData();
    let isFormData = data instanceof FormData;
    return (asn.id > 0 ? this.http.put<Asn>(this.serverUrl + ASNS + '/' + asn.id, data, isFormData ? this.httpMultipartOptions : this.httpOptions) :
      this.http.post<Asn>(this.serverUrl + ASNS, data, isFormData ? this.httpMultipartOptions : this.httpOptions)).pipe(
      map((data: any) => {
        this.notificationService.toast('ASN saved successfully', true, {severity: 'success', summary: 'Success'});
        return new Asn(data);
      }),
      catchError(this.handleError<Asn>('SavingAsn', null))
    );
  }

  deleteAsn(id: number): Observable<boolean> {
    return this.http.delete<boolean>(this.serverUrl + ASNS + '/' + id, this.httpOptions).pipe(
      map((data: any) => {
        this.notificationService.toast('ASN deleted successfully', true, {severity: 'success', summary: 'Success'});
        return true;
      }),
      catchError(this.handleError<boolean>('DeletingAsn', false))
    );
  }

  getPagedDeliveryOrders(status:string, page?: number): Observable<any[] | {totalRecords: number, data: {[id: number]: DeliveryOrder}}> {
    global.log("Service",`Get page ${page || 'all'} of delivery orders with status ${status}`);
    let params = {};
    if (page) {
      params['page'] = page.toString();
    }
    return this.http.get<any[]>(this.serverUrl + DELIVERIES_URL + '/' + status,{
      params: params,
      headers: this.httpOptions.headers,
      observe: 'response'
    }).pipe(
      map((data: any) => {
        let totalRecords = +data.headers.get('totalrecords');
        return {totalRecords: totalRecords, data: this.buildDeliveryOrders(data.body)};
      }),
      catchError(this.handleError<any[]>('RetrievingDeliveryOrders',[]))
    );
  }

  getDeliveryOrder(id: number): Observable<DeliveryOrder> {
    return this.http.get<DeliveryOrder>(this.serverUrl + DELIVERIES_URL + '/show/' + id, this.httpOptions).pipe(
      map((data: any) => {
        return new DeliveryOrder(data);
      }),
      catchError(this.handleError<DeliveryOrder>('RetrievingDeliveryOrder',null))
    );
  }

  saveDeliveryOrder(delivery: DeliveryOrder): Observable<DeliveryOrder> {
    let data = delivery.toSaveDataForm();
    let isFormData = data instanceof FormData;
    return (delivery.id > 0 ? this.http.put<DeliveryOrder>(this.serverUrl + DELIVERIES_URL + '/' + delivery.id, data, isFormData ? this.httpMultipartOptions : this.httpOptions) :
      this.http.post<DeliveryOrder>(this.serverUrl + DELIVERIES_URL, data, isFormData ? this.httpMultipartOptions : this.httpOptions)).pipe(
      map((new_data: any) => {
        this.notificationService.toast('Delivery order saved successfully', true, {severity: 'success', summary: 'Success'});
        return new_data ? new DeliveryOrder(new_data) : delivery;
      }),
      catchError(this.handleError<DeliveryOrder>('SavingDeliveryOrder', delivery))
    );
  }

  getDeliveryImages(id: number, type: string): Observable<any[]> {
  	  type = type === 'damages' ? DAMAGES_URL : IMAGES_URL;
  	  let url = this.serverUrl + DELIVERIES_URL + '/' + type + '/' + id;
    return this.http.get<any[]>(url, this.httpOptions).pipe(
      map((data: any[]) => {
        return data;
      }),
      catchError(this.handleError<any[]>('RetrievingDeliveryImages',[]))
    );
  }

  getPops() {
    if (!this._pops) {
      this._pops = new BehaviorSubject<{ [name: number]: Document }>({});
      this.setPopsSnap();
    }
    return this._pops;
  }

  setPopsSnap() {
    global.log("Service",`Get available documents`);
    this.http.get<any[]>(this.serverUrl + POPS_LIST_PATH, this.httpOptions).pipe(
      catchError(this.handleSnapError<any[]>('Get documents', []))
    ).subscribe((data: any) => this.buildPops(data));
    this.authenticationService.currentUser.subscribe((user: User) => {
      if (user && user.id){
        global.log("Service",`Setup channel for user id: ${user.id}`);
        this.openChannel({channel: POPS_CHANNEL, user_id: user.id }).
          subscribe((data: any) => {
            if (data)
              this.buildPops(data)
          });
      }
    });
  }

  uploadPop(doc: FormData): Observable<boolean> {
    global.log("Service",`saving new document`);
    return this.http.put(this.serverUrl + UPLOAD_POP_PATH, doc, this.httpMultipartOptions).pipe(
      map(() => true),
      catchError(this.handleError<boolean>(` upload pop`, false))
    );
  }

  deletePop(id: number) {
    this.http.delete(this.serverUrl + `${USERS_PATH}/${id}/${POPS_PATH}`, this.httpOptions).pipe(
      catchError(this.handleSnapError<any[]>('Delete pop', []))
    ).subscribe(() => global.log("Service",`Pop delete request completed`));
  }

  buildPops(data: any[]) {
    global.log("Service",`Snapshot of documents received: ${data.length}`);
    this._pops.next(_(data).map((m) => new Document(m)).keyBy("id").value());
  }

  getNotReceivedAsns(type: string): Observable<{ [id: number]: Asn }> {
    return this.http.get<Asn[]>(`${this.serverUrl}${ASNS}/${type}`, this.httpOptions).pipe(
      map((data: any[]) => {
        return this.buildAsns(data)
      }),
      catchError(this.handleError<Asn[]>('RetrievingPendingAsns', []))
    );
  }

  getAnsBundles(id: number): Observable<{ [id: number]: Bundle }> {
    return this.http.get<Bundle[]>(`${this.serverUrl}${ASNS}/${id}/bundles`, this.httpOptions).pipe(
      map((data: any[]) => this.buildBundles(data)),
      catchError(this.handleError<Bundle[]>('RetrievingAsnBundles', []))
    );
  }

  toggleBundlePartReceived(part: Part): Observable<Part> {
    return this.http.put<Part>(`${this.serverUrl}${PARTS_URL}/${part.id}/toggle_received`, {}, this.httpOptions).pipe(
      map(response => new Part(response)),
      catchError(this.handleError<Part>('TogglingAsnBundlePartReceived', part))
    );
  }

  closeAsn(id: number): Observable<Asn> {
    return this.http.put<Asn>(`${this.serverUrl}${ASNS}/${id}/mark_received`, {}, this.httpOptions).pipe(
      map(response => new Asn(response)),
      catchError(this.handleError<Asn>('ClosinAsnScan', new Asn({ id })))
    );
  }

  resetAsnScan(id: number): Observable<Asn> {
    return this.http.put<Asn>(`${this.serverUrl}${ASNS}/${id}/reset_scan`, {}, this.httpOptions).pipe(
      map(response => new Asn(response)),
      catchError(this.handleError<Asn>('ResettingAsnScan', new Asn({ id })))
    );
  }

  addPartToAsn(asn_id: number, serial: string, bundle_id?: number): Observable<Bundle> {
    var url = `${this.serverUrl}${ASNS}/${asn_id}/add_serial_number/${serial}`;
    if (bundle_id) url += `/${bundle_id}`;
    return this.http.put<Bundle>(url, {}, this.httpOptions).pipe(
      map(response => {
        return new Bundle(response);
      }),
      catchError(this.handleError<any>('AddingPartToASN', {}))
    );
  }

  updateBundle(bundle: Bundle, props: string[] = null): Observable<Bundle> {
    var data = bundle.toSaveData(props);
    return this.http.put<Bundle>(`${this.serverUrl}${BUNDLES_URL}/${bundle.id}`, data, this.httpOptions).pipe(
      map(response => {
        return new Bundle(response)
      }),
      catchError(this.handleError<any>('UpdatingBundle', {}))
    );
  }

  private buildBundles(data: any[]): { [id: number]: Bundle } {
    var data = _(data).map((e) => new Bundle(e)).keyBy('id').value();
    return data;
  }

  getDeliveryOrderPop(id: number): Observable<any> {
    let getReq = this.http.get(this.serverUrl + DELIVERIES_URL + '/pop/' + id + '.pdf', {
      headers: new HttpHeaders({'Content-Type': 'application/pdf'}),
      observe: 'response',
      responseType: "blob"
    });
    return getReq.pipe(
      map((response: any) => {
        return response.body;
      }),
      catchError(this.handleError<any>(' retrieving delivery order pop', null))
    );
  }

  private buildForwards(data: any[]): {[id: number]: Forward} {
    let tmp = _(data).map((m: any) => new Forward(m)).keyBy('id').value();
    return tmp;
  }

  private buildAddresses(data: any[]): {[id: number]: Address} {
    let tmp = _(data).map((m: any) => new Address(m)).keyBy('id').value();
    return tmp;
  }

  private buildAsns(data: any[]): {[id: number]: any} {
    let tmp = _(data).map((m: any) => new Asn(m)).keyBy('id').value();
    return tmp;
  }

  private buildDeliveryOrders(data: any[]): {[id: number]: DeliveryOrder} {
    let tmp = _(data).map((m: any) => new DeliveryOrder(m)).keyBy('id').value();
    return tmp;
  }

}
