import { Catalog } from "./catalog";

export class BundleType extends Catalog {
    validationKey: string = "bundle_type";
    cost: number;

    constructor(data:any) {
        super(data);
        if(this.cost) this.cost = +this.cost;
    }

    protected get saveProp(): string[] {
        return  super.saveProp.concat(["cost"]);
    }
}
