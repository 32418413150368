import { Store } from '@ngxs/store';
import { AuthenticationService, Carrier, Client, Printer, LocalStorage, SettingsEnum, NotificationService, ChannelsService, User, Warehouse } from 'wms-lib';
import * as actions from 'wms-lib';
import { OnInit, Directive, NgZone } from '@angular/core';
import * as _ from 'lodash';
import { NotificationsBaseComponent } from './notifications.component.base';

@Directive({selector: '[appBaseComponent]'})
export class AppComponentBase extends NotificationsBaseComponent implements OnInit {
  constructor(
		protected store: Store,
		protected authenticationService: AuthenticationService,
		ng_zone: NgZone,
		notificationService: NotificationService,
		channels: ChannelsService
	) {
		super(store, authenticationService, ng_zone, notificationService, channels);
	}

  ngOnInit() {
    this.store.dispatch(new actions.LoadCatalogsAction());
    this.currentUser$ = this.authenticationService.currentUser;
    this.currentUser$.subscribe((user: User) => {
      if (user && (user.isMaterialist || user.isSupervisor || user.isClient))
        user.clients.
        filter((c: Client) => c.part_number).
        forEach((f: Client) => this.store.dispatch(new actions.LoadPartNumbersAction(f.id)));
      this.store.select((state) => state.catalogsState.warehouses).subscribe((warehouses: {[id: number]: Warehouse}) => {
        Warehouse.SELECTED = user.warehouse_id ? warehouses[user.warehouse_id] : _(warehouses).values().first();
      });
    });
    this.store.select((state) => state.catalogsState.carriers).subscribe((carriers: {[id: number]: Carrier}) => Carrier.CARRIERS = carriers);
    this.store.select((state) => state.catalogsState.clients).subscribe((clients: {[id: number]: Client}) => {
      Client.CLIENTS = clients;
    });
    this.store.select((state) => state.catalogsState.printers).subscribe((printers: {[id: number]: Printer}) => {
      let tmp = LocalStorage.getString(SettingsEnum.PRINTER);
      if (tmp)
        Printer.SELECTED = printers[tmp];
      else
        Printer.SELECTED = _(printers).values().first();
    });
    super.ngOnInit();
  }
}
