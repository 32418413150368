import { Component, OnInit, Input, ViewChild, OnChanges} from '@angular/core';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import { ArrivePackingList, ContainerType} from 'wms-lib';
import * as actions from 'wms-lib';


@Component({
    selector: 'pallet-dialog-form',
    templateUrl: './pallets-dialog-form.component.html',
    styleUrls: ['../../../../shared/components/base-components/packing-lists.component.scss']
})
export class PalletDialogFormComponent implements OnInit, OnChanges {
    @Input() apl: ArrivePackingList = undefined;
    container_types: {[id: number]: ContainerType}
    pallet_quantity: number = 0;
    container_type_id: number = undefined;
    constructor(private store: Store) {}

    ngOnInit(): void {
        this.store.select(state => state.catalogsState.container_types).subscribe((data: {[id: number]: ContainerType}) => { 
            this.container_types = data;
            this.container_type_id = _(data).values().find((ct: ContainerType) => ct.default == 't').id;
        });
    }

    ngOnChanges(changes: any) {
        this.apl = changes.apl.currentValue;
    }

    get total_packages(): number {
        return this.apl?.total_packages || 0;
    }

    get total_packages_with_pallets(): number {
        return this.apl?.packagesWithPallets.length;
    }

    addPallet() {
        this.store.dispatch(new actions.AddPalletToPackageAction( { quantity: this.pallet_quantity, container_type_id: this.container_type_id, arrival_package_id: this.apl.id } ))
    }
}
