import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { NotificationViewModel } from "../../models/notification-view-model";
import * as actions from '../actions/notifications.actions';
import * as _ from 'lodash';

export interface INotificationsState {
    notifications: {[controller:string]:{[id:number]: NotificationViewModel}},
    modified?: boolean
}

const defaultStateValue = {
    notifications: {},
    modified: false,
};
@State<INotificationsState>({
    name: 'notificationsState',
    defaults:  { ...defaultStateValue}
})
@Injectable()
export class NotificationsState {

    @Action(actions.AddNotificationAction)
    addNotification(ctx: StateContext<INotificationsState>, {payload}: actions.AddNotificationAction ) {
        let notifications = ctx.getState().notifications;
        notifications[payload.controller] = _.merge(notifications[payload.controller] ? notifications[payload.controller]: {},{[payload.id]: payload});
        notifications[payload.controller] = {...notifications[payload.controller]};
        ctx.patchState({notifications: {...notifications}, modified: true});
    }

    @Action(actions.DismissNotificationAction)
    dismissNotification(ctx: StateContext<INotificationsState>, {payload}: actions.AddNotificationAction ) {
        let notifications = ctx.getState().notifications;
        delete notifications[payload.controller][payload.id];
        notifications[payload.controller] = {...notifications[payload.controller]};
        ctx.patchState({notifications: {...notifications}, modified: true});
    }

    @Action(actions.ResetModifiedNotificationsFieldAction)
    resetModifiedNotificationsField(ctx: StateContext<INotificationsState>) {
        ctx.patchState({modified: false})
    }

    @Action(actions.RestoreNotificationAction)
    restoreNotifications(ctx: StateContext<INotificationsState>, {payload}: actions.RestoreNotificationAction ){
        ctx.setState({...payload, modified: false});
    }

    @Action(actions.ClearNotificationsStateAction)
    clearState(ctx: StateContext<INotificationsState>){ 
        global.log("State",`Clear shipments state`);
        ctx.setState({...defaultStateValue});
    }
}