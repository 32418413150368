import { OnInit, Directive } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { Ticket, TicketsService, Client, TicketPagedList, GetTicketAction } from 'wms-lib';
import * as _ from 'lodash';
import { Observable } from "rxjs";
import { map } from 'rxjs/operators';

const STATUS_COLORS = ['rgb(240, 140, 140)', 'rgb(255, 255, 162)', 'rgb(132, 221, 132)'];

@Directive({selector: '[homeBase]'})
export class HomeBaseComponent implements OnInit {
    tickets_summary: any[];
    tickets_details$: Observable<Ticket[]>;
    constructor(
        protected router: Router,
        protected store: Store,
        protected tickets_service: TicketsService) { }

    ngOnInit() {
        this.store.select((state) => state.catalogsState.clients).subscribe((clients: {[id: number]: Client}) => Client.CLIENTS = clients);

        this.tickets_service.getSummary().subscribe((data: any) => {
          if (!data.errors) {
            this.tickets_summary = _(data).map(
              (v: any,k: string) => ({
                name: k,
                total: _(v).sumBy((vv: any) => vv.value),
                gauge_options: this.build_gauge_options(v.map((vv: any) => vv.value)),
                items: v
              })
            ).filter((item: any) => item.total > 0).value();
          }
        });
    }

    build_gauge_options(values: number[]): any {
        let total = _.sum(values);
        let filter_colors = [];
        let delimiters = _(values).
            map((v: number) => (v/total)*100).
            filter((f: number, idx: number) => {
                if (f == 0) {
                    filter_colors.push(idx);
                    return false;
                }
                return true;
            }).
            map((s => a => s += a)(0)).
            dropRight().value();

        return {
            hasNeedle: false,
            arcColors: STATUS_COLORS.filter((f: string, idx: number) => !filter_colors.includes(idx)),
            arcDelimiters: delimiters,
            rangeLabel: ['0', ''+total]
        };
    }

    displayTicketsDetails(dialog: any,event: any, group: string, key: string) {
        dialog.toggle(event);
        this.tickets_details$ = this.tickets_service.getPagedTicketsFromApi(key, null, null, group.startsWith("Assign") ? 'in' : 'out').
            pipe(map((tp: TicketPagedList) => _(tp.items).values().value()));
        event.preventDefault();
    }

    navigateToTicket(id: number, event: any) {
        this.router.navigate(['/help-desk', 'tickets'], { queryParams: { ticket_id: id }});
        event.preventDefault();
    }
}
