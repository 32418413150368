import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
    name: 'filterBy'
})
export class FilterByPipe implements PipeTransform {
    transform(data: any, prop: string, condition: any = null, comparator: string = null): Array<any> {
        if (condition !== null) {
            return _(data).values().filter((e: any) => 
                Array.isArray(e[prop]) ?
                e[prop].some((x: any) => condition instanceof RegExp ? condition.exec(x) : condition == x) :
                condition instanceof RegExp ? condition.exec(e[prop]) : this.compare(condition, e[prop], comparator) ).value();
        }
        return _.values(data);
    }

    compare(condition: any, prop: any, comparator: string) {
        return comparator ? eval(`${+prop} ${comparator} ${+condition}`) : (condition == prop);
    }
}
