import { Component, OnInit, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Client, NotificationService, Catalog, Asn, AuthenticationService } from 'wms-lib';
import * as actions from 'wms-lib';
import * as _ from 'lodash';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-asns-list-table',
    templateUrl: './asns-list-table.component.html',
    styleUrls: ['./asns-list-table.component.scss']
})
export class AsnsListTableComponent implements OnInit, OnChanges {
    bundle_types$: Observable<{[id: number]: Catalog }>;
    asns: {[id: number]: Asn };
    client_id: number = 0;
    clients: { [id: number]: Client } = {};
    paginating: boolean = false;
    @Input() status: string;
    @Input() pagination: boolean = false;
    totalRecords$: Observable<number>;
    
    constructor(private router: Router, protected store: Store, protected notificationService: NotificationService, protected authenticationService: AuthenticationService) {
        this.store.select(state => state.exposState.asns).subscribe((asns) => {
            this.paginating = false;
            this.asns = asns;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.status && changes.status.currentValue) {
            this.status = changes.status.currentValue;
            this.store.dispatch(new actions.LoadAsnsByStatusAction({status: this.status, page: this.pagination ? 1 : null }));
            this.totalRecords$ = this.store.select((state) => state.exposState.total_asns).pipe(
                map((total_asns: {[status: string]: number}) => _.get(total_asns, this.status, 0))
            );
        }
    }

    ngOnInit() {
        this.store.select((state) => state.catalogsState.active_client).subscribe((client: Client) => this.client_id = client.id );
        this.store.select((state) => state.catalogsState.clients).subscribe((clients: {[id: number]: Client}) => this.clients = clients);
    }

    edit(asn?: Asn) {
        this.router.navigate(['exports','asns', asn ? asn.id : 0]);
    }

    deleteAsn(asn: Asn) {
        this.notificationService.showFeedback(
            'Are you sure you want to this ASN?',
            'Confirm',
            false,
            null,
            () => this.store.dispatch(new actions.DeleteAsnAction(asn.id))
        );
    }

    lazyLoad(event: any) {
        let page = (event.first/event.rows)+1;
        this.paginating = true;
        this.store.dispatch(new actions.LoadAsnsByStatusAction({status: this.status, page: page }));
    }

    canDelete(){     
        return this.authenticationService.currentUserValue.isAdmin;
    }

    get colSpan() {
        if (this.status == 'transit') return 8;
        if (this.status == 'working') return 10;
        if (this.status == 'ready') return 9;
        if (this.status == 'closed') return 8;
        return 8;
    }
    
}
