import { Arrival, Package, Document, PackingListGroupTypes, Pallet, ArrivePackingList, Warehouse } from '../../models';
import { Injectable } from '@angular/core';
import { State, Action, StateContext, Selector, createSelector } from '@ngxs/store';
import { ArrivalsService, ConciliationService, PackagesService, ShipmentsService, ArrivePackingListService } from '../../services';
import * as actions from '../actions';
import * as _ from 'lodash';
import { Subscription } from 'rxjs';


export interface IArrivalsState {
  arrivals: { [id: number]: Arrival };
  total_arrivals: number,
  open_arrival: Arrival;
  all_loaded: boolean;
  error: any;
  documents: { [id: string]: Document };
  working_packing_id: number;
  expanded_packings: number[];
  working_package_id: number;
  working_package_id_part_number: number;
  undoc_sel_pkgs: number[];
  document: Document | any;
  updating: boolean;
  state_ahead: boolean;
  service_active: string;
  last_completed_packing_list_id?: number,
  packings_tab_id?: number;
  connected: boolean;
  batchMode: boolean;
}

const defaultStateValue = {
  arrivals: {},
  total_arrivals: null,
  open_arrival: null,
  all_loaded: false,
  error: null,
  documents: {},
  working_packing_id: null,
  working_package_id: null,
  working_package_id_part_number: null,
  document: null,
  expanded_packings: [],
  undoc_sel_pkgs: [],
  updating: false,
  state_ahead: false,
  service_active: null,
  last_completed_packing_list_id: null,
  packings_tab_id: null,
  connected: false,
  batchMode: false
}

@State<IArrivalsState>({
  name: 'arrivalsState',
  defaults: {...defaultStateValue }
})
@Injectable()
export class ArrivalsState {
  private _open_arrivals: Subscription;

  @Selector()
  static findPackage(state: IArrivalsState) {
    return (id: number) => {
      return state.open_arrival.findPackage(id);
    };
  }

  @Selector()
  static posFromActiveArrival(state: IArrivalsState) {
    return () => {
      return state.open_arrival ? state.open_arrival.all_packings_pos : [];
    };
  }

  static getDocumentFromWorkingPacking() {
    return createSelector([ArrivalsState], (state: IArrivalsState) => {
      if (state.open_arrival.arrive_packing_lists[state.working_packing_id || state.last_completed_packing_list_id]) {
        return state.open_arrival.arrive_packing_lists[state.working_packing_id || state.last_completed_packing_list_id].document;
      }
      return null;
    });
  }


  constructor(
    private arrivals_service: ArrivalsService,
    private conciliation_service: ConciliationService,
    private package_service: PackagesService,
    private shipment_service: ShipmentsService,
    private arrive_packing_list_service: ArrivePackingListService
  ) {
  }


  @Action(actions.GetActiveArrivalsAction)
  getActiveArrivals(ctx: StateContext<IArrivalsState>, action: actions.GetActiveArrivalsAction) {
    global.log("State",`Get active arrival`)
    if (!this._open_arrivals || ctx.getState().service_active != action.payload) {
      this._open_arrivals = this.arrivals_service.getActiveArrivals(action.payload)
        .subscribe((response: {[id: number]: Arrival}) => {
          const state = ctx.getState();
          const arrivals = _(state.arrivals).values().
            filter((a: Arrival) => a.open == false).keyBy("id").
            merge(response || {}).
            value();
          ctx.patchState({ arrivals: { ...arrivals }, service_active: action.payload});
        });
    }
  }

  @Action(actions.GetPagedArrivalsAction)
  getPagedArrivals(ctx: StateContext<IArrivalsState>, action: actions.GetPagedArrivalsAction) {
    global.log("State",`Get page ${action.payload.page} of ${action.payload.type} arrivals ${action.payload.search ? `with search ${action.payload.search}` : ''}`)
    this.arrivals_service
      .getPagedArrivals(action.payload.type, action.payload.page, action.payload.search)
      .subscribe((response: {totalRecords: number, arrivals: {[id: number]: Arrival}}) => {
        const state = ctx.getState();
        let patch = {};
        if (state.service_active != action.payload.type) {
          patch["service_active"] = action.payload.type;
          patch["arrivals"] = {};
        } else
          patch["arrivals"] = { ...state.arrivals };

        patch["total_arrivals"] = response.totalRecords;
        if (_.isEmpty(response))
          patch["all_loaded"] = true;
        else
          patch["arrivals"] = _.merge(response.arrivals, patch["arrivals"]);
        ctx.patchState(patch);
      });
  }

  @Action(actions.GetArrivalAction)
  getArrival(ctx: StateContext<IArrivalsState>, action: actions.GetArrivalAction) {
    global.log("State",`Get arrival ${action.payload.id}`)
    this.arrivals_service.getArrival(action.payload.id, action.payload.type).
      subscribe((a: Arrival) => {
        const state = ctx.getState();
        if (!state.updating) ctx.patchState({ open_arrival: a });
      });
    this.arrivals_service.allConnected.subscribe((connected: boolean) => ctx.patchState({ connected: connected }));
  }

  @Action(actions.UpdateArriveAction)
  updateArrive(ctx: StateContext<IArrivalsState>, action: actions.UpdateArriveAction) {
    if (action.payload.package)
      ctx.dispatch(new actions.SetWorkingPackageAction({ package: action.payload.package }));

    ctx.patchState({ open_arrival: action.payload.arrival });
    const state = ctx.getState();
    if (!state.batchMode) {
      if (!state.updating) {
        global.log("State",`Update Arrive Action`)
        ctx.patchState({ updating: true });
        if (action.payload.arrival.stored)
          this.arrivals_service.save(action.payload.arrival, (action.payload.arrival.open === false && !action.payload.arrival.printed_packages) ? Arrival.update_open_props : null).
          subscribe((a: Arrival) => {
            ctx.patchState({ updating: false });
            if (a.isValid)
              this.concurrencyHandler(ctx, a);
            else
              ctx.patchState({ open_arrival: a, state_ahead: false });
          });
        else {
          action.payload.arrival.warehouse_id = Warehouse.SELECTED.id;
          this.arrivals_service.create(action.payload.arrival).subscribe((a: Arrival) => this.concurrencyHandler(ctx, a));
        }
      } else
        ctx.patchState({ state_ahead: true });
    }
  }

  private concurrencyHandler(ctx: StateContext<IArrivalsState>, arrival: Arrival){
    global.log("State",`Concurrent handler used`);
    const state = ctx.getState();
    if (state.state_ahead) {
      arrival.merge(state.open_arrival);
      ctx.patchState({ updating: false, state_ahead: false });
      ctx.dispatch(new actions.UpdateArriveAction({ arrival: arrival, package: null }))
    } else {
      ctx.patchState({ open_arrival: arrival, updating: false });
      if (!arrival.open){
        let arrivals = state.arrivals;
        arrivals[arrival.id] = arrival;
        ctx.patchState({ arrivals: _.clone(arrivals) });
      }
    }
  }

  @Action(actions.MarkPackageToBeDeletedAction)
  markPackageToBeDeleted(ctx: StateContext<IArrivalsState>, { payload }: actions.MarkPackageToBeDeletedAction) {
    this.markOrUnmarkPackageDeletion(ctx, payload.pkg, true);
  }

  @Action(actions.MarkPackageToNotBeDeletedAction)
  markPackageToNotBeDeleted(ctx: StateContext<IArrivalsState>, { payload }: actions.MarkPackageToNotBeDeletedAction) {
    this.markOrUnmarkPackageDeletion(ctx, payload.pkg, false);
  }

  private markOrUnmarkPackageDeletion(ctx: StateContext<IArrivalsState>, pkg: Package, remove: boolean) {
    let state = ctx.getState();
    pkg._destroy = remove;
    let packages = state.open_arrival
      .arrive_packing_lists[pkg.arrive_packing_list_id].packages;
    packages[pkg.id] = pkg;
    packages = { ...packages };
    ctx.patchState({ open_arrival: state.open_arrival });
  }

  @Action(actions.GetDocumentsAction)
  getDocuments(ctx: StateContext<IArrivalsState>) {
    global.log("State",`Get documents`)
    this.conciliation_service.getDocuments().subscribe((response) => {
      const docs = this.assignNewDocumentToSelectedPackingIfAny(ctx,response);
      ctx.patchState({ documents: docs });
    });
  }

  private assignNewDocumentToSelectedPackingIfAny(ctx: StateContext<IArrivalsState>, docs: any) {
    let state = ctx.getState();
    if (state.working_packing_id) {
      const new_doc = _(docs).find((f: Document) => !state.documents[f.id] );
      if (new_doc && !state.open_arrival.arrive_packing_lists[state.working_packing_id].document) {
        ctx.dispatch(new actions.AssignDocumentToWorkingPackingAction({ document: new_doc }))
        delete docs[new_doc.id];
      }
    }
    return docs;
  }

  @Action(actions.AssignDocumentToWorkingPackingAction)
  assignDocumentToWorkingPacking(ctx: StateContext<IArrivalsState>, action: actions.AssignDocumentToWorkingPackingAction) {
    global.log("State",`Assign Document To working Packing`)
    if (!action.payload.document)
      ctx.dispatch(new actions.SetWorkingPackingAction({packing_id: null, attach_doc: false}));
    let state = ctx.getState();
    if (state.working_packing_id) {
      let working_pack = state.open_arrival.arrive_packing_lists[state.working_packing_id];
      let document = action.payload.document != null && !(action.payload.document instanceof Document) ? new Document(action.payload.document) : action.payload.document;
      working_pack.document = document;

      if (working_pack.isValid){
        ctx.patchState({ working_package_id: null, working_packing_id: null });
      } else {
        ctx.patchState({
          last_completed_packing_list_id: null,
          packings_tab_id: PackingListGroupTypes.active});
      }
      ctx.dispatch(new actions.UpdateArriveAction({ arrival: state.open_arrival, package: null }));
    }
  }

  @Action(actions.DeleteDocumentsAction)
  deleteDocuments(ctx: StateContext<IArrivalsState>, {payload} : actions.DeleteDocumentsAction) {
    global.log("State",`Delete documents`)
    let documents = ctx.getState().documents;
    delete documents[payload];
    ctx.patchState({ documents: {...documents} });
    this.conciliation_service.deleteDocuments(payload);
  }

  @Action(actions.SetNavInstructionAction)
  setNavInstruction(ctx: StateContext<IArrivalsState>, {payload} : actions.SetNavInstructionAction) {
    ctx.patchState({ [payload.field]: payload.value });
  }

  @Action(actions.SetWorkingPackingAction)
  setWorkingPacking(ctx: StateContext<IArrivalsState>, { payload }: actions.SetWorkingPackingAction) {
    ctx.patchState({ working_packing_id: payload.packing_id });
    if (payload.packing_id) {
      let state = ctx.getState();
      ctx.dispatch(new actions.ToggleExpandedPackageAction({id: payload.packing_id, expanded: true, single: true}));
      if (state.document && payload.attach_doc){
        ctx.dispatch(new actions.AssignDocumentToWorkingPackingAction({document: state.document}));
        ctx.patchState({ document: null });
      }
    }
  }

  @Action(actions.SetWorkingPackageAction)
  setWorkingPackage(ctx: StateContext<IArrivalsState>, { payload }: actions.SetWorkingPackageAction) {
    ctx.patchState({ working_package_id: payload.package && payload.package.id });
    ctx.dispatch(new actions.SetWorkingPackingAction({ packing_id: payload.package && payload.package.arrive_packing_list_id, attach_doc: false }));
  }

  @Action(actions.SetWorkingPackageForPartNumberAction)
  setWorkingPackageForPartNumber(ctx: StateContext<IArrivalsState>, { payload }: actions.SetWorkingPackageForPartNumberAction) {
  	  ctx.patchState({ working_package_id_part_number: payload.package && payload.package.id });
  }

  @Action(actions.ToggleUndocPkgAction)
  toggleUndocPkg(ctx: StateContext<IArrivalsState>, { payload }: actions.ToggleUndocPkgAction) {
    let usp = ctx.getState().undoc_sel_pkgs;
    if (payload.package){
      usp = usp.filter((f: number) => f != payload.package.id);
      if (payload.selected) usp.push(payload.package.id);
    } else
      usp = [];
    ctx.patchState({ undoc_sel_pkgs: usp });
  }

  @Action(actions.ToggleExpandedPackageAction)
  toggleExpandedPackages(ctx: StateContext<IArrivalsState>, { payload }: actions.ToggleExpandedPackageAction) {
    let ep = ctx.getState().expanded_packings;
    ep = payload.single ? [] : ep.filter((f: number) => f != payload.id);
    if (payload.expanded) ep.push(payload.id);
    ctx.patchState({ expanded_packings: ep });
  }

  @Action(actions.SetWorkingDocumentAction)
  setWorkingDocument(ctx: StateContext<IArrivalsState>, { payload }: actions.SetWorkingDocumentAction) {
    ctx.patchState({ document: payload.document });
  }

  @Action(actions.ClearArrivalsStateAction)
  clearState(ctx: StateContext<IArrivalsState>){
    global.log("State",`Clear arrivals state`);
    ctx.setState({...defaultStateValue});
  }

  @Action(actions.DeleteArrivalAction)
  deleteArrival(ctx: StateContext<IArrivalsState>, {payload} : actions.DeleteArrivalAction) {
    global.log("State",`Delete arrival`)
    let arrivals = ctx.getState().arrivals;
    let aToDel = arrivals[payload];
    delete arrivals[payload];
    ctx.patchState({ arrivals: {...arrivals} });
    this.arrivals_service.delete(payload).subscribe((result: boolean) => {
      if (!result) {
        arrivals[payload] = aToDel;
        ctx.patchState({ arrivals: {...arrivals} });
      }
    });
  }

  @Action(actions.UpdateRecivingPackageAction)
  updateReceivingPackage(ctx: StateContext<IArrivalsState>, { payload }: actions.UpdateRecivingPackageAction){
    global.log("State",`Update package `);
    payload.arrive_packing_list.arrival.arrive_packing_lists = {...payload.arrive_packing_list.arrival.arrive_packing_lists};
    ctx.patchState({open_arrival: payload.arrive_packing_list.arrival});
    this.package_service.update(payload).subscribe((pkg: Package)=> {
      pkg.arrive_packing_list.arrival.arrive_packing_lists = {...pkg.arrive_packing_list.arrival.arrive_packing_lists};
      ctx.patchState({open_arrival: pkg.arrive_packing_list.arrival});
    });
  }

  @Action(actions.CreatePalletAction)
  createPalletAction(ctx: StateContext<IArrivalsState>, action: actions.CreatePalletAction) {
    if (action.payload.packages.length > 0) {
      global.log("State", 'Package set pallet id')
      let plt = new Pallet({
        packages: action.payload.packages,
        container_type_id: action.payload.container_type_id,
        consolidation: action.payload.consolidation || false
      });
      plt.arrival.arrive_packing_lists = {...plt.arrival.arrive_packing_lists};
      ctx.patchState({open_arrival: plt.arrival });
      this.shipment_service.palletize(plt).subscribe((pallet: Pallet) => {
        if (pallet && pallet.id > 0) {
          plt.id = pallet.id;
          plt.container_weight = pallet.container_weight;
          ctx.patchState({open_arrival: plt.arrival.clone() });
        }
      });
    }
  }

  @Action(actions.ReAssignReceiptAction)
  reAssignReceiptAction(ctx: StateContext<IArrivalsState>, action: actions.ReAssignReceiptAction) {
    if (action.payload.packages.length > 0) {
      global.log("State", 'Reassign receipt id')
      let arrival = ctx.getState().open_arrival;
      arrival.reassign(action.payload.packages,action.payload.receipt_id);
      ctx.dispatch(new actions.UpdateArriveAction({ arrival: arrival, package: null }));
    }
  }

  private togglePallet(target: Package | Pallet, pallet: Pallet): Arrival {
    let apl = (target instanceof Pallet) ? target.arrival : target.arrive_packing_list.arrival ;
    let pkgs = target.packages.filter((p: Package) =>
      (target instanceof Pallet && target.havePackage(p)) ||
      (target instanceof Package && p.id == target.id));
    pkgs.forEach((p: Package) => {
      p.takeSnapshoot();
      p.pallet = pallet;
      p.isPalletized = !!p.pallet;
    })
    apl.arrive_packing_lists = {...apl.arrive_packing_lists};
    Object.keys(apl.arrive_packing_lists).forEach((k: string) =>
      apl.arrive_packing_lists[k].packages = { ...apl.arrive_packing_lists[k].packages } )
    return apl;
  }

  @Action(actions.CreateUnPalletAction)
  deletePallet(ctx: StateContext<IArrivalsState>, action: actions.UnPalletAction) {
    global.log("State",`Un pallet ${action.payload.id}`);
    if (action.payload instanceof Package)
      this.unPalletPackage(ctx, action.payload);
    else {
      let apl = this.togglePallet(action.payload, null);
      ctx.patchState({open_arrival: apl });
      this.shipment_service.unpalletize(action.payload).subscribe((pallet: Pallet) => {
        ctx.patchState({open_arrival: apl });
        if (pallet && pallet.id > 0) {
          ctx.patchState({open_arrival: pallet.arrival });
        }
      });
    }
  }

  @Action(actions.AddPalletToPackageAction)
  addPalletToPackages(ctx: StateContext<IArrivalsState>, action: actions.AddPalletToPackageAction) {
    global.log("State",`Add pallet to packages`)
    this.arrivals_service.addPalletToPackages(action.payload.arrival_package_id, action.payload.quantity, action.payload.container_type_id).subscribe((res: any) => {
      if (res) {
        ctx.patchState({open_arrival: res});
      }
    });
  }

  @Action(actions.MoveArrivalPackingsAction)
  moveArrivalPackings(ctx: StateContext<IArrivalsState>, action: actions.MoveArrivalPackingsAction) {
    global.log("State",`Move arrival packings`);
    let to = action.payload.to;
    let trk = action.payload.trk;
    this.arrive_packing_list_service.moveArrivalPackings(to, trk).subscribe((result: Arrival) => {
      if (result) {
        ctx.patchState({open_arrival: result});
      }
    });
  }

  @Action(actions.ToggleBatchModeStateAction)
  toggleBatchModeStateAction(ctx: StateContext<IArrivalsState>){
    const currentMode = ctx.getState().batchMode;
    const arrival = ctx.getState().open_arrival;
    ctx.patchState({batchMode: !currentMode});
    if (currentMode) this.updateArrive(ctx,{payload: { arrival: arrival, package: null }});
  }

  private unPalletPackage(ctx: StateContext<IArrivalsState>, pkg: Package) {
    let pallet = pkg.pallet;
    let apl = this.togglePallet(pkg, null);
    this.package_service.update(pkg, ["status", "pallet_id"]).subscribe((pkg: Package) => {
      if (pkg.pallet_id) {
        apl  = this.togglePallet(pkg, pallet);
        ctx.patchState({open_arrival: pkg.arrive_packing_list.arrival });
      }
    })
  }

  @Action(actions.UpdateDamageReportAction)
  UpdateDamageReport(ctx: StateContext<IArrivalsState>, action: actions.UpdateDamageReportAction) {
    global.log("State",`Update package damage report`);
    let arrival = ctx.getState().open_arrival;
    if (arrival && arrival.id == action.payload.arrival_id) {
      arrival.addDamateReport(action.payload);
      ctx.dispatch(new actions.UpdateArriveAction({ arrival: arrival, package: action.payload.package }))
    }
  }
}
