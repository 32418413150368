import { Component } from '@angular/core';
import * as actions from 'wms-lib';
import { AuthenticationService, ExposState, Arrival, ArrivePackingList } from 'wms-lib';
import { Store } from '@ngxs/store';
import { DocumentViewerBaseComponent } from './document-viewer.component.base';
import { ActivatedRoute } from '@angular/router';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-pop-viewer',
  templateUrl: './document-viewer.component.html',
  styleUrls: ['./document-viewer.component.scss']
})
export class PopViewerComponent extends DocumentViewerBaseComponent {
  _arrival: Arrival;
  apl: ArrivePackingList;
  constructor(protected store : Store,
              route: ActivatedRoute,
              protected authenticationService : AuthenticationService
             ) {
    super(store,route, authenticationService);
  }

  ngOnInit() {
    if (!this.document) {
      const val1$ = this.store.select(ExposState.getPopFromWorkingDelivery());
      const val2$ = this.store.select((state) => state.exposState.pop);
      this.addSub(combineLatest(val1$, val2$).subscribe(results => {
        const docFromPacking = results[0];
        const docSelected = results[1];
        this.document = docFromPacking || docSelected;
      }));
    }
  }

  releaseDocument(){
    this.store.dispatch(new actions.AssignPopToWorkingDeliveryAction({
      document: null,
      keep_working_delivery_id: false
    }));
  }

  get loaded(): boolean {
    return !!this.document;
  }

  goToTabletCarousel() {

  }


  get showPartNumberResume(): boolean {
  	  return false;
  }

}
