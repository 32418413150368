import { Client, Carrier, Location, User, Tracking, TrackingDetail, BundleType, PartNumber, Commodity } from '../../models';
import { State, Action, StateContext, createSelector } from '@ngxs/store';
import { Injectable } from '@angular/core';
import * as actions from '../actions';
import { CatalogsService, AuthenticationService } from '../../services';
import * as _ from 'lodash';
import { ICatalog } from '../../models/catalog';
import { Printer } from '../../models/printer';
import { NotificationService } from '../../platform-services/notification.service';

export interface ICatalogsState {
  clients: { [id: number]: Client };
  locations: { [id: number]: Location };
  carriers: { [id: number]: Carrier };
  damage_types: { [id: number]: ICatalog };
  warehouses: { [id: number]: ICatalog };
  good_types: { [id: number]: ICatalog };
  bundle_types: { [id: number]: BundleType };
  good_categories: {[id:number]: ICatalog};
  ticket_statuses:  {[id:number]: ICatalog};
  printers:  {[id:number]: Printer};
  priorities: {[id: number]: ICatalog};
  container_types: {[id: number]: ICatalog};
  conveyance_types: {[id: number]: ICatalog};
  classifications: {[id: number]: ICatalog};
  active_client: Client;
  users: User[];
  roles: string[];
  trackings: Tracking[];
  totalTrackings: number;
  allTrackingsLoaded: boolean;
  tracking_details: TrackingDetail[];
  trackings_found: string[];
  open_trackings: TrackingDetail[];
  units: {[id:number]: ICatalog};
  part_numbers: {[client_id: number]: {[part_id: number]: PartNumber}};
  countries: {[id: number]: ICatalog};
  workingTracking: Tracking;
  commodities: {[id: number]: Commodity};
}

@State<ICatalogsState>({
  name: 'catalogsState',
  defaults: {
    clients: {},
    carriers: {},
    locations: {},
    damage_types: {},
    warehouses: {},
    good_types: {},
    bundle_types: {},
    good_categories: {},
    ticket_statuses: {},
    printers: {},
    priorities: {},
    container_types: {},
    conveyance_types: {},
    classifications: {},
    active_client: null,
    users: null,
    roles: null,
    trackings: null,
    totalTrackings: null,
    allTrackingsLoaded: false,
    tracking_details: null,
    trackings_found: [],
    open_trackings: [],
    units: {},
    part_numbers: {},
    countries: {},
	workingTracking: null,
	commodities: {}
  }
})

@Injectable()
export class CatalogsState {

  static locationByType(type: string = null) {
    return createSelector([CatalogsState], (state: ICatalogsState) => {
      return _(state.locations).values().filter((l: Location) => l.type === (type || l.type)).value();
    });
  }

  static getMapCatalog(name_catalog: string) {
    return createSelector([CatalogsState], (state: ICatalogsState) => {
      let types = _(state[name_catalog]).map((m: any) => [m.id, m.name] as [number, string] );
      return new Map<number, string>(types);
    });
  }

  static getArrayCatalog(name_catalog: string) {
    return createSelector([CatalogsState], (state: ICatalogsState) => {
      return _.values(state[name_catalog]);
    });
  }

  static getPartNumbersByClient(client_id: number) {
    return createSelector([CatalogsState], (state: ICatalogsState) => {
      return state.part_numbers[client_id];
    });
  }

  static getPartNumbersByActiveClient() {
    return createSelector([CatalogsState], (state: ICatalogsState) => {
      return state.part_numbers[state.active_client.id];
    });
  }

  static getPartNumbersWithStockByActiveClient() {
    return createSelector([CatalogsState], (state: ICatalogsState) => {
      return _(state.part_numbers[state.active_client.id]).values().filter((pn: PartNumber) => pn.have_stock).value();
    });
  }

  constructor(
    private catalogs_service: CatalogsService,
    private notificationService: NotificationService,
    private authenticationService: AuthenticationService) {}

  @Action(actions.GetCatalogAction)
  getCatalog<T extends ICatalog>(ctx: StateContext<ICatalogsState>, { payload }: actions.GetCatalogAction<T>) {
    global.log("State", `Get ${payload.name_catalog} types`);
    this.catalogs_service.getCatalog<T>(payload.name_catalog, payload.live).subscribe((response) => {
      ctx.patchState({ [payload.name_catalog]: response });
    });
  }

  @Action(actions.SetActiveClientAction)
  setActiveClient(ctx: StateContext<ICatalogsState>, { payload }: actions.SetActiveClientAction) {
    global.log("State", `Setting active client ${payload.client ? payload.client.id: ''}`);
    ctx.patchState({active_client : payload.client});
  }

  @Action(actions.SaveCatalogAction)
  saveCatalog<T extends ICatalog>(ctx: StateContext<ICatalogsState>, { payload }: actions.SaveCatalogAction<T>) {
    global.log("State", `Save ${payload.name_catalog}`);
    this.patchCatalog<T>(ctx, payload.catalog, payload.name_catalog);
    this.catalogs_service.saveCatalog<T>(payload.catalog, payload.name_catalog).subscribe((catalog: T) => {
      this.patchCatalog<T>(ctx, payload.catalog, payload.name_catalog, true);
      this.patchCatalog<T>(ctx, catalog, payload.name_catalog)
      if(catalog.default)
        this.defaultCatalog<T>(ctx, catalog, payload.name_catalog);
    });
  }

  @Action(actions.SaveGroupCatalogAction)
  saveGroupCatalog<T extends ICatalog>(ctx: StateContext<ICatalogsState>, { payload }: actions.SaveGroupCatalogAction<T>) {
    global.log("State", `Save Group ${payload.name_catalog}`);
    this.patchGroupCatalog<T>(ctx, payload.catalog, payload.name_catalog);
    this.catalogs_service.saveCatalog<T>(payload.catalog, payload.name_catalog).subscribe((catalog: T) => {
      this.patchGroupCatalog<T>(ctx, payload.catalog, payload.name_catalog, true);
      this.patchGroupCatalog<T>(ctx, catalog, payload.name_catalog)
      if(catalog.default)
        this.defaultGroupCatalog<T>(ctx, catalog, payload.name_catalog);
    });
  }

  @Action(actions.DeleteCatalogAction)
  deleteCatalog<T extends ICatalog>(ctx: StateContext<ICatalogsState>, { payload }: actions.DeleteCatalogAction<T>) {
    global.log("State", `Delete ${payload.name_catalog}`);
    this.patchCatalog<T>(ctx, payload.catalog, payload.name_catalog, true);
    if (payload.catalog.id > 0)
      this.catalogs_service.deleteCatalog(payload.catalog.id, payload.name_catalog).subscribe((result: boolean) => {
        if(!result) this.patchCatalog<T>(ctx, payload.catalog, payload.name_catalog);
      });
  }

  @Action(actions.DeleteGroupCatalogAction)
  deleteGroupCatalog<T extends ICatalog>(ctx: StateContext<ICatalogsState>, { payload }: actions.DeleteGroupCatalogAction<T>) {
    global.log("State", `Delete Group ${payload.name_catalog}`);
    this.patchGroupCatalog<T>(ctx, payload.catalog, payload.name_catalog, true);
    if (payload.catalog.id > 0)
      this.catalogs_service.deleteCatalog(payload.catalog.id, payload.name_catalog).subscribe((result: boolean) => {
        if(!result) this.patchGroupCatalog<T>(ctx, payload.catalog, payload.name_catalog);
      });
  }

  @Action(actions.ReorderCatalogAction)
  reorderCatalog(ctx: StateContext<ICatalogsState>, { payload }: actions.ReorderCatalogAction) {
    global.log("State", `Reorder ${payload.name_catalog}`);
    let catalog_state = ctx.getState()[payload.name_catalog];
    let old_sequence = _({...catalog_state}).mapValues((o: ICatalog) => o.sequence).value();
    _(payload.sequence).forEach((s: number,k: string) => catalog_state[k].sequence = s);
    ctx.patchState({ [payload.name_catalog]: {...catalog_state} });
    this.catalogs_service.reorderCatalog(payload.name_catalog, payload.sequence).subscribe((result: boolean) => {
      if(!result) {
        _(old_sequence).forEach((s: number,k: string) => catalog_state[k].sequence = s);
        ctx.patchState({ [payload.name_catalog]: {...catalog_state} });
      }
    });
  }

  @Action(actions.ImportCatalogAction)
  importCatalog<T extends ICatalog>(ctx: StateContext<ICatalogsState>, { payload }: actions.ImportCatalogAction<T>) {
    global.log("State", `Import Group ${payload.name_catalog}`);
    this.catalogs_service.import(payload.name_catalog, payload.data, payload.options).subscribe((data: {[id: number]: T}) => {
      if(data && Object.keys(data).length > 0){
        let data_imported = _.filter(data,(p: T)=>p.id > 0);
        let data_imported_errors = _.filter(data,(p: T)=> !_.isEmpty(p.errors));

        if(data_imported_errors.length > 0)
          this.notificationService.toast(`${data_imported_errors.length} records could not be imported`, false);

        if(data_imported.length > 0)
          this.notificationService.toast(`${data_imported.length} records of ${Object.keys(data).length} have been imported`, false, {severity:'success', summary: 'Success'});

        this.mergeGroupImportCatalog<T>(ctx, data, payload.name_catalog);
      } else
        ctx.patchState({ [payload.name_catalog]: {...ctx.getState()[payload.name_catalog]} });
    });
  }

  private patchCatalog<T extends ICatalog>(ctx: StateContext<ICatalogsState>, catalog: T, catalog_name: string, remove: boolean = false) {
    const state = ctx.getState();
    let catalog_state = state[catalog_name];
    if (remove)
      delete catalog_state[catalog.id];
    else
      catalog_state[catalog.id] = catalog;
    ctx.patchState({ [catalog_name]: {...catalog_state} });
  }

  private patchGroupCatalog<T extends ICatalog>(ctx: StateContext<ICatalogsState>, catalog: T, catalog_name: string, remove: boolean = false) {
    const state = ctx.getState();
    let catalog_state = state[catalog_name];
    let client_id = state.active_client.id;
    if (remove)
      delete catalog_state[client_id][catalog.id];
    else
      catalog_state[client_id][catalog.id] = catalog;

    catalog_state[client_id] = _.keyBy(catalog_state[client_id],'id');
    ctx.patchState({ [catalog_name]: {...catalog_state} });
  }

  private defaultCatalog<T extends ICatalog>(ctx: StateContext<ICatalogsState>, catalog: T, catalog_name: string) {
    const state = ctx.getState();
    let catalog_state = state[catalog_name];
    _(catalog_state).mapValues((s)=> {
      if(s.id != catalog.id) {
        delete s.default
      }
      return s
    }).value()
    ctx.patchState({ [catalog_name]: {...catalog_state} });
  }

  private defaultGroupCatalog<T extends ICatalog>(ctx: StateContext<ICatalogsState>, catalog: T, catalog_name: string) {
    const state = ctx.getState();
    let client_id = state.active_client.id;
    let catalog_state = state[catalog_name][client_id];
    _(catalog_state).mapValues((s)=> {
      if(s.id != catalog.id) {
        delete s.default
      }
      return s
    }).value()
    ctx.patchState({ [catalog_name]: {...catalog_state} });
  }

  private mergeGroupImportCatalog<T extends ICatalog>(ctx: StateContext<ICatalogsState>, catalogs: {[id: number]: T}, catalog_name: string) {
    const state = ctx.getState();
    let client_id = state.active_client.id;
    let catalog_state = state[catalog_name];
    let catalogs_by_key = _.keyBy(catalogs,'id');
    let unedited = _.omit(catalog_state[client_id], catalogs_by_key);
    catalog_state[client_id] = _.merge(unedited,catalogs_by_key);
    ctx.patchState({ [catalog_name]: {...catalog_state} });
  }

  @Action(actions.GetUsersAction)
  getUsers(ctx: StateContext<ICatalogsState>) {
    global.log("State", `Get users`);
    this.catalogs_service.getUsers().subscribe((users: User[]) => {
      ctx.patchState({ users: users});
    });
  }

  @Action(actions.GetRolesAction)
  getRoles(ctx: StateContext<ICatalogsState>) {
    global.log("State", `Get roles`);
    this.catalogs_service.getRoles().subscribe((roles: string[]) => {
      ctx.patchState({ roles: roles});
    });
  }

  @Action(actions.SaveUserAction)
  saveUser(ctx: StateContext<ICatalogsState>, { payload }: actions.SaveUserAction) {
    global.log("State", `Save user`);
    let users = ctx.getState().users;
    let idx = users && _(users).findIndex((u: User) => u.id == payload.id)
    this.catalogs_service.saveUser(payload).subscribe((user: User) => {
      if (idx && users[idx]) {
        users[idx] = user;
        ctx.patchState({ users: _.cloneDeep(users)});
      }
      if (this.authenticationService.currentUserValue.id == user.id)
        this.authenticationService.setNextUser(user);
    });
  }

  @Action(actions.AddNewUserAction)
  addNewUser(ctx: StateContext<ICatalogsState>, { payload }: actions.AddNewUserAction) {
    global.log("State", `Add new user`);
    let users = ctx.getState().users;
    users.unshift(payload);
    ctx.patchState({ users: users});
  }

  @Action(actions.DeleteNewUserAction)
  deleteNewUser(ctx: StateContext<ICatalogsState>, { payload }: actions.DeleteNewUserAction) {
    global.log("State", `Delete new user`);
    let users = ctx.getState().users.filter((f: User) => f.id != payload.id );
    ctx.patchState({ users: users});
  }

  @Action(actions.GetTrackingsAction)
  getTrackings(ctx: StateContext<ICatalogsState>, { payload }: actions.GetTrackingsAction) {
    global.log("State", `Get trackings`);
    this.catalogs_service.getTrackings(payload.page).subscribe((response: {totalRecords: number, trackings: Tracking[]}) => {
      const currentState = ctx.getState();
      if (_.isEmpty(response)){
        ctx.patchState({ allTrackingsLoaded: true });
      } else {
      	  let trackings = payload.append && payload.page > 1 ? _.merge(response.trackings, { ...currentState.trackings }) : response.trackings;
      	  let tf =  ctx.getState().trackings_found;
      	  let founds = _(trackings).map('tracking_details').flatten().filter((td: TrackingDetail) => td.found_at).map('trk').uniq().value();
        ctx.patchState({
          trackings: trackings,
          totalTrackings: response.totalRecords,
          trackings_found: _.uniq([...tf, ...founds])
        });
      }
    });
  }


  @Action(actions.AddTrackingsAction)
  addTrackings(ctx: StateContext<ICatalogsState>) {
    global.log("State", `Add trackings`);
    let trackings = ctx.getState().trackings;
    trackings.unshift(new Tracking());
    ctx.patchState({ trackings: [...trackings], totalTrackings: ctx.getState().totalTrackings + 1});
  }

  @Action(actions.SaveTrackingAction)
  saveTracking(ctx: StateContext<ICatalogsState>, { payload }: actions.SaveTrackingAction) {
    global.log("State", `Save tracking`);
    let trackings = ctx.getState().trackings;
    let idx = trackings && trackings.findIndex((t: Tracking) => t.id == payload.id);
    this.catalogs_service.saveTracking(payload).subscribe((tracking: Tracking) => {
		if(_.has(tracking,'error_message') && tracking.error_message) {
			this.notificationService.toast(tracking.error_message, false);
		}
		if(tracking.id > 0) {
			let totalTrackings = ctx.getState().totalTrackings || 0;
			let tracking_details = ctx.getState().tracking_details || [];
			let workingTracking = ctx.getState().workingTracking || null;
			let tf =  ctx.getState().trackings_found;
			let founds = _(trackings).map('tracking_details').flatten().filter((td: TrackingDetail) => td.found_at).map('trk').uniq().value();
			if(idx >= 0) {
				trackings[idx] = tracking;
			} else {
				trackings.unshift(tracking);
				totalTrackings++;
			}
			workingTracking = tracking;
			tracking_details = tracking.tracking_details.filter((td: TrackingDetail) => _.has(td,'id'));
			workingTracking.tracking_details = tracking_details;
			ctx.patchState({ trackings: [...trackings], totalTrackings: totalTrackings, tracking_details: tracking_details, workingTracking: workingTracking, trackings_found: _.uniq([...tf, ...founds]) });
		} else {
			let workingTracking = ctx.getState().workingTracking;
			let tracking_details = ctx.getState().tracking_details;
			workingTracking = tracking;
			tracking_details = tracking.tracking_details.filter((td: TrackingDetail) => _.has(td,'id'));
			workingTracking.tracking_details = tracking_details;
			ctx.patchState({ tracking_details: tracking_details, workingTracking: workingTracking });
		}
    });
  }

  @Action(actions.SetWorkingTrackingAction)
  setWorkingTracking(ctx: StateContext<ICatalogsState>, { payload }: actions.SetWorkingTrackingAction) {
  	  global.log("State", `Set working tracking`);
  	  ctx.patchState({ workingTracking: payload });
  }

  @Action(actions.DeleteTrackingAction)
  delete(ctx: StateContext<ICatalogsState>, {payload}: actions.DeleteTrackingAction){
    global.log("State",`Deleting Shipment`)
    let trackings = ctx.getState().trackings;
    let idx = trackings && trackings.findIndex((t: Tracking) => t.id == payload.id);
    trackings.splice(idx,1);
    ctx.patchState({ trackings: [...trackings], totalTrackings: ctx.getState().totalTrackings - 1});
    if (payload.id > 0)
      return this.catalogs_service.deleteTracking(payload).subscribe((result: boolean) => {
        if (!result) {
          trackings.splice(idx,0,payload);
          ctx.patchState({ trackings: [...trackings], totalTrackings: ctx.getState().totalTrackings + 1 });
        }
      });
  }

  @Action(actions.ViewTrackingDetailsAction)
  viewTrackingsDetails(ctx: StateContext<ICatalogsState>, {payload}: actions.ViewTrackingDetailsAction) {
    global.log("State", `View trackings details`);
    ctx.patchState({ tracking_details: payload });
  }

  @Action(actions.AddTrackingFoundAction)
  addTrackingsFound(ctx: StateContext<ICatalogsState>, {payload}: actions.AddTrackingFoundAction) {
    global.log("State", `Add tracking found`);
    let found = ctx.getState().trackings_found;
    found.push(payload);
    ctx.patchState({ trackings_found: [...found] });
  }

  @Action(actions.GetOpenTrackingsAction)
  getOpenTrackings(ctx: StateContext<ICatalogsState>) {
    global.log("State", `Get users`);
    this.catalogs_service.getOpenTrackings().subscribe((open: TrackingDetail[]) => {
      ctx.patchState({ open_trackings: open});
    });
  }

  @Action(actions.LoadPartNumbersAction)
  loadPartNumbers(ctx: StateContext<ICatalogsState>, {payload}: actions.LoadPartNumbersAction) {
    global.log("State", `Get part numbers for client id: ${payload}`);
    this.catalogs_service.getPartNumbers(payload).subscribe((parts: PartNumber[]) => {
      let state_parts = {...ctx.getState().part_numbers};
      state_parts[payload] = _.keyBy(parts,'id');
      ctx.patchState({ part_numbers: state_parts});
    });
  }

  @Action(actions.LoadStockByPartNumbersAction)
  loadStockByPartNumbers(ctx: StateContext<ICatalogsState>, {payload}: actions.LoadStockByPartNumbersAction) {
    global.log("State", `Get stock by part numbers for client id: ${payload}`);
    this.catalogs_service.getStockByPartNumber(payload).subscribe((parts: PartNumber[]) => {
      let state_parts = {...ctx.getState().part_numbers};
      parts.forEach((p: PartNumber) => state_parts[payload][p.id] = p )
      ctx.patchState({ part_numbers: state_parts});
    });
  }



  @Action(actions.LoadCatalogsAction)
  loadCatalogs(ctx: StateContext<ICatalogsState>) {
    global.log("State", `Load catalogs`);
    this.catalogs_service.cleanLiveCatalogs();
    ctx.dispatch(new actions.GetCatalogAction({name_catalog: "locations", live: false}));
    ctx.dispatch(new actions.GetCatalogAction({name_catalog: "carriers", live: false}));
    ctx.dispatch(new actions.GetCatalogAction({name_catalog: "damage_types", live: false}));
    ctx.dispatch(new actions.GetCatalogAction({name_catalog: "warehouses", live: false}));
    ctx.dispatch(new actions.GetCatalogAction({name_catalog: "good_types", live: false}));
    ctx.dispatch(new actions.GetCatalogAction({name_catalog: "bundle_types", live: false}));
    ctx.dispatch(new actions.GetCatalogAction({name_catalog: "clients", live: true}));
    ctx.dispatch(new actions.GetCatalogAction({name_catalog: "good_categories", live: false}));
    ctx.dispatch(new actions.GetCatalogAction({name_catalog: "ticket_statuses", live: false}));
    ctx.dispatch(new actions.GetCatalogAction({name_catalog: "priorities", live: false}));
    ctx.dispatch(new actions.GetCatalogAction({name_catalog: "conveyance_types", live: false}));
    ctx.dispatch(new actions.GetCatalogAction({name_catalog: "printers", live: false}));
    ctx.dispatch(new actions.GetCatalogAction({name_catalog: "container_types", live: false}));
    ctx.dispatch(new actions.GetCatalogAction({name_catalog: "units", live: false}));
    ctx.dispatch(new actions.GetCatalogAction({name_catalog: "countries", live: false}));
    ctx.dispatch(new actions.GetCatalogAction({name_catalog: "commodities", live: false}));
    ctx.dispatch(new actions.GetCatalogAction({name_catalog: "classifications", live: false}));
  }
}
