import { Packable } from './packable';
import * as _ from 'lodash';
import { ArrivePackingList } from './arrive-packing-list';
import { Package } from './package';
import { Client } from './client';
import { ICatalog } from './catalog';

export class Location extends Packable implements ICatalog {
    validationKey: string = "location"
    name: string;
    levels: number;
    columns: number;
    type: string;
    packages_count: number;
    packings_count: number;
    available_spaces: string[];
    clients: {[id: number]: number};
    code: string;
    sequence: number;
    public static sort_client: Client;

    get allocations(): number {
        return this.levels * this.columns;
    }

    get dimensions(): string {
        return this.levels + 'x' + this.columns;
    }

    get capacity(): number {
        const val = this.packages_count * 100 / (this.columns * this.levels);
        return (val > 100 ? 100 : val);
    }

    get location_description() {
        return `${this.name} (${this.dimensions})`;
    }

    findAPL(receipt_id: string): ArrivePackingList {
        return _(this.arrive_packing_lists).find((apl: ArrivePackingList) => apl.receipt_id == receipt_id);
    }

    findAsset(id: number | string): Package | ArrivePackingList {
        return typeof(id) == "number" ? this.findPackage(id) : this.findAPL(id);
    }

    get sort_index(): string | number {
        return Location.sort_client ? (this.clients[Location.sort_client.id]||0) : this.code;
    }

    get columns_a(): number[] {
        return _.range(1,this.columns+1);
    }

    get levels_a(): number[] {
        return _.range(1,this.levels+1);
    }

    get saveProp(): string[] {
        return super.saveProp.concat(["name","type","levels","columns","code"]);
    }

    get available_spaces_count(): number {
    	return this.available_spaces.length;
    }
}
