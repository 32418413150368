import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { CarrierSelectionDialogBaseComponent } from './carrier-selection-dialog.component.base';
import { Store } from '@ngxs/store';
import { ModalDialogState, Carrier} from 'wms-lib';

@Component({
    selector: 'app-carrier-selection-dialog',
    templateUrl: './carrier-selection-dialog.component.html',
    styleUrls: ['./carrier-selection-dialog.component.scss']
})
export class CarrierSelectionDialogComponent extends CarrierSelectionDialogBaseComponent implements OnInit {
    
    constructor(protected store: Store,
                protected readonly change_detector_ref: ChangeDetectorRef,
                private modal_state: ModalDialogState) { 
        super(store,change_detector_ref);
    }

    ngOnInit() {
        this.params_context = this.modal_state.params;
        super.ngOnInit();
    }

    select(carrier: Carrier) {
        this.modal_state.closeCallBack(carrier);
    }
}
