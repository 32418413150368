import { OnInit, Directive, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import * as actions from 'wms-lib';
import { ICatalog, NotificationService } from 'wms-lib';
import * as _ from 'lodash';
import * as XLSX from 'xlsx';


@Directive({selector: '[catalogComponent]'})
export abstract class CatalogComponent<T extends ICatalog> implements OnInit {
    reordenable: boolean = true;
    @ViewChild('dt', { static: false }) dt: any;
    constructor(protected store: Store, protected notificationService: NotificationService) {}

    abstract name_catalog: string;
    items: {[id: number]: T };
    abstract newItem(data: any): T;
    data_import: any[] = [];

    ngOnInit(): void {
        this.store.dispatch(new actions.GetCatalogAction<T>({name_catalog: this.name_catalog, live: false}));
        this.store.select((state) => state.catalogsState[this.name_catalog]).subscribe((items: {[id: number]: T}) => this.items = items);
    }

    addNew(_type?: string) {
        let c = this.newItem({name: '', type: _type});
        this.items[c.id] = c;
        this.items = {...this.items};
    }

    onRowEditSave(item: T) {
        this.store.dispatch(new actions.SaveCatalogAction<T>({catalog: item, name_catalog: this.name_catalog}));
    }

    saveSequence() {
        this.store.dispatch(new actions.ReorderCatalogAction({
            name_catalog: this.name_catalog,
            sequence: _(this.dt.value).
                map((d: ICatalog, i: number) => ({id: d.id, sequence: i+1})).
                mapKeys("id").mapValues("sequence").value()
        }));
    }

    delete(item: T){
        this.notificationService.showFeedback(
            'Are you sure you want to delete ' + item.name + '?',
            'Confirm',
            false,
            null,
            () => this.store.dispatch(new actions.DeleteCatalogAction<T>({catalog: item, name_catalog: this.name_catalog}))
        );
    }

    import(data: any,options: any) {
        this.store.dispatch(new actions.ImportCatalogAction({
            name_catalog: this.name_catalog,
            data: data,
            options: options
        }));
    }

    get canReorder(): boolean {
        return this.reordenable && _(this.items).values().every((i: ICatalog) => i.id);
    }
}
