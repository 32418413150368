import { Component, OnInit, Input } from '@angular/core';
import {  CyclicalInventory, Tag, CatalogsState } from 'wms-lib';
import { Store } from '@ngxs/store';

@Component({
	selector: 'app-inventory-cyclic-tags',
	templateUrl: './inventory-cyclic-tags.component.html',
	styleUrls: ['./inventory-cyclic-tags.component.scss']
})
export class InventoryCyclicTagsComponent implements OnInit {
	_cyclical_inventory: CyclicalInventory;
	bundle_types: Map<number, string> = new Map([]);
	isLoading: boolean = false;
	@Input() set cyclical_inventory(cyclical_inventory: CyclicalInventory) {
		if(cyclical_inventory) {
			this._cyclical_inventory = cyclical_inventory;
			this.isLoading = false;
		}
	}
	get cyclical_inventory(): CyclicalInventory {
		return this._cyclical_inventory;
	} 

	constructor(private store: Store) { }

	ngOnInit() {
		this.isLoading = true;
		this.store.select(CatalogsState.getMapCatalog('bundle_types')).
			subscribe((c: Map<number, string>) => {
			this.bundle_types = c;
		})
	}

}
