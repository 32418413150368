import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Inject, Injectable, NgZone } from "@angular/core";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { ArrivePackingList, Arrival, AplList } from "../models";
import { NotificationService } from "../platform-services/notification.service";
import { GenericService } from "./generic.service";
import { PackingsFilter } from "../view-models";

const APL_URL = 'arrive_packing_lists/:id';
const UPDATE_BATCH_URL = 'arrive_packing_lists/batch';
const PACKINGS_URL = 'arrive_packing_lists';
const APL_LIST_URL = 'apl_lists';

@Injectable({
  providedIn: 'root'
})
export class ArrivePackingListService extends GenericService {

  constructor(@Inject('env') env: any,
              http: HttpClient,
              ng_zone: NgZone,
              notificationService: NotificationService) {
    super(env,http,ng_zone, notificationService);
  }

  // This service is used for retrive json or the pdf
  getPackings(filter: PackingsFilter, format: string = "json"): Observable<{totalRecords: number, pls: ArrivePackingList[]}>  {
    let getReq = format == "json" ?
      this.http.get(this.serverUrl + PACKINGS_URL + "." + format, {
        params: filter.params(),
        headers: new HttpHeaders({'Content-Type': `application/${format}`, 'Accept': `application/${format}`}),
        observe: 'response',
        responseType: "json"
      }) :
      this.http.get(this.serverUrl + PACKINGS_URL + "." + format, {
        params: filter.params(),
        headers: new HttpHeaders({'Content-Type': `application/${format}`, 'Accept': `application/${format}`}),
        observe: 'response',
        responseType: "blob"
      })
    return getReq.pipe(
        map((response: any) => ({
          totalRecords: +response.headers.get("totalrecords"),
          pls: format == "pdf" ? response.body : response.body.map((apl: any) => new ArrivePackingList(apl))
        })),
        catchError(this.handleError<any>(' retrieving packings', null))
      );
  }

  update(apl: ArrivePackingList): Observable<boolean> {
    const url = this.serverUrl + APL_URL.replace(':id', apl.id.toString());
    return this.http.put(url, apl.toSaveData(), this.httpOptions).pipe(
      map(()=> true),
      catchError(this.handleError<boolean>(' update arrive packing list ', false))
    );
  }

  updateBatch(apls: ArrivePackingList[]): Observable<boolean> {
    let data = apls.map((apl: ArrivePackingList) => ({
      id: apl.id,
      good_category_id: apl.good_category_id
    }));
    return this.http.put(this.serverUrl + UPDATE_BATCH_URL, data, this.httpOptions).pipe(
      map(()=> true),
      catchError(this.handleError<boolean>(' update arrive packing list ', false))
    );
  }

  moveArrivalPackings(to: number, trk: string): Observable<Arrival> {
    return this.http.put(this.serverUrl + PACKINGS_URL + "/" + to + "/move_arrival_packings", {trk: trk}, this.httpOptions).pipe(
      map((data: any) => {
        global.log("Service",`Arrival Packing list ${to} moved to ${trk}`);
        this.notificationService.toast(' Arrival Packing list moved to ' + trk, false, {severity:'success', summary: 'Success'});
        return new Arrival(data);
      }), catchError((err)=> {
        this.notificationService.toast(err.error.error, false);
        return of(null);
      })
    );
  }

  get(id: number | string): Observable<ArrivePackingList> {
    const url = this.serverUrl + APL_URL.replace(':id', ''+id);
    return this.http.get(url, this.httpOptions).pipe(
      map((apl: any)=> apl ? new ArrivePackingList(apl) : null),
      catchError(this.handleError<ArrivePackingList>(' get arrive packing list ', null))
    );
  }

  save_list(title: string, apls: ArrivePackingList[]) {
    return this.http.post(this.serverUrl + APL_LIST_URL, {
      apl_list: {
        title: title,
        apl_list_details_attributes: apls.map((apl: ArrivePackingList) => ({arrive_packing_list_id: apl.id}))
      }}, this.httpOptions).pipe(
        map((data: any) => new AplList(data) ),
        catchError(this.handleError<AplList>(' save receipts list ', null))
      );
  }
}
