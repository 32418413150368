import { Routes } from '@angular/router';
import { ArrivalsComponent }  from './components/arrivals/arrivals.component';
import { DamagesComponent }   from './components/damages/damages.component';
import { ConciliatingComponent } from './components/conciliating/conciliating.component';
import { DocumentViewerComponent } from '../shared/components/document-viewer/document-viewer.component';
import { DocumentsCarrouselComponent } from '../shared/components/documents-carrousel/documents-carrousel.component';

export const componentDeclarations: any[] = [
];

export const providerDeclarations: any[] = [
];

export const routes: Routes = [
    {
        path: ':type/edit/:id',
        component: ConciliatingComponent,
        children: [
            {
                path: '',
                component: DocumentsCarrouselComponent,
                outlet: 'documents',
                pathMatch: 'full'
            },
            {
                path: 'documents',
                component: DocumentsCarrouselComponent,
                outlet: 'documents'
            },
            {
                path: 'view-document',
                component: DocumentViewerComponent,
                outlet: 'documents'
            }
        ]
    },
    {
        path: 'phone-conciliating-documents',
        component: DocumentsCarrouselComponent
    },
    {
        path: 'view-document',
        component: DocumentViewerComponent,
    },
    {
        path: 'damages/:id',
        component: DamagesComponent
    },
    {
        path: ':type',
        component: ArrivalsComponent
    }
];
