import { Pipe, PipeTransform } from '@angular/core';
import * as _ from 'lodash';

@Pipe({
  name: 'slice'
})
export class SlicePipe implements PipeTransform {
    transform(data: any, start: number, end: number): unknown {
        return _(data).values().slice(start, end).value();
  }

}
