import * as _ from 'lodash';
import { Package, PackageStatuses } from "./package";
import { ArrivePackingList } from "./arrive-packing-list";
import { NA_ID, DEF_ID } from './arrive-packing-list';
import { Carrier } from './carrier';
import { Packable } from "./packable";
import { Pallet } from './pallet';
import { DamageReport } from './damage-report';

export class Arrival extends Packable {
  static readonly ParcelArrival = "ParcelArrival";
  static readonly FreightArrival = "FreightArrival";
  validationKey = "arrival";
  id: number;
  open: boolean;
  dock: string;
  carrier_id: number;
  warehouse_id: number;
  carrier_name: string;
  dummy:string;
  _completed_counter: number;
  _regular_counter: number;
  _deflected_counter: number;
  carrier: Carrier;
  milk_run: boolean;
  printed_packages: boolean = false;
  receiving_end: Date;

  constructor(data: any) {
    super(data);
    this.setupCarrier(data);
    this.setupDefaults(data);
    this.setPallets(data.pallets);
  }

  static get update_open_props() {
    return ['open'];
  }

  clone(): Arrival {
    let data: any = {...this};
    data._arrive_packing_lists = null;
    let a = new Arrival(data);
    a.arrive_packing_lists = {...this.arrive_packing_lists};
    return a;
  }

  get stored(): boolean {
    return !!this.id;
  }

  private setupCarrier(data: any){
    if (data && data.carrier)
      this.carrier = new Carrier(data.carrier);
  }

  private setupDefaults(data: any){
    this.id = typeof(data) == 'number' ? data : +this.id;
    this.arrive_packing_lists = this.arrive_packing_lists || {};
    this.open = _.isNil(this.open) ? true : this.open;
  }

  setPallets(pallets?: any){
    if (this.all_packages.length > 0)
      _(this.all_packages).
      groupBy((p: Package) => p.pallet_id > 0 ? p.pallet_id : null).
      each((pkgs: Package[], id: string) => {
        let data = pallets && pallets[id] ? pallets[id] : {id: (+id) || undefined};
        data = {...data, ...{packages: pkgs}};
        return new Pallet(data)
      });
  }

  get saveProp(): string[] {
    this.cleanUpEmpty();
    return ['open', 'dock', 'carrier_id', 'warehouse_id', '_counter', 'milk_run', 'arrive_packing_lists_attributes','type', 'receiving_end'];
  }

  get isPackingsLoaded(): boolean {
    return !this._counter;
  }

  get color(): string {
    return this.open ? '#d1fbeb' : null;
  }

  get completed_counter(): number {
    return this._completed_counter || this.calcCompletedCounter();
  }

  get regular_counter(): number {
    return this._regular_counter || this.calcRegularCounter();
  }

  get deflected_counter(): number {
    return this._deflected_counter || this.calcDeflectedCounter();
  }

  set completed_counter(value: number) {
    this._completed_counter = value;
  }

  get all_packings_pos(): string[]{
    return _(this.all_packages)
      .map((p: Package) => p.po_list)
      .flatten()
      .compact()
      .uniq()
      .value();
  }

  get first_package(): Package {
    return _.first(this.all_packages);
  }

  get require_dock(): boolean {
    return this.isFreight  && !this.dock;
  }


  get arrive_packing_lists_attributes(): ArrivePackingList[] {
    return _(this.arrive_packing_lists).sortBy((a: ArrivePackingList) => a.havePackagesDeleted ? 0 : 1).value();
  }

  calcCompletedCounter(): number {
    let valids = _(this.arrive_packing_lists).filter(e=> e.isValid === true);
    let res = _.sum(valids.map(pl => _.size(pl.packages)).value());
    return res;
  }

  calcRegularCounter(): number {
    let valids = _(this.arrive_packing_lists).filter((e: ArrivePackingList)=> e.client.ignore === false);
    let res = _.sum(valids.map(pl => _.size(pl.packages)).value());
    return res;
  }

  calcDeflectedCounter(): number {
    let valids = _(this.arrive_packing_lists).filter((e: ArrivePackingList)=> e.client.ignore === true);
    let res = _.sum(valids.map(pl => _.size(pl.packages)).value());
    return res;
  }

  addPackingList(packing_list: ArrivePackingList){
    packing_list.arrival = this;
    this.arrive_packing_lists[packing_list.id] = packing_list;
    this.arrive_packing_lists = { ...this.arrive_packing_lists };
    this.cleanUp(packing_list);
    this.setPallets();
  }

  private removePackagesFromGenericPacking(packing_list: ArrivePackingList){
    let gpl: ArrivePackingList = _(this.arrive_packing_lists).find((pl: ArrivePackingList) => pl.carrier_packing_id == "NA")
    if (gpl) _(gpl.packages).filter((_,k) => packing_list.packages[k]).forEach((p: Package) => p._destroy = true);
  }

  private cleanUp(edited_packing_list: ArrivePackingList) {
    if (!edited_packing_list.generic)
      this.removePackagesFromGenericPacking(edited_packing_list);
    this.cleanUpEmpty();
  }

  private cleanUpEmpty(){
    Object.keys(this.arrive_packing_lists).forEach((k: string) => {
      if (_(this.arrive_packing_lists[k].packages).size() == 0) delete this.arrive_packing_lists[k];
    });
  }

  attemptAddOrUpdatePackage(pkg: Package, deflected: boolean = false): Package {
    pkg.carrier_packing_id = pkg.carrier_packing_id || (deflected ? DEF_ID : NA_ID);
    let currentPkg = this.findPackage(pkg.trk);
    if (currentPkg) {
      let currentData = currentPkg.toSaveData();
      if (currentPkg.allow_update && pkg.dense) _.mergeWith(currentPkg,pkg,Package.customMerge);
      if (pkg.status == PackageStatuses.palletized) currentPkg.status = pkg.status;
      if (_.isEqualWith(currentData, currentPkg.toSaveData(), Package.customCompare))
        return currentPkg;
      pkg = new Package(currentPkg);
      pkg.carrier_packing_id = currentPkg.carrier_packing_id;
    }
    this.addPackageToPacking(pkg);
    this.cleanUp(this.arrive_packing_lists[pkg.arrive_packing_list_id]);
    this.arrive_packing_lists = { ...this.arrive_packing_lists };
    return pkg;
  }

  addPackageToPacking(pkg: Package, id?: number) {
    id = id || pkg.arrive_packing_list_id || this.findPackingList(pkg);
    this.addPackageToThisPacking(pkg, id);
  }

  addPackageToPackingAnyStatus(pkg: Package, except?: number) {
    let id = pkg.arrive_packing_list_id || (except ?  this.findPackingListForDrop(pkg,except) : this.findPackingList(pkg));
    if (this.arrive_packing_lists && this.arrive_packing_lists[id] && this.arrive_packing_lists[id].isValid) {
      //Si ya esta documetado no agregar y registrar paquete como no documentado
      pkg.carrier_packing_id = NA_ID;
      id = this.findPackingList(pkg);
    }
    this.addPackageToThisPacking(pkg, id);
  }

  addPackageToThisPacking(pkg: Package, id?: number) {
    if (this.arrive_packing_lists &&
      this.arrive_packing_lists[id] &&
      this.arrive_packing_lists[id].isValid &&
      !this.arrive_packing_lists[id].deflected) { //Si ya esta documentado no agregar y registrar paquete como no documentado
      pkg.carrier_packing_id = NA_ID;
      id = this.findPackingList(pkg);
    }
    if (id) {
      pkg.arrive_packing_list_id = id;
      this.arrive_packing_lists[id].addOrUpdatePackage(pkg);
      this.arrive_packing_lists[id].packages = { ...this.arrive_packing_lists[pkg.arrive_packing_list_id].packages}
    } else {
      let na = new ArrivePackingList(pkg);
      pkg.arrive_packing_list_id = na.id;
      this.arrive_packing_lists[na.id] = na;
      this.arrive_packing_lists[na.id].arrival = this; //TODO: Pasar a constructor
    }
    this.setPallets();
  }

  findPackingList(pkg: Package): number {
    return (this.findReceipt(pkg) || {id: null}).id;
  }

  findPackingListForDrop(pkg: Package, except: number): number {
    return (this.findReceiptForDrop(pkg, except) || {id: null}).id;
  }

  findReceipt(pkg: Package): ArrivePackingList {
    return _(this.arrive_packing_lists).find((f: ArrivePackingList) =>
      !f.empty && f.id &&
      (
        (pkg.isPalletized && !!f.findPackageByPallet()) ||
          (!pkg.isPalletized && (f.carrier_packing_id == pkg.carrier_packing_id || !!f.findPackageByTrk(pkg.trk))
          )
      ));
  }

  findReceiptForDrop(pkg: Package, except: number): ArrivePackingList {
    return _(this.arrive_packing_lists).find((f: ArrivePackingList) =>
      !f.empty &&
      f.id != except &&
      (f.carrier_packing_id == pkg.carrier_packing_id || !!f.findPackageByTrk(pkg.trk)));
  }

  findByReceiptId(receipt_id: string) {
    return _(this.arrive_packing_lists).values().find((f: ArrivePackingList) => f.receipt_id == receipt_id);
  }

  deletePackage(id: number, arrive_packing_list_id?: string) {
    let pkg = this.findPackage(id);
    if (pkg) {
      let arrivepackinglist_id = arrive_packing_list_id || pkg.arrive_packing_list_id;
      var res = null;
      if (this.arrive_packing_lists[arrivepackinglist_id]){
        res = delete this.arrive_packing_lists[arrivepackinglist_id].packages[id];
        this.arrive_packing_lists[arrivepackinglist_id].packages = { ...this.arrive_packing_lists[arrivepackinglist_id].packages };
      }
      return res ? pkg : null;
    }
  }

  packageExists(id: string, all: boolean = true) {
    let pkg = this.findPackage(id);
    return pkg && (all || !pkg.arrive_packing_list.generic);
  }

  updateAssignedPackages(){
    _(this.arrive_packing_lists).forEach((pl: ArrivePackingList) => pl.inferClient() );
  }

  merge(arrival: Arrival){
    _.mergeWith(this, arrival, (fromServer: any, inState: any, key: string) => {
      if (key == "_arrive_packing_lists") {
        let shpFromServer = _.keyBy(fromServer, "carrier_packing_id");
        let shpInSate = _.keyBy(inState, "carrier_packing_id");
        let result = {}
        //Merge each pl from the server with the data in the state
        Object.keys(fromServer).forEach((k: string) => {
          if (shpInSate[fromServer[k].carrier_packing_id])
            fromServer[k].merge(shpInSate[fromServer[k].carrier_packing_id])
          result[k] = fromServer[k];
        })
        //I pass all the pl that exist in state to the result
        Object.keys(inState).forEach((k: string) => {
          if (!shpFromServer[inState[k].carrier_packing_id]) result[k] = inState[k];
        });
        //Fix re groping done from the backend or other device
        _(result).
          map((apl: ArrivePackingList,id: string) => _(apl.packages).map((v: Package,k: string) => [+id,+k,v.trk]).value()). //Extract data needed to compare
          flatten().groupBy(2).filter((g: any[]) => g.length > 1).                                                           //Flat data and make groups for the duplicated trk numbers
          map((g: any[]) => g.filter(f => f[1] < 0)).
          forEach((g: any[]) => g.forEach((d: any[]) => delete result[d[0]].packages[d[1]]));
        return result;
      }
      if (key == "id")
        return fromServer;
      if (!inState && fromServer)
        return fromServer;
    });
  }

  get palletized_packages(): Package[] {
    return this.all_packages.filter((p: Package) => p.isPalletized);
  }

  getWorkingPallet(): Pallet {
    return this.all_packages.map((p: Package) => p.pallet).find((p: Pallet) => p && p.id < 0) || (new Pallet({}));
  }

  getPallet(id: number) {
    let pkg = this.palletized_packages.find((p: Package) => p.pallet_id == id);
    return pkg && pkg.pallet;
  }

  get palletized_receipt_ids(): string[] {
    return _(this.palletized_packages).
      map((p: Package) => p.receipt_id).
      filter((r:string) => !!r).
      uniq().value();
  }

  reassign(packages: Package[], receipt_id: string) {
    //Get APL
    let apl = this.findByReceiptId(receipt_id);
    if (apl) {
      packages.forEach((p: Package) => {
        this.addPackageToPacking(new Package(p),apl.id)
        p._destroy = true;
      });
    }
  }

  get isParcel(): boolean {
    return !this.isFreight;
  }

  addDamateReport(dr: DamageReport){
    this.arrive_packing_lists[dr.arrive_packing_list_id].packages[dr.package_id].damage_report = dr;
  }

  get haveShipment(): boolean {
  	  return _(this.arrive_packing_lists).some((apl: ArrivePackingList) => apl.haveShipment);
  }

  get usePartNumber(): boolean {
    return _(this.arrive_packing_lists).some((apl: ArrivePackingList) => apl.usePartNumber);
  }

  get aplsWithPartNumber(): ArrivePackingList[] {
    return _(this.arrive_packing_lists).filter((apl: ArrivePackingList) => apl.usePartNumber).value();
  }

}
