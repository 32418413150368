import { OnInit, Input, ViewChild, ElementRef, Output, EventEmitter, NgZone, Directive } from '@angular/core';
import { Package, Client, DamageReport, PackageStatuses, CatalogsState, AuthenticationService, Arrival, PartNumber } from 'wms-lib';
import { Store } from '@ngxs/store';
import * as store_actions from 'wms-lib';
import { NotificationService, CodebarReaderService } from 'wms-lib';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { ScanListenerComponent } from '../scan-listener/scan-listener.component';

@Directive({selector: '[packageBase]'})
export class PackageBaseComponent extends ScanListenerComponent implements OnInit {
  protected _package: Package;
  protected _propertyUpdated: boolean = false;
  protected _selected: boolean = false;
  protected _property_pn_focused: string = '';
  protected el: ElementRef;
  @Input() show_header: boolean = false;
  @Input() compact: boolean = true;
  @Output() modelUpdated: EventEmitter<any> = new EventEmitter<any>();

  partNumberCols = [
	  { field: 'name', header: 'Name' },
	  { field: 'description', header: 'Description' },
	  { field: 'unit_name', header: 'Unit' },
	  { field: 'quantity', header: 'Quantity' }
  ];

  @ViewChild('packageContainer', { static: false })
  packageContainer: ElementRef;
  @ViewChild('deleteContainer', { static: false })
  deleteContainer: ElementRef;
  @ViewChild('dropContainer', { static: false })
  dropContainer: ElementRef;
  @Input()
  set package(p: Package) {
    this._package = p;
    if (this._package)
      this._package.validate();
  }

  get package(): Package {
    return this._package;
  }

  isFormMode: boolean = false;
  packageMetadata: any;
  buildDamageReport: () => Observable<DamageReport>;
  bundle_types: Map<number, string> = new Map([]);
  active_client: Client;
  part_numbers: { [key: number]: PartNumber } = {};

  constructor(
    protected store: Store,
    public notification_service: NotificationService,
    protected codebar_service: CodebarReaderService,
    protected ng_zone: NgZone,
    protected authenticationService : AuthenticationService
  ) {
    super(codebar_service, ng_zone);
    this.packageMetadata = JSON.parse(
      JSON.stringify(require('../../../models/_package.json'))
    );
  }

  ngOnInit() {
    this.addSub(this.store.select((state) => state.arrivalsState.working_package_id).subscribe((wp: number) => {
      this.selected = this.package && (this.package.id === wp);
    }));
    this.addSub(this.store.select((state) => state.arrivalsState.working_package_id_part_number).subscribe((wp: number) => {
      this.selected = this.package && (this.package.id === wp);
      this.isFormMode = this.selected || this.isFormMode;
    }));
    this.addSub(this.store.select((state) => state.arrivalsState.undoc_sel_pkgs).subscribe((usp: number[]) => {
      if (this.package && this.package.arrive_packing_list && this.package.arrive_packing_list.generic) {
        this.selected = usp.includes(this.package.id);
      }
    }));
    this.addSub(this.store.select(CatalogsState.getMapCatalog('bundle_types')).
      subscribe((c: Map<number, string>) => {
        this.bundle_types = c;
      }));
    this.store.select(state => state.catalogsState.active_client).subscribe((client: Client) => {
      this.active_client = client;
    } );
	this.store.select(state => state.catalogsState.part_numbers).subscribe((part_numbers: { [key: number]: PartNumber }) => {
		this.part_numbers = part_numbers;
	});
  }

  get clients(): any[] {
    return _(Client.CLIENTS_A)
      .map((m: Client) => ({ key: m.id, label: m.name }))
      .value();
  }

  toggle() {
    if (this.canEdit) {
      if(this.package.isFreight && this.package.usePartNumber) {
        this.store.dispatch(new store_actions.SetWorkingPackageForPartNumberAction({ package: this.package }));
      }
      if (this.compact || this.package.arrive_packing_list.generic) {
        if (this.package.arrive_packing_list.generic)
          this.store.dispatch(new store_actions.ToggleUndocPkgAction({ package: this.package, selected: !this.selected }));
        else
          this.store.dispatch(new store_actions.SetWorkingPackageAction({ package: this.package }));
      } else {
		  this.togglePackageComplementaryForm();
      }
    }
  }

  drop() {
    this.package.drop();
    this.store
      .dispatch(new store_actions.UpdateArriveAction({
        arrival: this.package.arrive_packing_list.arrival,
        package: this.package}));
  }

  addLocation(code: any) {
    if (this.package && code && this.selected) {
		this.package.location_tag = code;
		this.updateArrival();
    }
  }



  updateArrival() {
      this.store.dispatch(new store_actions.UpdateArriveAction({
        arrival: this.package.arrive_packing_list.arrival,
        package: this.package
      }));
  }

  get isDown(): boolean {
    return this.package.status === PackageStatuses.down;
  }

  delete() {
    throw new Error('This method is abstract');
  }

  commitPackageDelete() {
    this.store.dispatch(new store_actions.UpdateArriveAction({arrival: this.package.arrive_packing_list.arrival, package: this.package}));
  }

  restorePackageDeleted() {
    this.store.dispatch(new store_actions.MarkPackageToNotBeDeletedAction({ pkg: this.package }));
  }
  //#region Form Handle
  togglePackageComplementaryForm() {
    if (this.isFormMode)
      this.validateAndSave()
    this.isFormMode = !this.isFormMode;
  }

  protected validateAndSave() {
	if(this.package && this.package.arrive_packing_list) {
		this.package.arrive_packing_list.validate();
	}
	if (this._propertyUpdated) {this.modelUpdated.emit(this.package); }
  }
  //#endregion

  takeDamage() {
    if (this.package.isDamage){
      this.routeToDamageReport();
    } else if (this.package.arrival_open)
      this.buildDamageReport().subscribe((dr: DamageReport) => {
        if (dr) {
          dr.package = this.package;
          this.package.damage_report = dr;
          this.store.dispatch(new store_actions.UpdateDamageReportAction(dr));
          this.routeToDamageReport();
        }
      });
  }

  routeToDamageReport(){
    throw new Error('This method is abstract');
  }

  get selected(): boolean {
    return this._selected;
  }
  set selected(value: boolean) {
    this._selected = value;
    if (this.el) {
      if (this._selected)
        this.el.nativeElement.classList.add('selected-pk');
      else
        this.el.nativeElement.classList.remove('selected-pk');
    }
  }

  get canEdit(): boolean {
    // This also covers the case when the case when the arrival is closed
    // in theory if the arrival is closed all the packages need to be valids
    //return !this.package.arrive_packing_list.isValid || this.authenticationService.currentUserValue.isSupervisor;
    return true;
  }

  get canDelete(): boolean {
    return this.authenticationService.currentUserValue.isSupervisor || this.authenticationService.currentUserValue.isParcelSup;
  }

  get canPrint(): boolean {
    return this.authenticationService.currentUserValue.canReprint || !this.package.alredyPrinted;
  }

  print() {
    let printed = this.package.last_print;
    try {
      if(this.canPrint){
        this.package.last_print = new Date();
        this.package.arrive_packing_list.arrival.printed_packages = true;
        this.codebar_service.sendToPrinter([this.package.zpl])
        this.package.updateCallback();
      }
    } catch (e) {
      this.package.last_print = printed;
      this.package.arrive_packing_list.arrival.printed_packages = false;
      this.notification_service.toast(e.name + ': ' + e.message);
    }
  }

}
