import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'colorText'
})
export class ColorTextPipe implements PipeTransform {

  transform(value: string, backgroundColor: string = ''): string {
    const rgb = this.hexToRgb(value) || { r: 0, g: 0, b: 0 };
    const brightness = (rgb.r * 299 + rgb.g * 587 + rgb.b * 114) / 1000;
    return brightness > 125 ? '#000000' : '#ffffff';
  }

  private hexToRgb(hex: string): { r: number, g: number, b: number } | null {
    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null;
  }
}
