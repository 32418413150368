import { Component, OnInit, Input, OnChanges, SimpleChanges, Output } from '@angular/core';
import { Address, Client, Forward } from 'wms-lib';
import * as actions from 'wms-lib';
import { Store } from '@ngxs/store';
import { StateListenerComponent } from '../scan-listener/state-listener.component';

@Component({
    selector: 'app-forwards-address',
    templateUrl: './forward-address.component.html',
    styleUrls: ['./forward-address.component.scss']
})
export class ForwardAddressComponent extends StateListenerComponent implements OnInit, OnChanges {
    @Input() client?: Client;
    @Input() popup: boolean = false;
    forward?: Forward;
    active_client: Client;
    
    constructor(protected store: Store) {
        super();
        this.store.dispatch(new actions.LoadForwardsAction());
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.client) {
            this.client = changes.client.currentValue;
            this.unsubscribe_state();
        }
    }

    ngOnInit() {        
        this.addSub(this.store.select(state => state.catalogsState.active_client).subscribe(client => {
            this.client = client;
        }));
    }

    onForwardReceived(forward: Forward) {   
        this.forward = forward;
    }

    onAddressChange(address: Address){
        this.forward.address = address;
        this.store.dispatch(new actions.SaveForwardAction({forward: this.forward}));
    }

}
