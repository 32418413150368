import { Routes } from '@angular/router';
import { AuthGuard } from './shared/guards/auth.guard';
import { LoginComponent } from './shared/components/login/login.component';
import { AppMainComponent } from './app.main.component';
import { SettingsComponent } from './shared/components/settings/settings.component';
import { HomeComponent } from './home/home.component';
import { SearchComponent } from './shared/components/search/search.component';
import { ClientsComponent } from './shared/components/catalogs/clients/clients.component';
import { CarriersComponent } from './shared/components/catalogs/carriers/carriers.component';
import { GoodTypesComponent } from './shared/components/catalogs/good-types/good-types.component';
import { ConveyanceTypesComponent } from './shared/components/catalogs/conveyance-types/conveyance-types.component';
import { BundleTypesComponent } from './shared/components/catalogs/bundle-types/bundle-types.component';
import { DamageTypesComponent } from './shared/components/catalogs/damage-types/damage-types.component';
import { LocationsComponent } from './shared/components/catalogs/locations/locations.component';
import { ContainerTypesComponent } from './shared/components/catalogs/container-types/container-types.component';
import { UnitsComponent } from './shared/components/catalogs/units/units.component';
import { PartNumbersComponent } from './shared/components/catalogs/part-numbers/part-numbers.component';
import { PrintersComponent } from './shared/components/catalogs/printers/printers.component';
import { ReceiptsComponent } from './clients/components/receipts/receipts.component';
import { PackingsFilter } from 'wms-lib';
import { PrioritiesComponent } from './shared/components/catalogs/priorities/priorities.component';
import { GoodCategoriesComponent } from './shared/components/catalogs/good-categories/good-categories.component';
import { UsersComponent } from './shared/components/users/users.component';
import { UserComponent } from './shared/components/users/user/user.component';
import { PackingsOnHandComponent } from './shared/components/packings-on-hand/packings-on-hand.component';
import { TrackerComponent } from './shared/components/tracker/tracker.component';
import {  KpisParcelComponent } from './shared/components/kpis/kpis-parcel/kpis-parcel.component';
import { KpisShipmentComponent   } from './shared/components/kpis/kpis-shipment/kpis-shipment.component';
import { KpisTicketComponent } from './shared/components/kpis/kpis-ticket/kpis-ticket.component';
import { WarehouseComponent } from './shared/components/catalogs/warehouses/warehouses.component';
import { UpdatePasswordComponent } from './shared/components/update-password/update-password.component';
import { ForwardAddressComponent } from './shared/components/forward-address/forward-address.component';
import { InventoryCyclicComponent } from './inventory/components/inventory-cyclic/inventory-cyclic.component';
import { ClassificationsComponent } from './shared/components/catalogs/classifications/classifications.component';
import { CommoditiesComponent } from './shared/components/catalogs/commodities/commodities.component';

export const routes: Routes = [
  {
    path: '', component: AppMainComponent, children: [
      { path: '', component: HomeComponent, pathMatch: 'full', canActivate: [AuthGuard]},
      { path: 'users', component: UsersComponent, pathMatch: 'full', canActivate: [AuthGuard]},
      { path: 'profile', component: UserComponent, pathMatch: 'full', canActivate: [AuthGuard]},
      { path: 'clients', component: ClientsComponent , pathMatch: 'full', canActivate: [AuthGuard]},
      { path: 'carriers', component: CarriersComponent , pathMatch: 'full', canActivate: [AuthGuard]},
      { path: 'good-types', component: GoodTypesComponent, canActivate: [AuthGuard] },
      { path: 'bundle-types', component: BundleTypesComponent, canActivate: [AuthGuard] },
      { path: 'warehouses', component: WarehouseComponent, canActivate: [AuthGuard] },
      { path: 'damage-types', component: DamageTypesComponent, canActivate: [AuthGuard] },
      { path: 'conveyance-types', component: ConveyanceTypesComponent, canActivate: [AuthGuard] },
      { path: 'priorities', component: PrioritiesComponent, canActivate: [AuthGuard] },
      { path: 'good-categories', component: GoodCategoriesComponent, canActivate: [AuthGuard] },
      { path: 'container-types', component: ContainerTypesComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'locations', component: LocationsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'units', component: UnitsComponent, canActivate: [AuthGuard] },
      { path: 'part-numbers', component: PartNumbersComponent, canActivate: [AuthGuard] },
      { path: 'printers', component: PrintersComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'settings', component: SettingsComponent, canActivate: [AuthGuard] },
      { path: 'categorize-packages', component: ReceiptsComponent, data: { filter: new PackingsFilter(), title: 'Pending to categorize', todo: true, clasify: true} },
      { path: 'search/:query', component: SearchComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'packings_on_hand', component: PackingsOnHandComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'receipts', component: ReceiptsComponent, data: { filter: new PackingsFilter(1, true, 'desc'), title: 'Receipts', todo: true, clasify: false} },
      { path: 'tracker', component: TrackerComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'classifications', component: ClassificationsComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'commodities', component: CommoditiesComponent, canActivate: [AuthGuard] },
      {
        path: 'receivings',
        loadChildren: () => import('./receiving/receiving.module').then(m => m.ReceivingModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'inventory',
        loadChildren: () => import('./inventory/inventory.module').then(m => m.InventoryModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'shipments',
        loadChildren: () => import('./shipments/shipments.module').then(m => m.ShipmentsModule),
        canActivate: [AuthGuard]
      },
      {
        path: 'help-desk',
        loadChildren: () => import('./help-desk/help-desk.module').then(m => m.HelpDeskModule),
        canActivate: [AuthGuard]
      },
      {
         path: 'exports',
         loadChildren: () => import('./exports/exports.module').then(m => m.ExportModule),
         canActivate: [AuthGuard]
      },
      { path: 'forwards', component: ForwardAddressComponent, canActivate: [AuthGuard] },
      { path: 'kpis/parcels', component: KpisParcelComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'kpis/shipments', component: KpisShipmentComponent, pathMatch: 'full', canActivate: [AuthGuard] },
      { path: 'kpis/tickets', component: KpisTicketComponent, pathMatch: 'full', canActivate: [AuthGuard] },


    ]
  },
  { path: 'login', component: LoginComponent },
  { path: 'update_password', component: UpdatePasswordComponent }

];
