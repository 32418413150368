import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthenticationService } from 'wms-lib';


@Injectable({
    providedIn: 'root'
})
export class AuthGuard implements CanActivate {
    constructor(protected router: Router, protected authenticationService: AuthenticationService) { }

    canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
        if (this.authenticationService.isLoggedIn) {
            global.log("Component", "Already logged");
            let user = this.authenticationService.currentUserValue;
            return user.access.includes(next.routeConfig.path.split("/")[0]);
        } else {
            this.router.navigate(['login'], { queryParams: { returnUrl: state.url } });
            return false;
        }
    }
}
