import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Store } from '@ngxs/store';
import { DeleteTicketAction, Ticket, AuthenticationService } from 'wms-lib';
import { DialogService } from 'primeng/dynamicdialog';
import { ConfirmationService } from 'primeng/api';
import { TicketDetailsComponent } from '../ticket-details/ticket-details.component';
import { TicketFormComponent } from '../ticket-form/ticket-form.component';
import { TicketListCardsBaseComponent } from './ticket-list-cards.base.component';

@Component({
    selector: 'app-ticket-list-cards',
    templateUrl: './ticket-list-cards.component.html',
    styleUrls: ['./ticket-list-cards.component.scss'],
    providers: [ DialogService ]
})
export class TicketListCardsComponent 
extends TicketListCardsBaseComponent implements OnInit {
    @Output() onDragging = new EventEmitter();
    @Output() onDragged = new EventEmitter();

    columns = [
        { field: 'id', header: 'Id' },
        { field: 'title', header: 'Title' },
        { field: 'description', header: 'Description' },
        { field: 'created_by', header: 'Created By' },
        { field: 'assign_to', header: 'Assign To' },
        { field: 'ticket_status', header: 'Status' }
    ];

    constructor(store: Store,
                private dialogService: DialogService,
                private confirmationService: ConfirmationService,
                protected authenticationService: AuthenticationService) {
        super(store, authenticationService);
        this.clear_loaded_pages = true;
    }

    dragStart(event: any,ticket: Ticket) {
        this.onDragging.emit({event,ticket});
    }

    dragEnd(event: any,ticket: Ticket) {
        this.onDragged.emit({event,ticket});
    }

    edit(ticket: Ticket) {
        this.dialogService.open(TicketFormComponent,{ width: '70%', data: { id: ticket.id}});
    }

    viewDetails(ticket: Ticket) {
        this.dialogService.open(TicketDetailsComponent,{ data: {ticket: ticket},  width: '70%'});
    }

    delete(ticket: Ticket) {

        this.confirmationService.confirm({
            message: 'Do you want to delete the ticket #' + ticket.id,
            accept: () => {
                this.store.dispatch(new DeleteTicketAction(ticket));
            }
        })
    }

    loadTicketsLazy(event: any) {
        const page = (event.first / event.rows) > 0 ? (event.first / event.rows) + 1 : 1;
        if (!this.page[this.client_id] || this.page[this.client_id].page != page) 
            this.requestTicketsByClient(this.client_id,this.status.key,page);
    }
}
