import { NgModule } from '@angular/core';
import { ConfirmationService } from 'primeng/api';
import { environment } from '../../environments/environment';
import { WmsLibModule } from 'wms-lib';
import { LoginComponent } from './components/login/login.component';
import { SettingsComponent } from './components/settings/settings.component';
import { MenuService } from './services/app.menu.service';
import { SharedRoutingModule } from './shared-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NG_PRIME_COMPONENTS } from './shared.common';
import { ScannerDirective } from './directives/scanner.directive';
import { DocumentService } from './services/documents.service';
import { ActionBarComponent } from './ui/action-bar/action-bar.component';
import { ProgressBarComponent } from './ui/progress-bar/progress-bar.component';
import { ClientSelectionDialogComponent } from './components/dialogs/client-selection-dialog/client-selection-dialog.component';
import { ClientSelectionDialogBaseComponent } from './components/dialogs/client-selection-dialog/client-selection-dialog.component.base';
import { CatalogSelectionDialogComponent } from './components/dialogs/catalog-selection-dialog/catalog-selection-dialog.component';
import { ClientsComponent } from './components/catalogs/clients/clients.component';
import { CarriersComponent } from './components/catalogs/carriers/carriers.component';
import { GoodTypesComponent } from './components/catalogs/good-types/good-types.component';
import { DamageTypesComponent } from './components/catalogs/damage-types/damage-types.component';
import { PrioritiesComponent } from './components/catalogs/priorities/priorities.component';
import { LocationsComponent } from './components/catalogs/locations/locations.component';
import { ContainerTypesComponent } from './components/catalogs/container-types/container-types.component';
import { ConveyanceTypesComponent } from './components/catalogs/conveyance-types/conveyance-types.component';
import { UnitsComponent } from './components/catalogs/units/units.component';
import { PartNumbersComponent } from './components/catalogs/part-numbers/part-numbers.component';
import { PrintersComponent } from './components/catalogs/printers/printers.component';
import { BundleTypesComponent } from './components/catalogs/bundle-types/bundle-types.component';
import { ShipmentsComponent } from './components/shipments/shipments.component';
import { ShipmentDetailPreviewComponent } from './components/shipment-details-preview/shipment-detail-preview.component';
import { DocumentViewerComponent } from './components/document-viewer/document-viewer.component';
import { UnitConverterDirective } from './directives/unit-converter.directive'
import { ClientChangerComponent } from './components/client-changer/client-changer.component';
import { SearchComponent } from './components/search/search.component';
import { SearchPackingListsComponent } from './components/search/search-packing-lists/search-packing-lists.component';
import { SearchPackingListComponent } from './components/search/search-packing-list/search-packing-list.component';
import { SearchPackageComponent } from './components/search/search-package/search-package.component';
import { DamageReportComponent } from './components/damage-report/damage-report.component';
import { MatStepperModule } from '@angular/material/stepper';
import { GoodCategoriesComponent } from './components/catalogs/good-categories/good-categories.component';
import { UsersComponent } from './components/users/users.component';
import { UserComponent } from './components/users/user/user.component';
import { PackingsOnHandComponent } from './components/packings-on-hand/packings-on-hand.component';
import { TrackerComponent } from './components/tracker/tracker.component';
import { ListComponent } from './components/list/list.component';
import { ListDetailComponent } from './components/list-detail/list-detail.component';
import { ListDetailPackageComponent } from './components/list-detail-package/list-detail-package.component';
import { HistoryComponent } from './components/history/history.component';
import { NgxJsonViewerModule } from 'ngx-json-viewer';
import { HomeComponent } from './components/home/home.component';
import { GaugeChartModule } from 'angular-gauge-chart';
import { KpisParcelComponent } from './components/kpis/kpis-parcel/kpis-parcel.component';
import { KpisShipmentComponent } from './components/kpis/kpis-shipment/kpis-shipment.component';
import { KpisTicketComponent } from './components/kpis/kpis-ticket/kpis-ticket.component';
import { WarehouseComponent } from './components/catalogs/warehouses/warehouses.component';
import { RefreshSessionComponent } from './dialogs/refresh-session/refresh-session.component';
import { UpdatePasswordComponent } from './components/update-password/update-password.component';
import { ForwardComponent } from './components/forward/forward.component';
import { AddressFormComponent } from './components/address-form/address-form.component';
import { ForwardAddressComponent } from './components/forward-address/forward-address.component';
import { DocumentsCarrouselComponent } from './components/documents-carrousel/documents-carrousel.component';
import { PopsCarrouselComponent } from './components/documents-carrousel/pops-carrousel.component';
import { PopViewerComponent } from './components/document-viewer/pop-viewer.component'
import { DeliverySearchResultComponent } from './components/search/delivery-search-result/delivery-search-result.component'
import { DeliveryListTableComponent } from './components/delivery-list-table/delivery-list-table.component';
import { PartNumberTableResumeComponent } from './components/part-number/part-number-table-resume/part-number-table-resume.component';
import { ClassificationsComponent } from './components/catalogs/classifications/classifications.component';
import { CommoditiesComponent } from './components/catalogs/commodities/commodities.component';

const local_exports = [
  LoginComponent,
  ShipmentsComponent,
  ShipmentDetailPreviewComponent,
  WmsLibModule,
  ScannerDirective,
  ClientSelectionDialogComponent,
  DocumentViewerComponent,
  SharedRoutingModule,
  UnitConverterDirective,
  ClientChangerComponent,
  SearchComponent,
  SearchPackingListsComponent,
  SearchPackingListComponent,
  SearchPackageComponent,
  DamageReportComponent,
  ConveyanceTypesComponent,
  MatStepperModule,
  TrackerComponent,
  HistoryComponent,
  HomeComponent,
  UpdatePasswordComponent,
  RefreshSessionComponent,
  DocumentsCarrouselComponent,
  PopsCarrouselComponent,
  PopViewerComponent,
  DeliverySearchResultComponent,
  DeliveryListTableComponent,
  PartNumberTableResumeComponent,
  ClassificationsComponent,
  CommoditiesComponent
];
const local_imports = [
  CommonModule,
  SharedRoutingModule,
  WmsLibModule.forRoot(environment),
  FormsModule,
  ReactiveFormsModule,
  MatStepperModule,
  NgxJsonViewerModule,
  GaugeChartModule
];
const imports_modules = [...NG_PRIME_COMPONENTS, ...local_imports];
const exports_modules = [...NG_PRIME_COMPONENTS, ...local_exports];

@NgModule({
  imports: imports_modules,
  declarations: [
    ActionBarComponent,
    ProgressBarComponent,
    LoginComponent,
    SettingsComponent,
    ShipmentsComponent,
    ShipmentDetailPreviewComponent,
    ClientsComponent,
    CarriersComponent,
    ClientSelectionDialogComponent,
    ScannerDirective,
    GoodTypesComponent,
    DamageTypesComponent,
    ContainerTypesComponent,
    WarehouseComponent,
    UnitsComponent,
    PartNumbersComponent,
    PrintersComponent,
    BundleTypesComponent,
    LocationsComponent,
    ClientSelectionDialogComponent,
    ScannerDirective,
    ClientSelectionDialogBaseComponent,
    CatalogSelectionDialogComponent,
    DocumentViewerComponent,
    UnitConverterDirective,
    ClientChangerComponent,
    SearchComponent,
    SearchPackingListsComponent,
    SearchPackingListComponent,
    SearchPackageComponent,
    DamageReportComponent,
    PrioritiesComponent,
    GoodCategoriesComponent,
    UsersComponent,
    UserComponent,
    PackingsOnHandComponent,
    ConveyanceTypesComponent,
    TrackerComponent,
    HistoryComponent,
    HomeComponent,
    UpdatePasswordComponent,
    RefreshSessionComponent,
    KpisParcelComponent,
    KpisShipmentComponent,
    KpisTicketComponent,
    ForwardComponent,
    AddressFormComponent,
    ForwardAddressComponent,
    DocumentsCarrouselComponent,
    PopsCarrouselComponent,
    PopViewerComponent,
    DeliverySearchResultComponent,
    DeliveryListTableComponent,
	  PartNumberTableResumeComponent,
    ListComponent,
    ListDetailComponent,
    ListDetailPackageComponent,
    ClassificationsComponent,
    CommoditiesComponent
  ],
  exports: exports_modules,
  entryComponents: [ClientSelectionDialogComponent],
  providers: []
})
export class SharedModule {
  static forRoot() {
    return {
      ngModule: SharedModule,
      providers: [ // services that you want to share across modules
        MenuService,
        DocumentService,
        ConfirmationService
      ]
    };
  }
}
