import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthenticationService, User } from 'wms-lib';
import { MessageService } from 'primeng/api';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [ MessageService ]
})
export class LoginComponent implements OnInit {
  sucess_auth = false;
  private returnUrl: string;
  loading: boolean = false;
  totp_session: string;
  mfa: string;

  constructor(
    private authService: AuthenticationService,
    private router: Router,
    private route: ActivatedRoute,
    private ng_msg_service: MessageService
  ) { }

  ngOnInit() {
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || null;
    this.loading = false;
  }

  login(form: NgForm, username: any, code: any) {
    this.loading = true;
    this.authService.LogIn(form.value.username, form.value.password, form.value.totp_code, this.totp_session, this.mfa)
      .subscribe((response: User) => {
        if (response && (!response.errors || Object.keys(response.errors).length == 0) && !response.totp_session) {
          this.router.navigate([this.returnUrl || '/']);
        } else if (response && response.totp_session) {
          this.totp_session = response.totp_session;
          this.mfa = response.mfa;
          setTimeout(()=>{
            code.valueAccessor._elementRef.nativeElement.focus();
          },0);
        } else {
          form.reset();
          this.totp_session = null;
          setTimeout(()=>{
            username.valueAccessor._elementRef.nativeElement.focus();
          },0);
          this.ng_msg_service.add({severity:'error', summary:'Login failed', detail:'Invalid username or password'});
        }
        this.loading = false;
      });
  }

  get mfa_type(): string {
    return this.mfa == "SOFTWARE_TOKEN_MFA" ? "Software" : "Email";
  }
}
