import * as moment from 'moment';
import * as _ from 'lodash';
import { validateSync as clsValidate, ValidationError } from 'class-validator';
import { Arrival } from './arrival';
import { Location } from './location';
import { Shipment } from './shipment';
import { Package } from './package';
import { Pallet } from './pallet';
import { PartNumber } from './part-number';
import { DeliveryOrder } from './delivery-order';

export enum UOM { KG = 'KG', LB = 'LB' }

export const FACTORS = { [UOM.KG]: 1, [UOM.LB]: 2.2046 };
export function convertFromMtsTo(value: number, unit: string) { return value * (FACTORS[unit] || 1); };
export function convertToMtsFrom(value: number, unit: string) { return value / (FACTORS[unit] || 1); };
const NO_PARSE_DATE = ["raw_po", "name"];

export enum CARRIER_TYPES{  PARCEL= 'ParcelCarrier', FREIGHT = 'FreightCarrier' };

const IMG_DATAURL_REG = /^data:(?<type>\w+)\/(?<ext>\w+);base64,(?<content>.*)$/i;

export function parseDataUrl(data: string): RegExpExecArray {
  return IMG_DATAURL_REG.exec(data);
}


export class BindableBase {
  id: number;

  constructor(data?: any) {
    if (data) {
      Object.keys(data).forEach(k => {
        if (typeof (data[k]) === 'string' &&
          !NO_PARSE_DATE.includes(k) &&
          !k.includes('id') &&
          !/^\d+$/.exec(data[k]) &&
          moment(data[k], moment.ISO_8601, true).isValid()){
          this[k] = moment(data[k]).toDate();
        }
        else {
          if (!_.isNil(data[k])) this[k] = data[k];
        }
      });
    }
  }
}
export class Bindable extends BindableBase{
  created_at: Date;
  updated_at: Date;
  errors: any;
  public _backup: any;
  public updateCallback: () => void;

  constructor(data?: any) {
    super(data);
    this.created_at = this.created_at || new Date();
    this.updated_at = this.updated_at || new Date();
  }

  protected get saveProp(): string[] {
    return this.id > 0 ? ["id"] : [];
  }


  get filters(): any {
    return {};
  }

  toSaveData(properties:string[] = null) {
    const result = {};
    const props = properties || this.saveProp;

    props.forEach(p => {
      result[p] = this.extractDataToSave(this[p], p);
    });
    return result;
  }

  takeSnapshoot(properties: string[] = null) {
    this._backup = this.toSaveData(properties);
  }

  rollback(): any {
    _(this._backup).forEach((v: any, k: string) => {
      try { this[k] = v;}
      catch {}
    });
    return this;
  }

  haveChanged(properties: string[] = null): boolean {
    return !_.isEqual(this._backup, this.toSaveData(properties));
  }

  extractDataToSave(d: any, p?: string): any {
    if (d) {
      if (d.toSaveData) {
        return d.toSaveData();
      }
      else if (Array.isArray(d)) {
        if (this.filters[p]) {
          return d.filter(this.filters[p]).map(r => this.extractDataToSave(r));
        }
        else {
          return d.map(r => this.extractDataToSave(r));
        }
      } else if (d.constructor === Object) {
        return _.mapValues(d, (v: any) => this.extractDataToSave(v));
      }
      else {
        return d;
      }
    }
    return d;
  }

  protected parseDataUrl(data: string): RegExpExecArray {
    return IMG_DATAURL_REG.exec(data);
  }

  protected dataURLtoBlob(data: string, mime: string) {
    let bstr = atob(data), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--) u8arr[n] = bstr.charCodeAt(n);
    return new Blob([u8arr], {type:mime});
  }
}

export abstract class Validable extends Bindable {
  abstract validationKey: string;
  errors: { [id: number]: ValidationError } = {};

  public get isValid(): boolean {
    this.validate();
    return _.size(this.errors) == 0;
  }

  validate() {
    this.errors = _(clsValidate(this.validationKey, this)).
      keyBy('property').value();
  }
}

export class SettingsEnum {
  public static langs: Array<{ key: string, label: string }> = [
    { key: 'en', label: 'English' },
    { key: 'es', label: 'Spanish' }
  ];
  public static units: Array<{ key: string, label: string }> = [
    { key: 'LB', label: 'LB' },
    { key: 'KG', label: 'KG' }
  ];
  public static LANG: string = "lang";
  public static UNIT: string = "unit";
  public static DEFLECTED: string = "deflected";
  public static PRINTER: string = "printer";
  public static WAREHOUSE: string = "warehouse";
  public static CLIENT: string = "client_id";
}

export class Resume extends Array {
  constructor(items: any[]) {
    super(...items);
  }

  total(prop: string) {
    return _.sumBy(this, prop);
  }
}

export interface ISearchResult {
  package: Package;
  arrival: Arrival;
  location: Location;
  shipment: Shipment;
  pallet: Pallet;
  part_numbers: {[id: string]: PartNumber};
  delivery_order: DeliveryOrder
}

export function applyMixins(derivedCtor: any, baseCtors: any[]) {
  baseCtors.forEach(baseCtor => {
    Object.getOwnPropertyNames(baseCtor.prototype).forEach(name => {
      if (name !== 'constructor') {
        derivedCtor.prototype[name] = baseCtor.prototype[name];
      }
    });
  });
}

export interface IShareable {
  share_html: string
  share_text: string
  subject: string
  filename: string
  as_csv: string;
}
