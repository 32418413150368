import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { SettingsEnum, LocalStorage, AuthenticationService, ICatalog, Printer, Warehouse } from 'wms-lib';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  lang_labels: any[];
  unit_labels: any[];
  printers: any[];
  warehouses: any[];

  constructor(private store: Store, private auth: AuthenticationService) { }

  ngOnInit() {
    this.store.select((state) => state.catalogsState.printers).subscribe((printers: {[id:number]: ICatalog}) => {
      this.printers = Object.values(printers).map((p: ICatalog) => ({label: p.name, value: p.id}));
    });
    this.store.select((state) => state.catalogsState.warehouses).subscribe((warehouses: {[id:number]: ICatalog}) => {
      this.warehouses = Object.values(warehouses).map((p: ICatalog) => ({label: p.name, value: p.id}));
    });
    this.lang_labels = SettingsEnum.langs.map(x => ({label: x.key, value: x.key}));
    this.unit_labels = SettingsEnum.units.map(x => ({label: x.key, value: x.key}));
  }

  get selectedLang(): string {
    let value = LocalStorage.getString(SettingsEnum.LANG);
    if (!value && this.lang_labels[0]) {
      value = this.lang_labels[0].value;
      LocalStorage.saveString(SettingsEnum.LANG, value);
    }
    return value;
  }
  set selectedLang(value: string) {
    LocalStorage.saveString(SettingsEnum.LANG, value);
  }

  get selectedUnit(): string {
    let value = LocalStorage.getString(SettingsEnum.UNIT);
    if (!value && this.unit_labels[0]) {
      value = this.unit_labels[0].value;
      LocalStorage.saveString(SettingsEnum.UNIT, value);
    }
    return value;
  }
  set selectedUnit(value: string) {
    LocalStorage.saveString(SettingsEnum.UNIT, value);
  }

  get selectedPrinterId(): number {
    return +LocalStorage.getString(SettingsEnum.PRINTER);
  }
  set selectedPrinterId(value: number) {
    Printer.SELECTED = this.printers.find((p: Printer) => p.value == value);
    LocalStorage.saveString(SettingsEnum.PRINTER, ''+value);
  }

  get selectedWarehouseId(): number {
    return +LocalStorage.getString(SettingsEnum.WAREHOUSE);
  }
  set selectedWarehouseId(value: number) {
    Warehouse.SELECTED = this.warehouses.find((p: Warehouse) => p.value == value);
    LocalStorage.saveString(SettingsEnum.WAREHOUSE, ''+value);
  }

  get deflected(): boolean {
    let value = (LocalStorage.getString(SettingsEnum.DEFLECTED) && !!(+LocalStorage.getString(SettingsEnum.DEFLECTED)));
    if (value === null || value === undefined) {
      value = false;
      LocalStorage.saveString(SettingsEnum.DEFLECTED, value ? "1" : "0");
    }
    return value;
  }
  set deflected(value: boolean) {
    LocalStorage.saveString(SettingsEnum.DEFLECTED, value ? "1" : "0");
  }

}
