import { UOM, convertFromMtsTo, convertToMtsFrom } from "wms-lib"; 

export class UnitConverterBase {
    constructor(private _unit: UOM) {}

    convertFrom(value: number) {
        let x = convertFromMtsTo(value, this._unit);
        return x < 1 ? x : Math.round(x);
    }

    convertTo(value: number) {
        return convertToMtsFrom(value, this._unit);
    }
}
