import { Directive, OnInit, ChangeDetectorRef } from '@angular/core';
import { Store } from '@ngxs/store';
import * as actions from 'wms-lib';
import { Client } from 'wms-lib';
import * as _ from 'lodash';

@Directive({selector: '[appClientSelectionDialog]'})
export class ClientSelectionDialogBaseComponent implements OnInit {
  clients: { [id: number]: Client };
  selected_client: Client;
  fix = false;
  label_tags: string[] = [];
  from_tags: string[] = [];
  to_tags: string[] = [];
  params_context: any = {};


  constructor(protected store: Store,
              protected readonly change_detector_ref: ChangeDetectorRef) {
    setTimeout(() => this.change_detector_ref.detectChanges(), 0);
  }

  get filter_field(): string {
    return this.params_context.filterField;
  }

  get filter_value(): string {
    return this.params_context.filterValue;
  }

  ngOnInit() {
    this.store.select(state => state.catalogsState.clients).
      subscribe((clients: { [id: number]: Client }) => {
        this.clients = _(clients).values().filter((f: Client) => f.active).orderBy('name').keyBy('id').value();
        if (this.params_context.selectFirst) {
          let options = Object.values(this.clients);
          if (this.filter_field) {
            options = options.filter((f: Client) => f[this.filter_field] === this.filter_value);
          }
          if (this.params_context.selectFirst)
            this.selected_client = options[0];
        }
      });
  }

  isMatch(tag: string, compare: string[]): boolean {
    return compare.includes(tag);
  }

  select(client: Client) {
    if (this.params_context.pl && this.params_context.pl.client_id) {
      this.selected_client = client;
      this.reset();
    }
  }

  addTag(tag: string) {
    this.to_tags.push(tag);
  }

  removeTag(tag: string, prop: string) {
    this[prop] = this[prop].filter((f: string) => f !== tag);
  }

  reset() {
    this.label_tags = this.params_context.pl.filter_tags;
    this.from_tags = this.clients[this.params_context.pl.client_id].filter_tags;
    this.to_tags = this.selected_client.filter_tags;
  }

  save() {
    this.clients[this.params_context.pl.client_id].filter_tags = this.from_tags;
    this.store.dispatch(new actions.SaveCatalogAction<Client>({catalog: this.clients[this.params_context.pl.client_id],
                                                               name_catalog: 'clients'}));
    this.selected_client.filter_tags = this.to_tags;
    this.store.dispatch(new actions.SaveCatalogAction<Client>({catalog: this.selected_client, name_catalog: 'clients'}));
  }
}
