import { Component } from '@angular/core';
import { Printer } from 'wms-lib';
import { CatalogComponent } from '../../base-components/catalog.component';

@Component({
    selector: 'app-printers',
    templateUrl: './printers.component.html',
    styleUrls: ['./printers.component.sass']
})
export class PrintersComponent extends CatalogComponent<Printer> {
    name_catalog = 'printers';


    addNew(_type?: string) {
        let c = this.newItem({name: '', ip: '', port: '', brand: '', model: ''});
        this.items[c.id] = c;
        this.items = {...this.items};
    }

    newItem(data: any) {
        return new Printer(data);
    }
}

