import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl} from '@angular/platform-browser'

@Pipe({
    name: 'safeUrl'
})
export class SafeUrlPipe implements PipeTransform {
    constructor(private sanitizer: DomSanitizer){}
    transform(src: string | object): SafeResourceUrl {
        return this.sanitizer.bypassSecurityTrustResourceUrl(''+src);
    }
}
