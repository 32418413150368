import * as _ from 'lodash';
import { Bindable } from './common';
import { Package } from "./package";

export class Tag extends Bindable {
	cyclical_inventory_id: number;
	tag_location: string;
	package_id: number;
	package: Package;

	constructor(data: any) {
		super(data);
		this.package = new Package(data.package);
	}

	get is_scanned(): boolean {
		return !!this.tag_location;
	}

	get saveProp(): string[] {
		return super.saveProp.concat(['cyclical_inventory_id', 'tag_location', 'package_id']);
	}

}
