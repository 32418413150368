import { Package } from "./package";
import { ArrivePackingList } from "./arrive-packing-list";
import { Validable } from "./common";
import { IPackable } from "./IPackable";
import * as _ from 'lodash';
import { Arrival } from "./arrival";

export abstract class Packable extends Validable implements IPackable {
    protected _counter: number;
    id:number;
    open: boolean;
    type: string;
    protected _arrive_packing_lists: { [id:string]: ArrivePackingList};

    constructor(data: any) {
        super(data);
        this.setupPackingLists();
    }

    get arrive_packing_lists() {
        return this._arrive_packing_lists;
    }
    set arrive_packing_lists(value: { [id:string]: ArrivePackingList}) {
        this._arrive_packing_lists = value;
    }

    get completed(): boolean {
        return  this.counter === this.completed_counter;
    }

    get completed_counter(): number {
        throw new Error('Not implemented');
    }

    get counter() {
        return this._counter || this.calcCounter();
    }

    set counter(value: number) {
        this._counter = value;
    }

    get all_packages(): Package[] {
        return _(this.arrive_packing_lists)
            .values()
            .map((x: ArrivePackingList) => x.packages_a)
            .flatten()
            .filter((f: Package) => !f._destroy)
            .value();
    }

    get all_packages_with_part_number(): Package[] {
    	return this.all_packages.filter((p: Package) => p.part_number_id);
	}

    get isFreight(): boolean {
        return this.type == Arrival.FreightArrival;
    }

    get isParcel(): boolean {
        return this.type == Arrival.ParcelArrival;
    }

    private setupPackingLists() {
        this._arrive_packing_lists = _(this._arrive_packing_lists).
            map((m: any) => new ArrivePackingList({ ...m, arrival: this })).
            keyBy("id").value();
    }

    findPackage(id: string | number): Package {
        return this.all_packages.find((x: Package) => typeof(id) === 'string' ? x.trk === id || id.includes(x.simple_trk) : x.id === id);
    }

    calcCounter(): number {
        return _(this.arrive_packing_lists).values().sumBy('count');
    }

    get can_be_closed() {
        return this.open && Object.keys(this.arrive_packing_lists).length > 0 && this.completed;
    }

    get active_packings(): number {
        return _(this.arrive_packing_lists).values().sumBy((pl: ArrivePackingList) => !pl.deflected && !pl.isValid ? 1 : 0);
    }

    get active_packages(): number {
        return _(this.arrive_packing_lists).values().sumBy((pl: ArrivePackingList) => !pl.deflected && !pl.isValid ? Object.keys(pl.packages).length : 0);
    }

    get completed_packings(): number {
        return _(this.arrive_packing_lists).values().sumBy((pl: ArrivePackingList) => !pl.deflected && pl.isValid ? 1 : 0);
    }

    get completed_packages(): number {
        return _(this.arrive_packing_lists).values().sumBy((pl: ArrivePackingList) => !pl.deflected && pl.isValid ? Object.keys(pl.packages).length : 0);
    }

    get deflected_packings(): number {
        return _(this.arrive_packing_lists).values().sumBy((pl: ArrivePackingList) => pl.deflected ? 1 : 0);
    }

    get deflected_packages(): number {
        return _(this.arrive_packing_lists).values().sumBy((pl: ArrivePackingList) => pl.deflected ? Object.keys(pl.packages).length : 0);
    }

    get isToggable(): boolean {
        this.validate();
        return !this.open || _(this.arrive_packing_lists).values().every((apl: ArrivePackingList) => apl.isValid || apl.deflected);
    }
}
