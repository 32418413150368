import { Catalog } from "./catalog";

export class Unit extends Catalog {
    
    constructor(data:any) {
        super(data);
        this.id = data.id;
    }

}
