import { InventoryFilter } from '../../view-models';
import { Package, IStorageLocation, CyclicalInventory, Tag } from '../../models';

export class GetLocationInventoryAction {
	public static readonly type = '[Inventory] Get Location Inventory';
	constructor(public payload: { location_id: number }) {}
}

export class SetLocationDetailFilterAction {
	public static readonly type = '[Inventory] Set location detail filter';
	constructor(public payload: InventoryFilter) {}
}

export class ClearInventoryStateAction {
	public static readonly type = '[Inventory] Clear inventory state';
}

export class AssignLocationAction {
	public static readonly type = '[Inventory] Assign package to location';
  constructor(public payload: { pkg: Package, storageLocation: IStorageLocation }) {}
}

export class UnassignLocationAction {
	public static readonly type = '[Inventory] Unassign package to location';
  constructor(public payload: Package) {}
}

export class GetCyclicalInventoriesAction {
	public static readonly type = '[Inventory] Get Cyclical Inventories';
	constructor(public payload: { query?: {[key: string]: any} } = {}) { }
}

export class SaveCyclicalInventoryAction {
	public static readonly type = '[Inventory] Save Cyclical Inventory'; 
	constructor(public payload: CyclicalInventory) {}
}

export class DeleteCyclicalInventoryAction {
	public static readonly type = '[Inventory] Delete Cyclical Inventory';
	constructor(public payload: CyclicalInventory) {}
}

export class GetCyclicalInventoryTagsAction {
	public static readonly type = '[Inventory] Get Cyclical Inventory Tags';
	constructor(public payload: CyclicalInventory) {}
}

export class LoadAndSetCycleInventoryAction {
	public static readonly type = '[Inventory] Load and Set Cycle Inventory Action';
	constructor(public payload: CyclicalInventory) {}
}

export class SetCyclicalInventoryLocationAction {
	public static readonly type = '[Inventory] Set Cyclical Inventory Location';
	constructor(public payload: { location_tag: string }) {}
}

export class SetCyclicalInventoryTagAction {
	public static readonly type = '[Inventory] Set Cyclical Inventory Tag';
	constructor(public payload: Tag | undefined) {}
}

export class ToggleActiveCyclicalInventoryAction {
	public static readonly type = '[Inventory] Toggle Active Cyclical Inventory';
	constructor(public payload: CyclicalInventory) {}
}

export class OpenCloseCyclicalInventoryAction {
	public static readonly type = '[Inventory] Open Close Cyclical Inventory';
	constructor(public payload: { cyclical: CyclicalInventory, open: boolean }) {}
}

export type inventoryActions = GetLocationInventoryAction | SetLocationDetailFilterAction | ClearInventoryStateAction | AssignLocationAction | UnassignLocationAction | GetCyclicalInventoriesAction | SaveCyclicalInventoryAction | DeleteCyclicalInventoryAction | GetCyclicalInventoryTagsAction | LoadAndSetCycleInventoryAction | SetCyclicalInventoryLocationAction | SetCyclicalInventoryTagAction | ToggleActiveCyclicalInventoryAction | OpenCloseCyclicalInventoryAction;
