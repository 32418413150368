export class LocalStorage{
  static multiple_sessions: boolean = true;

  static saveString(key: string, value: string) {
    LocalStorage.sessionType.setItem(key, value);
  }
  static getString(key: string): string {
    return LocalStorage.sessionType.getItem(key);
  }
  static remove(key: string){
    LocalStorage.sessionType.removeItem(key);
  }

  static get sessionType(): any {
    return LocalStorage.multiple_sessions ? localStorage : sessionStorage;
  }
}
