import { Injectable } from '@angular/core';
import { Store } from '@ngxs/store';
import { MessageService } from 'primeng/api';
import { ConfirmationService } from 'primeng/api';
import { NotificationViewModel } from '../models/notification-view-model';
import { AddNotificationAction } from '../ngxs-store/actions';

@Injectable({
    providedIn: 'root',
    deps: [MessageService,Store,ConfirmationService]
})
export class NotificationService {
    constructor(private store:Store,
                protected message_service: MessageService,
                protected confirmation_service:ConfirmationService) {}

    toast(message: string, 
          localize: boolean = true,
          toast_options: {severity:string,summary:string, key?:string} = {summary: 'Error', severity:'error'}) {
        const options = {...toast_options, detail:message};
        this.message_service.add(options);
    }

    showFeedback(message: string,title: string = '', localize: boolean = false, hide?: () => void, tap?: () => void) {
        this.confirmation_service.confirm({
            message: message,
            header: title,
            icon: 'pi pi-info-circle',
            accept: () => {
                tap();
            },
            reject: () => {
                if (hide) hide();
            }
        });
    }

    pushNotification(message: any ) {
        const notification = new NotificationViewModel(message)
        
        if (this.store)
            this.store.dispatch(new AddNotificationAction(notification));
        this.toast(notification.body, true,{ severity:'success',summary: notification.title, key: 'notifications'});
    }

    onPressNotificationCallback(callback : (notificationData: any) => void) {
    }
}
