import { NgModule } from '@angular/core';
import { RouterModule } from "@angular/router";
import { CommonModule } from '@angular/common';
import { routes } from './exports.common';

@NgModule({
    imports: [ RouterModule.forChild(routes), CommonModule ],
    exports: [ RouterModule ]
})
export class RoutingModule { }
