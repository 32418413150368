import { OnDestroy, NgZone, Directive, HostListener } from '@angular/core';
import { CodebarReaderService } from 'wms-lib';
import { Subscription } from 'rxjs';
import { StateListenerComponent } from './state-listener.component';

@Directive({selector: '[scanListener]'})
export class ScanListenerComponent extends StateListenerComponent implements OnDestroy {
  protected scanner_subscriptions: { [id: string]: Subscription } = {};
  protected state_subscriptions: Subscription[] = [];
  protected onActivate: () => void;

  constructor(protected codebar_service: CodebarReaderService, protected ng_zone: NgZone) {
    super();
  }

  @HostListener('unloaded')
  ngOnDestroy() {
    global.log("Component",`Des subscribiendome de los eventos del scanner: ${this.constructor.name}`)
    super.ngOnDestroy();
    this.unsubscribe_scanner();
  }

  @HostListener('loaded')
  onLoad() {
    if (this.onActivate && !this.state_subscribed)
      this.onActivate();
  }

  setScannerOperation(type: string) {
    this.codebar_service.setOperation(type);
  }

  get isTablet(): boolean {
    return this.codebar_service.isTablet;
  }

  get isPhone(): boolean {
    return this.codebar_service.isPhone;
  }

  protected subscribe_scanner(subscribers: { [dispatcher: string]: (val: string) => void }) {
    Object.keys(subscribers).forEach((d: string) => {
      this.unsubscribe_scanner(d);
      this.scanner_subscriptions[d] = this.codebar_service.dispatchers[d].subscribe((data: string) => {
        this.ng_zone.run(() => subscribers[d].bind(this)(data));
      });
    });
  }

  protected unsubscribe_scanner(id: string = null) {
    // Because is called also from the ngOnDestroy is possible that "this" do not exist any more,
    // in that case scanner_subscription is also gone, so nothing to do any more,
    // this behavior was detected at debug mode when
    // native script reboot the app. I don't know if will also happen on production mode
    if (this) {
      if (!id)
        Object.keys(this.scanner_subscriptions).forEach((k: string) => {
          this.scanner_subscriptions[k].unsubscribe();
        });
      else if (this.scanner_subscriptions[id])
        this.scanner_subscriptions[id].unsubscribe();
    }
  }
}
