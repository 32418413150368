import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-location-card-detail',
  templateUrl: './location-card-detail.component.html',
  styleUrls: ['./location-card-detail.component.scss']
})
export class LocationCardDetailComponent implements OnInit {
 
  constructor() {}

  ngOnInit() {

  }
}