import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PicklistComponent } from './components/picklist/picklist.component';
import { ShipmentsRoutingModule } from './shipments-routing.module'
import { SharedModule } from '../shared/shared.module';

@NgModule({
    declarations: [
        PicklistComponent
    ],
    imports: [
        CommonModule,
        ShipmentsRoutingModule,
        SharedModule.forRoot()
    ]
})
export class ShipmentsModule { }
