const COLORS={
    "Error": "\x1b[31m",
    "Service": "\x1b[32m",
    "Snapshoot": "\x1b[33m",
    "State": "\x1b[34m",
    "Scann": "\x1b[35m",
    "Channel": "\x1b[36m",
    "Component": "\x1b[37m"
}

export const LOGGERS = {
    "console": (category: string, message: string) => {
        let color = COLORS[category] || '';
        console.log(color + `${category}: ${message}` + (color ? "\x1b[0m" : ''));
    },
    "file": (category: string, message: string) => {
        let entry = {date: new Date(), category: category, message: message};
        console.log(JSON.stringify(entry));
    }
}


export function getLogPath() {
  return null;
}
