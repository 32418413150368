import { Component } from '@angular/core';
import { CatalogComponent } from "../../base-components/catalog.component";
import { Location } from 'wms-lib';

@Component({
  selector: 'app-locations',
  templateUrl: './locations.component.html',
  styleUrls: ['./locations.component.scss']
})
export class LocationsComponent extends CatalogComponent<Location> {
    name_catalog: string = "locations";
    location_types: any[] = [
        {label: "Shelf", value: "Shelf"},
        {label: "RacK", value: "RacK"},
        {label: "Floor", value: "Floor"}
    ];

    newItem(data: any) {
        return new Location(data);
    }
}
