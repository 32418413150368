import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Observable } from "rxjs";
import {  LoginStateService } from "../services";

export class TokenInterceptor implements HttpInterceptor {
  constructor(private loginInfoService: LoginStateService) {
    if (!this.loginInfoService) console.log('Authservice is null fs');
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.loginInfoService.isLoggedIn &&  !(/.+login.*/.test(request.url))) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${this.loginInfoService.authToken}`
        }
      });
    }

    return next.handle(request);
  }
}
