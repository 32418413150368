import { Injectable } from "@angular/core";
import { LocalStorage } from "../helpers";
import { JwtHelperService } from "@auth0/angular-jwt";

@Injectable({
    providedIn: 'root'
})
export class LoginStateService {
    protected jwtHelper: JwtHelperService;

    constructor() {
        this.jwtHelper = new JwtHelperService();
    }

    get userFromLocalStorage() {
        return LocalStorage.getString('currentUser');
    }

    get isLoggedIn() {
        return !!this.userFromLocalStorage && ! this.jwtHelper.isTokenExpired(this.authToken);
    }

    get authToken() {
        const token = LocalStorage.getString('auth_token');
        return token;
    }

    getExpiration() {
        const token = LocalStorage.getString('auth_token');
        const date = this.jwtHelper.getTokenExpirationDate(token);
        return date;
    }

}