import { Component } from '@angular/core';
import { Catalog } from 'wms-lib';
import { CatalogComponent } from '../../base-components/catalog.component';

@Component({
    selector: 'app-priorities',
    templateUrl: '../catalogs.component.html',
    styleUrls: ['../catalogs.component.sass']
})
export class PrioritiesComponent extends CatalogComponent<Catalog> {
    name_catalog = 'priorities';
    title = "Priorities"

    addNew(_type?: string) {
        let c = this.newItem({name_es: '', name_en: '', type: _type});
        this.items[c.id] = c;
        this.items = {...this.items};
    }

    newItem(data: any) {
        return new Catalog(data);
    }
}

