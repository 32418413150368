import { Catalog } from "./catalog";

export class Country extends Catalog {
    validationKey: string = "country";
    
    constructor(data:any) {
        super(data);
    }

    protected get saveProp(): string[] {
        return  super.saveProp;
    }

}