import { HttpClientModule } from '@angular/common/http';
import { MetricPipe, SortByPipe, SortByLocationTagPipe, FilterByPipe, MapToSelectOptionsPipe, MapArrayToSelectOptionsPipe, ObjectToSelectOptionsPipe, SafeUrlPipe, MapCatalogToSelectOptionsPipe, ColorTextPipe, MapToSelectOptionsWithNullPipe } from './pipes';
import { NgModule, NO_ERRORS_SCHEMA } from '@angular/core';
import { NgxsModule } from '@ngxs/store';
import { ArrivalsState, InventoryState, ShipmentsState, CatalogsState, NotificationsState, ExposState } from './ngxs-store/states';
import { HelpDeskState } from './ngxs-store/states/help-desk.state';
import { LOGGERS } from './helpers/logger';
import './global'
import { ChannelsService} from './platform-services/channels.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { TokenInterceptor } from './interceptors/token-interceptor.service';
import { AuthErrorInterceptor } from './interceptors/auth-error-interceptor.service';
import { LoginStateService } from './services';

global.log = LOGGERS["console"];

@NgModule({
    imports: [
        HttpClientModule,
        NgxsModule.forFeature([ArrivalsState,InventoryState,ShipmentsState,CatalogsState, HelpDeskState, NotificationsState, ExposState])
    ],
    exports: [NgxsModule, MetricPipe, SortByPipe, SortByLocationTagPipe, FilterByPipe, MapToSelectOptionsPipe, MapToSelectOptionsWithNullPipe, MapArrayToSelectOptionsPipe, ObjectToSelectOptionsPipe, SafeUrlPipe, MapCatalogToSelectOptionsPipe, ColorTextPipe],
    declarations: [MetricPipe, SortByPipe,SortByLocationTagPipe, FilterByPipe, MapToSelectOptionsPipe, MapToSelectOptionsWithNullPipe, MapArrayToSelectOptionsPipe, ObjectToSelectOptionsPipe, SafeUrlPipe, MapCatalogToSelectOptionsPipe, ColorTextPipe],
    providers: [
      {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true, deps: [LoginStateService]},
      {provide: HTTP_INTERCEPTORS, useClass: AuthErrorInterceptor, multi: true}
    ],
    schemas: [NO_ERRORS_SCHEMA]
})
export class WmsLibModule {
    static forRoot(environment: any) {
		return {
			ngModule: WmsLibModule,
			providers: [
				{
					ChannelsService,
					MetricPipe,
					SortByPipe,
					SortByLocationTagPipe,
					FilterByPipe,
					MapToSelectOptionsPipe,
					MapToSelectOptionsWithNullPipe,
					MapArrayToSelectOptionsPipe,
					ObjectToSelectOptionsPipe,
					SafeUrlPipe,
					ColorTextPipe,
					provide: 'env', // you can also use InjectionToken
					useValue: environment
				}
			]
		};
	}
}
