import { Component, OnInit } from '@angular/core';
import { ModalDialogState }  from 'wms-lib';
import { PoSelectionDialogBaseComponent } from './po-selection-dialog.component.base';

@Component({
    selector: 'app-po-selection-dialog',
    templateUrl: './po-selection-dialog.component.html',
    styleUrls: ['./po-selection-dialog.component.scss']
})

export class PoSelectionDialogComponent extends PoSelectionDialogBaseComponent implements OnInit {    
    private _selected: string[]; 
    private _suggestions: string[];
    constructor(private modalState: ModalDialogState) {
        super();
    }

    ngOnInit(){
        this.params = {context: this.modalState.params};
        this._selected = this.modalState.params.selected;
    }

    get suggestions(): string [] {
        if (!this._suggestions) this._suggestions = this.options;
        if (this._new) this._suggestions = this._suggestions.filter((f: string) => f.toLowerCase().includes(this._new.toLocaleLowerCase()));
        return this._suggestions;
    }

    accept(apply_all: boolean = false) {
        this.modalState.closeCallBack({ result: this.selected.join(','), array_result: this.selected, apply_all: apply_all});
    }

    close(){
        this.modalState.closeCallBack(null);
    }

    onTextChanged(event: any) {
        this._suggestions = null;
        this._new = event.query;
    }

    get selected(): string[] {
        return this._selected;
    }

    set selected(value:string[]){
        this._selected = value;
    }

    no_po() {
        this._selected = ["NA"];
    }
}
