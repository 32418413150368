import { Bindable } from "./common";
import { RemoteAsset } from "./remote-asset";
import { Ticket } from "./ticket";

export class Attachment extends RemoteAsset {

    tiket_id: number;
    ticket: Ticket;
    _destroy: boolean = false;
    file: File;

    constructor(data:any) {
        super(data);
        this.initData();
    }

    initData(){
        if (this.ticket && !(this.ticket instanceof Ticket)) {
            this.ticket = new Ticket(this.ticket);
        }
    }

    get saveProp(): string[] {
        if (this._destroy)
            return ["id", "_destroy"]
        else {
            let p = super.saveProp;
            if (!this.stored)
                p.push("base64", "filename","file");
            return p;
        }
    }
}