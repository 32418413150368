
import { Component, OnInit } from "@angular/core";
import { Store } from "@ngxs/store";
import { AppMainComponent } from '../../../../../src/app/app.main.component';

@Component({
    selector: "app-kpis",
    templateUrl: "./kpis.component.html",
    styleUrls: ["./kpis.component.scss"]
})
export class KpisComponent implements OnInit {
    constructor(protected app: AppMainComponent, protected store: Store) { }

    ngOnInit(): void {
        this.app.onMenuButtonClick(null);
    }
}
