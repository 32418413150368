import { NgModule } from "@angular/core";
import { RouterModule } from "@angular/router";
import { routes } from './receiving.common';
import { CommonModule } from '@angular/common';

@NgModule({
    imports: [ RouterModule.forChild(routes), CommonModule ],
    exports: [ RouterModule ]
})
export class ReceivingRoutingModule {
}
