import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { DocumentsCarrouselBaseComponent } from './documents-carrousel.component.base';
import { Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { CodebarReaderService, ArrivalsService } from 'wms-lib';
import * as store_actions from 'wms-lib';
import { ArrivalsState, NotificationService } from 'wms-lib';
import { combineLatest } from 'rxjs';

@Component({
  selector: 'app-documents-carrousel',
  templateUrl: './documents-carrousel.component.html',
  styleUrls: ['./documents-carrousel.component.scss']
})
export class DocumentsCarrouselComponent extends DocumentsCarrouselBaseComponent implements OnInit {
  working_packing_id: string = null;
  @ViewChild('crs', { static: false }) crs: any;

  constructor(
    private router: Router,
    protected store: Store,
    protected activatedRoute: ActivatedRoute,
    protected codebar_service: CodebarReaderService,
    protected ng_zone: NgZone,
    protected arrivalsService: ArrivalsService,
    public notification_service: NotificationService
  )  {
    super(store,activatedRoute, codebar_service, ng_zone, notification_service);
  }

  ngOnInit() {
    this.getWorkingPackingList();
    this.loadDocuments();
    const documentSource1$ = this.store.select(ArrivalsState.getDocumentFromWorkingPacking());
    const documentSource2$ = this.store.select((state) => state.arrivalsState.document);

    this.addSub(combineLatest(documentSource1$, documentSource2$).subscribe(docs => {
      const doc = docs[0] || docs[1];
      if (doc) {
        if (this.isTablet) {
          this.goToTabletDocumentViewer();
        } else {
          this.goToPhoneDocumentViewer();
        }
      }
    }));

    this.addSub(this.store.select(state => state.arrivalsState.open_arrival).subscribe(arrival => {
      this.current_arrival = arrival;
    }));
  }

  goToTabletDocumentViewer() {
    this.router.navigate([{ outlets: {documents: ['view-document']} }],
                         { relativeTo: this.activatedRoute.parent });
  }

  goToPhoneDocumentViewer() {
    this.router.navigate(["/receivings", "view-document"]);
  }

  deleteDocument() {
    this.store.dispatch(new store_actions.DeleteDocumentsAction(this.crs.value[this.crs.page].id));
  }

  getWorkingPackingList(){
    this.store.select(state => state.arrivalsState.working_packing_id).subscribe(wpl_id => {
      this.working_packing_id = wpl_id;
    });
  }

  loadDocuments() {
    this.documents$ = this.store.select((state) => state.arrivalsState.documents);
  }

  selectDocument(document: any) {
    if(this.working_packing_id)
      this.store.dispatch(new store_actions.AssignDocumentToWorkingPackingAction({ document: document }));
    else
      this.store.dispatch(new store_actions.SetWorkingDocumentAction({ document: document }));
  }

  addDocument(event: any) {
    super.addDocument(event, this.arrivalsService.uploadDocument.bind(this.arrivalsService), true);
  }
}
