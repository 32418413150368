import {  Injectable, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { SaveTicketAction, Ticket } from 'wms-lib';
import { TicketComment } from 'wms-lib';

@Injectable()
export class TicketCommentsBaseComponent implements OnInit {

  ticket: Ticket;
  comment: string = '';

  constructor(protected store: Store) { }

  ngOnInit() {
    this.store.select(state => state.helpDeskState.active_ticket).subscribe(ticket => {
      if (ticket)
        this.ticket = ticket;
    })
  }

  add() {
    let ticket = new Ticket(this.ticket);
    ticket.ticket_comments[(new Date().getTime() * -1)] = new TicketComment({comment: this.comment});

    this.store.dispatch(new SaveTicketAction({ ticket: ticket})).subscribe(() => this.comment = '');
  }

}
