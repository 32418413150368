import { Validable } from "./common";
import * as _ from 'lodash';
import { ICatalogLogo } from "./catalog";

export class Carrier extends Validable implements ICatalogLogo {
    validationKey: string = 'carrier';
    id: number;
    name: string;
    logo_url: string;
    carrier_code: String;
    regexps: {[id: string]: RegExp};
    type: string;
    sequence: number;
    pop_url: string;

    static CARRIERS: {[id: number]: Carrier} = {};
    static get CARRIERS_LIST(): Carrier[] {
        return _.values(this.CARRIERS);
    }

    constructor(data: any){
        super(data);
        if (data.regexps)
            this.regexps = _.mapValues(data.regexps, (r: string) => new RegExp(r, "i"));
    }

    get saveProp(): string[] {
        let p =  super.saveProp.concat(["name", "carrier_code","type"]);
        if (!this.parseDataUrl(this.logo_url)) p.push("logo");
        if (!this.parseDataUrl(this.pop_url)) p.push("pop");
        return p;
    }

    toSaveData() {
        let data = super.toSaveData();
        let urlData = this.parseDataUrl(this.logo_url);
        let urlTemplate = this.parseDataUrl(this.pop_url);
        if (urlData || urlTemplate) {
            const formData: FormData = new FormData();
            if(urlData){
                formData.append("carrier[logo]",
                this.dataURLtoBlob(urlData.groups["content"], `${urlData.groups["type"]}/${urlData.groups["ext"]}`),
                `logo_${this.id}.${urlData.groups["ext"]}`);
            }
            if(urlTemplate){
                formData.append("carrier[pop]",
                this.dataURLtoBlob(urlTemplate.groups["content"], `${urlTemplate.groups["type"]}/${urlTemplate.groups["ext"]}`),
                `pop_${this.id}.${urlTemplate.groups["ext"]}`);
            }

            _(data).forEach((v: any, k: string) => {
                if(v === undefined) return;
                if (Array.isArray(v))
                    v.forEach((t: string) => formData.append(`carrier[${k}][]`, t));
                else
                    formData.append(`carrier[${k}]`, v);
            });
            return formData;
        } else {
            return data;
        }
    }

    get isFedex(): boolean {
        return this.carrier_code.startsWith("FDE");
    }
}
