import { Component, OnInit, Input, HostListener, HostBinding } from '@angular/core';
import { Store } from '@ngxs/store';
import { Catalog, ArrivePackingList, UpdatePackingCategoryAction, Shipment, GoodCategory, Arrival, AuthenticationService, Package, Client } from 'wms-lib';
import * as _ from 'lodash';
import * as actions from 'wms-lib';
import { combineLatest } from 'rxjs';

@Component({
    selector: 'tr[app-receipts-packing-list]',
    templateUrl: './receipts-packing-list.component.html',
    styleUrls: ['./receipts-packing-list.component.scss']
})
export class ReceiptsPackingListComponent implements OnInit {
    private _pl: ArrivePackingList;
    @Input() set pl(value: ArrivePackingList) {
        this._pl = value;
        this.old_category_id = value.good_category_id;
        if (!value.good_category_id) this.editing = true;
    }
    get pl(): ArrivePackingList { return this._pl;}
    @Input() expanded: boolean = false;
    @Input() multiselect: boolean = false;
    @Input() todo: boolean = false;
    @Input() clasify: boolean = false;
    @Input() table: any;
    good_categories: {[id: number]: GoodCategory } = {};
    good_types: {[id: number]: Catalog } = {};
    shipment: Shipment = new Shipment({packages: {}, id: 0});
    old_category_id: number;
    editing: boolean;
    client: Client;
    constructor(private store: Store, private authenticationService : AuthenticationService) {}

    ngOnInit() {
        combineLatest(
            this.store.select(state => state.catalogsState.good_types),
            this.store.select(state => state.catalogsState.good_categories),
            this.store.select((state) => state.catalogsState.active_client)
        ).subscribe(([good_types, good_categories, client]) => {
            this.good_types = good_types;
            this.good_categories = _(good_categories).filter((f: GoodCategory) => (client && f.client_id == client.id) || f.client_id == null).keyBy("id").value();
            if (this.good_categories && this.good_types && this.good_types[this.pl.good_type_id]){
                let gc = _(this.good_categories).values().find((f: Catalog) => f.name == this.good_types[this.pl.good_type_id].name)
                if (gc) this.pl.good_category_id = gc.id;
            }
            this.client = client;
        });
        this.store.select(state => state.shipmentsState.shipment_template).subscribe(shipment => this.shipment = shipment);
    }

    doSave() {
        this.store.dispatch(new UpdatePackingCategoryAction({pl: this._pl, old_cat: this.old_category_id, flush: this.todo}));
        this.editing = false;
    }

    change() {
        this.table.sortSingle();
    }

    addOrDeletePackingFromShipment(pl: ArrivePackingList) {
        this.store.dispatch(new actions.AddPackingListToShipmentAction(pl));
    }

    get selected(): boolean {
        return this.shipment && !!this.shipment.arrive_packing_lists[this.pl.id];
    }

    selectedPackage(p: Package): boolean {
        return this.shipment && !!this.shipment.packages[p.id];
    }

    get selectedAll(): boolean {
        return !_(this.pl.packages).filter((p: Package)=>p.inStock).find((p: Package) => !this.selectedPackage(p));
    }

    @HostListener('keydown.escape', ['$event'])
    onEscapeKeyDown(event: KeyboardEvent) {
        this._pl.good_category_id = this.old_category_id;
        if (this.editing && this.old_category_id) {
            this.editing = false;
            event.preventDefault();
        }
    }

    @HostBinding('style.background')
    get fragmented(): string {
        return this.isFragmented ? "#012F54" : null;
    }

    @HostBinding('style.color')
    get textColor(): string {
        return this.isFragmented ? 'white' : null;
    }

    get isFragmented(): boolean {
        return this.client.partial_receipts &&
            (this.pl && this.pl.fragmented);
    }

    enableEdit() {
        if (!this.pl.isShipped)
            this.editing=true;
    }

    get isParcel(): boolean {
        return this.pl.arrival_type == Arrival.ParcelArrival
    }

    get isFreight(): boolean {
        return this.pl.arrival_type == Arrival.FreightArrival;
    }

    get isAdmin(): boolean {
        return this.authenticationService.currentUserValue.isAdmin;
    }

    get canEdit(): boolean {
        return this.authenticationService.currentUserValue.canCategorize;
    }

    get isShipmentPicked(): boolean {
      return this.shipment && this.shipment.isPicked;
    }

    showParts(panel: any, event: any) {
        if (this.pl.havePartNumbers)
            panel.show(event);
    }

    get isShipmentNew(): boolean {
        return this.shipment && this.shipment.isNew;
    }
}
