import { Validable } from "./common";
import * as _ from 'lodash';
import { ICatalogLogo } from "./catalog";

export const CLIENT_FEATURES = [
	{label: 'Deflected', value: 'ignore'},
	{label: 'Part Numbers', value: 'part_number'},
	{label: 'Partial Receipts', value: 'partial_receipts'},
	{label: 'Expos', value: 'expos'},
];

export class Client extends Validable implements ICatalogLogo {
    validationKey: string = "client";
    id: number;
    name: string;
    full_name: string;
    filter_tags: string[];
    ignore: boolean;
    logo_url:string;
    code: string;
    sequence: number;
    part_number: boolean;
    partial_receipts: boolean;
    active: boolean;
    expos: boolean;
    _features: string[] = [];

    static CLIENTS: {[id: number]: Client} = {};
    static get CLIENTS_A(): Client[] {
        return _.values(this.CLIENTS);
    }
    static get DELFECT_TO(): Client {
        return _(this.CLIENTS).values().filter((c: Client) => c.ignore).first();
    }

    constructor(data?: any) {
        super(data);
        this.id = this.id || (+(new Date())) * -1;
        this.prepareFeatures();
    }

    tag_match(filter_tags: string[]): string[] {
        return _.intersection(this.filter_tags || [], filter_tags);
    }

    get saveProp(): string[] {
        let p =  super.saveProp.concat(["name", "full_name", "filter_tags", "ignore", "code", "part_number","partial_receipts", "active", "expos"]);
        if (!this.parseDataUrl(this.logo_url)) p.push("logo_url");
        return p;
    }

    toString() {
        return this.name;
    }

    toSaveData() {
        let data = super.toSaveData();
        let urlData = this.parseDataUrl(this.logo_url);
        if (urlData) {
            const formData: FormData = new FormData();
            formData.append("client[logo]",
                            this.dataURLtoBlob(urlData.groups["content"], `${urlData.groups["type"]}/${urlData.groups["ext"]}`),
                            `logo_${this.id}.${urlData.groups["ext"]}`);
            _(data).forEach((v: any, k: string) => {
                if (Array.isArray(v))
                    v.forEach((t: string) => formData.append(`client[${k}][]`, t));
                else
                    formData.append(`client[${k}]`, v);
            });
            return formData;
        } else {
            return data;
        }
    }

    prepareFeatures() {
    	if (this.ignore) this._features.push('ignore');
    	if (this.part_number) this._features.push('part_number');
    	if (this.partial_receipts) this._features.push('partial_receipts');
    	if (this.expos) this._features.push('expos');
    }

    get features(): string[] {
		return this._features;
    }

    set features(modules: string[]) {
    	this.ignore = false;
    	this.part_number = false;
    	this.partial_receipts = false;
    	this.expos = false;
    	modules.map((m: string) => {
    		if (m == "ignore") this.ignore = true;
			if (m == "part_number") this.part_number = true;
			if (m == "partial_receipts") this.partial_receipts = true;
			if (m == "expos") this.expos = true;
    	});
    	this._features = modules;
    }

    
}
