import { getPlatform } from '@angular/core';

export * from './arrivals.service';
export * from './catalogs.service';
export * from './codebar-reader.service';
export * from './conciliation.service';
export * from './generic.service';
export * from './packages.service';
export * from './inventory.service';
export * from './authentication.service';
export * from './shipments.service';
export * from './code-parse.service';
export * from './moda-dialog.service';
export * from './tickets.service';
export * from './report.service';
export * from './login-state.service';
export * from './arrive-packing-list.service';
export * from './http-loader.service';
export * from './expos.service';
