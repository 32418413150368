import { Component } from '@angular/core';
import { GoodCategory, Client } from 'wms-lib';
import { CatalogComponent } from '../../base-components/catalog.component';
import * as actions from 'wms-lib';

@Component({
    selector: 'app-good-categories',
    templateUrl: './good-categories.component.html',
    styleUrls: ['./good-categories.component.sass']
})
export class GoodCategoriesComponent extends CatalogComponent<GoodCategory> {
    name_catalog = 'good_categories';
    clients: {[id: number]: Client};

    ngOnInit(): void {
        super.ngOnInit();
        this.store.dispatch(new actions.GetCatalogAction<Client>({name_catalog: 'clients', live: false}));
        this.store.select((state) => state.catalogsState['clients']).subscribe((clients: {[id: number]: Client}) => this.clients = clients);
    }

    addNew(_type?: string) {
        let c = this.newItem({name_es: '', name_en: '', type: _type});
        this.items[c.id] = c;
        this.items = {...this.items};
    }

    newItem(data: any) {
        return new GoodCategory(data);
    }
}

