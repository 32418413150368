import { OnInit, Directive } from '@angular/core';
import { Observable } from 'rxjs';
import { tap, map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import { Arrival, Carrier, Warehouse } from 'wms-lib';
import * as actions from 'wms-lib';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';

@Directive({selector: '[appArrivalBaseComponent]'})
export class ArrivalsBaseComponent implements OnInit {
  protected current_page: number = 1;
  type: string;
  items$: Observable<{ [id: number]: Arrival }> = new Observable<{ [id: number]: Arrival }>();
  carriers$: Observable<{ [id: number]: Carrier }> = new Observable<{ [id: number]: Carrier }>();
  warehouses$: Observable<{ [id: number]: Warehouse }> = new Observable<{ [id: number]: Warehouse }>();
  idToSeach: string = null;
  loading = false;
  totalRecords$: Observable<number>;

  constructor(protected _route: ActivatedRoute, protected store: Store) {
  }

  ngOnInit() {
    this._route.params.subscribe((params: any) => {
      this.type = params['type'];
      this.idToSeach = null;
      this.store.dispatch(new actions.GetActiveArrivalsAction(this.type));
      this.store.dispatch(new actions.GetPagedArrivalsAction({type: this.type, page: 1 }));
    });
    this.items$ = this.store.select((state) => state.arrivalsState.arrivals).pipe(tap(() => this.loading = false));
    this.carriers$ = this.store.select(state => state.catalogsState.carriers);
    this.warehouses$ = this.store.select(state => state.catalogsState.warehouses);
    this.totalRecords$ = this.store.select((state) => state.arrivalsState.total_arrivals);
  }

  getIcon(carriers: {[id: number]: Carrier}, a: Arrival): string {
    let def = 'font://' + String.fromCharCode(0xf513);
    return this.getCarrierIcon(carriers,a) || def;
  }

  getCarrierIcon(carriers: {[id: number]: Carrier}, a: Arrival): string{
    return (carriers && carriers[a.carrier_id] && carriers[a.carrier_id].logo_url) || null;
  }

  deleteArrive(a: Arrival) {
	this.idToSeach = null;
    this.store.dispatch(new actions.DeleteArrivalAction(a.id));
  }

  getWarehouse$(id: number): Observable<string> {
    return this.warehouses$.pipe(
      map((warehouses: { [id: number]: Warehouse }) => ((warehouses || {})[id] || {name: ''}).name )
    );
  }

  searchByID() {
	  if(this.idToSeach) {
		  this.loading = true;
		  this.store.dispatch(new actions.ClearArrivalStateAction());
		  this.store.dispatch(new actions.GetPagedArrivalsAction({type: this.type, page: 1, search: this.idToSeach}));
	  }
  }

  clearSearch() {
	  this.loading = true;
	  this.idToSeach = null;
	  this.store.dispatch(new actions.ClearArrivalStateAction());
	  this.store.dispatch(new actions.GetPagedArrivalsAction({type: this.type, page: 1}));
  }
}
