import { Bindable } from "./common";
import { Bundle } from "./bundle";

const SAVE_PROPS = ['bundle_id','serial_number','value','_destroy'];

export class Part extends Bindable {
  id: number;
  bundle_id: number;
  serial_number: string;
  received_at?: Date;
  value: number;
  bundle: Bundle;

  _destroy: boolean = false;

  constructor(data: any) {
    super(data);
    this.id = this.id || (+(new Date())) * -1;
  }

  get saveProp(): string[] {
    return super.saveProp.concat(SAVE_PROPS);
  }

  get isValid(): boolean {
    return this._destroy || (!!this.serial_number && this.value > 0);
  }

  get received(): boolean {
    return !!this.received_at;
  }

}
