import { convertFromMtsTo, UOM } from "../models/common";
import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'metric'
})
export class MetricPipe implements PipeTransform {
    transform(value: number, unit: string = UOM.KG): any {
        return convertFromMtsTo(value, unit);
    }
}
