import { Injectable, Inject, NgZone } from '@angular/core';
import { Observable } from 'rxjs';
import { GenericService } from './generic.service';
import * as _ from 'lodash';
import { map, catchError, tap } from 'rxjs/operators';
import { BehaviorSubject, of } from 'rxjs';
import { Arrival, ArrivePackingList } from '../models';
import { HttpClient } from "@angular/common/http";
import { NotificationService } from '../platform-services/notification.service';


const ARRIVALS_PATH = "arrivals";
const ACTIVE_PATH = "/active";
const ACTIVE_CHANNEL = "ArrivalsChannel";
const UPLOAD_DOCUMENT_PATH = "users/documents";
const ARRIVALS_RESUME_URL = 'arrive_packing_lists/resume';

@Injectable({
    providedIn: 'root'
})
export class ArrivalsService extends GenericService {
    private activeArrivals: {[type: string]: BehaviorSubject<{[id: number]: Arrival}>};
    private activeArrival: BehaviorSubject<Arrival>;

    constructor(@Inject('env') env: any,
                http: HttpClient,
                ng_zone: NgZone,
                notificationService: NotificationService) {
        super(env, http, ng_zone, notificationService);
    }

    getActiveArrivals(type: string): Observable<{[id: number]: Arrival}> {
        global.log("Service", "Get active arrival");
        if (!this.activeArrivals) {
            this.activeArrivals = {
                [Arrival.ParcelArrival]: new BehaviorSubject<{[id: number]: Arrival}>({}),
                [Arrival.FreightArrival]: new BehaviorSubject<{[id: number]: Arrival}>({})
            }
            this.setActiveArrivalsSnap();
        }
        return this.activeArrivals[type];
    }

    getArrival(id: number, type: string): Observable<Arrival> {
        global.log("Service",`Get arrival ${id}`);
        this.activeArrival = new BehaviorSubject<Arrival>(null);
        if (id > 0){
            this.http.get<any[]>(this.serverUrl + ARRIVALS_PATH + "/" + id, this.httpOptions).pipe(
                catchError(this.handleSnapError<any[]>('Snap arrivals', []))
            ).subscribe((data: any) => this.buildSnapArrival(data) );
            this.createArriveChannel(id);
        } else
            this.activeArrival.next(new Arrival({id: id, type: type}));
        return this.activeArrival;
    }

    setActiveArrivalsSnap() {
        this.http.get<any[]>(this.serverUrl + ARRIVALS_PATH + ACTIVE_PATH, this.httpOptions).pipe(
            catchError(this.handleSnapError<any[]>('Snap arrivals', []))
        ).subscribe((data: any) => this.buildSnapActiveArrivals(data));
        this.openChannel({channel: ACTIVE_CHANNEL}).subscribe((data: any) => {
            if (data)
                this.buildSnapActiveArrivals(data)
        });
    }

    getPagedArrivals(type: string, page: number = 1, search?: string): Observable<{totalRecords: number, arrivals: {[id: number]: Arrival}}> {
        global.log("Service",`Get page ${page} of ${type} arrivals ${search ? 'with search ' + search : ''}`);
        const query = search ? `?search=${search}` : '';
        return this.http.get<any[]>(this.serverUrl + ARRIVALS_PATH + "/" + type + "/" + page + query, {headers: this.httpOptions.headers, observe: 'response'}).pipe(
            map((response: any) => ({totalRecords: +response.headers.get("totalrecords"), arrivals: this.buildArrivals(response.body)}) ),
            catchError(this.handleError<{totalRecords: number, arrivals: {[id: number]: Arrival}}>(' obtener llegadas', null))
        );
    }

    save(arrival: Arrival, props:string[] = null): Observable<Arrival> {
        global.log("Service",`saving arrival with id ${arrival.id}`);
        var data = arrival.toSaveData(props);
        return this.http.put(this.serverUrl + ARRIVALS_PATH + "/" + arrival.id, data, this.httpOptions).pipe(
            map((data: any) => new Arrival(data)),
            catchError(this.handleArrivalError(arrival))
        );
    }

    create(arrival: Arrival): Observable<Arrival> {
        global.log("Service",`creating arrival with id ${arrival.id}`);
        return this.http.post(this.serverUrl + ARRIVALS_PATH, arrival.toSaveData(), this.httpOptions).pipe(
            map((data: any) => new Arrival(data)),
            tap((data: Arrival) => this.createArriveChannel(data.id)),
            catchError(this.handleError<Arrival>(' crear llegada', arrival))
        );
    }

    delete(id: number): Observable<boolean> {
        global.log("Service",`deleting arrival with id ${id}`);
        return this.http.delete(this.serverUrl + ARRIVALS_PATH + "/" + id, this.httpOptions).pipe(
            map(() => true),
            catchError(this.handleArrivalError(false))
        );
    }

    uploadDocument(doc: FormData): Observable<boolean> {
        global.log("Service",`saving new document`);
        return this.http.put(this.serverUrl + UPLOAD_DOCUMENT_PATH, doc, this.httpMultipartOptions).pipe(
            map(() => true),
            catchError(this.handleError<boolean>(` upload document`, false))
        );
    }

    //TODO: Revisar si este sigue siendo necesario y borrarlo
    getArrivePackingLists(arrival: Arrival): Observable<{ [id: string]: ArrivePackingList }> {
        global.log("Service",`Busco packing list de ${arrival.id}`);
        return this.http.get<any[]>(this.serverUrl + ARRIVALS_PATH + "/" + arrival.id + "/arrive_packing_lists", this.httpOptions).pipe(
            map((data: any) => _(data).map((m: any) => new ArrivePackingList(m)).keyBy('id').value()),
            catchError(this.handleError<{[id: number]: ArrivePackingList}>(' obtener llegadas', {}))
        );
    }

    private createArriveChannel(id: number) {
        this.closeChannelsByCriteria(ACTIVE_CHANNEL + "_");
        this.openChannel({channel: ACTIVE_CHANNEL, id: id}).subscribe((data: any) => {
            if(data) this.buildSnapArrival(data);
        });
    }

    private buildSnapActiveArrivals(data: any[]) {
        global.log("Service", `Snapshot of active arrives received: ${data.length}`);
        let arrivals = this.buildArrivals(data);
        const parcelData = _(arrivals).filter((f: any) => f.type == Arrival.ParcelArrival).keyBy("id").value();
        this.activeArrivals[Arrival.ParcelArrival].next(parcelData);
        const freightData = _(arrivals).filter((f: any) => f.type == Arrival.FreightArrival).keyBy("id").value();
        this.activeArrivals[Arrival.FreightArrival].next(freightData);
    }

    private buildArrivals(data: any[]): {[id: number]: Arrival} {
        return _(data).map((m: any) => new Arrival(m)).keyBy('id').value()
    }

    private buildSnapArrival(data: any) {
        global.log("Service",`Snap of arrvial ${data.id} received`);
        this.activeArrival.next(new Arrival(data));
    }

    handleArrivalError<Arrival>(result: Arrival): (error: any) => Observable<Arrival> {
        return (response: any): Observable<Arrival> => {
            let message = `It was not possible to save arrival`;
            let rollback: any;
            if (response.error) {
                if (response.error.id) {
                    rollback = new Arrival(response.error);
                    rollback.errors = {0: response.message};
                    global.log("Error", response.message);
                    if (response.error.error_message)
                        message += `: ${response.error.error_message}`;
                } else if (response.error.errors){
                    result['errors'] = {0: response.error.errors};
                    global.log("Error", response.error.errors);
                }
            }
            global.log("Error", message);
            this.notificationService.toast(message, true);
            return of(rollback ? rollback : result);
        };
    }

    addPalletToPackages(arrival_package_id: number, quantity: number, container_type_id: number): Observable<Arrival> {
        global.log("Service",`adding pallet to packages`);
        return this.http.put(this.serverUrl + ARRIVALS_PATH + "/add_pallet_to_packages", {arrival_package_id: arrival_package_id, quantity: quantity, container_type_id: container_type_id}, this.httpOptions).pipe(
            map((data: any) => {
                global.log("Service",`adding pallet to packages`);
                if (data.errors && data.errors['base']) {
                    this.notificationService.toast(_.first(data.errors['base']), false);
                    return null;
                } else {
                    this.notificationService.toast('Packages have been added on pallets' , false, {severity:'success', summary: 'Success'});
                }
                return new Arrival(data);
            }),
            catchError((err)=> {
                this.notificationService.toast(err.error.error, false);
                return of(null);
            })
        );
    }


    getSummary(): Observable<any> {
        return this.http.get(this.serverUrl + ARRIVALS_RESUME_URL, this.httpOptions).pipe(
            catchError(this.handleError(' get tickets resume', {}))
        );
    }
}
