import { Injectable } from '@angular/core';
import { AsyncSubject, BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class HttpLoaderService {
    active_requests$: BehaviorSubject<number>;
    public is_loading$: Observable<boolean>;

    private  _urls$: BehaviorSubject<string[]>;
    private _urls: string[] = [];
    private count = 0;

    constructor() {
        this.active_requests$ = new BehaviorSubject(this.count);
        this._urls$ = new BehaviorSubject<string[]>(this._urls);

        this.is_loading$ = this.active_requests$.pipe(
            map(requests => {
                return ( (requests || 0) > 0);
            })
        );
    }

    get urls$(): Observable<string[]> {
        return this._urls$.asObservable();
    }


    public onRequestStart(url: string) {
        setTimeout(() => {
            this._urls.push(url);
            this._urls$.next(this._urls);
            this.count += 1;
            this.active_requests$.next(this.count);
        }, 1000);
    }

    public onRequestEnd(url: string) {
        setTimeout(() => {
            this._urls = this._urls.filter(u => u !== url);
            this._urls$.next(this._urls);
            this.count -= 1;
            if (this.count < 0) {this.count = 0; }
            this.active_requests$.next(this.count);
        }, 1000);
    }
}
