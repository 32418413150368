import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { PartNumber, CatalogsState, PackingsFilter } from 'wms-lib';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import * as _ from 'lodash';
import { map } from 'rxjs/operators';

@Component({
    selector: 'app-parts-filter',
    templateUrl: './parts-filter.component.html',
    styleUrls: ['./parts-filter.component.scss']
})
export class PartsFilterComponent implements OnInit {
    @Output() filterChange: EventEmitter<PackingsFilter> = new EventEmitter<PackingsFilter>();
    @Input() filter: PackingsFilter;
    parts$: Observable<PartNumber[]>;
    quantity: number;
    constructor(private store: Store) { }

    ngOnInit(): void {
        this.parts$ = this.store.select(CatalogsState.getPartNumbersByActiveClient()).pipe(
            map((parts: {[id:number]: PartNumber}) => parts ? _.map(parts, (pn: PartNumber) => ({label: pn.name, value: pn})) : parts)
        );
    }

    updateFilter(value: PartNumber, prop: string) {
        let part_number = this.filter.part_number;
        let quantity = this.filter.quantity;
        this.filter = new PackingsFilter();
        this.filter.part_number = part_number;
        this.filter.quantity = quantity;
        this.filter[prop] = value;
        this.filterChange.emit(this.filter);
    }

}
