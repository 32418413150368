import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import {TicketsService, ArrivalsService, ShipmentsService, InventoryService } from 'wms-lib';
import * as _ from 'lodash';
import * as moment from 'moment';
import { HomeBaseComponent } from '../../../shared/components/home/home.base.component';

const STATUS_COLORS = ['rgb(240, 140, 140)', 'rgb(255, 255, 162)', 'rgb(132, 221, 132)'];

@Component({
    selector: 'app-client-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class ClientHomeComponent extends HomeBaseComponent implements OnInit {

    arrivals_summary: {[arrival_type: string]: any[]} = {};
    arrivals_summary_data_gauge = {};  

    inventory_sumary: {[inventory_sumary_type: string]: any[]} = {};
    inventory_sumary_group: {[inventory_sumary_group_type: string]: any[]} = {};
    slow_movement_data_chart = {};
    receipts_sumary_data_chart = {};
    receipts_sumary_data_chart_week = {};  

    shipments_sumary: {[shipments_type: string]: any[]} = {};
    shipments_sumary_data_gaus = {};


    receipts_type = 'weight';
    receipts_type_slow = 'weight';
    receipts_type_sumary = 'weight';
    receipts_type_sumary_week = 'weight';

    receipts_type_options = [
        { label: 'weight', value: 'weight'},
        { label: 'packages', value: 'bundles' }
      ];

    constructor(
        router: Router,
        store: Store,
        tickets_service: TicketsService,
        private arrivals_service: ArrivalsService,
        private shipments_service: ShipmentsService,
        private inventory_service: InventoryService) { super(router,store,tickets_service) }

    ngOnInit() {
        super.ngOnInit();

        this.inventory_service.getSummary().subscribe((data: any) => {

            function dataGroup(){
                let obj = {};
                    _.forEach(data, (v,k)=>{
                    obj[k] = {};
                    _.forEach(v,(val, key)=>{
                        if(typeof val == 'object'){
                        // obj[k][key] = _(val).values().sum()
                        obj[k][key] = _(val).map((n)=> Number(n)).sum();
                        } else {
                        obj[k] = _(v).map((n)=> Number(n)).sum();
                        }
                    })
                    })

                    return obj;

            }

            this.inventory_sumary = data;
            this.inventory_sumary_group = dataGroup();
            
            this.slow_movement_data_chart = this.slow_movement_client_data(this.receipts_type_slow);
            this.receipts_sumary_data_chart = this.receipts_sumary_data(this.receipts_type_sumary);
            this.receipts_sumary_data_chart_week = this.receipts_sumary_data_week(this.receipts_type_sumary_week);
        });

        this.arrivals_service.getSummary().subscribe((data: any) => {

            this.arrivals_summary = data;
             _.forEach(this.arrivals_summary,(v)=>{    
                 _.remove(v, {name: "Backlog processed"});
             });
             

             _.forEach(this.arrivals_summary,(v,k)=>{
                _.forEach(v, (a,i)=>{
                    this.arrivals_summary[k][i]['color'] = STATUS_COLORS[i]
                })
            });

            // this.arrivals_summary_data_chart =  this.arrivals_summary_data();

            this.arrivals_summary_data_gauge = this.arrivals_summary_gauge();

        });


        this.shipments_service.getSummary().subscribe((data: any) => {

            this.shipments_sumary = _.map(data,(v:any, k:string)=>({name: k, total: v }));
            _.forEach(this.shipments_sumary, (v, k, )=>{
                this.shipments_sumary[k]['color'] = STATUS_COLORS[k];
            })

           this.shipments_sumary_data_gaus = this.shipments_sumary_data();

        });
        
    }


    build_gauge_options(values: number[]): any {
        let total = _.sum(values);
        let filter_colors = [];
        let delimiters = _(values).
            map((v: number) => (v/total)*100).
            filter((f: number, idx: number) => {
                if (f == 0) {
                    filter_colors.push(idx);
                    return false;
                }
                return true;
            }).
            map((s => a => s += a)(0)).
            dropRight().value();

        return {
            hasNeedle: false,
            arcColors: STATUS_COLORS.filter((f: string, idx: number) => !filter_colors.includes(idx)),
            arcDelimiters: delimiters,
            rangeLabel: ['0', ''+total]
        };
    }

    slow_movement_client_data_change(obj:any){
        let type = obj.value.value;
        this.receipts_type_slow = type;
        this.slow_movement_data_chart = this.slow_movement_client_data(type);
    }

    slow_movement_client_data(type: string): any {

        let slow_movment = this.inventory_sumary.slow_movment[type];
        
        let data = {};
        let options = {};
        data = {
            labels: _.keys(slow_movment),
            datasets: [
                {
                    label: '',
                    data: _.map(slow_movment, (v,k)=> _(v).map((a)=> Number(a)).sum()),
                    fill: false,
                    borderColor: '#42A5F5',
                    tension: .4
                }
            ]
        };
        options = {
            legend: {
                display: false
            }
        }
        return {data, options};
    }

    receipts_sumary_data_change(obj:any){
        let type = obj.value.value;
        this.receipts_type_sumary = type;
        this.receipts_sumary_data_chart = this.receipts_sumary_data(type);   
    }

    receipts_sumary_data(type:string): any {
        let inventory = this.inventory_sumary;
        let obj = {};

        let days = this.days_of_week();

        _.forEach(inventory, (v,k) => {

            if(_.has(inventory[k], type) && _.indexOf(['ParcelArrival','FreightArrival'], k) != -1 ){   
                let labels = [];
                let data = [];

                let inventoryFilter = _.omitBy(inventory[k][type], (value, key) => days.indexOf(key) == -1 );

                let ordered = {};   
                _(inventoryFilter).keys().sort().each((key) => {
                    ordered[key] = inventoryFilter[key];
                })
                inventoryFilter = ordered;

                obj[k] = {};
                obj[k]['data'] = {};
                labels = _.keys(inventoryFilter)
                obj[k]['data']['labels'] = labels;
                obj[k]['data']['datasets'] = [];

                _.forEach(labels, (q)=>{
                    let val =  _(inventoryFilter[q]).map((n)=>Number(n)).sum() || 0
                    data.push(val);
                })
                obj[k]['data']['datasets'].push({label:k, data, fill:false, borderColor: '#42A5F5', tension: .4});

                obj[k]['options'] = {
                    legend: {
                        display: false
                    }
                }
            }
        });
        return obj;
    }

    
    receipts_sumary_data_week_change(obj:any){
        let type = obj.value.value;
        this.receipts_type_sumary_week = type;
        this.receipts_sumary_data_chart_week = this.receipts_sumary_data_week(type);
    }

    receipts_sumary_data_week(type: string): any{
        let inventory = this.inventory_sumary;
        let obj = {};

        function week_number(f){
            return moment(f).format('w');
        }

        _.forEach(inventory,(v,k)=>{
            if(_.has(inventory[k], type) && _.indexOf(['ParcelArrival','FreightArrival'], k) != -1 ){  
              let inventoryFilter = inventory[k][type];
              obj[k] = {};
              let weeks = _(inventoryFilter).keys().groupBy(week_number).map((v,i)=> {
                let val = _.reduce(v,(r,k)=> _(inventoryFilter[k]).map((c)=>Number(c)).sum() + r, 0 ) 
                return {[i]: val}
              }).reduce((a,b)=> _.assign(a, b),  {});
              obj[k] = weeks;
            }
        })
        
        
        let  o = {};
        _.forEach(obj,(v,k)=>{
          o[k] = {};
          o[k]['data'] = {};
          o[k]['data']['datasets'] = [];
          let data = [];
          // let labels = _(v).keys().map((v)=>'semana ' + v).value();
          let labels = _(v).keys().value();
          o[k]['data']['labels'] = _(v).keys().map((v)=>'semana ' + v).value();
          
          _.forEach(labels, (q)=>{
              data.push(v[q] || 0);
          })
        
          o[k]['data']['datasets'].push({label:k, data, fill:false, borderColor: '#42A5F5', tension: .4});
          
        })

        return o

    }

    arrivals_summary_gauge(){
        
        let obj = {};
        _.forEach(this.arrivals_summary,(v,k)=>{    
            obj[k] = {};
            let colors = [];
            
            /*let values =  _.map(v, (a,e)=>{
                return {name: a.name, total: (a.receipts + a.packages), color:STATUS_COLORS[e]}
            })*/    
   
            let values = _(v).map((o)=>{ return {[o.name]: (/*o.receipts*/ + o.packages)} }).value().reduce(function(memo, current) { return _.assign(memo, current) },  {});

            values = _(values).map().orderBy().value();
        
            let arcDelimiters = values;
            let total = _.last(arcDelimiters);

            let delimiters = _(arcDelimiters).
            map((v: number) => (v/total)*100).
            filter((f: number, idx: number) => {
                if (f == 0) {
                    return false;
                }
                // colors.push(values[idx].color);
                colors.push(STATUS_COLORS[idx]);
                return true;
            }).
            map((s => a => s += a)(0)).
            dropRight().value();

            delimiters = _.map(delimiters,(f: any)=> isNaN(f) ? 0 : f );
            // delimiters = (delimiters[0] == 100) ? [99] : delimiters;

            if(delimiters[0] == 100){
                colors = [_.find(v, { 'name': 'Processed'}).color];
                delimiters = [];
            }


            obj[k] =  (total) ? {
                hasNeedle: false,
                arcColors: colors,
                arcDelimiters: delimiters,
                rangeLabel: ['0', ''+ total ]
            } : {};

        })  
        // console.log('////////////////////////////');
        // console.log('arrivals_summary_gauge');
        // console.log(obj);
        // console.log('-------------------------------');
        // console.log(this.arrivals_summary);
        // console.log('////////////////////////////');
        return obj;
    }

    shipments_sumary_data(): any {   
        let values = _.orderBy(this.shipments_sumary, ['total'], ['asc']);
        let arcDelimiters = _.map(values, 'total');
        let total = _.sum(arcDelimiters);
        let colors = [];
        let delimiters = _(arcDelimiters).
        map((v: number) => (v/total)*100).
        filter((f: number, idx: number) => {
            if (f == 0) {
                return false;
            }
            colors.push(values[idx].color);
            return true;
        }).
        map((s => a => s += a)(0)).
        dropRight().value();
        delimiters = _.map(delimiters,(f)=> isNaN(f) ? 0 : f );
        // delimiters = (delimiters[0] == 100) ? [99] : delimiters;

        
        if(delimiters[0] == 100){
            // colors = [_.find(v, { 'name': 'Processed'}).color];
            delimiters = [];
        }

        
        let res = (total) ? {
            hasNeedle: false,
            arcColors: colors,
            arcDelimiters: delimiters,
            rangeLabel: ['0', ''+ total]
        } : {};
        // console.log('////////////////////////////');
        // console.log('shipments_sumary_data');
        // console.log(res);
        // console.log('-------------------------------');
        // console.log(this.shipments_sumary);
        // console.log('////////////////////////////');

        return res
    }

    days_of_week(): any {

        let currentDate = moment();
        let weekStart = currentDate.clone().startOf('week');
        let weekEnd = currentDate.clone().endOf('week');
        let days = [];

        for (let i = 0; i <= 6; i++) {
            let day = moment(weekStart).add(i, 'days').format('YYYY-MM-DD');     
            // if(moment(day).format('dddd') != 'Saturday' && moment(day).format('dddd') != 'Sunday'){
              days.push(day);
            // }  
        };
        return days;
    }

}
