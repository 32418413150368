import * as _ from 'lodash';
import { Package, PackageStatuses, ConsignedStatus } from "./package";
import { Arrival } from "./arrival";
import { Packable } from "./packable";
import { Pallet } from './pallet';
import { ArrivePackingList } from './arrive-packing-list';
import { RemoteAsset } from './remote-asset';


const SAVE_PROPS = ['shipping_date', 'carrier', 'trailer', 'priority_id', 'conveyance_type_id', 'comments', 'arrive_packing_lists_attributes', '_packages_attributes', 'shipped_date', 'palletization_comments', 'shipped_comments', 'images','classification_id','dock'];

export class Shipment extends Packable {
  validationKey = "shipment";
  user_id: number;
  client_id: number;
  priority_id: number;
  conveyance_type_id: number;
  trailer: string;
  order_updated_at: Date;
  shipping_date: Date;
  shipped_date: Date;
  shipped_id: number;
  carrier: string;
  comments: string;
  palletization_comments: string;
  shipped_comments: string;
  id: number;
  open: boolean;
  type:string;
  images: RemoteAsset[];
  _counter: number;
  _parcel_packges: Package[];
  _freight_packges: Package[];
  _parcel_pallets: Pallet[];
  _loose_packages: Package[];
  private _packages: {[package_id: number]: Package};
  private _raw_arrive_packing_lists: any;
  private _haveInbond: boolean = null;
  user_working_id: number;
  user_working_name: string;
  shipped_by_id: number;
  total_weight: number;
  classification_id: number;
  dock: string;

  constructor(data:any) {
    super(data);
    this.setDefaults();
    this.setImages(this.images);
    this.setupPackages(data._packages);
    if (data.arrive_packing_lists) this._raw_arrive_packing_lists = data.arrive_packing_lists;
    if (Object.keys(data).includes("haveInbond"))
      this.haveInbond = data.haveInbond;
  }

  clone(): Shipment {
    let data: any = {...this};
    data._arrive_packing_lists = null;
    let s = new Shipment(data);
    s.arrive_packing_lists = {...this.arrive_packing_lists};
    return s;
  }

  setImages(data: any[]) {
    this.images = (data || []).map( d => d instanceof RemoteAsset ? d : new RemoteAsset(d));
  }

  private setDefaults() {
    this.id = this.id || Date.now() * -1;
    this.trailer = this.trailer || '';
    this.carrier = this.carrier || '';
    this.shipping_date = this.shipping_date || new Date();
    this.comments = this.comments || '';
  }

  private setupPackages(raw_packages: any[]) {
    this._packages = undefined;
    this.packages = _(raw_packages).
      map((m: any) => new Package(m)).
      keyBy("id").value();
  }

  get saveProp(): string[] {
    return  super.saveProp.concat(SAVE_PROPS);
  }

  get arrive_packing_lists() {
    return super.arrive_packing_lists;
  }
  set arrive_packing_lists(value: { [id:string]: ArrivePackingList}) {
    _(this.arrive_packing_lists).each((s: ArrivePackingList) => s.shipment = undefined );
    _(value).each((s: ArrivePackingList) => s.shipment = this );
    super.arrive_packing_lists = value;
  }

  get packages(): {[package_id: number]: Package} {
    return this._packages;
  }

  set packages(value: {[package_id: number]: Package}) {
    // Sacamos si se estan quitando o agregando paquetes
    let removed = _.difference(_.keys(this._packages), _.keys(value));
    let added = _.difference(_.keys(value), _.keys(this._packages));
    if (removed.length > 0 || added.length > 0) {
      // Sacamos los apls que no son parciales donde:
      // ninguno de sus paquetes esta en la lista de packages
      let single_apls = !!this._packages ?
        _(this._arrive_packing_lists).
        values().
        filter((f: ArrivePackingList) => _.values(f.packages).every((p: Package) => !this._packages[p.id] ) ).
        mapKeys('id').
        value() : this._arrive_packing_lists;

      // Sacamos los apls de la nueva lista de paquetes
      let package_apls = _(value).
        groupBy('arrive_packing_list_id').
        mapValues((v: Package[]) => new ArrivePackingList({...v[0].arrive_packing_list})).
        value();

      this.updatePackagesState(value);
      // Unimos los apls completos con los apls parciales
      this.arrive_packing_lists = {
        ...single_apls,
        ...package_apls
      };
      this.updatePackagesState(value);
    }

    this._packages = value;
  }

  updatePackagesState(value: {[package_id: number]: Package}) {
    let ids = _.keys(value);
    this.all_packages.forEach((p: Package) => {
      if (p.shipment_id == this.id && !ConsignedStatus.includes(p.status))
        p.status = ids.includes(''+p.id) ?  PackageStatuses.wip : PackageStatuses.processed;
      p.shipment = ids.includes(''+p.id) ?  this : undefined;
    });
  }

  get parcel_packages(): Package[] {
    if (!this._parcel_packges)
      this._parcel_packges = this.getPackageByType(Arrival.ParcelArrival)
    return this._parcel_packges;
  }

  get freight_packages(): Package[] {
    if (!this._freight_packges)
      this._freight_packges = this.getPackageByType(Arrival.FreightArrival)
    return this._freight_packges;
  }

  get loose_packages(): Package[] {
    if (!this._loose_packages) {
      this._loose_packages = this.parcel_packages.
        filter((p: Package) => p.pallet_id && !p.pallet.container_type_id);
    }
    return this._loose_packages;
  }

  get parcel_pallets(): Pallet[] {
    if (!this._parcel_pallets) {
      this._parcel_pallets = _(this.parcel_packages).
        filter((p: Package) => !!p.pallet_id && !!p.pallet.container_type_id).
        groupBy('pallet_id').
        map((p: Package[], id: string) => new Pallet({id: id, packages: p}) ).value();
    }
    return this._parcel_pallets;
  }

  getPackageByType(arrival_type: string): Package[] {
    return _(this.arrive_packing_lists).values().
      filter((pl: ArrivePackingList) => pl.arrival_type == arrival_type).
      map((pl: ArrivePackingList) => _.values(pl.packages)).
      flatten().
      filter((p: Package) => p.belongToShipment(this.id)).
      value();
  }

  get arrive_packing_lists_attributes(): any[] {
    return _.values(this.arrive_packing_lists).
      filter((f: ArrivePackingList) => !f.packages_a.some((p: Package) => !!this.packages[p.id] )).
      map((apl: ArrivePackingList) => ({id: apl.id}));
  }

  get _packages_attributes(): any[] {
    return  _.values(this._packages).map((p: Package) => ({id: p.id}));
  }

  get isValidated(): boolean {
    return this.all_packages.
      filter((p: Package) => p.belongToShipment(this.id)).
      every((p: Package) => p.isShipped);
  }

  get closed(): boolean {
    return !!this.shipped_date;
  }

  get haveComplementary(): boolean {
    return this.parcel_packages.every((p: Package) => !!p.pallet_id);
  }

  get isShipped(): boolean {
    return !!this.shipped_id;
  }

  get haveInbond(): boolean {
    //TODO: Quitar 30 en hardcode
    if (this._haveInbond === null || this._haveInbond === undefined)
      this._haveInbond = this.all_packages.some((p: Package) => p.good_category_id == 30)
    return this._haveInbond;
  }

  set haveInbond(value: boolean) {
    this._haveInbond = value;
  }

  get isByPart(): boolean {
    return _.size(this._raw_arrive_packing_lists) == 0 &&
      !!this.packages &&
      _(this.packages).size() > 0 &&
      _(this.packages).every((p: Package) => !!p.part_number_id)
  }

  get isByPackage(): boolean {
    return !!this._packages && _(this._packages).size() > 0;
  }

  get isNew(): boolean {
    return this.id <= 0;
  }

  togglePackagesToReceipt(apl: ArrivePackingList) {
    this.packages = _(this.packages).
      filter((p: Package) => !apl.packages[p.id]).
      keyBy('id').
      value();
    apl.assignShipment(this);
  }

  get isPicked (): boolean {
    let packages = _.unionBy(this.all_packages, _.values(this.packages), 'id');
    return packages.some((p: Package) => p.isPicked);
  }

}
