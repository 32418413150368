import { BindableBase } from "./common";
import { Ticket } from "./ticket";
import * as _ from 'lodash';

export class PagedList<T> extends BindableBase {
    page: number;
    page_size:number;
    total_items:number;
    all_loaded:boolean;
    items: {[id:number]: T};

    constructor(data: any , private ctr: (new (data:any) => T)){
        super(data);
        this.initData();
        this.all_loaded = false;
    }

    initData() {
        if (this.items) {
            this.items = _(this.items).map(t => new this.ctr(t)).keyBy('id').value();
        }

        if (Number.isFinite(this.page)) {
            this.page = <number>this.page;
        }


    }
}

export class TicketPagedList extends PagedList<Ticket> {

    client_id: number;
    status: string;

    constructor(data:any) {
        super(data, Ticket);
    }
}