import { GenericService } from "./generic.service";
import { Observable } from "rxjs";
import * as _ from 'lodash';
import { Carrier, Client, Location, Printer, ISearchResult, Shipment, Arrival, Package, Pallet, GoodCategory, User, Tracking, TrackingDetail, BundleType, Unit, PartNumber, Warehouse, Country, DeliveryOrder, Classification, Commodity } from "../models";
import { map, catchError } from "rxjs/operators";
import { Injectable, Inject, NgZone } from "@angular/core";
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from "@angular/common/http";
import { ICatalog, Catalog } from "../models/catalog";
import { ContainerType } from "../models/container_type";
import { NotificationService } from '../platform-services/notification.service';

const SEARCH_PATH = "search/";
const SUGGESTIONS_PATH = "suggestions/";
const USERS_PATH = "users";
const ROLES_PATH = "users/roles";
const TRACKING_PATH = "trackings";
const OPEN_TRK_PATH = "trackings/open";
const HISTORY_PATH = "history";
const PART_NUMBERS = "part_numbers/by_client";
const PART_NUMBERS_STOCK = "part_numbers/on_stock";

@Injectable({
  providedIn: 'root'
})
export class CatalogsService extends GenericService {
  constructors: any = {
    "carriers": Carrier,
    "clients": Client,
    "locations": Location,
    "damage_types": Catalog,
    "good_types": Catalog,
    "bundle_types": BundleType,
    "good_categories": GoodCategory,
    "ticket_statuses": Catalog,
    "priorities": Catalog,
    "conveyance_types": Catalog,
    "printers": Printer,
    "container_types": ContainerType,
    "part_numbers": PartNumber,
    "units": Unit,
    "warehouses": Warehouse,
    "countries": Country,
    "classifications": Classification,
    "commodities": Commodity 
  };

  live_catalogs: {[name: string]: BehaviorSubject<{[id: number]: any}>} = {};

	constructor(@Inject('env') env: any, http: HttpClient, ng_zone: NgZone, notificationService: NotificationService) {
		super(env, http, ng_zone, notificationService);
	}

  getCatalogFromApi<T extends ICatalog>(name_catalog: string): Observable<{ [id: number]: T }> {
    return this.http.get<any[]>(this.serverUrl + name_catalog, this.httpOptions).pipe(
      map((data: any[]) => _(data).map((m: any) => {
        return new this.constructors[name_catalog](m);
      }).keyBy("id").value()),
      catchError(this.handleError<{ [id: number]: T }>(`get ${name_catalog}`, {}))
    );
  }

  setCatalogSnap<T extends ICatalog>(name_catalog: string): BehaviorSubject<{[id: number]: T}> {
    this.live_catalogs[name_catalog] = new BehaviorSubject<{[id: number]: T}>({});
    this.getCatalogFromApi<T>(name_catalog).subscribe((catalog: {[id: number]: T}) => {
      this.live_catalogs[name_catalog].next(catalog);
      this.openChannel({channel: `${name_catalog.replace(/^.{1}/g, name_catalog[0].toUpperCase())}Channel`})
        .subscribe((data: any[]) => {
          if (data) {
            const array = data.map((m: any) => new this.constructors[name_catalog](m));
            this.live_catalogs[name_catalog].next(_(array).keyBy('id').value());
          }
        });
    });
    return this.live_catalogs[name_catalog];
  }

  getCatalog<T extends ICatalog>(name_catalog: string, live: boolean = false): Observable<{ [id: number]: T }> {
    return live ? (this.live_catalogs[name_catalog] || this.setCatalogSnap<T>(name_catalog)) : this.getCatalogFromApi<T>(name_catalog);
  }

  saveCatalog<T extends ICatalog>(catalog: T, name_catalog: string): Observable<T> {
    global.log("Service",`saving carrier with id ${catalog.id}`);
    catalog.errors = null;
    let dataToSave = catalog.toSaveData();
    return (catalog.id > 0 ?
      this.http.put(this.serverUrl + name_catalog + "/" + catalog.id, dataToSave, dataToSave instanceof FormData ? this.httpMultipartOptions : this.httpOptions) :
      this.http.post(this.serverUrl + name_catalog, dataToSave, dataToSave instanceof FormData ? this.httpMultipartOptions : this.httpOptions )).pipe(
        map((data: any) => catalog.id > 0 ? catalog : new this.constructors[name_catalog](data)),
        catchError(this.handleError<T>(` update ${name_catalog}`, catalog))
      );
  }

  deleteCatalog(id: number, name_catalog: string): Observable<boolean> {
    return this.http.delete(this.serverUrl + name_catalog + "/" + id, this.httpOptions).pipe(
      map(() => true),
      catchError(this.handleError<boolean>(` delete ${name_catalog} ${id}`, false))
    );
  }

  reorderCatalog(name_catalog: string, sequence: {[id: string]: number}) {
    return this.http.put(this.serverUrl + name_catalog + "/reorder", sequence, this.httpOptions).pipe(
      map(() => true),
      catchError(this.handleError<boolean>(` reorder ${name_catalog}`, false))
    );
  }

  aimport(name_catalog: string, data: any, options: any) {
    return this.http.post(this.serverUrl + name_catalog + "/import", {data, options} ,this.httpOptions).pipe(
      map(() => true),
      catchError(this.handleError<boolean>(` Import ${name_catalog}`, false))
    );
  }

  import<T extends ICatalog>(name_catalog: string, data: T[], options: any): Observable<{ [id: number]: T }> {
    return this.http.post<any[]>(this.serverUrl + name_catalog+ "/import", {data: data.map((d)=>d.toSaveData()), options}, this.httpOptions).pipe(
      map((data: any[]) => _(data).map((m: any) => {
        return new this.constructors[name_catalog](m);
      }).value()),
      catchError(this.handleError<{ [id: number]: T }>(`Import ${name_catalog}`, {}))
    );
  }


  search(query: string): Observable<ISearchResult> {
    return this.http.get<ISearchResult>(this.serverUrl + SEARCH_PATH + query, this.httpOptions).pipe(
      map((data: any) => ({
        package: data.package ? new Package(data.package) : null,
        arrival: data.arrival ? new Arrival(data.arrival) : null,
        location: data.location ? new Location(data.location) : null,
        shipment: data.shipment ? new Shipment(data.shipment) : null,
        pallet: data.pallet ? new Pallet(data.pallet) : null,
        part_numbers: data.part_numbers ? _(data.part_numbers).mapValues((pn: any)=> new PartNumber(pn)).value() : null,
        delivery_order: data.delivery_order ? new DeliveryOrder(data.delivery_order): null
      })),
      catchError(this.handleError<ISearchResult>(` search ${query}`, {package: null, arrival: null, location: null, shipment: null, pallet: null, part_numbers: null, delivery_order: null}))
    );
  }

  suggestions(query: string): Observable<string[]> {
    return this.http.get<string[]>(this.serverUrl + SUGGESTIONS_PATH + query, this.httpOptions).pipe(
      catchError(this.handleError<string[]>(` search ${query}`, []))
    );
  }

  getUsers(): Observable<User[]> {
    return this.http.get<User[]>(this.serverUrl + USERS_PATH, this.httpOptions).pipe(
      map((data: any[]) => data.map((u: any) => new User(u))),
      catchError(this.handleError<User[]>(`Get Users`, []))
    );
  }

  getRoles(): Observable<string[]> {
    return this.http.get<string[]>(this.serverUrl + ROLES_PATH, this.httpOptions).pipe(
      catchError(this.handleError<string[]>(`Get Roles`, []))
    );
  }

  saveUser(user : User): Observable<User> {
    let dataToSave = user.toSaveData();
    return (user.id > 0 ?
      this.http.put<User>(this.serverUrl + USERS_PATH + `/${user.id}`,
                          dataToSave instanceof FormData ? dataToSave : {user: dataToSave},
                          dataToSave instanceof FormData ? this.httpMultipartOptions : this.httpOptions) :
      this.http.post<User>(this.serverUrl + USERS_PATH,
                           dataToSave instanceof FormData ? dataToSave : {user: dataToSave},
                           dataToSave instanceof FormData ? this.httpMultipartOptions : this.httpOptions)).pipe(
                             map((data: any) => {
                              if(user.id) user.cleanPasswords();
                              return user.id > 0 ? user : new User(data)
                             }),
                             catchError(this.handleError<User>(`Update user`, user, user.id > 0 )))
  }

  getTrackings(page: number): Observable<{totalRecords: number, trackings: Tracking[]}> {
    return this.http.get<Tracking[]>(this.serverUrl + TRACKING_PATH,{
      params: { 'page': page.toString()},
      headers: this.httpOptions.headers,
      observe: 'response'
    }).pipe(
      map((response: any) => ({
        totalRecords: +response.headers.get("totalrecords"),
        trackings: response.body.map((u: any) => new Tracking(u))
      })),
      catchError(this.handleError<{totalRecords: number, trackings: Tracking[]}>(`Get Tracking`, null))
    );
  }

  saveTracking(tracking : Tracking): Observable<Tracking> {
    let dataToSave = tracking.toSaveData();
    return (tracking.id > 0 ?
      this.http.put<User>(this.serverUrl + TRACKING_PATH + `/${tracking.id}`, {tracking: dataToSave},this.httpOptions) :
      this.http.post<User>(this.serverUrl + TRACKING_PATH, {tracking: dataToSave}, this.httpOptions)).pipe(
        map((data: any) => new Tracking(data)),
        catchError(this.handleError<Tracking>(`Save Tracking`, tracking, tracking.id > 0 )))
  }

  deleteTracking(tracking : Tracking): Observable<boolean> {
    return this.http.delete(this.serverUrl + TRACKING_PATH + "/" + tracking.id, this.httpOptions).pipe(
      map(() => true),
      catchError(this.handleError<boolean>(` delete tracking ${tracking.id}`, false))
    );
  }

  getHistory(tipo: string, id: number): Observable<any[]> {
    return this.http.get<User[]>(this.serverUrl + HISTORY_PATH + `/${tipo}/${id}`, this.httpOptions).pipe(
      catchError(this.handleError<User[]>(`Get History`, []))
    );
  }

  getOpenTrackings(): Observable<TrackingDetail[]> {
    return this.http.get<User[]>(this.serverUrl + OPEN_TRK_PATH, this.httpOptions).pipe(
      map((data: any[]) => data.map((d: any) => new TrackingDetail(d)) ),
      catchError(this.handleError<TrackingDetail[]>(`Get History`, []))
    );
  }

  getPartNumbers(client_id: number): Observable<PartNumber[]> {
    return this.http.get<PartNumber[]>(this.serverUrl + PART_NUMBERS + `/${client_id}`, this.httpOptions).pipe(
      map((data: any[]) => data.map((d: any) => new PartNumber(d)) ),
      catchError(this.handleError<PartNumber[]>(`Get Part Number`, []))
    );
  }

  getStockByPartNumber(client_id: number): Observable<PartNumber[]> {
    return this.http.get(this.serverUrl + `${PART_NUMBERS_STOCK}/${client_id}`, this.httpOptions).pipe(
      map((data: any[]) => data.map((d: any) => new PartNumber(d) ) ),
      catchError(this.handleError(' get stock by part number', []))
    );
  }

  cleanLiveCatalogs() {
    this.live_catalogs = {};
  }
}
