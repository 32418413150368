import { Routes } from '@angular/router';
import { HomeComponent } from './components/home/home.component';
import { LocationCardDetailComponent } from './components/location-card-detail/location-card-detail.component';
import { InventoryCyclicComponent } from './components/inventory-cyclic/inventory-cyclic.component';

export const componentDeclarations: any[] = [
];

export const providerDeclarations: any[] = [
];

export const routes: Routes = [
    { path: 'home', component: HomeComponent },
    { path: 'location-details', component: LocationCardDetailComponent},
    {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
    },
	{
		path: 'cyclics',
		component: InventoryCyclicComponent
	}
];
