import { Bindable } from "./common";
import { DamageReport } from "./damage-report";
import { Part } from "./part";
import * as _ from 'lodash';
import * as moment from 'moment'
import { DeliveryOrder } from "./delivery-order";
import { Subject } from "rxjs";

const SAVE_PROPS = ['bundle_number','delivery_order_id','bundle_type_id','net_weight','gross_weight','dims','parts_attributes','status','_destroy','damage_report_attributes'];
const DIMS_PATTERN = /^(#\d+|(\d+(\.\d+)?)(x|X)(\d+(\.\d+)?)(x|X)(\d+(\.\d+)?))$/i;

export enum BundlesStatus {
  received = 'R',
  processed = 'P'
}

export class Bundle extends Bindable {
  id: number;
  bundle_number: string;
  delivery_order_id: number;
  bundle_type_id: number;
  net_weight: number;
  gross_weight: number;
  dims: string;
  parts: { [key: string]: Part } = {};
  _destroy: boolean = false;
  status: string;
  damage_report: DamageReport;
  delivery_order: DeliveryOrder;
  request_damage: Subject<void> = new Subject<void>();
  tts_name: string;

  get numeric_bundle() {
    return !Number.isNaN(parseInt(this.bundle_number)) ? parseInt(this.bundle_number) : 0;
  }

  constructor(data: any) {
    super(data);
    this.id = this.id || (+(new Date())) * -1;
    if (_.size(data.parts) > 0) {
      this.buildParts(data.parts || []);
    }
    if (data.damage_report) this.setupDamageReport(data.damage_report);
    if (data.delivery_order && data.delivery_order.constructor.name == "Object") this.delivery_order = new DeliveryOrder(data.delivery_order);
  }

  get isDamage(): boolean {
    return !!this.damage_report;
  }

  private setupDamageReport(damage_report: any) {
    this.damage_report = new DamageReport(damage_report);
    this.damage_report.bundle = this;
  }

  get damage_report_attributes(): DamageReport{
    return this.damage_report;
  }

  get parts_array() {
    if (this.parts) {
      return _.values(this.parts);
    }
    return [];
  }

  buildParts(parts: any[]) {
    this.parts = {};
    for(let p of parts) {
      this.parts[p.id] = new Part({...p, ...{bundle: this}});
    }
  }

  addOrUpdatePart(part: Part) {
    let parts = { ...this.parts };
    part.bundle_id = this.id;
    parts[part.id] = part;
    this.parts = parts;
  }

  deletePart(id: number) {
    if(id < 0) {
      delete this.parts[id];
    } else {
      this.parts[id]._destroy = true;
    }
    this.parts = { ...this.parts };
  }


  get parts_attributes(): Part[] {
    return Object.values(this.parts);
  }

  get saveProp(): string[] {
    return super.saveProp.concat(SAVE_PROPS);
  }

  get isValid(): boolean {
    return this._destroy || (this.bundle_type_id > 0 && ( !this.dims || !!this.dims && DIMS_PATTERN.test(this.dims)) );
  }

  get isComplete(): boolean {
    if(this._destroy) return true;
    let deletedParts = _.filter(this.parts, (p: Part) => p._destroy);
    if(_.size(this.parts) == 0 || _.size(deletedParts) == _.size(this.parts)) return false;
    return (_.every(this.parts, (p: Part) => p.isValid) && this.isValid);
  }

  get title(): string {
    return this.bundle_number;
  }

  get serials(): string {
    return ` (${_(this.parts).values().map((p: Part) => p.serial_number).join(", ")})`;
  }

  get serial(): string {
    return `EX${this.id}`;
  }

  get total_parts(): number {
    return _.size(_.filter(this.parts, (p: Part) => !p._destroy));
  }

  get isProcessed(): boolean {
    return this.status == BundlesStatus.processed;
  }

  get received(): boolean {
    return _.every(this.parts_array,'received');
  }

  get partialy_received(): boolean {
    return _.some(this.parts_array,'received');
  }

  get color(): string {
    return this.isProcessed ? '#d1fbeb' : '';
  }

  get client_name(): string {
    return (this.delivery_order && this.delivery_order.asn && this.delivery_order.asn.client) ?
      this.delivery_order.asn.client.name :
      "";
  }

  get fordward_name(): string {
    return (this.delivery_order && this.delivery_order.address && this.delivery_order.address.forward) ?
      this.delivery_order.address.forward.name :
      "";
  }

  get delivery_order_number(): string {
    return this.delivery_order ? this.delivery_order.order_number : "";
  }

  get canTakeDamage(): boolean {
    return this.partialy_received;
  }

  get canPrint(): boolean {
    return this.partialy_received;
  }

  get zpl(): string {
    return `^XA

^FX Top section with logo, name and address.
^CF0,60
^FO150,40^GD40,90,18^FS
^FO171,95^GB30,20,20^FS
^FO205,40^GB15,90,10^FS
^FO194,33^GB40,40,10,W^FS
^FO230,40^FDRio Bravo^FS
^CF0,30
^FO230,105^FH^FDAmerican Industries_A9^FS

^FX Second section barcode.
^CFA,30
^FO20,160^FDBundle Id (EX):^FS
^BY4,2,100
^FO20,200^BC^FDEX${this.id}^FS

^FX Third section with details
^FO20,350^GB700,1,3^FS

^FO20,360^FDRcv Date:^FS
^CF0,30
^FO190,360^FD${moment(this.created_at).format('MMMM D, YYYY h:mma')}^FS
^CFA,30
^FO20,400^FDClient:^FS
^CF0,30
^FO190,400^FD${this.client_name}^FS
^CFA,30
^FO20,440^FDShip to:^FS
^CF0,30
^FO190,440^FD${this.fordward_name}^FS
^CFA,30
^FO20,480^FDDO:^FS
^CF0,30
^FO190,480^FD${this.delivery_order_number}^FS

^FX Footer
^FO20,510^GB700,1,3^FS
^CF0,30
^FO20,530^FH^FDThink Fordward_A9^FS
^FO350,530^FDwww.airiobravo.com^FS

^XZ`;
  }

}
