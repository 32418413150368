import { Component, OnInit, NgZone, Input } from '@angular/core';
import { CodebarReaderService } from 'wms-lib';
import { Store } from '@ngxs/store';
import { PackingListComponent } from '../../../../shared/components/base-components/packing-list.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalDialogService, AuthenticationService, NotificationService } from 'wms-lib';
import * as store_actions from 'wms-lib';

import * as _ from 'lodash';

@Component({
    selector: 'tr[app-search-packing-list]',
    templateUrl: './search-packing-list.component.html',
    styleUrls: ['../../../../shared/components/base-components/packing-list.component.scss']
})
export class SearchPackingListComponent extends PackingListComponent implements OnInit {
    @Input() table: any;
    constructor(protected store:Store,
                protected router: Router,
                protected activatedRoute: ActivatedRoute,
                protected codebar_service: CodebarReaderService,
                protected ng_zone: NgZone,
                protected modalDialogService: ModalDialogService,
                protected authenticationService: AuthenticationService,
                protected notificationService: NotificationService) {
        super(store,router,activatedRoute,codebar_service,ng_zone, modalDialogService, authenticationService, notificationService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.setExpanded(true);
    }

    protected setExpanded(value: boolean) {
        if ((this._expanded !== undefined && this._expanded != value) || (this._expanded === undefined && value))
            this.table.toggleRow(this.pl);
        super.setExpanded(value);
    }

    navigate() {
        this.store.dispatch(new store_actions.SetWorkingPackingAction({ packing_id: this.pl.id, attach_doc: false }));
        this.router.navigate(['/receivings', 'ParcelArrival', 'edit', this.pl.arrival_id]);
    }
}
