
export class PurchaseOrderModalBaseService {

    apply_all: boolean = false;
    protected _suggestions: string[] = [];
    protected _selected: string[] = [];

    constructor() {
    }

    set dialog_result(result:any){
        if (result){
            this.selected_pos = [...result.array_result];
            this.apply_all = result.apply_all;
            this.value = result.result;
        }            
    }

    set value(val: string) {
        throw new Error('This method is abstract');
    }

    set selected_pos(value: string[]) {
        this._selected = value;
    }

    set suggestions(values: string[]) {
        this._suggestions = values;
    }

    get value() {
        throw new Error('This method is abstract');
    }    
}
