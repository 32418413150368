import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthenticationService } from 'wms-lib';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: [
        '../assets/layout/css/layout-rbi.scss',
        '../assets/theme/theme-rbi.scss',
        './app.component.scss'
    ],
    encapsulation: ViewEncapsulation.None
})

export class AppComponent implements OnInit{

    constructor(private store:Store,
                private authenticationService: AuthenticationService){}

    ngOnInit() {
     
    }

    

}
