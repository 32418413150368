import { Injectable, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { Attachment, SaveTicketAction, Ticket } from 'wms-lib';

@Injectable()
export class TicketAttachmentsBaseComponent implements OnInit {

  ticket: Ticket;
  
  selected_attachments: Attachment[] = [];

  constructor(private store: Store) { 
  }

  ngOnInit() {
    this.store.select(state => state.helpDeskState.active_ticket).subscribe(ticket => {
      this.ticket = ticket;
    })
  }



  deleteAttachment() {
    this.selected_attachments.forEach((a: Attachment) => a._destroy = true);
    this.save();
    this.selected_attachments = [];
  }

  deleteSingleAttachment(attachment: Attachment) {
  	  attachment._destroy = true;
	  this.save();
	  this.selected_attachments = [];
  }

  attachmentTap(attachment: Attachment) {
    this.toggleSelectedAttachment(attachment);
  }

  toggleSelectedAttachment(attachment: Attachment) {
    let att = this.selected(attachment);
    if (att) {
      this.selected_attachments = this.selected_attachments.filter(a => a.id !== attachment.id);
    } else {
      this.selected_attachments.push(attachment);
    }
  }

  selected(attachment: Attachment) {
    return this.selected_attachments.find(a => a.id === attachment.id)
  }

  save() {
    return this.store.dispatch(new SaveTicketAction({ ticket: this.ticket}));
  }
  

}
