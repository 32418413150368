import { Component, OnInit } from '@angular/core';
import { User, AuthenticationService } from 'wms-lib';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    user: User;
    constructor(private authenticationService: AuthenticationService) {
    }

    ngOnInit() {
        this.authenticationService.currentUser.subscribe((u: User) => {
            this.user = u;
        });
    }

}
