import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RoutingModule } from './routing.module';
import { SharedModule } from '../shared/shared.module';
import { AsnsComponent } from './components/asns/asns.component';
import { AsnDetailComponent } from './components/asns/asn-detail/asn-detail.component';
import { AsnsListTableComponent  } from './components/asns/asns-list-table/asns-list-table.component';
import { DeliveryComponent } from './components/delivery/delivery.component';
import { DeliveryRowComponent } from './components/delivery/delivery-row/delivery-row.component';

@NgModule({
  declarations: [
    AsnsComponent,
    AsnDetailComponent,
    AsnsListTableComponent,
    DeliveryComponent,
    DeliveryRowComponent
  ],
  imports: [
    CommonModule,
    RoutingModule,
    SharedModule
  ]
})
export class ExportModule { }
