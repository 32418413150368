import { Component, OnInit } from '@angular/core';



@Component({
    selector: 'app-catalog-selection-dialog',
    templateUrl: './catalog-selection-dialog.component.html',
    styleUrls: ['./catalog-selection-dialog.component.scss']
})
export class CatalogSelectionDialogComponent implements OnInit {
    constructor() {
    }

    ngOnInit() {

    }

}
