import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TicketFormComponent } from './components/tickets/ticket-form/ticket-form.component';
import { SharedModule } from '../shared/shared.module';
import { TicketListComponent } from './components/tickets/ticket-list/ticket-list.component';
import { HelpDeskRoutingModule } from './help-desk.rounting.module';
import { TicketListCardsComponent } from './components/tickets/ticket-list-cards/ticket-list-cards.component';
import { TicketDetailsComponent } from './components/tickets/ticket-details/ticket-details.component';
import { TicketAttachmentsComponent } from './components/tickets/ticket-attachments/ticket-attachments.component';
import { TicketCommentsComponent } from './components/tickets/ticket-comments/ticket-comments.component';

@NgModule({
  declarations: [TicketFormComponent, 
    TicketListComponent, 
    TicketListCardsComponent, 
    TicketDetailsComponent, 
    TicketAttachmentsComponent, 
    TicketCommentsComponent],
  imports: [
    CommonModule,
    HelpDeskRoutingModule,
    SharedModule.forRoot()
  ],
  entryComponents: [
    TicketDetailsComponent,
    TicketFormComponent
  ]
})
export class HelpDeskModule { }