import { Validable } from './common';
import { Arrival } from './arrival';
import { Package, IStorageLocation } from './package';
import * as _ from 'lodash';
import { Document } from './document';
import { Client } from './client';
import { Shipment } from './shipment';
import { PartNumber } from './part-number';


export const NA_ID = 'NA';
export const DEF_ID = 'DEF_NA';

const SAVE_PROPS = ['arrival_id', 'carrier_packing_id', 'client_id', 'packages_attributes', 'document_id', 'good_type_id', 'weight', '_count', 'created_at', 'updated_at', 'part_numbers_attributes', 'commodity_id'];


export enum PackingListGroupTypes {
  active = 0,
  completed = 1,
  deflected = 2
}
export class ArrivePackingList extends Validable {
  static LOC_REGEXP: RegExp;
  public validationKey = 'arrive-packing-list';
  public carrier_packing_id: string;
  public receipt_id: string;
  public arrival_id: number;
  public good_type_id: number;
  public packages: { [id: number]: Package };
  public client_id: number;
  public document: Document;
  public shipment: Shipment;
  public shipment_id: number;
  public weight: number;
  public processed_date: Date;
  public processed_by_username: string;
  public part_numbers: PartNumber[];
  public year: number;
  public sequence: number;
  public _count: number;
  public created_at: Date;
  public commodity_id: number;
  private _total_packages: number;
  private _arrival: Arrival;
  private _arrival_type: string;
  private _part_numbers_a: PartNumber[];

  constructor(data: ArrivePackingList, pids: number[]);  //Se usa cuando se convierte de un apl indocumentado, es el pl original y los packages con los que se va a conformar el nuevo
  constructor(data: Package);                            //Se usa cuando se crea el apl a partir de el escaneo/entrada de un nuevo paquete
  constructor(data: string);                             //Se usa cuando se instancia desde freight y el data el tracking number del pl a crear
  constructor(data: any);                                //Se usa cuando se instancia desde el servicio o de manera general desde un objeto anonimo
  constructor(data?: any, pids?: number[]) {
    super(data instanceof Package || typeof(data) == "string" ? {} : data);
    this.setupDefaults(data);
    if (data instanceof ArrivePackingList) this.setupFromPacking(data, pids);
    if (data instanceof Package) this.addOrUpdatePackage(data);
    if (typeof(data) == "string") this.setupFreight(data);
    if (data.packages && !(data instanceof ArrivePackingList) && !(data instanceof Package)) this.setupPackages(data.packages);
    if (data.document) this.setupDocuments(data.document);
    if (data.shipment) this.setupShipment(data.shipment);
    if (data.part_numbers) this.setupPartNumbers(data.part_numbers);
    if (this.packages && !this.client_id) this.inferClient();
    this._count = this.count;
  }

  private setupDefaults(data: any) {
    this.id = this.id || (+new Date())*-1;
    this.carrier_packing_id = this.carrier_packing_id || data.carrier_packing_id ||  NA_ID;
    this.good_type_id = this.good_type_id || null;
    this.packages = {};
    this.weight = this.weight || 0;
    this.commodity_id = this.commodity_id || null;
  }

  private setupFromPacking(data: ArrivePackingList, pids: number[]){
    this.client_id = data.client_id;
    if (this.carrier_packing_id == NA_ID)
      this.carrier_packing_id = this.inferCarrierPackingId(_(data.packages).map((p: Package) => p.trk).value());
    pids.forEach((p: number) => {
      this.packages[p] = new Package(data.packages[p]);
      this.packages[p].arrive_packing_list = this;
    });
    this.id = (+new Date())*-1;
    this.resetCardinality();
  }

  private setupPackages(packages: any) {
    _(packages).forEach((p: any) => this.addOrUpdatePackage(p instanceof Package ? p : new Package(p)));
  }

  private setupDocuments(document: any) {
    this.document = new Document(_.merge(document, {arrive_packing_list: this}));
  }

  private setupFreight(carrier_packing_id: string) {
    this.carrier_packing_id = carrier_packing_id;
    this._count = 0;
    this.addNewPackage();
  }

  private setupShipment(shipment: any) {
    if(!(this.shipment instanceof Shipment)) this.shipment = new Shipment(shipment);
  }

  private setupPartNumbers(part_numbers: any[]) {
    this.part_numbers = part_numbers.map((pn: any) => new PartNumber(pn));
  }

  private addNewPackage(){
    let npkg = new Package(this);
    this.packages[npkg.id] = npkg;
    this.resetCardinality();
  }

  addOrUpdatePackage(pkg: Package) {
    pkg.arrive_packing_list = this;
    let isNew = !this.packages[pkg.id];
    this.packages[pkg.id] = pkg;
    if (isNew) this.resetCardinality();
    if (!this.client_id) this.inferClient();
  }

  convert(pids?: number[]): ArrivePackingList {
    pids = pids || this.packages_a.map((p: Package) => p.id);
    let na = new ArrivePackingList(this, pids);
    this.client_id = null;
    this.document = null;
    this.arrival.addPackingList(na);
    return na;
  }

  get saveProp(): string[] {
    if (this.id && this.id > 0)
      return super.saveProp.concat(this.document && !this.document.stored ? SAVE_PROPS.concat(['document']) : SAVE_PROPS);
    else
      return super.saveProp.concat(SAVE_PROPS);
  }

  toSaveData(properties:string[] = null) {
    let result = super.toSaveData(properties);
    if (!this.isFreight && result["weight"] > 0)
      result["weight"] = 0;
    return result;
  }

  get calcWeight(): number {
    return this.isFreight ? +this.weight : _(this.packages).map((p: Package) => +p.weight).sum();
  }

  get generic(): boolean {
    return this.carrier_packing_id === NA_ID && !this.receipt_id;
  }

  get arrival(): Arrival {
    return this._arrival;
  }
  set arrival(value: Arrival) {
    if (value){
      this._arrival = value;
      this.arrival_id = value.id;
    }
  }

  get count(): number {
    return  this.packages_a.filter((pkg: Package) => pkg._destroy === false).length;
  }
  set count(value: number) {
    if (value > 0){
      while(this.count < value)
        this.addNewPackage();
      while(this.count > value)
        this.popPackage();
      this._total_packages = value;
      this.packages = {...this.packages};
    }
  }

  popPackage() {
    let last = _(this.packages).values().filter((pkg: Package) => pkg._destroy === false).sortBy("created_at").last();
    if (last) this.packages[last.id]._destroy = true;
    this.resetCardinality();
  }

  updateCounter(){
    this.count = this._count;
  }


  get empty(): boolean {
    return this.count == 0;
  }

  get total_packages(): number {
    if (!this._total_packages)
      this.inferTotalPackages();
    return this._total_packages;
  }

  get locations(): string[] {
    return this.packages ?
      [] :
      _(this.packages).map((x: Package) => x.location_tag).compact().uniq().value();
  }

  get packages_po_list(): string[] {
    return _(this.packages)
      .filter((pkg: Package) => pkg && pkg.po && typeof(pkg.po) == "string" && pkg.po.length > 0)
      .map((pkg: Package) => pkg.po.split(","))
      .flatten()
      .uniq()
      .value();
  }

  get isValid(): boolean {
    let result =
      this.deflected ||
      (super.isValid &&
        this.carrier_packing_id !== NA_ID &&
        _.every(this.packages, 'isValid') &&
        (this.isFreight ? (this.weight || 0) > 0 : true));
    return result;
  }

  set client(value: Client) {
    if (value)
      this.client_id = value.id;
  }

  get client(): Client {
    return Client.CLIENTS && Client.CLIENTS[this.client_id];
  }

  get sort_index(): number {
    return +this.updated_at;
  }

  get deflected(): boolean {
    return !!(this.client && this.client.ignore);
  }

  get document_id(): number {
    return this.document && this.document.id;
  }

  packageExists(package_id: number): boolean {
    return !!this.packages[package_id];
  }

  get filter_tags(): string[] {
    return _(this.packages).
      map((p: Package) => p.filter_tags).
      flatten().
      compact().
      uniq().
      value();
  }

  get packages_attributes(): Package[] {
    return this.packages_a.filter((p: Package) => !(p.id < 0 && p._destroy));
  }

  get part_numbers_attributes(): PartNumber[] {
    return this.part_numbers;
  }

  inferClient(override: boolean = false) {
    if (!this.client_id || override) {
      let tmp1 = _(this.packages).
        filter((p: Package) => p.filter_tags.length > 0).
        map((p: Package) => Client.CLIENTS_A.map((c: Client) =>  ({id: c.id, tag_match: c.tag_match(p.filter_tags)}) ) ).
        flatten();
      let rank = tmp1.maxBy((m: any) => m.tag_match.length);
      if (rank && rank.id && rank.tag_match.length > 0) this.client_id = rank.id;
    }
  }

  apply_po_to_all(po: string) {
    _(this.packages).forEach((p: Package) => p.po = _.compact(_.union(p.po.split(","),po.split(","))).join(",") );
    this.validate();
  }

  drop(pkg: Package) {
    let p = new Package(pkg)
    p.raw_client = p.raw_client_plus = '';
    this.arrival.addPackageToPackingAnyStatus(p,this.id);
    this.resetCardinality();
  }

  pull(usp: number[]){
    usp.forEach((pid: number) => {
      let pkg = this.arrival.findPackage(pid);
      if (pkg){
        let npkg = new Package(pkg);
        npkg.arrive_packing_list = this;
        this.arrival.addPackageToPackingAnyStatus(npkg);
      }
    });
  }

  private inferTotalPackages() {
    let p = _(this.packages).find((p: Package) => p.cardinality && !!p.cardinality[1]);
    this._total_packages = (p && p.cardinality[1]) || this.countCurrentPackages();
  }

  private inferCarrierPackingId(pids: string[]): string {
    return _(pids).map((p: string) => p.substring(12,19) ).uniq().value()[0] || ''+(+new Date());
  }

  deletePackage(package_id: number) {
    delete this.packages[package_id];
  }

  merge(pl: ArrivePackingList){
    _.mergeWith(this, pl, (fromServer: any, inState: any, key: string) => {
      if (key == "packages") {
        let pkgFromServer = _.keyBy(fromServer, "trk");
        let pkgInSate = _.keyBy(inState, "trk");
        let result = {}
        Object.keys(fromServer).forEach((k: string) => {
          if (pkgInSate[fromServer[k].trk])
            fromServer[k].merge(pkgInSate[fromServer[k].trk])
          result[k] = fromServer[k];
        })
        Object.keys(inState).forEach((k: string) => {
          if (!pkgFromServer[inState[k].trk]) result[k] = inState[k];
        });
        return result;
      }
      if (key == "id" || key == "arrival_id")
        return fromServer;
      if (!inState && fromServer)
        return fromServer;
    });
  }

  findCardinality(pkg: Package): string {
    return `${this.packages_a.findIndex((p: Package) => p.id == pkg.id)+1}/${this.count}`
  }

  get isFreight(): boolean {
    if (this._arrival_type)
      return this._arrival_type == Arrival.FreightArrival;
    else
      return this.arrival && this.arrival.isFreight;
  }

  get arrival_type(): string {
    return this._arrival_type;
  }

  hasPackagesWithCategory(good_category_id: number): boolean {
    const data = _(this.packages).filter(pkg => pkg.good_category_id === good_category_id).value();
    return _.size(data) > 0;
  }

  get isLocated(): boolean {
    return _(this.packages).some((p: Package) => !!p.location_tag);
  }

  resetCardinality() {
    let t = this.countCurrentPackages();
    _(this.packages).filter((p: Package) => p._destroy == false).each((p: Package, i: number) => p.raw_cardinality = `${i+1}/${t}`);
  }

  countCurrentPackages(): number {
    return _(this.packages).values().sumBy((p: Package) => p._destroy == true ? 0 : 1);
  }

  get location_tags(): string[] {
    return _(this.packages).map((m: Package) => m.location_tag).compact().uniq().value();
  }

  get location_tag(): string {
    return `${this.getLocationPart('scope')}${this.getLocationPart('location_code')}-${this.getLocationPart('level')}-${this.getLocationPart('column')}`;
  }

  get location_id(): number {
    return _(this.packages).map((m: Package) => m.location_id).compact().uniq().value()[0];
  }

  get arrive_packing_list_id(): number {
    return this.id;
  }

  getLocationPart(part: string): string {
    let codes = this.getLocationParts(part);
    return codes.length == 1 ? codes[0] : '';
  }

  getLocationParts(part: string): string[] {
    return _(this.location_tags).
      map((t: string) => ArrivePackingList.LOC_REGEXP.exec(t)).
      compact().map((r: any) => r.groups && r.groups[part]).
      compact().uniq().value();
  }

  set good_category_id(value: number) {
    _(this.packages).forEach((item: Package) => item.good_category_id = value);
  }

  get good_category_id(): number {
    return _(this.packages).map((item: Package) => item.good_category_id).first();
  }

  get good_categories(): number[] {
    return _(this.packages).
      map((pkg: Package) => pkg.good_category_id).
      uniq().
      value();
  }

  get bundle_type_id(): number {
    return _(this.packages).map((item: Package) => item.bundle_type_id).first();
  }

  set bundle_type_id(value: number) {
    _(this.packages).each((p: Package) => p.bundle_type_id = value);
  }

  get uncategorized(): boolean {
    return _(this.packages).some((p: Package) => !p.good_category_id)
  }

  get bundleWeight(): number {
    return this.weight / this.count;
  }

  get isShipped(): boolean {
    return this.shipment && !!this.shipment.shipped_id;
  }

  get isPicked(): boolean {
    return _(this.packages).some((p: Package) => p.isPicked);
  }

  get isDamaged(): boolean {
    return _(this.packages).some((p: Package) => !!p.damage_report);
  }

  get year_sequence(): number {
	  const parts = this.receipt_id.split("-");
	  const year = parseInt(parts[1]);
	  const sequence = parseInt(parts[2]);
	  const sequence_length = sequence.toString().length;
	  return year + sequence / Math.pow(10, sequence_length);
  }

  get havePackagesDeleted(): boolean {
    return this.packages_attributes.some((p: Package) => p._destroy);
  }

  get master_label(): string {
    if (this.isFreight || (this.arrival.carrier_name.startsWith("FEDEX") && this.carrier_packing_id && this.carrier_packing_id != NA_ID && this.carrier_packing_id.length >= 10))
      return  this.carrier_packing_id;
    else
      return this.packages && Object.keys(this.packages).length > 0 ? (_(this.packages).values().first().simple_trk || _(this.packages).values().first().trk) : "";
  }

  get client_code(): string {
    return this.receipt_id ? this.receipt_id.split("-")[0] : null;
  }

  get client_code_match(): boolean {
    return this.client_code == this.client.code;
  }

  findPackageByTrk(trk: string): Package {
    return _(this.packages).find((p: Package) => p.trk == trk);
  }

  findPackageByPallet(): Package {
    return _(this.packages).find((p: Package) => !p._destroy && p.isPalletized && p.pallet_id < 0);
  }

  get needToConvert(): boolean {
    return this.generic && !!this.client_id; // && !this.deflected;
  }

  get undoc(): boolean {
    return this.carrier_packing_id && (this.carrier_packing_id == NA_ID || this.carrier_packing_id == DEF_ID);
  }

  get usePartNumber(): boolean {
    return this.client && this.client.part_number;
  }

  get havePartNumbers(): boolean {
    return this.isFreight ?
      this.packages_a.some((p: Package) => !!p.part_number_id ) :
      this.part_numbers && this.part_numbers.length > 0;
  }

  get part_numbers_str(): string {
    return this.part_numbers_a.map((pn: PartNumber) => pn.name).join();
  }

  get part_numbers_a(): PartNumber[] {
    if (!this._part_numbers_a)
      this._part_numbers_a = this.isFreight ?
      _(this.packages_a).map((p: Package) => p.part_number).compact().value() :
    [...this.part_numbers || []];
    return this._part_numbers_a;
  }

  get packages_a(): Package[] {
    return _.values(this.packages);
  }

  get fragmented(): boolean {
    return (this.packages_a.some((p: Package) => p.haveShipment)
      && this.packages_a.some((p: Package) => !p.haveShipment)) ||
      _(this.packages_a).map((p: Package) => p._shipment_id).uniq().size() > 1;
  }

  get haveShipment(): boolean {
    return !!this.shipment_id || !!this.shipment;
  }

  get somePackageHaveShipment(): boolean {
  	  return _(this.packages).some((p: Package) => p.haveShipment);
  }

  get isParcel(): boolean {
    return !this.isFreight;
  }

  updatePartNumbers(pns: PartNumber[]){
    this.part_numbers = this.part_numbers || [];
    this.part_numbers.forEach((pn: PartNumber) => pn._destroy = true);
    this.part_numbers = this.part_numbers.concat(pns.map((pn: PartNumber) => new PartNumber({part_number_id: pn.id, quantity: pn.quantity})));
  }

  get part_numbers_dic(): {[id: number]: PartNumber} {
    return _.keyBy(this.part_numbers, 'part_number_id');
  }

  contained(packages: Package[]): boolean {
    let ids = packages.map((p: Package) => p.id);
    return _(this.packages).every((p: Package) => ids.includes(p.id));
  }

  assignShipment(s: Shipment) {
    this.shipment = s;
    _(this.packages).each((p: Package) => {
      if (p.arrive_packing_list) p.arrive_packing_list.shipment = s;
    });
    s.arrive_packing_lists[this.id] = this;
  }

  unassignShipment(s: Shipment) {
    this.shipment = undefined;
    _(this.packages).each((p: Package) => {
      if (p.arrive_packing_list) p.arrive_packing_list.shipment = undefined;
    });
    delete s.arrive_packing_lists[this.id];
  }

  get havePallets(): boolean {
    return this.packages_a.some((p: Package) => p.pallet_id > 0);
  }

  get packagesWithPallets(): Package[] {
    return this.packages_a.filter((p: Package) => p.pallet_id > 0);
  }

  unlocatePackage(id: number) {
    this.packages[id].unlocate();
  }

  locatePackage(id: number, storageLocation: IStorageLocation) {
    this.packages[id].locate(storageLocation);
  }
}
