import { Component, OnInit, Input } from '@angular/core';
import { DeliveryOrder } from 'wms-lib';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delivery-search-result',
  templateUrl: './delivery-search-result.component.html',
  styleUrls: ['./delivery-search-result.component.scss']
})
export class DeliverySearchResultComponent implements OnInit {
  @Input() delivery_order: DeliveryOrder;

  constructor(private _router: Router) { }

  ngOnInit() {

  }

  openAsn() {
    this._router.navigate(["/","exports","asns",this.delivery_order.asn_id]);
  }
}
