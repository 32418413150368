import { Client } from "./client";
import { Bindable } from "./common";
import { DeliveryOrder } from "./delivery-order";
import * as _ from 'lodash';

const SAVE_PROPS = ['date','carrier','box','delivery_orders_attributes','client_id','warehouse_id'];

export enum AsnStatus {
    transit = 'T',
    working = 'W',
    ready = 'R',
    closed = 'C'
}

export class Asn extends Bindable {
    id: number;
    date: Date;
    carrier: string;
    box: string;
    delivery_orders: { [key: string]: DeliveryOrder } = {};
    filter: boolean = true;
    client_id: number;
    client: Client;
    receivedAt?: Date;
    status?: string;
    deliveries_count: number;
    ready_to_ship_count: number;
    not_ready_to_ship_count: number;
    received_at: Date;
    partially_received: boolean;
    warehouse_id: number;
    received_count: number;
    first_received_at: Date;
    last_received_at: Date;
    shipped_count: number;

    constructor(data: any) {
        super(data);
        this.id = this.id || (+(new Date())) * -1;
        this.buildDeliveryOrders(data.delivery_orders || []);
        if (data['client']) this.client = new Client(data['client']);
    }

    

    buildDeliveryOrders(delivery_orders: any[]) {
    this.delivery_orders = {};
    for(let d of delivery_orders) {
      this.delivery_orders[d.id] = new DeliveryOrder({...d, ...{asn: this}});
    }
  }

    get delivery_orders_attributes(): DeliveryOrder[] {
        return Object.values(this.delivery_orders);
    }

    get saveProp(): string[] {
        return super.saveProp.concat(SAVE_PROPS);
    }

    get isValid(): boolean {
        return !!this.date && !!this.carrier && !!this.box && this.client_id > 0;
    }

    get isComplete(): boolean {
        let deletedDeliveryOrders = _.filter(this.delivery_orders, (d: DeliveryOrder) => d._destroy);
        if(_.size(this.delivery_orders) == 0 || _.size(deletedDeliveryOrders) == _.size(this.delivery_orders)) {
            return false
        }
        return _.every(this.delivery_orders, (d: DeliveryOrder) => d.isComplete) && this.isValid;
    }

    get received():boolean {
        if (this.receivedAt) return true;
        return false;
    }

    get hasFilesAttached(): boolean {
        return _.some(this.delivery_orders, (d: DeliveryOrder) => d.hasFilesAttached);
    }

    get color(): string {
        return (this.status != 'transit' && this.partially_received)  ? '#d1fbeb' : '';
    }

     addOrUpdateDeliveryOrder(delivery_order: DeliveryOrder) {    
        let delieveries = { ...this.delivery_orders };
        delivery_order.asn_id = this.id;
        delieveries[delivery_order.id] = delivery_order;
        this.delivery_orders = delieveries;
    }

    deleteDeliveryOrder(id: number) {
        if(id < 0) {
            delete this.delivery_orders[id];
        } else {
            this.delivery_orders[id]._destroy = true;
        }
        this.delivery_orders = { ...this.delivery_orders };
    }

    generateFormData(mainKey='asn') {
        let data = super.toSaveData();
        let formData = new FormData();

        let iterateArray = (key: string, array: any[]) => {
            array.forEach((item: any, index: number) => {
                for(let itemKey in item) {
                    if(item[itemKey] !== undefined){
                        if(Array.isArray(item[itemKey])) {
                            iterateArray(`${key}[${index}][${itemKey}]`, item[itemKey]);
                        } else {
                            appendData(`${key}[${index}][${itemKey}]`, item[itemKey]);
                        }
                    }
                }
            });
        };

        let appendData = (key: string, value: any) => {
            if(!!this.parseDataUrl(value)) {
                let url = this.parseDataUrl(value);
                let replaced = key.replace('_url', '');
                formData.append(`${mainKey}[${replaced || key}]`, this.dataURLtoBlob(url.groups["content"], `${url.groups["type"]}/${url.groups["ext"]}`), `${key}.${url.groups["ext"]}`);
            } else {
                formData.append(`${mainKey}[${key}]`, value);
            }
        }

        for (const key in data) {
            if(Array.isArray(data[key])) {
                iterateArray(key, data[key]);
            } else {
                appendData(key, data[key]);
            }
        }
        return formData;
    }

    toSaveData() {
        return this.hasFilesAttached ? this.generateFormData() : super.toSaveData();
    }
}
