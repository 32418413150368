import { Catalog } from "./catalog";

export class Classification extends Catalog {
	default: string;

	protected get saveProp(): string[] {
		return  super.saveProp.concat(['name_en','name_es','default']);
	}

	get isDefault(): boolean {
		return this.default == 't';
	}

	set isDefault(value: boolean) {
		this.default = value ? 't' : 'f';
	}
  
}
