import { Component, ElementRef, ViewChild } from '@angular/core';
import { CatalogLogoComponent } from "../../base-components/catalog-logo.component";
import { Client, CLIENT_FEATURES } from 'wms-lib';
import * as _ from 'lodash';

@Component({
    selector: 'app-clients',
    templateUrl: './clients.component.html',
    styleUrls: ['./clients.component.sass']
})
export class ClientsComponent extends CatalogLogoComponent<Client> {
    name_catalog: string = "clients";
    overlay: any;
    client_working: Client = null;
    features: any = _.keyBy(CLIENT_FEATURES, 'value');

    newItem(data: any) {
        return new Client(data);
    }

    showForwards(client: Client, overlay: any,  event: any) {
        this.client_working = client;
        this.overlay = overlay;
        this.overlay.show(event);
    }
}

