import { Component, OnInit, NgZone } from '@angular/core';
import { CodebarReaderService, ModalDialogService, AuthenticationService, NotificationService } from 'wms-lib';
import { Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { PackingListComponent } from '../../../shared/components/base-components/packing-list.component';

@Component({
    selector: 'app-inventory-packing-list',
    templateUrl: './inventory-packing-list.component.html',
    styleUrls: ['../../../shared/components/base-components/packing-list.component.scss']
})
export class InventoryPackingListComponent extends PackingListComponent implements OnInit {
    constructor(protected store:Store,
                protected router: Router,
                protected activatedRoute: ActivatedRoute,
                protected codebar_service: CodebarReaderService,
                protected ng_zone: NgZone,
                protected modalDialogService: ModalDialogService,
                protected authenticationService: AuthenticationService,
                protected notificationService: NotificationService
               ) {
        super(store,router,activatedRoute,codebar_service,ng_zone, modalDialogService, authenticationService, notificationService);
    }

}
