import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { TicketsService, ArrivalsService, InventoryService, Client } from 'wms-lib';
import * as _ from 'lodash';
import { HomeBaseComponent } from './home.base.component';

const STATUS_COLORS = ['rgb(240, 140, 140)', 'rgb(255, 255, 162)', 'rgb(132, 221, 132)'];

@Component({
  selector: 'app-internal-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent extends HomeBaseComponent implements OnInit {

  receipts_type = 'weight';
  receipts_type_occupation = 'weight';
  receipts_type_slow = 'weight';
  receipts_type_lines = 'weight';
  receipts_type_client = 'weight';

  receipts_type_options = [
    { label: 'weight', value: 'weight'},
    { label: 'packages', value: 'bundles' }
  ];

  arrivals_summary: {[arrival_type: string]: any[]} = {};
  arrivals_summary_data_chart = {};

  inventory_sumary: {[inventory_sumary_type: string]: any[]} = {};
  occupation_data_chart = {};
  slow_movement_data_chart = {};
  receipts_data_chart = {};
  receipts_client_data_chart = {};

  constructor(
    router: Router,
    store: Store,
    tickets_service: TicketsService,
    private arrivals_service: ArrivalsService,
    private inventory_service: InventoryService) { super(router,store,tickets_service); }

  ngOnInit() {
    super.ngOnInit();

    this.arrivals_service.getSummary().subscribe((data: any) => {
      if (!data.errors) {
        this.arrivals_summary = data;

        _.forEach(this.arrivals_summary,(v)=>{
          _.remove(v, {name: "Backlog processed"});
          _.remove(v, {name: "Processed by me"});
        });

        _.forEach(this.arrivals_summary,(v,k)=>{
          _.forEach(v, (a,i)=>{
            this.arrivals_summary[k][i]['color'] = STATUS_COLORS[i]
          })
        });

        this.arrivals_summary_data_chart =  this.arrivals_summary_data();
      }
    });

    this.inventory_service.getSummary().subscribe((data: any) => {
      if (!data.errors) {
        this.inventory_sumary = data;
        this.occupation_data_chart = this.occupation_data(this.receipts_type);
        this.slow_movement_data_chart = this.slow_movement_data(this.receipts_type);
        this.receipts_data_chart = this.receipts_data(this.receipts_type);
        this.receipts_client_data_chart = this.receipts_client_data(this.receipts_type);
      }
    });

  }

  arrivals_summary_data (): any {

    let options = {};
    var data = {};
    _.forEach(this.arrivals_summary, function(v, k) {
      data[k] = {};
      data[k]['datasets'] = [];
      data[k]['labels'] =  _.map(v, _.iteratee('name'));
      let backgroundColor = _.map(v, _.iteratee('color'));
      let dat = _.chain(v).map(function(o) {
        return o.receipts + o.packages;
      }).value();
      data[k]['datasets'].push({data: dat, backgroundColor})
    });

    options = {
      legend: {
        display: false
      }
    };
    return {data, options};
  }

  getColor():any {
    const randomColor = Math.floor(Math.random()*16777215).toString(16);
    return "#" + randomColor;
  }

  occupation_data_change(obj:any){
    let type = obj.value.value;
    this.receipts_type_occupation = type;
    this.occupation_data_chart = this.occupation_data(type);
  }

  occupation_data(type: string): any {
    // let type = this.receipts_type;
    let occupation = this.inventory_sumary.occupation[type];
    let clients = Client.CLIENTS;
    let obj = {};
    obj['datasets'] = [];

    let cli = _(occupation).map((v,k)=> { return {key:k, value: Number(v)} } ).orderBy('value').map('key').value();

    let labels =  _(cli).map((v)=> (clients[v])?clients[v].full_name:'Unknow' ).value()
    let backgroundColor = _(occupation).keys().map((v: string)=>  this.getColor()   ).value()
    let data = _(occupation).map((v,k)=> { return { key:k, value: Number(v) } } ).orderBy('value',['desc']).map('value').value();

    obj['labels'] = labels;
    obj['datasets'].push({data, backgroundColor});

    let options = {
      plugins: {
        legend: {
          position:'right'
        }
      }
    };
    return {data: obj, options};
  }

  slow_movement_data_change(obj:any){
    let type = obj.value.value;
    this.receipts_type_slow = type;
    this.slow_movement_data_chart = this.slow_movement_data(type);
  }

  slow_movement_data(type: string): any {
    let clients = Client.CLIENTS;
    let obj = {};
    let slow_movment = this.inventory_sumary.slow_movment[type];
    let labels = _(slow_movment).keys().value();
    obj['labels'] = labels;
    obj['datasets'] = [];
    let options ={
      tooltips: {
        mode: 'index',
        intersect: false
      },
      responsive: true,
      scales: {
        xAxes: [{
          stacked: true,
        }],
        yAxes: [{
          stacked: true
        }]
      }
    }

    let clientes_list = [];
    _.forEach(slow_movment,(v,k)=>{
      clientes_list = _.concat(clientes_list, _(v).keys().value())
    })

    clientes_list = _(clientes_list).uniqBy().sortBy().value()

    let datasets = _.map(clientes_list,(v)=> {
      let data = _.map(labels,(f)=>{
        return slow_movment[f][v] || 0
      })

      return {
        type: 'bar',
        label: (clients[v]) ? clients[v]['full_name'] : 'Unknow',
        backgroundColor: this.getColor(),
        data,
        total: _.sum(data)
      }
    })
    obj['datasets'] = _.orderBy(datasets,['total'],['desc']);
    return {data: obj, options}
  }

  receipts_data_change(obj:any){
    let type = obj.value.value;
    this.receipts_type_lines = type;
    this.receipts_data_chart = this.receipts_data(type);
  }

  receipts_data(type: string): any {
    let clients = Client.CLIENTS;
    let obj = {};
    _.forEach(this.inventory_sumary,(v,k)=>{
      if(_.has(this.inventory_sumary[k],type) && _.indexOf(['ParcelArrival','FreightArrival'], k) != -1 ){
        let slow_movment = this.inventory_sumary[k][type];
        let labels = _(slow_movment).keys().value();
        obj[k] = {};
        obj[k]['labels'] = labels;
        obj[k]['datasets'] = [];

        let clientes_list = [];
        _.forEach(slow_movment,(v,k)=>{
          clientes_list = _.concat(clientes_list, _(v).keys().value())
        })

        clientes_list = _(clientes_list).uniqBy().sortBy().value()

        let total = [];
        let datasets = _.map(clientes_list,(v)=> {
          let data = _.map(labels,(f)=>{
            return slow_movment[f][v] || 0
          })

          total.push(_.sum(data));

          return {
            type: 'bar',
            label: (clients[v]) ? clients[v]['full_name'] : 'Unknow',
            backgroundColor: this.getColor(),
            data,
            total: _.sum(data)
          }
        })
        datasets = _.orderBy(datasets,['total'],['desc']);
        obj[k]['datasets'] = datasets;
      }
    })

    let options = {
      tooltips: {
        mode: 'index',
        intersect: false
      },
      responsive: true,
      scales: {
        xAxes: [{
          stacked: true,
        }],
        yAxes: [{
          stacked: true
        }]
      }
    };

    return {data: obj, options}
  }

  receipts_client_data_change(obj:any){
    let type = obj.value.value;
    this.receipts_type_client = type;
    this.receipts_client_data_chart = this.receipts_client_data(type);
  }

  receipts_client_data(type: string): any {
    // let type = this.receipts_type;
    let clients = Client.CLIENTS;
    let obj = {};
    _.forEach(this.inventory_sumary,(v,k)=>{
      if(_.has(this.inventory_sumary[k],type)  && _.indexOf(['ParcelArrival','FreightArrival'], k) != -1 ){
        let inventory = this.inventory_sumary[k][type];
        obj[k] = {};
        obj[k]['datasets'] = []

        let clientes_list = [];
        _.forEach(inventory,(v,k)=>{

          clientes_list = _.concat(clientes_list, _.keys(v))
        })
        clientes_list = _(clientes_list).uniqBy().sortBy().value();

        let d = _.map(clientes_list,(v)=>{
          let total = 0;
          _.forEach(inventory,(val,key)=>{
            total += Number(val[v]) || 0
          })
          let cliente = (clients[v])?clients[v]['full_name'] : 'Unknow';
          return {id: v,cliente, total, color: this.getColor()}
        })

        d = _.orderBy(d,['total'],['desc'])
        let labels = _.map(d,'cliente');
        let data = _.map(d,'total');
        let backgroundColor =_.map(d,'color');

        obj[k]['labels'] = labels
        obj[k]['datasets'].push({data, backgroundColor})
      }
    })

    return {data: obj}

  }



}
