import { Catalog } from "./catalog";
import { Shipment } from "./shipment";

export class GoodCategory extends Catalog {
    // this property is only for UI presentation in shipment review screen
    shipment: Shipment;
    client_id: number;
    default: string;

    protected get saveProp(): string[] {
        return  super.saveProp.concat(['name_en','name_es','client_id','default']);
    }

    get isDefault(): boolean {
        return this.default == 't';
    }

    set isDefault(value: boolean) {
        this.default = value ? 't' : 'f';
    }
  
}
