import { Pipe, PipeTransform } from '@angular/core';
import { Catalog, ICatalog } from '../models';
import * as _ from 'lodash';

@Pipe({
  name: 'MapToOptions'
})
export class MapToSelectOptionsPipe implements PipeTransform {

  transform(value: any): any {
      return [...value.keys()].map((k) => ({ label: value.get(k), value: k}));
  }

}

@Pipe({
    name: 'MapToOptionsWithNull'
  })
export class MapToSelectOptionsWithNullPipe implements PipeTransform {

  transform(value: any): any {
      let v =  [...value.keys()].map((k) => ({ label: value.get(k), value: k}));
      v.unshift({ label: 'NA', value: null});
      return v;
  }

}

@Pipe({
    name: 'MapCatalogToOptions'
})
export class MapCatalogToSelectOptionsPipe implements PipeTransform {

    transform(value: {[id: number]: ICatalog}, null_option: string = null): any {
        let result = _(value).values().map((i: Catalog) => ({ label: i.name, value: i.id})).value();
        if (null_option) result.unshift({ label: null_option, value: null});
        return result;
    }

}

@Pipe({
    name: 'MapArrayToOptions'
})
export class MapArrayToSelectOptionsPipe implements PipeTransform {

    transform(value: string[]): any {
        return value.map((i) => ({label: i, value: i}));
    }

}
