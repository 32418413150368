import { Injectable  } from '@angular/core';
import * as _ from 'lodash';

@Injectable({
    providedIn: 'root'
})
export class ReportService {
    options: {[name: string]: any} = {
        "__format": "html",
        "__svg": true,
        "__locale": "en_US",
        "__timezone": "US/Mountain", 
        "__masterpage":true
    };
    report_path: string = "birt/frameset?";
    sub_path: string = "report";
    base: string;
    path: string;

    constructor() {
        if(navigator && navigator.language) this.options["__locale"] = navigator.language.replace("-","_");
        this.base = window.location.origin.replace("3000","8080");
        this.path = `${this.base}/${this.report_path}`;
    }

    openReport(report_name: string,
               params: {[name: string]: any} = {},
               options: {[name: string]: any} = {},
               target: string = "_blank") {
        let all_options = {"__report": `${this.sub_path}/${report_name}.rptdesign`};
        _.merge(all_options,this.options,options,params);
        let options_path = _(all_options).map((v: any,k: string) => `${k}=${v}`).join("&");
        window.open(this.path + options_path, target);
    }
}
