import { Component } from '@angular/core';
import { ContainerType } from 'wms-lib';
import { CatalogComponent } from '../../base-components/catalog.component';

@Component({
    selector: 'app-container-types',
    templateUrl: './container-types.component.html',
    styleUrls: ['./container-types.component.sass']
})
export class ContainerTypesComponent extends CatalogComponent<ContainerType> {
    name_catalog = 'container_types';


    addNew(_type?: string) {
        let c = this.newItem({name_es: '', name_en: '', weight: 0, type: _type});
        this.items[c.id] = c;
        this.items = {...this.items};
    }

    newItem(data: any) {
        return new ContainerType(data);
    }
}

