import { Directive, Input, OnInit, HostListener  } from '@angular/core';
import { NgControl } from "@angular/forms";
import { Subscription } from 'rxjs';
import { UOM } from "wms-lib";
import { UnitConverter } from '../ui/converters/unit-converter';

@Directive({
    selector: '[appUnitConverter]'
})
export class UnitConverterDirective  implements OnInit {
    @Input('appUnitConverter') unit: UOM;
    private _unitConverter: UnitConverter;
    private _valueSubscription: Subscription;
    constructor(public ngControl: NgControl) {
    }

    ngOnInit(): void {
        this._unitConverter = new UnitConverter(this.unit || UOM.KG);
        this._valueSubscription = this.ngControl.control.valueChanges.subscribe((value: any) => {
            this.transFormTo(value);
            this._valueSubscription.unsubscribe();
        });
    }

    @HostListener("blur")
    onBlur() {
        this.transToFrom(this.ngControl.control.value);
    }

    @HostListener('keydown', ['$event'])
    onKeydownHandler(event: KeyboardEvent) {
        if (event.keyCode == 13) 
            this.transToFrom(this.ngControl.control.value);
    }

    private transFormTo(value: any) {
        this.ngControl.control.setValue(this._unitConverter.convertFrom(value), { emitEvent: false });
    }

    private transToFrom(value: any) {
        this.ngControl.control.setValue(this._unitConverter.convertTo(value), { emitEvent: false });
    }
}
