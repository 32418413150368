import { Component, NgZone, OnInit, Output, Input, EventEmitter, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { CodebarReaderService, ArrivePackingList, DamageReport, Shipment, Catalog, AuthenticationService, Package, Client, ReLoadPackingListAction, Document } from 'wms-lib';
import { PackingListsComponent } from '../../../../shared/components/base-components/packing-lists.component';
import * as _ from 'lodash';
import * as actions from 'wms-lib';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-receipts-packing-lists',
  templateUrl: './receipts-packing-lists.component.html',
  styleUrls: ['../../../../shared/components/base-components/packing-lists.component.scss']
})
export class ReceiptsPackingListsComponent extends PackingListsComponent implements OnInit {
  private readonly XLS_R = /^([A-Z]{2}-\d{2}-\d+\t.+)+$/gm;

  private _loading: boolean = false;
  selectedPL: ArrivePackingList;
  damage_report: DamageReport;
  shipment: Shipment = new Shipment({packages: {}, id: 0});
  good_categories$: Observable<{[id: number]: Catalog }>;
  original_category_id: number;
  rowGroupMetadata: any;
  usePartNumber: boolean = false;
  usePartialReceipts: boolean = false;
  @ViewChild('tblPls', { static: false }) table: any;
  @Output() onSelectDoc: EventEmitter<Document> = new EventEmitter<Document>();
  @Output() onLazyLoad: EventEmitter<any> = new EventEmitter<any>();
  private _pls: ArrivePackingList[];
  @Input() set pls(value: ArrivePackingList[]) {
    if (this.selectedPL) {
      if (Array.isArray(this._pls) && Array.isArray(value)) {
        let idxO = this._pls.findIndex((p: ArrivePackingList) => p.id == this.selectedPL.id);
        let idxN = value.findIndex((p: ArrivePackingList) => p.id == this.selectedPL.id);
        if (idxO >= 0 && idxN >= 0 && !this._pls[idxO].document && value[idxN].document)
          this.onSelectDoc.emit(value[idxN].document);
      } else {
        if (value[this.selectedPL.id] && value[this.selectedPL.id].document)
          this.onSelectDoc.emit(value[this.selectedPL.id].document);
      }
    }
    if (this.damage_report) {
      let pkg: Package = _(value).
        map((apl: ArrivePackingList) => apl.packages_a).
        flatten().
        find((p: Package) => p.id == this.damage_report.package_id);
      if (pkg) this.damage_report = pkg.damage_report;
    }
    this._pls = value;
  }
  get pls(): ArrivePackingList[] {
    return this._pls;
  }
  @Input() set raw_pls(value: ArrivePackingList[]) {
    this._loading = !value;
  }
  @Input() selectable: boolean = false;
  @Input() todo: boolean = false;
  @Input() clasify: boolean = false;
  @Input() grouped: boolean = false;
  @Input() lazy: boolean = false;
  @Input() totalRecords: number;
  @Input() scrollable: boolean = false;
  @Input() canOrderToShipment: boolean | null = null;
  constructor(private store: Store, codebase_service: CodebarReaderService, ng_zone: NgZone,
              authenticationService: AuthenticationService) {
    super(store, codebase_service, ng_zone, authenticationService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.store.select(state => state.catalogsState.active_client).subscribe((client: Client) => {
      this.usePartialReceipts = client.partial_receipts;
      this.usePartNumber = client.part_number;
    } );
    this.good_categories$ = this._store.select(state => state.catalogsState.good_categories);
    this._store.select(state => state.shipmentsState.shipment_template).subscribe(shipment => this.shipment = shipment );
  }

  onPlSelect(event: any) {
    if (!!event) {
      this.onSelectDoc.emit(event.data.document || new Document());
      if (!event.data.document)
        this.store.dispatch(new ReLoadPackingListAction(event.data));
    }
    else
      this.onSelectDoc.emit(null);
  }

  selectAll(chk: any, tblPls: any){
    let pls = tblPls.value.filter((pl: ArrivePackingList) =>
      (chk.checked && !this.isSelected(pl)) || (!chk.checked &&  this.isSelected(pl)));
    this._store.dispatch(new actions.AddPackingListsToShipmentAction(pls));
  }

  isSelected(pl: ArrivePackingList): boolean {
    return this.shipment && !!this.shipment.arrive_packing_lists[pl.id];
  }

  isPkgSelected(pk: Package): boolean {
    return this.shipment && !!this.shipment.packages[pk.id];
  }

  isAplOrPkgSelected(pk: Package): boolean {
    return this.isSelected(pk.arrive_packing_list) || this.isPkgSelected(pk);
  }

  sort(tblShp: any) {
    if (this.grouped)
      this.updateRowGroupMetaData(tblShp.value);
  }

  getGroupIndex(good_category_id: number): number {
    return this.rowGroupMetadata && this.rowGroupMetadata[good_category_id] && this.rowGroupMetadata[good_category_id].index;
  }

  updateRowGroupMetaData(rows: ArrivePackingList[]) {
    this.rowGroupMetadata = {};
    if (rows) {
      for (let i = 0; i < rows.length; i++) {
        let rowData = rows[i];
        let id = rowData.good_category_id;
        if (i == 0) {
          this.rowGroupMetadata[id] = { index: 0, size: 1, packages: rowData.count, weight: rowData.calcWeight };
        }
        else {
          let previousRowData = rows[i - 1];
          let previousRowGroup = previousRowData.good_category_id;
          if (id === previousRowGroup){
            this.rowGroupMetadata[id].size++;
            this.rowGroupMetadata[id].packages += rowData.count;
            this.rowGroupMetadata[id].weight += rowData.calcWeight;
          }
          else
            this.rowGroupMetadata[id] = { index: i, size: 1, packages: rowData.count, weight: rowData.calcWeight };
        }
      }
    }
  }

  get lzLoading(): boolean {
    return !this.pls || this._loading;
  }

  lazyLoad(event: any) {
    this.onLazyLoad.emit(event);
    this._loading=true;
  }

  reset() {
    if (this.table) this.table.reset();
  }

  import() {
    navigator.clipboard.readText().then((text: any) => {
      this.good_categories$.subscribe((good_cats: {[id: number]: Catalog}) => {
        if (typeof text === 'string' && this.XLS_R.test(text)) {
          let cats = text.
            split("\n").
            map((l) => l.split("\t")).
            reduce((dic, next) => {
              if (next && next.length == 2)
                dic[next[0]] = next[1].replace(/\r|\n/g, '').trim();
              return dic;
            },{});
          this._pls.forEach((apl: ArrivePackingList) => {
            if (cats[apl.receipt_id])
              apl.good_category_id = _.findKey(good_cats, ['name_es', cats[apl.receipt_id]]) ||
              _.findKey(good_cats, ['name_en', cats[apl.receipt_id]]);
          });
          this.store.dispatch(new actions.UpdatePackingCategoryInBatchAction(this._pls.filter((f: ArrivePackingList) => f.good_category_id)));
        }
      });
    }).catch(err => global.log("Error", err.message));
  }

  get colspan_pkgs(): number {
    return (this.selectable ? 10 : 9) + (+this.usePartialReceipts);
  }

  listAplPackages(apl: ArrivePackingList): Package[] {
  	  return _(apl.packages).values().filter((p: Package) => {
  	  	  return  ( this.shipment?.id == p.shipment_id ) || ( this.canOrderToShipment == null || p.canOrderToShipment == this.canOrderToShipment)
  	  }).value();
  }

  show_pkg(pk: Package): boolean {
    return !this.usePartialReceipts || pk.inStock || this.isAplOrPkgSelected(pk);
  }

  get pkg_selectable(){
    return this.selectable && this.usePartialReceipts;
  }

  get isShipmentPicked(): boolean {
    return this.shipment && this.shipment.isPicked;
  }

  get isAdmin(): boolean {
    return this.authenticationService.currentUserValue.isAdmin;
  }

  get isShipmentNew(): boolean {
    return this.shipment && this.shipment.isNew;

  }
}
