import 'lodash.combinations';
import * as _ from 'lodash';
import { PartNumber, Package } from '../models';

export class PackingsFilter {
  private readonly CS_R = /^([A-Z]{2}-\d{2}-\d+)(\s*,\s*([A-Z]{2}-\d{2}-\d+))*$/;
  private readonly R_R = /^(?<client>[A-Z]{2})-(?<start_year>\d{2})-(?<start_id>\d+)\/(?<end_year>\d{2})-(?<end_id>\d+)$/;
  private readonly XLS_R = /^([A-Z]{2}-\d{2}-\d+\n?)+$/;

  constructor();
  constructor(page: number);
  constructor(page: number, on_hand: boolean);
  constructor(page: number, on_hand: boolean, order: string);
  constructor(page: number, on_hand: boolean, order: string);
  constructor(public page: number = null,
              public on_hand: boolean = null,
              public order: string = "asc",
              public with_pn: boolean = false,
              public from: Date = null,
              public to: Date = null,
              public from_id: string = null,
              public to_id: string = null,
              public client_id: number = null,
              public pos: string[] = [],
              public categories: number[] = null,
              public processed: boolean = true,
              public shipment_id: number = null,
              public receipts: string = null,
              public part_number: PartNumber = null,
              public quantity: number = null
             ) {}

  params(): any {
    let params = {};
    if (this.with_pn)
      params['with_pn'] = true;
    else {
      if (this.on_hand !== null) {
        params['on_hand'] = this.on_hand;
        params['order'] = this.order;
        if (this.categories && this.categories.length > 0)
          params['good_category_id[]'] = this.categories;
      } else {
        params['processed'] = this.processed;
        if (this.pos.length > 0) params['pos'] = this.pos.join('|');
        if (!this.page) {
          params['shipment_id'] = this.shipment_id;
          if (this.categories === null)
            params['good_category_id'] = null
          else {
            if (this.categories && this.categories.length > 0)
              params['good_category_id[]'] = this.categories;
            else
              params['good_category_id_not'] = null;
          }
        }
      }
    }

    if (this.page) params['page'] = this.page;
    if (this.from) params['from'] = this.from.getFullYear().toString() + "/" + (this.from.getMonth() + 1).toString() + "/" + this.from.getDate().toString();
    if (this.to) params['to'] = this.to.getFullYear().toString() + "/" + (this.to.getMonth() + 1).toString() + "/" + this.to.getDate().toString();
    if (this.from_id) params['from_id'] = this.from_id
    if (this.to_id) params['to_id'] = this.to_id
    if (this.client_id) params['client_id'] = this.client_id;
    if (this.part_number) params['part_number'] = this.part_number;
    return params;
  }

  get pos_regexp(): RegExp {
    return this.pos && this.pos.length > 0 ? new RegExp("^"+this.pos.join('|')+"$") : null;
  }

  get good_categories_regexp(): RegExp {
    return this.categories && this.categories.length > 0 ? new RegExp("^"+this.categories.join('|')+"$") : null;
  }

  get receipts_regexp(): RegExp {
    if (this.receipts) {
      if (this.CS_R.test(this.receipts))
        return new RegExp("^"+this.receipts.split(",").join('|')+"$")
      if (this.R_R.test(this.receipts)){
        let groups = this.R_R.exec(this.receipts).groups;
        let receipts = _.range(+groups.start_year, (+groups.end_year)+1).
          map((year: number) => _.range(+groups.start_id,(+groups.end_id)+1).
            map((id: number) => `${groups.client}-${year}-${id}`)).
          flat().join("|");
        return new RegExp("^"+receipts+"$")
      }
      if (this.XLS_R.test(this.receipts))
        return new RegExp("^"+_.compact(this.receipts.split("\n")).join("$|^")+"$");
    }
    return null;
  }

  get receipts_array(): string[] {
    if (this.receipts)
      if (this.XLS_R.test(this.receipts))
        return _.compact(this.receipts.split("\n"));
    return [];
  }

  qtyToIds(pkgs: Package[]): number[]{
    let best =  _(pkgs).
      map((p: Package) => ({id: p.id, qty: p.quantity})).
      flatMap((v: any, i: any, a: any) => _.combinations(a, i + 1)).
      map((c: any[]) => ({ids: _.map(c,'id'), qty: _.sumBy(c,'qty')-this.quantity })).
      filter((f: any) => f.qty >= 0).
      sortBy('qty').
      first();
    return best ? best.ids : [];

  }

  get part_number_regexp() {
    return this.part_number && this.part_number.name ? new RegExp(this.part_number.name) : null;
  }
}
