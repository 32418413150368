import { OnInit, NgZone, Directive } from '@angular/core';
import { CodebarReaderService, Client, ModalDialogService, ModalDialogOptions, AuthenticationService, NotificationService } from 'wms-lib';
import { Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { PackingListBaseComponent } from  './packing-list.component.base';
import { ClientSelectionDialogComponent } from '../dialogs/client-selection-dialog/client-selection-dialog.component';
import { Patterns } from '../../validators/patterns'

@Directive({})
export class PackingListComponent extends PackingListBaseComponent implements OnInit {
  clonedPL: any = null;
  PATTERNS =  Patterns;
  constructor(protected store:Store,
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              protected codebar_service: CodebarReaderService,
              protected ng_zone: NgZone,
              protected modalDialogService: ModalDialogService,
              protected authenticationService : AuthenticationService,
              protected notificationService: NotificationService
             ) {
    super(store, activatedRoute, codebar_service, ng_zone, authenticationService, notificationService)
  }

  goToViewDocument() {
    this.select();
    if (this.isPhone)
      this.router.navigate(["/receivings", "view-document"]);
    if (this.isTablet)
      this.router.navigate([{ outlets: { documents: ['view-document'] } }], { relativeTo: this.activatedRoute });
  }

  goToDocuments() {
    this.select();
    this.router.navigate(["/receivings", "phone-conciliating-documents"]);
  }

  promtClient() {
    const options: ModalDialogOptions = {
      params: { pl : this.pl}
    };

    this.modalDialogService.showModal(ClientSelectionDialogComponent,options)
      .then((client: Client) => {
        this.onSelectClient(client);
      });
  }

  get title(): string {
    return this.pl && this.pl.carrier_packing_id && this.pl.carrier_packing_id !== 'NA' ? this.pl.carrier_packing_id : this.undoc_label;
  }

  get hasPallets(): boolean {
    return this.pl.packagesWithPallets.length > 0;
  }

  get palletColor(): string {
    if (this.pl.packagesWithPallets.length) return '#FEDA00';
    return null;
  }
}
