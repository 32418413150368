import { Component } from '@angular/core';
import { Address, Warehouse } from 'wms-lib';
import { CatalogComponent } from '../../base-components/catalog.component';

@Component({
    selector: 'app-warehouses',
    templateUrl: './warehouses.component.html',
    styleUrls: ['../catalogs.component.sass']
})
export class WarehouseComponent extends CatalogComponent<Warehouse> {
    name_catalog = 'warehouses';
    warehouse?: Warehouse;

    addNew(_type?: string) {
        let c = this.newItem({name: ''});
        this.items[c.id] = c;
        this.items = {...this.items};
    }

    newItem(data: any) {
        return new  Warehouse(data);
    }

    onAddressChange(address: Address){
        this.warehouse.address = address;
        this.onRowEditSave(this.warehouse);
    }

    onRowSelect(event: any) {
        this.warehouse = event.data;
    }

    onRowUnselect(event: any) {
        this.warehouse = undefined;
    }
}
