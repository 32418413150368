import { Component, NgZone, OnInit, ViewChild } from '@angular/core';
import { DocumentsCarrouselBaseComponent } from './documents-carrousel.component.base';
import { Store } from '@ngxs/store';
import { ActivatedRoute, Router } from '@angular/router';
import { CodebarReaderService, ExposService, NotificationService } from 'wms-lib';
import * as store_actions from 'wms-lib';

@Component({
  selector: 'app-pops-carrousel',
  templateUrl: './documents-carrousel.component.html',
  styleUrls: ['./documents-carrousel.component.scss']
})
export class PopsCarrouselComponent extends DocumentsCarrouselBaseComponent implements OnInit {
  working_delivery_id: string = null;
  @ViewChild('crs', { static: false }) crs: any;

  constructor(
    private router: Router,
    protected store: Store,
    protected activatedRoute: ActivatedRoute,
    protected codebar_service: CodebarReaderService,
    protected ng_zone: NgZone,
    protected exposService: ExposService,
    public notification_service: NotificationService
  )  {
    super(store,activatedRoute, codebar_service, ng_zone, notification_service);
  }

  ngOnInit() {
    this.getWorkingDelivery();
    this.loadPops();
  }

  goToTabletDocumentViewer() {
    this.router.navigate([{ outlets: {documents: ['view-document']} }],
                         { relativeTo: this.activatedRoute.parent });
  }

  deleteDocument() {
    this.store.dispatch(new store_actions.DeletePopAction(this.crs.value[this.crs.page].id));
  }

  getWorkingDelivery(){
    this.store.select(state => state.exposState.working_delivery_id).subscribe(wd_id => {
      this.working_delivery_id = wd_id;
    });
  }

  loadPops() {
    this.documents$ = this.store.select((state) => state.exposState.pops);
  }

  selectDocument(document: any) {
    if(this.working_delivery_id)
      this.store.dispatch(new store_actions.AssignPopToWorkingDeliveryAction({ document: document }));
    else
      this.store.dispatch(new store_actions.SetWorkingPopAction({ document: document }));
  }

  addDocument(event: any) {
    let ws = this.exposService.uploadPop.bind(this.exposService);
    super.addDocument(event, ws);
  }
}
