import { OnDestroy, Directive } from '@angular/core';
import { Subscription } from 'rxjs';

@Directive({selector: '[stateListener]'})
export class StateListenerComponent implements OnDestroy {
  protected state_subscriptions: Subscription[] = [];
  protected state_subscriptions_dic: {[id: string]: Subscription} = {};

  constructor() { }

  ngOnDestroy() {
    global.log("Component",`Des subscribiendome de los eventos del estado: ${this.constructor.name}`)
    this.unsubscribe_state();
  }

  protected addSub(s: Subscription): Subscription;
  protected addSub(s: Subscription, name: string): Subscription;
  protected addSub(s: Subscription, name?: string): Subscription {
    if (name) {
      if (this.state_subscriptions_dic[name]) this.state_subscriptions_dic[name].unsubscribe();
      this.state_subscriptions_dic[name] = s;
    } else
      this.state_subscriptions.push(s);
    return s;
  }

  protected unsubscribe_state() {
    if (this) {
      if (this.state_subscriptions) {
        this.state_subscriptions.forEach((s: Subscription) => s.unsubscribe());
        this.state_subscriptions = [];
      }
      if (this.state_subscriptions_dic) {
        Object.keys(this.state_subscriptions_dic).forEach((s: string) => this.state_subscriptions_dic[s].unsubscribe());
        this.state_subscriptions_dic = {};
      }
    }
  }

  protected get state_subscribed(): boolean {
    return (this.state_subscriptions && this.state_subscriptions.length > 0) ||
      (this.state_subscriptions_dic && Object.keys(this.state_subscriptions_dic).length > 0)
  }
}
