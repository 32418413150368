import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { Router } from '@angular/router';
import { PlatformService } from '../platform-services';
import { LocalStorage } from '../helpers';

@Injectable()
export class AuthErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.error.error == "password_expired") {
            this.router.navigate(['update_password']);
            return null;
          }

          if (error.error.error == "revoked token"){
            LocalStorage.remove('currentUser');
		        LocalStorage.remove('auth_token');
            PlatformService.cleanUpCookies();
            this.router.navigate(['login']);
            return null;
          }

          return throwError(error);
        })
    );
  }
}
