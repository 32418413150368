import { NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import { DeviceInfo } from './device-info.service'
import { NotificationService } from './notification.service';
import { Injectable, Inject  } from '@angular/core';
import * as _ from 'lodash';
import { ChannelsService } from './channels.service';
import { Printer, IShareable } from '../models';
import * as countriesData from './countries_states_cities.json';

@Injectable({
  providedIn: 'root'
})
export class PlatformService extends ChannelsService {
  public scannedText: Subject<string> = new Subject<string>();
  public scannedTextFiltred: Subject<{text: string, filter: string[]}> = new Subject<{text: string, filter: string[]}>();
  protected scannerList: Subject<string[]> = new Subject<string[]>();

  constructor(@Inject({}) env: any,
              ng_zone: NgZone,
              protected notificationService: NotificationService){
    super(env,ng_zone);
  }

  get isAndroid(): boolean {
    return DeviceInfo.isAndroid;
  }

  get isTablet() {
    return DeviceInfo.isTablet;
  }

  get isPhone() {
    return DeviceInfo.isPhone;
  }

  get lang(): string {
    return DeviceInfo.lang;
  }

  get haveScanBroadcaster(): boolean {
    return DeviceInfo.haveScanBroadcaster;
  }

  get registerBroadcastReceiver() {
    return null;
  }

  get serverUrl(): string {
    return this.api_server + "/";
  }

  get hostname(): string {
    return /^\w+:\/\/(?<hostname>((?!:).)+)(:\d+)?$/.exec(this.api_server).groups["hostname"];
  }

  setupPrinter() {
  }

  setState(action: 'START_SCANNING' | 'STOP_SCANNING' | 'TOGGLE_SCANNING' = 'START_SCANNING') {

  }

  requestAvailableScanners() {

  }

  switchToProfile(profile?: string) {

  }

  private getProfiles() {

  }

  shareLink(share: IShareable) {
  }

  getShareTmpPath(share: IShareable) {
    return null;
  }

  cleanUpCookies() {
    PlatformService.cleanUpCookies();
  }

  static cleanUpCookies() {
    var cookies = document.cookie.split(";");

    for (var i = 0; i < cookies.length; i++) {
      var cookie = cookies[i];
      var eqPos = cookie.indexOf("=");
      var name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
    }
  }

  static getLocationsCatalog(): any[] {
    return countriesData as unknown as any[];
  }

  sendToPrinter(data: string[]) {
    if (data.length > 0) {
      const zpl_str = data.shift();
      window.postMessage({
        type: "zebra_print_label",
        zpl: zpl_str,
        url: Printer.SELECTED.http_url_post
      }, "*");
      setTimeout(() => this.sendToPrinter(data), 1000);
    }
  }
}
