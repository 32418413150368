import { Injectable, NgZone, OnInit } from "@angular/core";
import { Store } from "@ngxs/store";
import { Observable } from "rxjs";
import { AuthenticationService, ChannelsService, Client, NotificationService, Shipment, Ticket, User } from "wms-lib";
import * as actions from 'wms-lib';

@Injectable()
export class NotificationsBaseComponent implements OnInit {
    protected currentUser$: Observable<User>;

    constructor(protected store: Store,
                protected authenticationService: AuthenticationService,
                protected ng_zone: NgZone,
                protected notificationService: NotificationService,
                protected channels: ChannelsService) {}

    ngOnInit() {
        this.currentUser$ = this.authenticationService.currentUser;
        this.currentUser$.subscribe(user => {
            if (user) {
                this.channels.openUserClientNotificationChannels(user,(data: any) => {
                    if (data['controller'] === 'tickets') 
                        this.handleTicketNotification(data);

                    if (user.roles.includes("materialist") || user.roles.includes("client"))
                        this.handleShipmentNotification(new Shipment(data), user);
                });
            } else 
                this.channels.closeChannelsByCriteria('ticket_notifications_client_');
        });
    }

    protected onTapPushNotification(callback: (data:any)=> void) {
        this.notificationService.onPressNotificationCallback((data) => {
            this.ng_zone.run(() => {
                callback(data);
            });
        })
    }

    private handleTicketNotification(data:any) {
        const ticket = new Ticket(data['data']);
        const action = data['action'] === 'create' ? 'Nuevo Ticket' : 'Ticket actualizado';
        const body = `(${ticket.client.name}) Ticket # ${ticket.id} - ${ticket.title}`;

        if (data['user_id'] !== this.authenticationService.currentUserValue.id){
            this.notificationService.pushNotification({
                id: new Date().getTime(),
                ticket_id: ticket.id,
                title: action,
                controller: data['controller'],
                actions: data['action'],
                body: body,
                at: new Date(new Date().getTime() + 5 * 1000)
            });
        }
        this.store.dispatch(new actions.SetTicketUpdate(ticket));
    }


    private handleShipmentNotification(shipment: Shipment, user: User)  {
        let client_name = (Client.CLIENTS[shipment.client_id] || {name: 'NA'}).name;
        if (((user.roles.includes("materialist") && !shipment.shipped_id) ||
            (user.roles.includes("client") && shipment.shipped_id)) &&
            user.clients.find((c: Client) => c.id == shipment.client_id))
            this.notificationService.pushNotification({
                id: new Date().getTime(),
                shipment_id: shipment.id,
                title: shipment.shipped_id ? `Shipment completed # ${shipment.id}` : `New shipment order # ${shipment.id}`,
                controller: 'shipments',
                body: `Shipment created by ${client_name}`,
                at: new Date(new Date().getTime() + 5 * 1000)
            });

        this.store.dispatch(new actions.ReloadShipmentsPageAction(shipment.client_id));
    }

}
