import { Component, OnInit, NgZone, AfterViewInit, Input, ElementRef, HostBinding } from '@angular/core';
import { Store } from '@ngxs/store';
import { NotificationService, CodebarReaderService, ModalDialogService, Package, AuthenticationService, PartNumber } from 'wms-lib';
import { PackageComponent } from '../../../../shared/components/base-components/package.component'
import { CanTakeDamageReport } from '../../../../shared/mixins/can-take-picture'
import { Router } from '@angular/router';
import * as store_actions from 'wms-lib';
import * as _ from 'lodash';

@Component({
  selector: 'tr[app-search-package]',
  templateUrl: './search-package.component.html',
  styleUrls: ['../../../../shared/components/base-components/package.component.scss']
})
export class SearchPackageComponent extends PackageComponent implements OnInit, CanTakeDamageReport, AfterViewInit {
  @Input() showReceipt: boolean = false;
  @Input() isSelected: boolean = false;
  constructor(
    protected store: Store,
    public notification_service: NotificationService,
    protected codebar_service: CodebarReaderService,
    protected ng_zone: NgZone,
    protected modal_service: ModalDialogService,
    protected _router: Router,
    protected authenticationService: AuthenticationService,
    elementRef: ElementRef
  ) {
    super(store,notification_service,codebar_service,ng_zone,modal_service,_router, authenticationService);
    this.el = elementRef;
  }

  @HostBinding('style.background')
  get status(): string {
    if(this.isSelected){
      return '#d1fbeb';
    } else if(!!this.package.shipment){
      return '#FEDA00';
    } else {
      return null;
    }
  }

  get selected(): boolean {
	  return this.isSelected;
  }

  set selected(value: boolean) {
    super.selected = value;
  }

  @Input()
  set package(p: Package) {
    this._package = p;
  }

  get package(): Package {
    return this._package;
  }

  get part_number(): PartNumber {
  	  return this.package.part_number_id ? this.package.part_number || _.get(this.part_numbers, [this.active_client.id,this.package.part_number_id], null) : null;
  }

  navigate() {
    this._router.navigate(['/receivings', 'ParcelArrival', 'edit', this.package.arrival_id]);
  }

  navigateReceipt(pack: Package) {
    this.store.dispatch(new store_actions.SetWorkingPackingAction({ packing_id:  pack.arrive_packing_list.id, attach_doc: false }));
    this._router.navigate(['/receivings', 'ParcelArrival', 'edit', pack.arrival_id]);
  }
}
