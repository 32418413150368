import { Component, OnInit, NgZone } from '@angular/core';
import { PackingListsComponent } from '../../../shared/components/base-components/packing-lists.component';
import { Store } from '@ngxs/store';
import { CodebarReaderService, AuthenticationService } from 'wms-lib';
import * as _ from 'lodash';

@Component({
    selector: 'app-inventory-packing-lists',
    templateUrl: './inventory-packing-lists.component.html',
    styleUrls: ['../../../shared/components/base-components/packing-lists.component.scss']
})
export class InventoryPackingListsComponent extends PackingListsComponent implements OnInit {
    constructor(store: Store,codebar_service: CodebarReaderService,ng_zone: NgZone,
                authenticationService: AuthenticationService) {
        super(store, codebar_service, ng_zone, authenticationService);
    }
}

