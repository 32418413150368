import { Component} from '@angular/core';
import { Store } from '@ngxs/store';
import { TicketCommentsBaseComponent } from './ticket-comments.component.base';

@Component({
  selector: 'app-ticket-comments',
  templateUrl: './ticket-comments.component.html',
  styleUrls: ['./ticket-comments.component.scss']
})
export class TicketCommentsComponent extends TicketCommentsBaseComponent {

  constructor(store: Store) { 
    super(store);
  }

}
