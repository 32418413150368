import { Directive, HostListener, ElementRef, Input  } from '@angular/core';
import { CodebarReaderService, DD_TYPES } from 'wms-lib';

@Directive({
  selector: '[appScanner]'
})
export class ScannerDirective {

  @Input() except: string[];
  @Input() only: string[];

  constructor(private el: ElementRef, private codebar_service: CodebarReaderService) {
  }

  @HostListener('keydown.enter') onEnter() {
    let val = this.el.nativeElement.value;
    if (this.except)
      this.codebar_service.scannedTextFiltred.next({text: val, filter: this.except});
    else if (this.only)
      this.codebar_service.scannedTextFiltred.next({text: val, filter: Object.keys(DD_TYPES).filter((k: string) => !this.only.includes(k))});
    else
      this.codebar_service.scannedText.next(val);
    global.log("Component",`Code scan: ${val}`)
    this.el.nativeElement.value = "";
  }
}
