import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CatalogsService, ISearchResult, Carrier, Arrival, RemoteAsset, AuthenticationService, Package, Client, Unit, PartNumber } from 'wms-lib';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import * as store_actions from 'wms-lib';
import * as _ from 'lodash';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  query: string;
  result: ISearchResult;
  carriers$: Observable<{ [id: number]: Carrier }> = new Observable<{ [id: number]: Carrier }>();
  show_img: RemoteAsset;
  active_client: Client;
  clients: {[id: number]: Client};
  units: {[id: number]: Unit};

  constructor(
    private activated_route: ActivatedRoute,
    private store: Store,
    private searchService: CatalogsService,
    protected authenticationService : AuthenticationService) { }

  ngOnInit() {
    this.activated_route.params.subscribe((params: any) => {
      this.query = params.query;
      this.searchService.search(this.query).subscribe((result: ISearchResult) => {
        this.result = result;
        if (result && result.package)
          this.store.dispatch(new store_actions.SetWorkingPackageAction({ package: result.package }));
        if (result && result.part_numbers) {
          this.store.select(state => state.catalogsState.units).subscribe(data => this.units = data);
          this.store.dispatch(new store_actions.GetCatalogAction<Client>({name_catalog: 'clients', live: false}));
          this.store.select((state) => state.catalogsState['clients']).subscribe((clients: {[id: number]: Client}) => this.clients = clients);
        }
      });
    });
    this.carriers$ = this.store.select(state => state.catalogsState.carriers);
    this.store.select(state => state.catalogsState.active_client).subscribe(client => {
      this.active_client = client;
    } );

  }

  getIcon(carriers: {[id: number]: Carrier}, a: Arrival): string {
    let def = 'font://' + String.fromCharCode(0xf513);
    return this.getCarrierIcon(carriers,a) || def;
  }

  getCarrierIcon(carriers: {[id: number]: Carrier}, a: Arrival): string{
    return (carriers && carriers[a.carrier_id] && carriers[a.carrier_id].logo_url) || null;
  }

  getCarrierName(carriers: {[id: number]: Carrier}, a: Arrival): string{
    return (carriers && carriers[a.carrier_id] && carriers[a.carrier_id].name) || null;
  }

  resultEmpty(result: ISearchResult) {
    return !(result.package || result.arrival || result.location || result.shipment || result.pallet || result.part_numbers || result.delivery_order);
  }

  somePackageisFreight(packages: Package[]): boolean {
    return _.some(packages, ['isFreight', true]);
  }

  get canViewHistory(): boolean {
    return this.authenticationService.currentUserValue.isSupervisor
  }

  get wide(): boolean {
    return (this.result && this.result.part_numbers && this.active_client && !!this.result.part_numbers[this.active_client.id]) ||
      (this.result && !!this.result.delivery_order)
  }

  get part(): PartNumber {
    return this.result && this.result.part_numbers && this.active_client && this.result.part_numbers[this.active_client.id];
  }
}
