import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { PackingsFilter, GoodCategory, ArrivePackingListService } from 'wms-lib';
import { combineLatest } from 'rxjs';
import * as _ from 'lodash';

@Component({
  selector: 'app-packings-on-hand',
  templateUrl: './packings-on-hand.component.html',
  styleUrls: ['./packings-on-hand.component.scss']
})
export class PackingsOnHandComponent implements OnInit {
  filter: PackingsFilter = new PackingsFilter();
  good_categories: {[id: number]: GoodCategory };
  src: string;
  loading: boolean = false;
  totalRecords: number;

  constructor(private store: Store, private arrivePackingListService: ArrivePackingListService) {
    this.filter.on_hand = true;
  }

  ngOnInit() {
    combineLatest(
      this.store.select(state => state.catalogsState.good_categories),
      this.store.select((state) => state.catalogsState.active_client)
    ).subscribe(([good_categories, client]) => {
      this.good_categories = _(good_categories).filter((f: GoodCategory) => (client && f.client_id == client.id) || f.client_id == null).keyBy("id").value();
      this.filter.client_id = client.id;
    });
  }

  view() {
    if (this.src) {
      URL.revokeObjectURL(this.src);
      this.src = null;
    }
    this.loading = true;
    this.arrivePackingListService.getPackings(this.filter, "pdf").subscribe((result: any) => {
      if (result && result.pls) {
        this.src = URL.createObjectURL(result.pls);
        this.totalRecords = result.totalRecords;
        this.loading = false;
      }
    });
  }

  paginate(event: any) {
    this.filter.page = event.page + 1;
    this.view();
  }
}
