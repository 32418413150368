import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngxs/store';
import { Carrier, DeliveryOrder, ExposService, Forward } from 'wms-lib';
import * as _ from 'lodash';
import * as actions from 'wms-lib';
import { MenuItem } from 'primeng/api';
import { DeliveryOrderAttachmentsComponent } from '../../../../shared/components/base-components/delivery-order-attachments.component';

@Component({
  selector: 'tr[app-delivery-row]',
  templateUrl: './delivery-row.component.html',
  styleUrls: ['./delivery-row.component.scss']
})
export class DeliveryRowComponent extends DeliveryOrderAttachmentsComponent implements OnInit {
  @Input() delivery_order: DeliveryOrder;
  @Input() priorities: { [id: number]: Carrier };
  @Input() carriers: { [id: number]: Carrier };
  @Input() forwards: { [id: number]: Forward };
  @Input() readOnly: boolean = false;
  @Input() editing: boolean = false;
  @Input() status: string = '';
  @Input() working_delivery_id: number;
  @Output() onSave: EventEmitter<DeliveryOrder> = new EventEmitter<DeliveryOrder>();
  @Output() onShowImage: EventEmitter<DeliveryOrder> = new EventEmitter<DeliveryOrder>();

  editedDeliveries: { [id: number]: DeliveryOrder } = {};
  deliveryOptions?: DeliveryOrder;
  menus: MenuItem[] = [];


  constructor(protected store: Store, private exposService: ExposService) { super(); }

  ngOnInit() {
  }

  onRowEditInit(delivery: DeliveryOrder) {
    this.editedDeliveries[delivery.id] = _.cloneDeep(delivery);
  }

  onRowEditSave(delivery: DeliveryOrder) {
    let deli = _.cloneDeep(this.editedDeliveries[delivery.id]);
    this.onSave.emit(deli);
    this.save(deli);
    delete this.editedDeliveries[delivery.id];
  }

  onRowEditCancel(delivery: DeliveryOrder) {
    delete this.editedDeliveries[delivery.id];
  }

  save(delivery: DeliveryOrder) {
    this.store.dispatch(new actions.SaveDeliveryOrderAction(delivery));
  }

  printPop(delivery: DeliveryOrder) {
    this.exposService.getDeliveryOrderPop(delivery.id).subscribe((data: any) => {
      let url = URL.createObjectURL(data);
      window.open(url, '_blank');
    });
  }

  canPrintPop(delivery: DeliveryOrder) {
    return !!delivery.carrier_id && !!this.pickCarrier(delivery.carrier_id)?.pop_url;
  }

  pickCarrier(carrier_id: number): Carrier {
    return this.carriers[carrier_id];
  }

  openOptions(event: any, tiered: any, delivery: DeliveryOrder) {
    this.deliveryOptions = delivery;
    this.menus = [
      {
        label: 'View Invoice',
        icon: 'pi pi-fw pi-file-pdf',
        disabled: !this.deliveryOptions.invoice_url,
        command: () => {
          this.viewFiles(this.deliveryOptions.invoice_url);
        }
      }, {
        label: 'View ITN',
        icon: 'pi pi-fw pi-file-pdf',
        disabled: !this.deliveryOptions.itn_url,
        command: () => {
          this.viewFiles(this.deliveryOptions.itn_url);
        }
      }, {
        label: 'View DGD',
        icon: 'pi pi-fw pi-file-pdf',
        disabled: !this.deliveryOptions.dgd_url,
        command: () => {
          this.viewFiles(this.deliveryOptions.dgd_url);
        }
      }, {
        label: 'View TRK',
        icon: 'pi pi-fw pi-file-pdf',
        disabled: !this.deliveryOptions.shipping_url,
        command: () => {
          this.viewFiles(this.deliveryOptions.shipping_url);
        }
      }
    ];

    if(this.deliveryOptions.status !== 'ready'){
      this.menus.push({
        label: 'View Images',
        icon: 'pi pi-fw pi-image',
        disabled: this.deliveryOptions.images_count == 0,
        command: () => {
          this.onShowImage.emit(this.deliveryOptions);
        }
      });
    }

    tiered.toggle(event);
  }

  viewFiles(url: string) {
    window.open(url, '_blank');
  }

  pickForwardByAddress(address_id: number): Forward {
    return _.find(this.forwards, (forward: Forward) => forward.address_id == address_id);
  }

  toggle(delivery: DeliveryOrder) {
    this.store.dispatch(new actions.SetWorkingDeliveryOrderAction({ id: delivery.id }));
  }
}
