import { Bindable, IShareable } from "./common";
import { AplListDetail } from "./apl-list-detail";

export class AplList extends Bindable implements IShareable {
  constructor(data: any) {
    super(data);
    if (data.apl_list_details) this.apl_list_details = data.apl_list_details.map((d: any) => new AplListDetail(d));
  }
  title: string;
  apl_list_details: AplListDetail[]

  get share_html(): string {
    return `To view the receipts list flow the link <a href="#server_url#apl_lists/${this.id}.csv">${this.subject}</a>`
  }

  get share_text(): string {
    return `To view the receipts list ${this.title} download the list from the link:\n\n #server_url#apl_lists/${this.id}.csv`
  }

  get subject(): string {
    return `List ${this.title}`
  }

  get filename(): string {
    return (this.title || '') == '' ? `Receipts list.csv` : `Receipts list ${this.title}.csv`
  }

  get as_csv(): string {
    return this.apl_list_details.
      map((d: AplListDetail) => `"${d.receipt_id}";"${d.client_name}";"${d.carrier_name}";"${d.good_type_name}"`).
      join("\n");
  }
}
