import { Injectable, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthenticationService, Client, SetActiveClientAction, User, LocalStorage, SettingsEnum } from 'wms-lib';
import { StateListenerComponent } from '../scan-listener/state-listener.component';
import * as _ from 'lodash';

@Injectable()
export class ClientChangerBaseComponent extends StateListenerComponent implements OnInit {

    raw_clients: Client[] = [];
    selected_client: Client;
    current_user: User;

    constructor(protected store: Store, 
                protected authService: AuthenticationService) {
        super();
    }

    get clients(): {[id:number]:Client} {
        return _.keyBy(this.raw_clients,'id');
    }


    get client_name() {
        if (this.selected_client) return this.selected_client.name;
        return "";
    }

    ngOnInit() {
        this.addSub(this.authService.currentUser.subscribe(user => {
            if (user) this.current_user = user;
            if (user && user.clients) {
                this.raw_clients = _.filter(user.clients, c => c.active);
                let client_id = +LocalStorage.getString(SettingsEnum.CLIENT);
                if (client_id)
                    this.selected_client = this.raw_clients.find(f => f.id == client_id);
                this.selected_client = this.selected_client || this.raw_clients[0];
                this.store.dispatch(new SetActiveClientAction({client: this.selected_client}));
            }
        }));

        this.addSub(this.store.select(state => state.catalogsState.active_client).subscribe(client => {
            if (client) {
                this.selected_client = this.clients[client.id];
                LocalStorage.saveString(SettingsEnum.CLIENT, ''+client.id);
            }
        }));
    }

    

    

}
