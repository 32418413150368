import { Bindable } from "./common";
import { BehaviorSubject } from "rxjs";

export class RemoteAsset extends Bindable {
    src$: BehaviorSubject<string | object> = new BehaviorSubject<string | object>(null);
    variant$: BehaviorSubject<string | object> = new BehaviorSubject<string | object>(null);
    
    public src: string | object;
    public filename: string;
    public base64: string;
    public stored: boolean;
    public variant: string | object;
    public _destroy: boolean = false;

    constructor(data?: any) {
        super(data);
        if (this.src) this.src$.next(this.src);
        if (this.variant) this.variant$.next(this.variant);
        this.id = this.id || (+new Date())*-1;
    }

    get saveProp(): string[] {
        if (this._destroy)
            return ["id", "_destroy"]
        else {
            let p = super.saveProp;
            if (!this.stored)
                p.push("base64", "filename");
            return p;
        }
    }

    get source(): string {
        return <string>this.src;
    }
}
