import { NotificationViewModel } from "../../models/notification-view-model";
import { INotificationsState } from "../states";

export class AddNotificationAction {
    public static readonly type = '[Notifications] Add notification';
    constructor(public payload: NotificationViewModel) { }
}

export class DismissNotificationAction {
    public static readonly type = '[Notifications] Remove notification';
    constructor(public payload: NotificationViewModel) { }
}

export class ResetModifiedNotificationsFieldAction {
    public static readonly type = '[Notifications] reset modified field for notifications state';
}

export class RestoreNotificationAction {
    public static readonly type = '[Notifications] Restore notifications';
    constructor(public payload: INotificationsState) { }
}

export class ClearNotificationsStateAction {
    public static readonly type = '[Notifications] Clear notifications state';
}

export type notificationActions = AddNotificationAction |
                                  DismissNotificationAction |
                                  ResetModifiedNotificationsFieldAction |
                                  RestoreNotificationAction |
                                  ClearNotificationsStateAction;