import { Component } from '@angular/core';
import { Catalog } from 'wms-lib';
import { CatalogComponent } from '../../base-components/catalog.component';

@Component({
    selector: 'app-damage-types',
    templateUrl: './damage-types.component.html',
    styleUrls: ['./damage-types.component.sass']
})
export class DamageTypesComponent extends CatalogComponent<Catalog> {
    name_catalog = 'damage_types';


    addNew(_type?: string) {
        let c = this.newItem({name_es: '', name_en: '', type: _type, notify: false});
        this.items[c.id] = c;
        this.items = {...this.items};
    }

    newItem(data: any) {
        return new Catalog(data);
    }
}

