import { Injectable, Inject, NgZone } from '@angular/core';
import { GenericService } from './generic.service';
import { Package } from '../models';
import * as _ from 'lodash';
import { HttpClient } from "@angular/common/http";
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { NotificationService } from '../platform-services/notification.service';

const PACKAGES_PATH = 'packages';

@Injectable({
    providedIn: 'root'
})
export class PackagesService extends GenericService {

    constructor(@Inject('env') env: any, http: HttpClient, ng_zone: NgZone,notificationService: NotificationService) {
        super(env, http, ng_zone,notificationService);
    }

    find(id: number): Observable<Package> {
        return this.http.get<any>(this.serverUrl + PACKAGES_PATH + `/${id}`, this.httpOptions).pipe(
            map((data: any) => new Package(data)),
            catchError(this.handleError<Package>(' getting package by trk', new Package({id: id})))
        );
    }

    update(pkg: Package, props?: string[]): Observable<Package> {
        return this.http.put(this.serverUrl + PACKAGES_PATH + `/${pkg.id}`, pkg.toSaveData(props), this.httpOptions).pipe(
            map(() => {
            	if(pkg.tag_location) pkg.tag_location = null;
            	if(pkg.verify_tag_location) pkg.verify_tag_location = false;
            	if(pkg.errors && pkg.errors['tag_location']) delete pkg.errors['tag_location']
            	return pkg;
            }),
            catchError(this.handleError<Package>(' Updating package', pkg, true))
        );
    }
}
