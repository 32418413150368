import { Directive, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';

import { Package, Damage, CatalogsState, CodebarReaderService, Arrival } from 'wms-lib';
import { Observable, of, combineLatest } from 'rxjs';
import { StateListenerComponent } from '../scan-listener/state-listener.component';
import { map } from 'rxjs/operators';

@Directive({ selector: '[damagesBase]' })
export abstract class DamagesBaseComponent extends StateListenerComponent implements OnInit {

    damage_report_metadata: any;
    takeDamageImage: () => Observable<Damage>;
    selected_damages: Damage[] = [];
    damage_types$: Observable<Map<number, string>> = of(new Map([]));
    unsavedData: boolean = false;
    
    
    constructor(
        protected _route: ActivatedRoute,
        protected store: Store,
        protected codebar_service: CodebarReaderService
    ) {
        super();
        this.damage_report_metadata = JSON.parse(
            JSON.stringify(require('../../../models/damage-report.json'))
        );
    }

    ngOnInit() {
        this.damage_types$ = this.store.select(CatalogsState.getMapCatalog('damage_types'));
    }

    abstract takeDamage();
    abstract updateDamageReport();
    abstract updateDamageReport();

    deleteDamage() {
        this.selected_damages.forEach((d: Damage) => d._destroy = true);
        this.updateDamageReport();
        this.selected_damages = [];
    }



    damageTap(damage: Damage) {
        this.toggleSelectedDamage(damage);
    }

    toggleSelectedDamage(damage: Damage) {
        let sd = this.selected(damage);
        if (sd)
            this.selected_damages = this.selected_damages.filter((d: Damage) => d.id != damage.id);
        else
            this.selected_damages.push(damage);
    }

    selected(damage: Damage): Damage {
        return this.selected_damages.find((d: Damage) => d.id == damage.id);
    }
}
