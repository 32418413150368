import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { GetPackingsAction, PartNumber, Client, Shipment, Package, AuthenticationService, PackingsFilter, OnStockStatus } from 'wms-lib';
import { Store } from '@ngxs/store';
import * as actions from 'wms-lib';
import * as _ from 'lodash';
import { StateListenerComponent } from '../../../../shared/components/scan-listener/state-listener.component';

@Component({
  selector: 'app-receipts-by-part',
  templateUrl: './receipts-by-part.component.html',
  styleUrls: ['./receipts-by-part.component.sass']
})
export class ReceiptsByPartComponent extends StateListenerComponent implements OnInit, OnChanges {
    @Input() inventory: PartNumber[];
    @Input() shipment: Shipment = new Shipment({packages: {}, id: 0});
    @Input() filter: PackingsFilter;
    effective_inventory: PartNumber[];

    constructor(private store: Store, private authenticationService : AuthenticationService) {
        super();
    };

    ngOnInit(): void {
        this.addSub(this.store.select((state) => state.catalogsState.active_client).subscribe((c: Client) => {
            if (c) {
                let filter = new PackingsFilter();
                filter.client_id = c.id;
                filter.with_pn = true;
                this.store.dispatch(new GetPackingsAction(filter));
            }
        }));
    }

    selected(p: Package): boolean {
        return this.shipment && !!this.shipment.packages[p.id];
    }

    selectedAll(p: PartNumber): boolean {
        return !_(p.packages).filter((p: Package)=>p.inStock && this.packageFilter(p)).find((p: Package) => !this.selected(p));
    }

    get isAdmin(): boolean {
        return this.authenticationService.currentUserValue.isAdmin;
    }

    addOrDeletePackageFromShipment(p: Package) {
        this.store.dispatch(new actions.AddPartToShipmentAction(p));
    }

    addOrDeletePackagesFromPartNumber(p: PartNumber) {
        let selectedAll = this.selectedAll(p); 
        p.packages.filter((p)=> p.inStock && this.packageFilter(p)).forEach((p: Package) => {
            if(!selectedAll  && !this.selected(p) || selectedAll && this.selected(p)) 
                this.addOrDeletePackageFromShipment(p);
        });
    }

    get editing(): boolean {
        return this.shipment && !!this.shipment.id;
    }

    applyFilter(): PartNumber[]{
        return (this.inventory || []).
            filter((p: PartNumber) =>  this.applyFilterByPartNumber(p));
    }

    applyFilterByPartNumber(p: PartNumber) {
        return !this.filterByPartNumber || this.filter.part_number.id == p.id;
    }

    packageFilter(p: Package): boolean {
		return this.shipment?.id == p.shipment_id || !!p.canOrderToShipment;
	}

    applyPackagesFilter(pkgs: Package[]): Package[] {
        let result = pkgs.filter((p: Package) => this.packageFilter(p));
        if (this.filterByQty) {
            let ids = this.filter.qtyToIds(pkgs);
            return result.filter((p: Package) => ids.includes(p.id))
        }
        return result;
    }

    packageFilterQuantity(p: Package[]): number {
    	let pkgs = this.applyPackagesFilter(p);
    	return _.sumBy(pkgs, (p: Package) => p.quantity);
    }

    private get filterByPartNumber(): boolean {
        return this.filter && !!this.filter.part_number;
    }

    private get filterByQty(): boolean {
        return this.filterByPartNumber && !!this.filter.quantity;
    }

    ngOnChanges(): void {
        this.effective_inventory = this.applyFilter();
    }
}
