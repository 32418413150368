import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { StateListenerComponent } from '../../shared/components/scan-listener/state-listener.component';
import { DismissNotificationAction, LocalStorage, NotificationViewModel, ResetModifiedNotificationsFieldAction, RestoreNotificationAction, AuthenticationService, Client, Warehouse } from 'wms-lib';
import { AppMainComponent } from '../../app.main.component';
import * as _ from 'lodash';
import { Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
	selector: 'app-topbar',
	templateUrl: './topbar.component.html',
	styleUrls: ['./topbar.component.scss']
})
export class TopbarComponent extends StateListenerComponent implements OnInit {
	public ntfs: NotificationViewModel[] = [];
	active_client: Client;
	public _reports: any[] = [];
	constructor(public app: AppMainComponent,
		private store: Store,
		private router: Router,
		private sanitizer: DomSanitizer,
		private authenticationService: AuthenticationService) {
		super();
	}

	ngOnInit() {
		this.ntfs = [];
		this.store.select(state => state.catalogsState.active_client).subscribe(client => {
			this.active_client = client;
			this.setReports();
		});
		this.addSub(
			this.store.select((state) => state.notificationsState).subscribe((nsState) => {
				if (nsState['modified'] && nsState.modified === true) {
					LocalStorage.saveString('local-notifications', JSON.stringify(nsState));
					this.store.dispatch(new ResetModifiedNotificationsFieldAction());
				} else if (nsState['notifications'] && _.size(nsState.notifications) > 0) {
					this.ntfs = _(nsState.notifications)
						.values()
						.flatten()
						.map((e) => _(e).values().value())
						.flatten()
						.value();
				} else {
					this.loadNotificationsFromLocalStorage();
				}
			})
		);
	}

	private loadNotificationsFromLocalStorage() {
		const state = JSON.parse(LocalStorage.getString('local-notifications'));
		if (state && state['notifications']) {
			this.store.dispatch(new RestoreNotificationAction(state));
		}
	}

	selectNotification(event: any) {
		switch (event.data.controller) {
			case 'tickets': {
				this.router.navigate(['/help-desk', 'tickets'], { queryParams: { ticket_id: event.data.ticket_id } });
				this.store.dispatch(new DismissNotificationAction(event.data));
				break;
			}
		}
	}

	get reports(): any[] {
		return _.filter(this._reports, 'show');
	}

	get warehouse(): string {
		return Warehouse.SELECTED?.name;
	}

	get showWarehouse(): boolean {
		return this.authenticationService.currentUserValue.isInternal;
	}

	setReports() {
		this._reports = [
			{ 
				label: 'Administration', icon: 'fas fa-cogs', visible: this.authenticationService.currentUserValue.isAdmin, items: [
					{
						label: 'Users', visible: this.authenticationService.currentUserValue.isAdmin, command: (event) => {
							let item = {
								report: 'users_report',
								params: { client_id: null, "__parameterpage": false, "__title": `WMS - Users`, darth: this.authenticationService.currentUserValue.isSupervisor },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;")
							};
							this.app.onTopbarSubItemClick(event, item);
						}
					},
					{
						label: 'Client storage', visible: this.authenticationService.currentUserValue.isAdmin, command: (event) => {
							let item = {
								report: 'client_storage',
								params: { client_id: null, "__parameterpage": false, "__title": `WMS - Client storage`, darth: this.authenticationService.currentUserValue.isSupervisor },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;")
							};
							this.app.onTopbarSubItemClick(event, item);
						}
					}
				]
			}, {
				label: 'Receipts', icon: 'fas fa-receipt', visible: true, items: [
					{
						label: 'Receipts by Data Range', visible: true,
						command: (event) => {
							let item = {
								report: "receipts_by_data_range",
								params: { client_id: null, "__parameterpage": false, "__title": `WMS - Shipments history`, darth: this.authenticationService.currentUserValue.isSupervisor },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;")
							};
							this.app.onTopbarSubItemClick(event, item);
						}
					}, {
						label: 'Receipts Unprocessed', visible: this.authenticationService.currentUserValue.isSupervisor, command: (event) => {
							let item = {
								report: "unprocessed_receipts",
								params: { client_id: null, "__parameterpage": false, "__title": `WMS - Unprocessed Receipts`, darth: this.authenticationService.currentUserValue.isSupervisor },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;"),
							};
							this.app.onTopbarSubItemClick(event, item);
						}
					}, {
						label: 'Receipts by Data Range and Users', visible: this.authenticationService.currentUserValue.isSupervisor, command: (event) => {
							let item = {
								report: "receipts_by_data_range_and_user",
								params: { client_id: null, "__parameterpage": false, "__title": `WMS - Receipt by Data Range and Users`, darth: this.authenticationService.currentUserValue.isSupervisor },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;")
							};
							this.app.onTopbarSubItemClick(event, item);
						}
					}, {
						label: 'Receipts by Data Range and Part Number', visible: this.active_client?.part_number, command: (event) => {
							let item = {
								report: "receipts_by_data_range_and_part_number",
								params: { client_id: null, "__parameterpage": false, "__title": `WMS - Receipt by Data Range and Part Numbers`, darth: this.authenticationService.currentUserValue.isSupervisor },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;"),
							};
							this.app.onTopbarSubItemClick(event, item);
						}
					}, {
						label: 'Shipping-Receiving Aging', visible: true, command: (event) => {
							let item = {
								report: "receipt_ship",
								params: { client_id: null, "__parameterpage": false, "__title": `WMS - Shipping-Receiving Aging`, darth: this.authenticationService.currentUserValue.isSupervisor },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;"),
							};
							this.app.onTopbarSubItemClick(event, item);
						}
					}, {
						label: 'Shipped Receipt By Part Numbers', visible: this.active_client?.part_number, command: (event) => {
							let item = {
								report: "receipt_ship_by_part_number",
								params: { client_id: null, "__parameterpage": false, "__title": `WMS - Received and Shipped by Part Numbers`, darth: this.authenticationService.currentUserValue.isSupervisor },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;"),
							};
							this.app.onTopbarSubItemClick(event, item);
						}
					}, {
						label: 'Printed Labels', visible: true, command: (event) => {
							let item = {
								report: "printed_labels",
								params: { client_id: null, "__parameterpage": false, "__title": `WMS - Printed Labels`, darth: this.authenticationService.currentUserValue.isSupervisor },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;"),
							};
							this.app.onTopbarSubItemClick(event, item);
						}
					}, {
						label: 'KPI receipts', visible: this.authenticationService.currentUserValue.isSupervisor, command: (event) => {
							let item = {
								report: "kpi_receipts_report",
								params: { client_id: null, "__parameterpage": false, "__title": `WMS - KPI receipts`, darth: this.authenticationService.currentUserValue.isSupervisor },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;"),
							};
							this.app.onTopbarSubItemClick(event, item);
						}
					}
				]
			}, {
				label: 'Inventory', icon: 'fas fa-boxes', visible: true, items: [
					{
						label: 'On hand inventory', visible: true, command: (event) => {
							let item = {
								report: "on_hand",
								params: { client_id: null, "__parameterpage": false, "__title": `WMS - On hand`, darth: this.authenticationService.currentUserValue.isSupervisor },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;")
							};
							this.app.onTopbarSubItemClick(event, item);
						}
					}, {
						label: 'Receipts on hand', visible: true, command: (event) => {
							let item = {
								report: "on_hand_grouped",
								params: { client_id: null, "__parameterpage": false, "__title": `WMS - Receipts On hand`, darth: this.authenticationService.currentUserValue.isSupervisor },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;")
							};
							this.app.onTopbarSubItemClick(event, item);
						}
					}, {
						label: 'By Supplier', visible: this.authenticationService.currentUserValue.isAdmin, command: (event) => {
							let item = {
								report: "on_hand_grouped_by_supplier",
								params: { client_id: null, "__parameterpage": false, "__title": `WMS - Receipts On hand`, darth: this.authenticationService.currentUserValue.isSupervisor },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;"),
							};
							this.app.onTopbarSubItemClick(event, item);
						}
					}, {
						label: 'By Locations', visible: this.authenticationService.currentUserValue.isInternal, command: (event) => {
							let item = {
								report: "locations",
								params: { client_id: undefined, "__parameterpage": false, "__title": `WMS - Locations`, darth: this.authenticationService.currentUserValue.isSupervisor },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;")
							};
							this.app.onTopbarSubItemClick(event, item);
						}
					}, {
						label: 'By Part Numbers', visible: this.active_client?.part_number, command: (event) => {
							let item = {
								report: "part_number_report",
								params: { client_id: null, _client: true, "__parameterpage": false, "__title": `WMS - Part Numbers`, darth: this.authenticationService.currentUserValue.isSupervisor },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;")
							};
							this.app.onTopbarSubItemClick(event, item);
						}
					}, {
						label: 'By Part Numbers details', visible: this.active_client?.part_number, command: (event) => {
							let item = {
								report: "part_number_report_details",
								params: { client_id: null, _client: true, "__parameterpage": false, "__title": `WMS - Part Numbers - Details`, darth: this.authenticationService.currentUserValue.isSupervisor },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;")
							};
							this.app.onTopbarSubItemClick(event, item);
						}
					}, {
						label: 'Packings on hand', visible: true, routerLink: ['packings_on_hand']
					}
				]
			}, {
				label: 'Storage', icon: 'fas fa-pallet', visible: true, items: [
					{
						label: 'Storage Report', visible: this.authenticationService.currentUserValue.isAdmin, command: (event) => {
							let item = {
								report: "storage_report",
								params: { client_id: null, "__parameterpage": false, "__title": 'WMS - Storage Report', darth: this.authenticationService.currentUserValue.isSupervisor },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;")
							};
							this.app.onTopbarSubItemClick(event, item);
						}
					}, {
						label: 'Slow movement', visible: true, command: (event) => {
							let item = {
								report: "slow_movement",
								params: { client_id: null, "__parameterpage": false, "__title": `WMS - Slow Movement`, darth: this.authenticationService.currentUserValue.isSupervisor },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;")
							};
							this.app.onTopbarSubItemClick(event, item);
						}
					}
				]
			}, {
				label: 'Shipments', icon: 'fas fa-dolly', visible: true, items: [
					{
						label: 'Shipments', visible: true, command: (event) => {
							let item = {
								report: "shipments",
								params: { client_id: null, "__parameterpage": false, "__title": `WMS - Shipments`, darth: this.authenticationService.currentUserValue.isSupervisor },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;")
							};
							this.app.onTopbarSubItemClick(event, item);
						}
					},{
						label: 'Shipments grouped', visible: true, command: (event) => {
							let item = {
								report: "shipments_grouped",
								params: { client_id: null, "__parameterpage": false, "__title": `WMS - Shipments grouped`, darth: this.authenticationService.currentUserValue.isSupervisor },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;")
							};
							this.app.onTopbarSubItemClick(event, item);
						}
					}, {
						label: 'KPI Shipments Pending', visible: this.authenticationService.currentUserValue.isSupervisor, command: (event) => {
							let item = {
								report: "kpi_shipments_pendings_report",
								params: { client_id: null, "__parameterpage": false, "__title": `WMS - KPI Shipments Pending`, darth: this.authenticationService.currentUserValue.isSupervisor, user_id: this.authenticationService.currentUserValue.id },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;")
							};
							this.app.onTopbarSubItemClick(event, item);
						}
					}
				]
			}, {
				label: 'Services', icon: 'fas fa-headset', visible: true, items: [
					{
						label: 'Tickets by Warehouse', visible: true, command: (event) => {
							let item = {
								report: "tickets",
								params: { client_id: null, _client: false, "__parameterpage": false, "__title": `WMS - Tickets (Warehouse)`, darth: this.authenticationService.currentUserValue.isSupervisor },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;")
							};
							this.app.onTopbarSubItemClick(event, item);
						}
					}, {
						label: 'Tickets by Customers', visible: true, command: (event) => {
							let item = {
								report: "tickets",
								params: { client_id: null, _client: true, "__parameterpage": false, "__title": `WMS - Tickets (Customers)`, darth: this.authenticationService.currentUserValue.isSupervisor },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;")
							};
							this.app.onTopbarSubItemClick(event, item);
						}
					}
				]
			}, {
				label: 'Expos', icon: 'fas fa-share-square', visible: true, items: [
					{
						label: 'ASNS', visible: true, command: (event) => {
							let item = {
								report: "asn_report",
								params: { client_id: null, "__parameterpage": false, "__title": `WMS - ASNS`, darth: this.authenticationService.currentUserValue.isSupervisor },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;")
							};
							this.app.onTopbarSubItemClick(event, item);
						},
					}, {
						label: 'Delivery Orders', visible: true, command: (event) => {
							let item = {
								report: "asn_by_deliveries",
								params: { client_id: null, "__parameterpage": false, "__title": `WMS - Delivery Orders`, darth: this.authenticationService.currentUserValue.isSupervisor },
								url: this.sanitizer.bypassSecurityTrustResourceUrl("javascript:;")
							};
							this.app.onTopbarSubItemClick(event, item);
						},
					}
				]
			}
		];
	}
}
