import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import * as actions from 'wms-lib';
import { Observable } from "rxjs";
import { Tracking, TrackingDetail, NotificationService, User, AuthenticationService, CodeParserService } from 'wms-lib';
import { map } from 'rxjs/operators';
import * as XLSX from 'xlsx';
import * as _ from 'lodash';

@Component({
  selector: 'app-tracker',
  templateUrl: './tracker.component.html',
  styleUrls: ['./tracker.component.scss']
})
export class TrackerComponent implements OnInit {
  private readonly XLS_R = /(\w+)\n?/gm;

  @ViewChild('trk', { static: false }) trkElement: ElementRef;
  @ViewChild('dt', { static: false }) dt: ElementRef;
  trackings$: Observable<Tracking[]>;
  totalRecords: number = 0;
  private _expandedRows: {[s: string]: boolean} = {};
  private _keys: string[];
  saving: boolean = false;
  totalRecords$: Observable<number>;
  paginating: boolean = false;
  user$: Observable<User>;

  get expandedRows$(): Observable<{[s: string]: boolean}> {
    return this.trackings$.pipe(
      map((trks: Tracking[]) => {
        if (trks) {
          this._keys = this._keys || trks.map(m => m.id+'');
          let diff = trks.filter((f: Tracking) => !this._keys.includes(f.id+''));
          if (diff.length > 0) {
            this._keys = trks.map(m => m.id+'');
            this._expandedRows = {[diff[0].id]: true};
          }
        }
        return this._expandedRows;
      })
    );
  }

  constructor(private store: Store,
              private notificationService: NotificationService,
              private authenticationService: AuthenticationService,
              private codeParseService: CodeParserService
             ) {
    this.trackings$ = this.store.select((state) => state.catalogsState.trackings);
    this.totalRecords$ = this.store.select((state) => state.catalogsState.totalTrackings);
    this.trackings$.subscribe(() => {
      this.saving = false;
      this.paginating = false;
      this.setTrkFocus();
    });
    this.user$ = this.authenticationService.currentUser;
  }

  ngOnInit() {
    this.paginating = true;
    this.store.dispatch(new actions.GetTrackingsAction({page: 1, append: false}));
  }

  addNew() {
    this.store.dispatch(new actions.AddTrackingsAction());
    this.setTrkFocus();
  }

  delete(trk: Tracking) {
    if (trk.id <= 0)
      this.store.dispatch(new actions.DeleteTrackingAction(trk));
    else
      this.notificationService.showFeedback(
        'Are you sure you want to delete the item?',
        'Confirm',
        false,
        null,
        () => this.store.dispatch(new actions.DeleteTrackingAction(trk))
      );
  }

  save(trk: Tracking) {
    this.saving = true;
    if (trk.isValid){
      trk.validate_trks = false;
      this.store.dispatch(new actions.SaveTrackingAction(trk))
    }
  }

  addTrk(trk: string, tracking: Tracking) {
    this.trkElement.nativeElement.value = "";
    if (tracking.addTrk([trk], this.codeParseService.codes["simple"]))
      this.save(tracking);
    else
      this.notificationService.toast("The number does not have the correct format")
  }

  import(event: any, data: string, tracking: Tracking) {
    try {
      let data = event.clipboardData.getData('text');
      event.preventDefault();
      if (tracking.addTrk(data.match(this.XLS_R).map((s: string) =>
        s.replace(/\n/,'').
        replace("'","").
        replace(/\s+/g,"") ), this.codeParseService.codes["simple"]))
        this.save(tracking);
      else
        this.notificationService.toast("A numbers does not have the correct format")
    } catch {
      this.trkElement.nativeElement.value = "";
    }
    console.log(data);
  }

  deleteTrk(trk: Tracking, detail: TrackingDetail) {
    detail._destroy = true;
    this.saving = true;
    this.store.dispatch(new actions.SaveTrackingAction(trk));
  }

  lazyLoad(event: any) {
    let page = (event.first/event.rows)+1;
    this.paginating = true;
    this.store.dispatch(new actions.GetTrackingsAction({page: page, append: false}));
  }

  private setTrkFocus() {
    setTimeout(()=>{
      if (this.trkElement && this.trkElement.nativeElement)
        this.trkElement.nativeElement.focus();
    },0);
  }

  export(tracking: Tracking){
	  this.exportToCSV(tracking.tracking_details_to_export, tracking.filename);
  }

  exportToCSV(data: any[], filename: string): void {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(data);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
    XLSX.writeFile(wb, `${filename}`);
  }
}
