import { Injectable } from '@angular/core';
import { State, Action, StateContext } from '@ngxs/store';
import { InventoryService } from '../../services/inventory.service';
import * as actions from '../actions';
import * as _ from 'lodash';
import { ArrivePackingList, CyclicalInventory, Tag } from '../../models';
import { InventoryFilter } from '../../view-models';

export interface IInventoryState {
  inventory: {[id: string]: ArrivePackingList};
  cyclicals: {[id: string]: CyclicalInventory};
  working_cyclical: CyclicalInventory;
  working_location_tag: string;
  working_tag: Tag;
  filter: InventoryFilter;
  isLoading: { [action: string]: boolean };
  totalRecords: { [action: string]: number };
}

const defaultStateValue = {
  inventory: {},
  cyclicals: {},
  working_cyclical: null,
  working_location_tag: null,
  working_tag: null,
  filter: null,
  isLoading: {},
  totalRecords: {}
};
@State<IInventoryState>({
  name: 'inventoryState',
  defaults: {...defaultStateValue}
})
@Injectable()
export class InventoryState {

  constructor(private inventory_service: InventoryService) { }

  setStateIsLoading(ctx: StateContext<IInventoryState>, action: string, isLoading: boolean) {
  	  let loading = {...ctx.getState().isLoading};
  	  loading[action] = isLoading;
  	  ctx.patchState({isLoading: loading});
  }


  @Action(actions.GetLocationInventoryAction)
  getLocationInventory(ctx: StateContext<IInventoryState>, {payload}: actions.GetLocationInventoryAction) {
    global.log("State", "Getting location inventory");
    this.inventory_service.getLocationInventory(payload.location_id).subscribe(data => {
      ctx.patchState({inventory: data});
    });
  }

  @Action(actions.SetLocationDetailFilterAction)
  setLocationDetailFieldFilter(ctx: StateContext<IInventoryState>, {payload}: actions.SetLocationDetailFilterAction) {
    ctx.patchState({filter: payload});
  }

  @Action(actions.AssignLocationAction)
  assignLocationAction(ctx: StateContext<IInventoryState>, {payload}: actions.AssignLocationAction) {
    const inventory = {...ctx.getState().inventory};
    inventory[payload.pkg.arrive_packing_list_id].locatePackage(payload.pkg.id, payload.storageLocation);
    ctx.patchState({inventory: inventory});
  }

  @Action(actions.UnassignLocationAction)
  unassignLocationAction(ctx: StateContext<IInventoryState>, {payload}: actions.UnassignLocationAction) {
    const inventory = {...ctx.getState().inventory};
    inventory[payload.arrive_packing_list_id].unlocatePackage(payload.id);
    ctx.patchState({inventory: inventory});
  }

  @Action(actions.ClearInventoryStateAction)
  clearState(ctx: StateContext<IInventoryState>){
    global.log("State",`Clear inventory state`);
    ctx.setState({...defaultStateValue});
  }

  @Action(actions.GetCyclicalInventoriesAction)
  getCyclicalInventories(ctx: StateContext<IInventoryState>, {payload}: actions.GetCyclicalInventoriesAction) {
	global.log("State",`Get cyclical inventories`);
	this.setStateIsLoading(ctx, 'cyclical', true);
	this.inventory_service.getCyclicalInventories(payload.query).subscribe((response: {totalRecords: number, data: CyclicalInventory[]}) => {
		let totalRecords = {...ctx.getState().totalRecords};
		let cyclicals = _.keyBy(response.data, 'id');
		totalRecords['cyclical'] = response.totalRecords;
	  ctx.patchState({cyclicals: cyclicals, working_cyclical: null, working_location_tag: null, working_tag: null, totalRecords: totalRecords});
	  this.setStateIsLoading(ctx, 'cyclical', false);
	});
  }

  @Action(actions.SaveCyclicalInventoryAction)
  saveCyclicalInventory(ctx: StateContext<IInventoryState>, {payload}: actions.SaveCyclicalInventoryAction) {
	global.log("State",`Save cyclical inventory`);
	this.setStateIsLoading(ctx, 'cyclical', true);
	this.inventory_service.saveCyclicalInventory(payload).subscribe(data => {
		let cyclics = _.cloneDeep(ctx.getState().cyclicals);
		let working_cyclical = ctx.getState().working_cyclical;
		cyclics[data.id] = data;
		if (working_cyclical && working_cyclical.id == data.id) {
			working_cyclical = data;
		}
		ctx.patchState({cyclicals: cyclics, working_cyclical: working_cyclical, working_tag: null});
		this.setStateIsLoading(ctx, 'cyclical', false);
	});
  }

  @Action(actions.DeleteCyclicalInventoryAction)
  deleteCyclicalInventory(ctx: StateContext<IInventoryState>, {payload}: actions.DeleteCyclicalInventoryAction) {
  	  global.log("State",`Delete cyclical inventory`);
  	  this.setStateIsLoading(ctx, 'cyclical', true);
  	  this.inventory_service.deleteCyclicalInventory(payload.id).subscribe(data => {
  	  	  const cyclicals = {...ctx.getState().cyclicals};
  	  	  delete cyclicals[payload.id];
  	  	  ctx.patchState({cyclicals: cyclicals});
		  this.setStateIsLoading(ctx, 'cyclical', false);
	  });
  }

  @Action(actions.GetCyclicalInventoryTagsAction)
  getCyclicalInventoryTags(ctx: StateContext<IInventoryState>, {payload}: actions.GetCyclicalInventoryTagsAction) {
  	  global.log("State",`Get cyclical inventory tags for ${payload.id}`);
  	  this.setStateIsLoading(ctx, 'cyclical', true);
  	  this.inventory_service.getCyclicalInventoryTags(payload.id).subscribe(data => {
  	  	  const cyclicals = {...ctx.getState().cyclicals};
  	  	  _.assign(cyclicals[payload.id], {tags: data});
  	  	  ctx.patchState({cyclicals: cyclicals});
  	  	  this.setStateIsLoading(ctx, 'cyclical', false);
	  });
	}

	@Action(actions.LoadAndSetCycleInventoryAction)
	loadAndSetCycleInventory(ctx: StateContext<IInventoryState>, {payload}: actions.LoadAndSetCycleInventoryAction) {
		global.log("State",`Load and set cycle inventory for ${payload.id}`);
		this.inventory_service.loadCyclicalInventory(payload.id).subscribe(data => {
			ctx.patchState({working_cyclical: data, working_location_tag: null, working_tag: null});
		});
	}

	@Action(actions.SetCyclicalInventoryLocationAction)
	setCyclicalInventoryLocation(ctx: StateContext<IInventoryState>, {payload}: actions.SetCyclicalInventoryLocationAction) {
		global.log("State",`Set cyclical inventory location to ${payload.location_tag}`);
		ctx.patchState({working_location_tag: payload.location_tag});
	}

	@Action(actions.SetCyclicalInventoryTagAction)
	setCyclicalInventoryTag(ctx: StateContext<IInventoryState>, {payload}: actions.SetCyclicalInventoryTagAction) {
		global.log("State",`Set cyclical inventory tag`);
		ctx.patchState({working_tag: payload});
	}

	@Action(actions.ToggleActiveCyclicalInventoryAction)
	toggleActiveCyclicalInventory(ctx: StateContext<IInventoryState>, {payload}: actions.ToggleActiveCyclicalInventoryAction) {
		global.log("State",`Toggle active cyclical inventory`);
		this.inventory_service.toogleActiveCyclicalInventory(payload).subscribe(data => {
			const cyclicals = {...ctx.getState().cyclicals};
			cyclicals[data.id] = data;
			ctx.patchState({cyclicals: cyclicals, working_cyclical: data});
		});
	}

	@Action(actions.OpenCloseCyclicalInventoryAction)
	openCloseCyclicalInventory(ctx: StateContext<IInventoryState>, {payload}: actions.OpenCloseCyclicalInventoryAction) {
		global.log("State",`Open close cyclical inventory`);
		this.inventory_service.openCloseCyclicalInventory(payload.cyclical, payload.open).subscribe(data => {
			const cyclicals = {...ctx.getState().cyclicals};
			cyclicals[data.id] = data;
			ctx.patchState({cyclicals: cyclicals, working_cyclical: data});
		});
	}
}
