import { DeliveryOrder, ICatalogLogo } from 'wms-lib';
import { StateListenerComponent } from '../scan-listener/state-listener.component';

export abstract class DeliveryOrderAttachmentsComponent extends StateListenerComponent {
    attachInvoice(event: any, delivery_order: DeliveryOrder) {
        let reader = new FileReader();
        reader.onloadend = () => delivery_order.invoice_url = <string>reader.result;
        reader.readAsDataURL(event.srcElement.files[0]);
    }

    attachItn(event: any, delivery_order: DeliveryOrder) {
        let reader = new FileReader();
        reader.onloadend = () => delivery_order.itn_url = <string>reader.result;
        reader.readAsDataURL(event.srcElement.files[0]);
    }

    attachDgd(event: any, delivery_order: DeliveryOrder) {
        let reader = new FileReader();
        reader.onloadend = () => delivery_order.dgd_url = <string>reader.result;
        reader.readAsDataURL(event.srcElement.files[0]);
    }

    attachShipping(event, delivery_order: DeliveryOrder) {
        let reader = new FileReader();
        reader.onloadend = () => delivery_order.shipping_url = <string>reader.result;
        reader.readAsDataURL(event.srcElement.files[0]);
    }
}
