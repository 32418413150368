import { BindableBase } from "./common";


export class NotificationViewModel extends BindableBase {

    title: string;
    body: string;
    action: string;
    controller: string;

    constructor(data:any) {
        super(data);
    }

}