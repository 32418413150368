import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import { PackingsFilter } from 'wms-lib';
import { combineLatest } from 'rxjs';
import { Store } from '@ngxs/store';
import { ArrivePackingList, Shipment } from 'wms-lib';
import * as _ from 'lodash';
import { StateListenerComponent } from '../../../../shared/components/scan-listener/state-listener.component';

@Component({
  selector: 'app-shipment-builder-by-receipts',
  templateUrl: './shipment-builder-by-receipts.component.html',
  styleUrls: ['./shipment-builder-by-receipts.component.sass']
})
export class ShipmentBuilderByReceiptsComponent extends StateListenerComponent implements OnInit {
  pls: ArrivePackingList[] = [];
  shipment: Shipment;
  filter: PackingsFilter;

  constructor(private store: Store, private cdRef: ChangeDetectorRef) { super(); }

  ngOnInit(): void {
    this.filter = new PackingsFilter();
    this.filter.categories = [];
  }

  ngAfterViewInit() {
    this.addSub(combineLatest(
      this.store.select(state => state.shipmentsState.packing_lists),
      this.store.select(state => state.shipmentsState.shipment_template)
    ).subscribe(([pls,ship]: [ArrivePackingList[], Shipment]) => {
      let tpls = [].concat(pls || [])
      if (ship && ship.arrive_packing_lists) tpls = tpls.concat(_(ship.arrive_packing_lists).values().value());
      this.pls = [...tpls];
      this.shipment = ship;
      this.cdRef.detectChanges();
    }));
  }
}
