import { Component, AfterViewInit, OnDestroy, OnInit, NgZone, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { AppComponentBase } from './app.component.base';
import { Store } from '@ngxs/store';
import { AuthenticationService, CatalogsService, ChannelsService, NotificationService, LocalStorage, SettingsEnum, Printer, Client, ReportService } from 'wms-lib';
import { Router } from '@angular/router';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { RefreshSessionComponent } from './shared/dialogs/refresh-session/refresh-session.component';

enum MenuOrientation {
	STATIC,
	OVERLAY,
	SLIM,
	HORIZONTAL
}

@Component({
	selector: 'app-main',
	templateUrl: './app.main.component.html',
	styleUrls: [ './app.main.component.scss' ]
})
export class AppMainComponent extends AppComponentBase implements AfterViewInit, OnDestroy, OnInit {
	@ViewChild('layoutContainer', { static: false })
	layourContainerViewChild: ElementRef;

	layoutCompact = true;

	layoutMode: MenuOrientation = MenuOrientation.STATIC;

	darkMenu = true;

	profileMode = 'inline';

	rotateMenuButton: boolean;

	topbarMenuActive: boolean;

	overlayMenuActive: boolean;

	staticMenuDesktopInactive: boolean;

	staticMenuMobileActive: boolean;

	rightPanelActive: boolean;

	rightPanelClick: boolean;

	layoutContainer: HTMLDivElement;

	menuClick: boolean;

	topbarItemClick: boolean;

	activeTopbarItem: any;

	menuHoverActive: boolean;

	configActive: boolean;

	configClick: boolean;

	rippleInitListener: any;

	rippleMouseDownListener: any;

	query: string;

	results: string[];

  client_id: number = 0;

  refreshDialog: DynamicDialogRef;

	constructor(
		public renderer2: Renderer2,
		protected searchService: CatalogsService,
		private router: Router,
		store: Store,
		authenticationService: AuthenticationService,
		ng_zone: NgZone,
		notificationService: NotificationService,
		channels: ChannelsService,
    private reportService: ReportService,
    private dialogService: DialogService
	) {
		super(store, authenticationService, ng_zone, notificationService, channels);
	}

	ngOnInit() {
		super.ngOnInit();
		this.ng_zone.runOutsideAngular(() => {
			this.bindRipple();
		});
    this.store.select((state) => state.catalogsState.printers).subscribe((printers: {[id: number]: Printer}) => {
      let tmp = LocalStorage.getString(SettingsEnum.PRINTER);
      if (tmp && printers[tmp])
        setInterval(() => {
          window.postMessage({
            type: "zebra_print_label",
            zpl: '',
            url: Printer.SELECTED.http_url_ping
          }, "*");
        }, 60000);
    });

    this.store.select((state) => state.catalogsState.active_client).subscribe((client: Client) => {
      if (client)
        this.client_id = client.id;
    });

    this.authenticationService.refreshToken$.subscribe((countdown: number) => {
      if (countdown)
        this.dialogService.open(RefreshSessionComponent, {header: 'Refresh session', width: '50%', data: {countdown: countdown}});
    });

    this.authenticationService.idle$.subscribe((idle: boolean) => {
      if (idle)
        this.refreshDialog = this.dialogService.open(RefreshSessionComponent, {header: 'Refresh session', width: '50%', data: {idle: idle, countdown: 10}});
      else
        if (this.refreshDialog) this.refreshDialog.close();
    });
	}

  bindRipple() {
	  this.rippleInitListener = this.init.bind(this);
	  document.addEventListener('DOMContentLoaded', this.rippleInitListener);
  }

  init() {
	  this.rippleMouseDownListener = this.rippleMouseDown.bind(this);
	  document.addEventListener('mousedown', this.rippleMouseDownListener, false);
  }

  rippleMouseDown(e) {
	  const parentNode = 'parentNode';
	  for (let target = e.target; target && target !== this; target = target[parentNode]) {
		  if (!this.isVisible(target)) {
			  continue;
		  }

		  // Element.matches() -> https://developer.mozilla.org/en-US/docs/Web/API/Element/matches
		  if (
			  this.selectorMatches(
				  target,
				  '.ripplelink, .ui-button, .ui-listbox-item, .ui-multiselect-item, .ui-fieldset-toggler'
			  )
		  ) {
			  const element = target;
			  this.rippleEffect(element, e);
			  break;
		  }
	  }
  }

  selectorMatches(el, selector) {
	  const matches = 'matches';
	  const webkitMatchesSelector = 'webkitMatchesSelector';
	  const mozMatchesSelector = 'mozMatchesSelector';
	  const msMatchesSelector = 'msMatchesSelector';
	  const p = Element.prototype;
	  const f =
		  p[matches] ||
		  p[webkitMatchesSelector] ||
		  p[mozMatchesSelector] ||
		  p[msMatchesSelector] ||
		  function(s) {
			  return [].indexOf.call(document.querySelectorAll(s), this) !== -1;
		  };
	  return f.call(el, selector);
  }

  isVisible(el) {
	  return !!(el.offsetWidth || el.offsetHeight);
  }

  rippleEffect(element, e) {
	  if (element.querySelector('.ink') === null) {
		  const inkEl = document.createElement('span');
		  this.addClass(inkEl, 'ink');

		  if (this.hasClass(element, 'ripplelink') && element.querySelector('span')) {
			  element.querySelector('span').insertAdjacentHTML('afterend', "<span class='ink'></span>");
		  } else {
			  element.appendChild(inkEl);
		  }
	  }

	  const ink = element.querySelector('.ink');
	  this.removeClass(ink, 'ripple-animate');

	  if (!ink.offsetHeight && !ink.offsetWidth) {
		  const d = Math.max(element.offsetWidth, element.offsetHeight);
		  ink.style.height = d + 'px';
		  ink.style.width = d + 'px';
	  }

	  const x = e.pageX - this.getOffset(element).left - ink.offsetWidth / 2;
	  const y = e.pageY - this.getOffset(element).top - ink.offsetHeight / 2;

	  ink.style.top = y + 'px';
	  ink.style.left = x + 'px';
	  ink.style.pointerEvents = 'none';
	  this.addClass(ink, 'ripple-animate');
  }
  hasClass(element, className) {
	  if (element.classList) {
		  return element.classList.contains(className);
	  } else {
		  return new RegExp('(^| )' + className + '( |$)', 'gi').test(element.className);
	  }
  }

  addClass(element, className) {
	  if (element.classList) {
		  element.classList.add(className);
	  } else {
		  element.className += ' ' + className;
	  }
  }

  removeClass(element, className) {
	  if (element.classList) {
		  element.classList.remove(className);
	  } else {
		  element.className = element.className.replace(
			  new RegExp('(^|\\b)' + className.split(' ').join('|') + '(\\b|$)', 'gi'),
			  ' '
		  );
	  }
  }

  getOffset(el) {
	  const rect = el.getBoundingClientRect();

	  return {
		  top: rect.top + (window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0),
		  left:
		  rect.left + (window.pageXOffset || document.documentElement.scrollLeft || document.body.scrollLeft || 0)
	  };
  }

  unbindRipple() {
	  if (this.rippleInitListener) {
		  document.removeEventListener('DOMContentLoaded', this.rippleInitListener);
	  }
	  if (this.rippleMouseDownListener) {
		  document.removeEventListener('mousedown', this.rippleMouseDownListener);
	  }
  }

  ngAfterViewInit() {
	  this.layoutContainer = this.layourContainerViewChild.nativeElement as HTMLDivElement;
  }

  onLayoutClick() {
	  if (!this.topbarItemClick) {
		  this.activeTopbarItem = null;
		  this.topbarMenuActive = false;
	  }

	  if (!this.menuClick) {
		  if (this.isHorizontal() || this.isSlim()) {
			  //this.menuService.reset();
		  }

		  if (this.overlayMenuActive || this.staticMenuMobileActive) {
			  this.hideOverlayMenu();
		  }

		  this.menuHoverActive = false;
	  }

	  if (!this.rightPanelClick) {
		  this.rightPanelActive = false;
	  }

	  if (this.configActive && !this.configClick) {
		  this.configActive = false;
	  }

	  this.configClick = false;
	  this.topbarItemClick = false;
	  this.menuClick = false;
	  this.rightPanelClick = false;
  }

  onMenuButtonClick(event = null) {
	  this.menuClick = true;
	  this.rotateMenuButton = !this.rotateMenuButton;
	  this.topbarMenuActive = false;

	  if (this.layoutMode === MenuOrientation.OVERLAY) {
		  this.overlayMenuActive = !this.overlayMenuActive;
	  } else {
		  if (this.isDesktop()) {
			  this.staticMenuDesktopInactive = !this.staticMenuDesktopInactive;
		  } else {
			  this.staticMenuMobileActive = !this.staticMenuMobileActive;
		  }
	  }

	  if(event) event.preventDefault();
  }

  onMenuClick($event) {
	  this.menuClick = true;
  }

  onTopbarMenuButtonClick(event) {
	  this.topbarItemClick = true;
	  this.topbarMenuActive = !this.topbarMenuActive;

	  this.hideOverlayMenu();

	  event.preventDefault();
  }

  onTopbarItemClick(event, item) {
	  this.topbarItemClick = true;

	  if (this.activeTopbarItem === item) {
		  this.activeTopbarItem = null;
	  } else {
		  this.activeTopbarItem = item;
	  }
	  event.preventDefault();
  }

  onTopbarSubItemClick(event: any,item: any) {
    if (item.report) {
      if (item.params && item.params.client_id !== undefined) item.params.client_id = this.client_id;
      if ((item.params.client_id == 0 || item.params.client_id == undefined) && this.authenticationService.currentUserValue.isSupervisor) delete item.params["client_id"];
      this.reportService.openReport(item.report, item.params);
    } else if (item.routerLink)
      this.router.navigate(item.routerLink);
	  event.preventDefault();
  }

  onRightPanelButtonClick(event) {
	  this.rightPanelClick = true;
	  this.rightPanelActive = !this.rightPanelActive;
	  event.preventDefault();
  }

  onRightPanelClick() {
	  this.rightPanelClick = true;
  }

  onConfigClick(event) {
	  this.configClick = true;
  }

  hideOverlayMenu() {
	  this.rotateMenuButton = false;
	  this.overlayMenuActive = false;
	  this.staticMenuMobileActive = false;
  }

  isTablet() {
	  const width = window.innerWidth;
	  return width <= 1024 && width > 640;
  }

  isDesktop() {
	  return window.innerWidth > 1024;
  }

  isMobile() {
	  return window.innerWidth <= 640;
  }

  isOverlay() {
	  return this.layoutMode === MenuOrientation.OVERLAY;
  }

  isStatic() {
	  return this.layoutMode === MenuOrientation.STATIC;
  }

  isHorizontal() {
	  return this.layoutMode === MenuOrientation.HORIZONTAL;
  }

  isSlim() {
	  return this.layoutMode === MenuOrientation.SLIM;
  }

  changeToStaticMenu() {
	  this.layoutMode = MenuOrientation.STATIC;
  }

  changeToOverlayMenu() {
	  this.layoutMode = MenuOrientation.OVERLAY;
  }

  changeToHorizontalMenu() {
	  this.layoutMode = MenuOrientation.HORIZONTAL;
  }

  changeToSlimMenu() {
	  this.layoutMode = MenuOrientation.SLIM;
  }

  searchSuggestions(event: any) {
	  this.searchService.suggestions(event.query).subscribe((results: string[]) => (this.results = results));
  }

  searchQuery() {
	  if (this.query) this.router.navigate([ '/search', this.query ]);
  }

  ngOnDestroy() {
	  this.unbindRipple();
  }

  get onKpiScreen(): boolean {
	  return this.router.url.includes('/kpis/');
  }
}
