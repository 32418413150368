export class DeviceInfo {
    static tablet_min_width: number = 900;
    
    static get screen_width():number{
        return window.screen.width;
    }
    static get isAndroid(): boolean {
        return false;
    }

    static get isTablet() {
        return this.screen_width >=  this.tablet_min_width;
    }

    static get isPhone() {
        return this.screen_width < this.tablet_min_width;
    }

    static get lang(): string {
        return 'es';
    }

    static get haveScanBroadcaster(): boolean {
        return false;
    }
}
