import { Routes } from '@angular/router';
import { TicketFormComponent } from './components/tickets/ticket-form/ticket-form.component';
import { TicketListComponent } from './components/tickets/ticket-list/ticket-list.component';

export const componentDeclarations: any[] = [
];

export const providerDeclarations: any[] = [
];

export const routes: Routes = [
    {
        path: 'tickets',
        component: TicketListComponent
    },
    {
        path: 'tickets',
        component: TicketListComponent
    },
    {
        path: 'new-ticket',
        component: TicketFormComponent
    },
    {
        path: 'edit-ticket/:id',
        component: TicketFormComponent
    },
    {
        path: '',
        redirectTo: 'tickets',
        pathMatch: 'full'
    }
];
