import { Address } from "./address";
import { Catalog } from "./catalog";

export class Warehouse extends Catalog {
  validationKey: string = "warehouse";
  public static SELECTED: Warehouse = null;
  private _name: string;
  address_id: number;
  address: Address;

  constructor(data: any) {
    super(data);
    if (this.address) {
      this.address = new Address(this.address);
    }
  }

  get name() :string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get saveProp(): string[] {
    return ["name", 'address_id','address'];
  }
}
