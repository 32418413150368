import { Validable } from "./common";
import { Package } from "./package";
import { Shipment } from "./shipment";
import * as _ from 'lodash';
import * as moment from 'moment'
import { Arrival } from "./arrival";

export class Pallet extends Validable {
    validationKey = "pallet";
    packages: Package[];
    container_type_id: number;
    container_weight: number;
    consolidation: boolean;

    constructor(data: any) {
        super(data);
        if (!this.id) this.id = (+(new Date()))*-1;
        if (data.container_weight)
            this.container_weight = +data.container_weight || 0;
        if (this.packages) {
            this.packages = this.packages.map((p: any) => p instanceof Package ? p : new Package(p));
            if (this.packages && this.packages[0] && this.packages[0].pallet){
                this.container_type_id = this.packages[0].pallet.container_type_id;
                this.container_weight = this.packages[0].pallet.container_weight || 0;
                if (this.consolidation === undefined) this.consolidation = this.packages[0].pallet.consolidation;
            }
            this.packages.forEach((p: Package) => p.pallet = this);
        }
    }

    get saveProp(): string[] {
        return super.saveProp.concat(["container_type_id", "package_ids", "consolidation"]);
    }

    get package_ids(): number[]{
        return this.packages.map((p: Package) => p.id);
    }

    get bundles(): number {
        return this.packages.length;
    }

    get type_of_bundles(): number[] {
        return _(this.packages).map('bundle_type_id').flatten().uniq().value();
    }

    get weight(): number {
        return _.sumBy(this.packages, 'weight');
    }

    get total_weight(): number {
        return this.weight + (this.container_weight || 0);
    }

    get good_category_id(): number {
        return _(this.packages).map('good_category_id').flatten().uniq().first();
    }

    get isShipped(): boolean {
        return this.packages.every((p: Package) => p.isShipped);
    }

    set isShipped(value: boolean) {
        this.packages.forEach((p: Package) => p.isShipped = value)
    }

    havePackage(p: Package): boolean {
        return this.packages && !!this.packages.find((pkg: Package) => pkg.id == p.id);
    }

    get display_label(): string {
        return this.id > 0 && `PA${this.id}`; 
    }

    zpl(client_name: string): string;
    zpl(client_name: string, category: string, shipment: Shipment): string;
    zpl(client_name: string, category?: string, shipment?: Shipment): string{
        if (shipment)
            return this.zpl_shipping(client_name, category, shipment);
        else
            return this.zpl_arrival(client_name);
    }

    zpl_shipping(client_name: string, category: string, shipment: Shipment) {
        return `^XA

        ^FX Top section with logo, name and address.
        ^CF0,60
        ^FO250,260^GD40,90,18^FS
        ^FO271,315^GB30,20,20^FS
        ^FO305,260^GB15,90,10^FS
        ^FO294,253^GB40,40,10,W^FS
        ^FO330,260^FDRio Bravo^FS
        ^CF0,30
        ^FO330,325^FH^FDAmerican Industries_A9^FS
        ^FO50,400^GB700,1,3^FS
        
        ^FX Second section with recipient address and permit information.
        ^CFA,30
        ^FO50,425^FDClient:^FS
        ^CF0,55
        ^FO50,470^FD${client_name}^FS
        ^CFA,15
        ^FO50,550^GB700,1,3^FS
        
        ^FX Third section with barcode.
        ^CFA,30
        ^FO50,560^FDPallet Id:^FS
        ^BY4,2,100
        ^FO50,600^BC^FD${this.display_label}^FS
        ^FO50,760^FDShip Date:^FS
        ^CF0,30
        ^FO230,760^FD${moment(shipment.shipped_date).format('MMMM D, YYYY h:mma')}^FS
        ^CFA,30
        ^FO50,800^FDCarrier:^FS
        ^CF0,30
        ^FO230,800^FD${shipment.carrier}^FS
        ^CFA,30
        ^FO50,840^FDTrailer:^FS
        ^CF0,30
        ^FO230,840^FD${shipment.trailer}^FS
        ^CFA,30
        ^FO50,880^FDBundles:^FS
        ^CF0,30
        ^FO230,880^FD${this.bundles}^FS
        ^CFA,30
        ^FO50,920^FDCategory:^FS
        ^CF0,30
        ^FO230,920^FD${category}^FS
        ^CFA,15
        ^FO50,960^GB700,1,3^FS

        ^FO50,1160^GB700,1,3^FS
        ^CF0,30
        ^FO50,1180^FH^FDThink Fordward_A9^FS
        ^FO500,1180^FDwww.airiobravo.com^FS
        ^XZ`;
    }

    zpl_arrival(client_name: string) {
        return `^XA

        ^FX Top section with logo, name and address.
        ^CF0,60
        ^FO250,260^GD40,90,18^FS
        ^FO271,315^GB30,20,20^FS
        ^FO305,260^GB15,90,10^FS
        ^FO294,253^GB40,40,10,W^FS
        ^FO330,260^FDRio Bravo^FS
        ^CF0,30
        ^FO330,325^FH^FDAmerican Industries_A9^FS
        ^FO50,400^GB700,1,3^FS
        
        ^FX Second section with recipient address and permit information.
        ^CFA,30
        ^FO50,425^FDClient:^FS
        ^CF0,55
        ^FO50,470^FD${client_name}^FS
        ^CFA,15
        ^FO50,550^GB700,1,3^FS
        
        ^FX Third section with barcode.
        ^CFA,30
        ^FO50,560^FDPallet Id:^FS
        ^BY4,2,100
        ^FO50,600^BC^FD${this.display_label}^FS
        ^FO50,880^FDBundles:^FS
        ^CF0,30
        ^FO230,880^FD${this.bundles}^FS
        ^CFA,30
        ^FO50,960^GB700,1,3^FS

        ^FO50,1160^GB700,1,3^FS
        ^CF0,30
        ^FO50,1180^FH^FDThink Fordward_A9^FS
        ^FO500,1180^FDwww.airiobravo.com^FS
        ^XZ`;
    }

    get arrival(): Arrival {
        return this.packages.length > 0 ? this.packages[0].arrive_packing_list.arrival : null;
    }
}
