import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthenticationService, Catalog, Pallet, ReportService, Shipment, Client } from 'wms-lib';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

@Component({
    selector: 'app-shipment-details-preview',
    templateUrl: './shipment-detail-preview.component.html',
    styleUrls: ['./shipment-detail-preview.component.scss']
})
export class ShipmentDetailPreviewComponent implements OnInit {
    @Input() shipment: Shipment;
    @Input() pallet: Pallet;
    @Input() shipmentOrder: boolean;

    container_types$: Observable<{ [id: number]: Catalog }> = new Observable<{ [id: number]: Catalog }>();
    priorities$: Observable<{ [id: number]: Catalog }> = new Observable<{ [id: number]: Catalog }>();
    conveyance_types$: Observable<{ [id: number]: Catalog }> = new Observable<{ [id: number]: Catalog }>();
	active_client: Client;

    constructor(
        private activated_route: ActivatedRoute,
        private store: Store,
        private reportService: ReportService, 
        protected authenticationService : AuthenticationService) { }

    ngOnInit() {
        this.container_types$ = this.store.select(state => state.catalogsState.container_types);
        this.priorities$ = this.store.select(state => state.catalogsState.priorities);
        this.conveyance_types$ = this.store.select(state => state.catalogsState.conveyance_types);
		this.store.select(state => state.catalogsState.active_client).subscribe((client: Client) => {
			this.active_client = client;
		} );
    }

    openComplementary() {
        this.reportService.openReport("ComplementaryReport", {id: this.shipment.id});;
    }

    openShipment() {
        this.reportService.openReport("ShippingReport", {id: this.shipment.id});;
    }

    get canViewHistory(): boolean {
        return this.authenticationService.currentUserValue.isSupervisor
    }
}
