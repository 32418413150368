import { Component, NgZone, OnInit, Output, EventEmitter, HostBinding, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { PackageComponent } from '../../../../shared/components/base-components/package.component';
import { Catalog, CodebarReaderService, NotificationService, ModalDialogService, DamageReport, AuthenticationService, Package } from 'wms-lib';
import { Router } from '@angular/router';
import * as actions from 'wms-lib';

@Component({
  selector: 'tr[app-receipts-package]',
  templateUrl: './receipts-package.component.html',
  styleUrls: ['../../../../shared/components/base-components/package.component.scss']
})
export class ReceiptsPackageComponent extends PackageComponent implements OnInit {
  good_categories: {[id: number]: Catalog };
  @Output() damageSelected: EventEmitter<DamageReport> = new EventEmitter<DamageReport>();
  @Input() selectable: boolean = false;

  constructor(
    _router: Router,
    store: Store,
    notification_service: NotificationService,
    modal_service: ModalDialogService,
    codebar_service: CodebarReaderService,
    ng_zone: NgZone,
    authenticationService : AuthenticationService) {
    super(store, notification_service, codebar_service, ng_zone, modal_service, _router, authenticationService);
  }

  ngOnInit() {
    super.ngOnInit();
    this.store.select(state => state.catalogsState.good_categories).subscribe(data => {
      this.good_categories = data;
    });
  }

  @HostBinding('style.background')
  get status(): string {
    return !this.selected ? null : '#FEDA00';
  }

  addOrDeletePackingFromShipment(pkg: Package) {
    this.store.dispatch(new actions.AddPackageToShipmentAction(pkg));
  }

  showDamage(){
    let dr = this.package.damage_report;
    if (!dr.damage_loaded)
      this.store.dispatch(new actions.ReLoadPackingListAction(this.package.arrive_packing_list));
    this.damageSelected.emit(this.package.damage_report);
  }

  get isAdmin(): boolean {
    return this.authenticationService.currentUserValue.isAdmin;
  }

  get selected(): boolean {
    return !!this.package.shipment;
  }
  set selected(value: boolean) {
    super.selected = value;
  }

  get fragmented(): boolean {
    return this.package &&
      this.package.arrive_packing_list &&
      this.package.arrive_packing_list.packages_a.some((p: Package) => !!p.shipment);
  }

  get shipmentByReceipt(): boolean {
    return this.package &&
      this.package.arrive_packing_list &&
      !!this.package.arrive_packing_list.shipment &&
      !this.fragmented;
  }

  get show_check(): boolean {
    return (!this.package.isPicked || this.isAdmin) && !this.shipmentByReceipt;
  }

  get isShipmentPicked(): boolean {
    return this.package.shipment && this.package.shipment.isPicked;
  }

  get isShipmentNew(): boolean {
    return this.package.shipment && this.package.shipment.isNew;
  }

}
