import { DamageReport } from "./damage-report";
import { RemoteAsset } from "./remote-asset";

export class Damage extends RemoteAsset {
    id: number;
    damage_report_id: number;
    damage_report: DamageReport;
    created_at: Date;
    preview: string;

    constructor(data?: any) {
        super(data);
    }
}
