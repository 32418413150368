import { Component, OnInit, NgZone } from '@angular/core';
import { ConciliatingBaseComponent } from './conciliating.component.base';
import { Store } from '@ngxs/store';
import { ActivatedRoute, Router, RouterEvent, NavigationCancel } from '@angular/router';
import { CodebarReaderService, NotificationService, ModalDialogService, ModalDialogOptions, Arrival, DD_TYPES, Package, ArrivePackingList, Warehouse} from 'wms-lib';
import { CarrierSelectionDialogComponent } from '../carrier-selection-dialog/carrier-selection-dialog.component';
import * as _ from 'lodash';
import { ClientSelectionDialogComponent } from '../../../shared/components/dialogs/client-selection-dialog/client-selection-dialog.component';
import * as actions from 'wms-lib';
import { Observable, Subscription, combineLatest } from 'rxjs';
import { filter, map } from "rxjs/operators";

@Component({
  selector: 'app-conciliating',
  templateUrl: './conciliating.component.html',
  styleUrls: ['./conciliating.component.scss']
})
export class ConciliatingComponent extends ConciliatingBaseComponent implements OnInit {
  deflectMode: boolean = false;
  index: number;
  connected$: Observable<boolean>;
  router_events: Subscription;
  warehouses$: Observable<{[id: number]: Warehouse}>;

  constructor(
    private modal_service: ModalDialogService,
    store: Store,
    activated_route: ActivatedRoute,
    codebar_service: CodebarReaderService,
    notification_service: NotificationService,
    ng_zone: NgZone,
    private router: Router
  ) {
    super(store, activated_route, codebar_service, notification_service, ng_zone);
  }

  ngOnInit(){
    super.ngOnInit();
    this.activated_route.params.subscribe((params: any) => {
      this.store.dispatch(new actions.GetArrivalAction({id: params.id, type: params.type}));
      if (!!+params.load || this.isPrimary){
        this.addSub(combineLatest(
          this.store.select(state => state.arrivalsState.open_arrival),
          this.store.select((state) => state.arrivalsState.working_packing_id)
        ).subscribe(([open_arrival, wpid]: [Arrival, number]) => {
          if (open_arrival) {
            if(params) {
              if (open_arrival.id == +params.id)
                this.subscribe_scanner({
                  [DD_TYPES.simple]: this.tryAddScanData,
                  [DD_TYPES.dense]: this.tryAddScanData,
                  [DD_TYPES.default]: this.tryAddScanData,
                  [DD_TYPES.domestic]: this.tryAddDomesticParcel
                });
              else
                this.router.navigate([ '/receivings', params.type, 'edit', open_arrival.id ]);
            }
            if (wpid){
              if(this.open_arrival.arrive_packing_lists && this.open_arrival.arrive_packing_lists[wpid] && this.open_arrival.arrive_packing_lists[wpid].deflected)
                this.index = 2;
              else if(this.open_arrival.arrive_packing_lists && this.open_arrival.arrive_packing_lists[wpid] && this.open_arrival.arrive_packing_lists[wpid].isValid)
                this.index = 1;
              else
                this.index = 0;
            }
          }
        }));
      }
    });
    this.connected$ = this.store.select(state => state.arrivalsState.connected);
    this.warehouses$ = this.store.select(state => state.catalogsState.warehouses);
    this.router_events = this.router.events.pipe(filter((e: RouterEvent) => e instanceof NavigationCancel))
      .subscribe((e: RouterEvent) => {
        if (!e.url.match(/^\/receivings\/(ParcelArrival|FreightArrival)\/.+/))
          this.store.dispatch(new actions.ClearArrivalStateAction());
      });
  }

  tryAddScanData(data: string) {
    if (this.open_arrival.isParcel)
      this.tryAddPackage(data);
    else
      this.tryAddPacking(data);
  }

  private tryAddPackage(code: string) {
    let pkg = new Package(this.codebar_service.parseCode(code));
    if (pkg.id) {
      if (this.open_arrival.carrier_id)
        this.addPackage(pkg);
      else
        this.createArrivalFromPkg(pkg);
    } else {
      this.notification_service.toast('CodeCannotBeParsed');
    }
  }

  protected tryAddDomesticParcel(qty: string) {
    let start = +(new Date());
    let pkgs = _.range(+qty).map((n: number) => new Package({id: (start+n)*-1, code: Package.AUTO+(start+n), trk: ''+(start+n)}))
    if (this.open_arrival.carrier_id)
      this.addPackagesGroup(pkgs);
    else
      this.createArrivalFromPkg(pkgs);
  }

  private async addPackage(pkg: Package) {
    try {
      let original = this.open_arrival.toSaveData();
      pkg = this.open_arrival.attemptAddOrUpdatePackage(pkg);
      if (this.deflectMode) await this.validateDefelcted(pkg);
      if (!_.isEqualWith(original,this.open_arrival.toSaveData(),Package.customCompare))
        this.store.dispatch(new actions.UpdateArriveAction({ arrival: this.open_arrival, package: pkg }));
      this.store.dispatch(new actions.SetWorkingPackageAction({ package: pkg }));
    } catch (e) {
      if (e.message === 'InvalidCarrierOrNotEqualToArrivalCarrier') {
        this.notification_service.showFeedback('PackageInAInvalidArrivalWasRemoved', 'PackageOmitted', true);
        if (this.open_arrival.deletePackage(pkg.id))
          this.store.dispatch(new actions.UpdateArriveAction({ arrival: this.open_arrival, package: pkg }));
      } else
        this.notification_service.toast(e.message, true);
    }
  }

  private addPackagesGroup(pkgs: Package[]) {
    if (pkgs && pkgs.length > 0) {
      let pkg = pkgs[0];
      let apl = new ArrivePackingList(pkg);
      pkgs.forEach((p: Package) => {
        if (p.trk != pkg.trk)
          apl.addOrUpdatePackage(p)
      });
      this.open_arrival.addPackingList(apl);
      this.store.dispatch(new actions.UpdateArriveAction({ arrival: this.open_arrival, package: null }));
    }
  }

  private async createArrivalFromPkg(pkgs: Package | Package[]) {
    let fpkgs = _([pkgs]).flatten().value();
    let pkg = _(fpkgs).first();
    let id = await this.getCarrierId(pkg);
    this.open_arrival.carrier_id = id;
    if (this.open_arrival.carrier_id) { //TODO: Pasar a una validacion a nivel modelo (isValid)
      let apl = new ArrivePackingList(pkg);
      fpkgs.forEach((p: Package) => {
        if (p.trk != pkg.trk)
          apl.addOrUpdatePackage(p)
      });
      this.open_arrival.addPackingList(apl);
      if (this.deflectMode) await this.validateDefelcted(pkg);
      this.store.dispatch(new actions.UpdateArriveAction({arrival: this.open_arrival, package: pkg}));
    }
  }

  async changeCarrier() {
  	  if(!this.canEdit) return;
    Object.defineProperty(this, 'open_arrival', { value: this.open_arrival, writable: false });
    let id = await this.getCarrierId();
    if(id) {
      Object.defineProperty(this, 'open_arrival', { value: this.open_arrival, writable: true });
      this.open_arrival.carrier_id = id;
      this.store.dispatch(new actions.UpdateArriveAction({arrival: this.open_arrival, package: null}));
    }
    Object.defineProperty(this, 'open_arrival', { value: this.open_arrival, writable: true });
  }

  saveArrival() {
    this.store.dispatch(new actions.UpdateArriveAction({arrival: this.open_arrival, package: null}));
  }

  private async validateDefelcted(pkg: Package): Promise<Package>{
    if (!pkg.arrive_packing_list.deflected){
      let id = await this.confirmDeflected(pkg.arrive_packing_list);
      pkg.arrive_packing_list = id;
    }
    return pkg;
  }

  private async confirmDeflected(pl: ArrivePackingList): Promise<ArrivePackingList>
  {
    let options: ModalDialogOptions = {
      params: {
        filterField: 'ignore',
        filterValue: true,
        selectFirst: true
      }
    };
    let client = await this.modal_service.showModal(ClientSelectionDialogComponent, options);
    if (client) pl.client_id = client.id
    return  pl;
  }

  getWarehouse$(id: number): Observable<string> {
    return this.warehouses$.pipe(
      map((warehouses: { [id: number]: Warehouse }) => {
        return ((warehouses || {})[id] || {name: ''}).name;
      })
    );
  }

  onInputReturn(){
    super.onInputReturn(this.trk.nativeElement.value);
    this.trk.nativeElement.value ="";
  }

  protected async getCarrierId(pkg?: Package): Promise<number> {
    let options: ModalDialogOptions = {
      params: {carrier_type: this.open_arrival.type.replace("Arrival", "Carrier") }
    };
    if (pkg)
      options.params["package"] = pkg;
    let carrier = await this.modal_service.showModal(CarrierSelectionDialogComponent, options);
    return carrier ? +carrier.id : null;
  }

  ngOnDestroy() {
    if (this.router_events) this.router_events.unsubscribe();
    super.ngOnDestroy();
  }

  get canEdit(): boolean {
  	  return !this.open_arrival.haveShipment;
  }
}
