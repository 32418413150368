
import { DamageReport, Damage, parseDataUrl, Attachment } from 'wms-lib';
import { Observable, of, Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { ElementRef } from '@angular/core'
import { RemoteAsset } from '../../../../../core/lib/src/models/remote-asset';

export class CanTakeDamageReport {
    damage_element: ElementRef;
    selectedImage: Subject<any>;
    buildDamageReport(): Observable<DamageReport> {
        this.damage_element.nativeElement.click();
        return this.selectedImage.pipe(map((image_data:any) =>{
            if(image_data)
                return new DamageReport({damages:[buildDamage(image_data)]});
            return null;
        }));
    }

    async onChangeImage():Promise<void>{
        this.selectedImage.next(await readImageFile(this.damage_element));
    }
}




export class CanTakeDamage {
    damage_element: ElementRef;
    selectedImage: Subject<any>;

    takeDamageImage(): Observable<Damage> {
        this.damage_element.nativeElement.click();
        return this.selectedImage.pipe(map((image_data:any) => {
            if (image_data)
                return buildDamage(image_data);
            return null;
        }));
    }

    async onChangeImage():Promise<void>{
        this.selectedImage.next(await readImageFile(this.damage_element));
    }
}


export class CanTakeAttachment {
    
    selectedAttachment: Subject<Attachment>;

    async takeAttachmentImage(image: File): Promise<void> {
        this.selectedAttachment.next(buildAsset<Attachment>(Attachment, await fileToBase64(image)));    
    }
}

function buildAsset<T extends RemoteAsset>(ctr: new (data:any) => T, image_data: any) {
    let url_data = parseDataUrl(image_data.url_data);
    return new ctr({
        src: URL.createObjectURL(image_data.file),
        base64: url_data.groups["content"],
        filename: image_data.file.name
    });
}



function buildDamage(image_data:any) : Damage {    
    return buildAsset<Damage>(Damage,image_data);
} 

function readImageFile(damage_element:ElementRef): Promise<any>{
    if(!IsValidImageDataInput(damage_element))
        return null;

    return fileToBase64(damage_element.nativeElement.files[0]);

}

function fileToBase64(image: File): Promise<any> {
    let reader = new FileReader();
    return new Promise((resolve,reject) =>{
        reader.onerror = () =>{
            reader.abort();
            reject(null);
        };
        reader.onload = () => {
            resolve({url_data: <string>reader.result, file: image });
        };
        reader.readAsDataURL(image);
    });   
}

function IsValidImageDataInput(damage_element:ElementRef): boolean{
    return damage_element.nativeElement.files && damage_element.nativeElement.files[0];
}
