import { Component, OnInit, NgZone, Input } from '@angular/core';
import { Store } from '@ngxs/store';
import { CodebarReaderService, Package, AuthenticationService } from 'wms-lib';
import { PackingListsComponent } from '../../../../shared/components/base-components/packing-lists.component';
import * as _ from 'lodash';
import { Router } from '@angular/router';

@Component({
    selector: 'app-search-packing-lists',
    templateUrl: './search-packing-lists.component.html',
    styleUrls: ['../../../../shared/components/base-components/packing-lists.component.scss']
})
export class SearchPackingListsComponent extends PackingListsComponent implements OnInit {
    @Input() package: Package;
    constructor(store: Store,private router: Router, codebar_service: CodebarReaderService,ng_zone: NgZone,
                authenticationService: AuthenticationService) {
        super(store, codebar_service, ng_zone, authenticationService);
    }
}
