import { Client } from '../../models/client';
import { ICatalog } from '../../models/catalog';
import { User } from '../../models/user';
import { Tracking, TrackingDetail } from '../../models/tracking';

export class GetMapCatalogAction {
    public static readonly type = '[Warehouse] Get Map Catalog';
    constructor(public payload: string) { }
}

export class GetCatalogAction<T extends ICatalog> {
    public static readonly type = '[Warehouse] Get Catalog';
    constructor(public payload: {name_catalog: string, live: boolean}) { }
}

export class SaveCatalogAction<T extends ICatalog> {
    public static readonly type = '[Warehouse] Save Catalog';
    constructor(public payload: {catalog: T, name_catalog: string}) { }
}

export class SaveGroupCatalogAction<T extends ICatalog> {
    public static readonly type = '[Warehouse] Save Group Catalog';
    constructor(public payload: {catalog: T, name_catalog: string}) { }
}

export class DeleteCatalogAction<T extends ICatalog> {
    public static readonly type = '[Warehouse] Delete Catalog';
    constructor(public payload: {catalog: T, name_catalog: string}) { }
}

export class DeleteGroupCatalogAction<T extends ICatalog> {
    public static readonly type = '[Warehouse] Delete Group Catalog';
    constructor(public payload: {catalog: T, name_catalog: string}) { }
}

export class ReorderCatalogAction {
    public static readonly type = '[Warehouse] Reorder Catalog';
    constructor(public payload: {name_catalog: string, sequence: {[id: string]: number}}) { }
}

export class SetActiveClientAction {
    public static readonly type = '[Warehouse] Set active client';
    constructor(public payload: {client: Client}) { }
}

export class GetUsersAction {
    public static readonly type = '[Warehouse] Get Users';
    constructor() { }
}

export class GetRolesAction {
    public static readonly type = '[Warehouse] Get Roles';
    constructor() { }
}

export class SaveUserAction {
    public static readonly type = '[Warehouse] Save User';
    constructor(public payload: User) { }
}

export class AddNewUserAction {
    public static readonly type = '[Warehouse] Add New User';
    constructor(public payload: User) { }
}

export class DeleteNewUserAction {
    public static readonly type = '[Warehouse] Delete New User';
    constructor(public payload: User) { }
}

export class GetTrackingsAction {
    public static readonly type = '[Trackings] Get Trackings';
    constructor(public payload: { page: number, append: boolean }) { }
}

export class AddTrackingsAction {
    public static readonly type = '[Trackings] Add Tracking';
    constructor() { }
}

export class SaveTrackingAction {
    public static readonly type = '[Trackings] Save Tracking';
    constructor(public payload: Tracking) { }
}

export class SetWorkingTrackingAction {
	public static readonly type = '[Trackings] Set Working Tracking';
	constructor(public payload: Tracking) { }
}


export class SaveTrackingAndViewDetailsAction {
    public static readonly type = '[Trackings] Save Tracking and View Details';
    constructor(public payload: Tracking) { }
}

export class DeleteTrackingAction {
    public static readonly type = '[Trackings] Delete Tracking';
    constructor(public payload: Tracking) { }
}

export class ViewTrackingDetailsAction {
    public static readonly type = '[Trackings] View Tracking Details';
    constructor(public payload: TrackingDetail[]) { }
}

export class AddTrackingFoundAction {
    public static readonly type = '[Trackings] Add Tracking Found';
    constructor(public payload: string) { }
}

export class GetOpenTrackingsAction {
    public static readonly type = '[Trackings] Get Open Trackings';
    constructor() { }
}

export class LoadCatalogsAction {
    public static readonly type = '[Catalogs] Load Catalogs';
    constructor() { }
}

export class LoadPartNumbersAction {
    public static readonly type = '[Catalogs] Load Part Numbers';
    constructor(public payload: number) { }
}

export class ImportCatalogAction<T extends ICatalog> {
    public static readonly type = '[Warehouse] Import Catalog';
    constructor(public payload: {name_catalog: string, data: any, options: any }) { }
}

export class LoadStockByPartNumbersAction {
    public static readonly type = '[Catalogs] Load Stock By Part Numbers';
    constructor(public payload: number) { }
}


export type catalogActions<T extends ICatalog> =
    GetMapCatalogAction |
    GetCatalogAction<T> |
    SaveCatalogAction<T> |
    SaveGroupCatalogAction<T> |
    DeleteCatalogAction<T> |
    DeleteGroupCatalogAction<T> |
    SetActiveClientAction |
    ReorderCatalogAction |
    GetUsersAction |
    GetRolesAction |
    SaveUserAction |
    AddNewUserAction |
    DeleteNewUserAction |
    GetTrackingsAction |
    AddTrackingsAction |
    SaveTrackingAction |
    DeleteTrackingAction |
    ViewTrackingDetailsAction |
    AddTrackingFoundAction |
    GetOpenTrackingsAction |
    LoadCatalogsAction |
    LoadPartNumbersAction |
    SetWorkingTrackingAction |
    SaveTrackingAndViewDetailsAction |
    ImportCatalogAction<T>;

