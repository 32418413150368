import { Validable, convertToMtsFrom, UOM } from './common';
import { ArrivePackingList, NA_ID } from './arrive-packing-list';
import * as _ from 'lodash';
import { DamageReport } from './damage-report';
import { Carrier } from './carrier';
import * as moment from 'moment'
import { GoodCategory } from './good_category';
import { Catalog } from './catalog';
import { Pallet } from './pallet';
import { TrackingDetail } from './tracking';
import { Arrival } from './arrival';
import { PartNumber } from './part-number';
import { Shipment } from './shipment';
import { Client } from './client';

const SAVE_PROPS = ['trk', 'simple_trk', 'carrier_packing_id', 'carrier_code', 'code', 'status', 'supplier', 'comments',
                    'location_tag', 'po', 'raw_weight', 'raw_cardinality', 'raw_cient', 'raw_client_plus', 'raw_plus',
                    'removed', 'bundle_type_id', 'good_category_id', 'damage_report_attributes', 'pallet_id', 'updated_at','part_number_id','quantity','last_print','verify_tag_location','tag_location'];

export enum PackageStatuses {
  received = 'R',
  down = 'D',
  processed = 'P',
  stored = 'S',
  wip = 'W',
  consigned = 'C',
  palletized = 'L',
  shipped = 'H'
}

export interface IStorageLocation {
  location_id: number;
  location_tag: string;
}

export const PickedStatus = [PackageStatuses.consigned.toString(), PackageStatuses.shipped.toString()]
export const OnStockStatus = [PackageStatuses.received.toString(), PackageStatuses.processed.toString(), PackageStatuses.stored.toString()]
export const ConsignedStatus = [PackageStatuses.consigned.toString(), PackageStatuses.palletized.toString(), PackageStatuses.shipped.toString()]
export const LocatedStatus = [PackageStatuses.stored.toString(), PackageStatuses.wip.toString()];


export class Package extends Validable implements IStorageLocation {
  static WEIGHT_REGEXP: RegExp = /\s*(?<weight>\d+(\.\d+)?|\.\d+)\s*(?<unit>(LB|KG))S?/i;
  static AUTO: string = "auto";
  validationKey = 'package';
  arrive_packing_list_id: number;
  trk: string;                // Codigo de guia completo largo, viene en el codigo denso
  simple_trk: string;         // Codigo de la guia chico que viene en la etiqueta (codigo de barras simple)
  carrier_packing_id: string; // Shipment id de la paqueteria
  carrier_code: string;       // Codigo de la paqueteria: UPS, FEDG, FEDE, etc
  code: string;               // Codigo completo que se leyo, simple o denso
  location_tag: string;
  po: string;
  bundle_type_id: number;
  good_category_id: number;
  damage_report: DamageReport;
  good_category: Catalog;
  status: string;
  dense: false;
  location_id: number;
  pallet_id: number;
  _pallet: Pallet;
  supplier: string;
  comments: string;
  tracking_detal: TrackingDetail;
  _part_number: PartNumber
  part_number_id: number;
  quantity: number;
  _receipt_id: string;
  shipment_id?: number;
  shipment?: Shipment;
  _last_print: Date;
  _part_number_name: string;

  // Se calcular desde el codigo denso
  cardinality: [number, number];
  label_uom: string;
  weight: number;
  raw_client: string;
  raw_client_plus: string;

  // Propiedades de control
  _destroy: boolean;
  removed: boolean;
  component: any;
  new_record: boolean;
  editing: boolean = false;
  verify_tag_location: boolean;
  tag_location: string;


  private _raw_weight: string;
  private _raw_po: string;
  private _raw_cardinality: string;
  private _arrive_packing_list: ArrivePackingList;
  private _carrier_id: number;

  constructor(data: any | ArrivePackingList | Package) {
    super(data instanceof ArrivePackingList ? {} : data);
    if (data instanceof ArrivePackingList) this.setupPacking(data);
    if (data instanceof Package) this.setupPackage(data);
    if (data.damage_report) this.setupDamageReport(data.damage_report);
    if (data.good_category) this.setupGoodCategory(data.good_category);
    if (data.part_number) this.setupPartNumber(data.part_number);
    if (data.shipment) this.shipment = new Shipment(data.shipment);
    if (data.arrive_packing_list && !(this.arrive_packing_list instanceof ArrivePackingList))
      this.arrive_packing_list = new ArrivePackingList(data.arrive_packing_list);
    this.setupDefaults(data);
  }

  private setupPacking(pl: ArrivePackingList) {
    this.arrive_packing_list = pl;
    this.code = ""+((+new Date())+_.values(pl.packages).length);
    this.trk = this.code;
  }

  private setupPackage(data: Package) {
    this.carrier_packing_id = NA_ID;
    this.arrive_packing_list_id = null;
    this.id = (+new Date())*-1;
    this.raw_cardinality = "1/1";
    this.damage_report = data.damage_report;
    data._destroy = true;
  }

  private setupGoodCategory(data: any) {
    this.good_category = new GoodCategory(data);
  }

  private setupDamageReport(damage_report: any) {
    this.damage_report = new DamageReport(damage_report);
    this.damage_report.package = this;
  }

  private setupPartNumber(part_number: any) {
    this.part_number = new PartNumber(part_number);
	  this._part_number_name = this.part_number.name;
  }

  private setupDefaults(data: any) {
    this.id = this.id || (+new Date())*-1;
    this._destroy = false;
    if (!(data instanceof ArrivePackingList))
      this.weight = (data && data.weight && +data.weight) || this.weight || 0;
    this.po = this.po || '';

    this.bundle_type_id = this.bundle_type_id || null;
    this.supplier = this.supplier || '';
    if (this.code) this.code = this.code.trim();
    if (this.trk) this.trk = this.trk.trim();
    if (this.simple_trk) this.simple_trk = this.simple_trk.trim();
    if (data.tracking_detail) this.tracking_detal = new TrackingDetail(data);
    if (this.quantity) this.quantity = +this.quantity;
    this.part_number_id = this.part_number_id || null;
    this._part_number_name = this._part_number_name || '';
    this.quantity = this.quantity || 0;
  }

  get saveProp(): string[] {
    if (this._destroy && this.id > 0)
      return ['id', '_destroy'];
    else {
      let p = super.saveProp.concat(SAVE_PROPS);
      if (this.weight) p.push('weight');
      if (this.arrive_packing_list_id > 0) p.push('arrive_packing_list_id');
      return p;
    }
  }


  get isValid(): boolean {
    let result = super.isValid && !this.editing;
    if (this.isFreight && this.errors["weight"] && _(this.errors).size() == 1)
      return true;
    return result;
  }

  get arrive_packing_list(): ArrivePackingList {
    return this._arrive_packing_list;
  }
  set arrive_packing_list(value: ArrivePackingList) {
    if (value) {
      this._arrive_packing_list = value;
      this.arrive_packing_list_id = value.id;
      if (this.carrier_packing_id && this.carrier_packing_id != NA_ID){
        /*
          This case is when the scan of a dense code trigger the creation of a new Packing List
          in this case the data will have the identifier of the shipment that will be the carrier packing id
        */
        value.carrier_packing_id = this.carrier_packing_id;
        this.arrive_packing_list_id = value.id;
      } else
        this.carrier_packing_id = value.carrier_packing_id;
    }
  }

  set raw_weight(value: string) {
    let match = Package.WEIGHT_REGEXP.exec(value);
    let data = match && match.groups;
    if (data && data.weight && data.unit) {
      this.weight = convertToMtsFrom(Number(data.weight), <UOM>data.unit);
      this.label_uom = data.unit;
    }
    this._raw_weight = value;
  }
  get raw_weight(): string {
    return this._raw_weight;
  }

  set raw_po(value: string) {
    this.po = value.replace(/\s+/,',');
    this._raw_po = value;
  }
  get raw_po(): string {
    return this._raw_po;
  }

  set raw_cardinality(value: string) {
    if (value)
      this.cardinality = [Number(value.split('/')[0]), Number(value.split('/')[1])];
    else
      this.cardinality = null;
    this._raw_cardinality = value;
  }
  get raw_cardinality(): string {
    return this._raw_cardinality;
  }

  get numerator(): number {
    return (this.cardinality || [0])[0];
  }

  get cardinality_label(): string {
    return this.cardinality ? `${this.cardinality[0]} / ${this.cardinality[1]}` : '';
  }

  get single(): boolean {
    return this.arrive_packing_list.count === 1;
  }

  get short_trk(): string {
    return this.isFreight ?
      this.arrive_packing_list.carrier_packing_id :
      this.simple_trk || (this.trk && this.trk.length > 25 && this.trk.substring(20)) || this.trk;
  }

  get display_label(): string {
    return  this.isFreight ? this.cardinality_label : this.short_trk;
  }

  get display_pk_label(): string {
    return `PK${this.id}`;
  }

  get display_label_wide(): string {
    return this.isFreight ?
      `${this.arrive_packing_list.carrier_packing_id} ${this.cardinality_label} (PK${this.id})` :
      `${this.short_trk} (PK${this.id})`;
  }

  get po_list(): string[] {
    return _(this.po)
      .split(',')
      .compact()
      .uniq()
      .value();
  }

  get isDamage(): boolean {
    return !!this.damage_report;
  }

  get haveCardinality(): boolean {
    return !this.cardinality || this.cardinality[1] > 1;
  }

  get carrier_id() {
    if (!this._carrier_id) this.inferCarrier();
    return this._carrier_id;
  }

  get full_raw_client(): string {
    return this._destroy ? '' : (this.raw_client || '') + ' ' + (this.raw_client_plus || '');
  }

  get filter_tags(): string[] {
    return this.full_raw_client.
      toLocaleLowerCase().
      replace(/\.|\,|\//gm, ' ').
      split(/\s+/).
      filter((f: string) => f.length > 0) || [];
  }

  get isFreight(): boolean {
    return this.arrive_packing_list && this.arrive_packing_list.isFreight;
  }

  get isParcel(): boolean {
    return !this.isFreight;
  }

  get damage_report_attributes(): DamageReport{
    return this.damage_report;
  }

  get client(): Client {
    return this.arrive_packing_list && this.arrive_packing_list.client;
  }

  get client_name(): string {
    return this.client ? this.client.name : '';
  }

  get carrier_name(): string {
    return this.arrive_packing_list.arrival.carrier_name;
  }

  get receipt_id(): string {
    return this._receipt_id || this.arrive_packing_list.receipt_id;
  }

  get isLocated(): boolean {
    return !!this.location_tag;
  }

  get isLocatedAndOnHand(): boolean {
    return this.isLocated && LocatedStatus.includes(this.status);
  }

  get part_number(): PartNumber {
    return this._part_number;
  }

  get part_number_name(): string {
  	  return this.part_number ? this.part_number.name : '';
  }

  set part_number_name(value: string) {
  	  this._part_number_name = value;
  }

  get part_number_description(): string {
  	  return this.part_number ? this.part_number.description : '';
  }

  set part_number(value: PartNumber) {
    this._part_number = value;
    this.part_number_id = value.id;
    this._part_number_name = value.name;
    this.quantity = value.quantity || this.quantity;
    value.quantity = undefined;
  }

  get usePartNumber(): boolean {
    return this.arrive_packing_list &&
      this.arrive_packing_list.usePartNumber;
  }

  get zpl(): string {
    if (!this.arrive_packing_list.client_code_match)
      throw new Error('Client code missmatch, refresh (F5)');
    return `^XA

^FX Packing section.
^CFA,30
^FO50,20^FDTRK:${this.arrive_packing_list.master_label}^FS
^BY4,2,100
^FO50,50^BC^^FD${this.receipt_id}^FS

^FX Top section with logo, name and address.
^CF0,60
^FO250,260^GD40,90,18^FS
^FO271,315^GB30,20,20^FS
^FO305,260^GB15,90,10^FS
^FO294,253^GB40,40,10,W^FS
^FO330,260^FDRio Bravo^FS
^CF0,30
^FO330,325^FH^FDAmerican Industries_A9^FS
^FO50,400^GB700,1,3^FS

^FX Second section with recipient address and permit information.
^CFA,30
^FO50,425^FDClient:^FS
^CF0,55
^FO50,470^FD${this.client_name}^FS
^CFA,15
^FO50,550^GB700,1,3^FS

^FX Third section with barcode.
^CFA,30
^FO50,560^FDReceipt Id:^FS
^BY4,2,100
^FO50,600^BC^FD${this.receipt_id}^FS
^FO50,760^FDRcv Date:^FS
^CF0,30
^FO220,760^FD${moment(this.created_at).format('MMMM D, YYYY h:mma')}^FS
^CFA,30
^FO50,800^FDCarrier:^FS
^CF0,30
^FO220,800^FD${this.carrier_name}^FS
^CFA,30
^FO50,840^FDRef:^FS
^CF0,30
^FO220,840^FD${this.po}^FS
^CFA,30
^FO50,880^FDPackage:^FS
^CF0,30
^FO220,880^FD${this.raw_cardinality}^FS
^CFA,30
^FO50,920^FDTRK:^FS
^CF0,30
^FO220,920^FD${this.short_trk}^FS
^CFA,15
^FO50,960^GB700,1,3^FS

${this.zpl_part}

^FX Fourth section (package codebar).
^CFA,30
^FO50,980^FDPackage Id:^FS
^BY4,2,100
^FO50,1020^BC^FDPK${this.id}^FS

^FO50,1160^GB700,1,3^FS
^CF0,30
^FO50,1180^FH^FDThink Fordward_A9^FS
^FO500,1180^FDwww.airiobravo.com^FS
^XZ`;
  }


  get zpl_part(): string {
    if (this.part_number_id)
      return `
^CFA,30
^FO520,840^FDPN:^FS
^CF0,30
^FO590,840^FD${this.part_number.name}^FS
^CFA,30
^FO520,880^FDQty:^FS
^CF0,30
^FO590,880^FD${this.quantity}^FS
^CFA,30
^FO520,920^FDTyp:^FS
^CF0,30
^FO590,920^FD${this.part_number.product_type || ''}^FS
`;
    else
      return `
^FO570,630
^BQN,2,6
^FDMM,https://airiobravowms.com/search/PK${this.id}^FS
`;
  }

  inferCarrier(){
    if (!this.carrier_code)
      return;
    var carriers_matched = Carrier.CARRIERS_LIST.find((c: Carrier) => c.carrier_code === this.carrier_code);
    this._carrier_id = (carriers_matched || {id: null}).id;
  }

  copy_po() {
    this.arrive_packing_list.apply_po_to_all(this.po);
  }

  drop() {
    this.arrive_packing_list.drop(this);
  }

  merge(pl: ArrivePackingList){
    _.mergeWith(this, pl, Package.customMerge);
  }

  public static customMerge(fromServer: any, inState: any, key: string): any {
    if (key == "id"  ||
      key == "arrive_packing_list_id" ||
      ((key == "cardinality" || key == "_raw_cardinality") && fromServer) )
      return fromServer;
    if (!inState && fromServer)
      return fromServer;
  }

  public static customCompare(one: any, other: any, key: string): boolean {
    if (key === "weight")
      return (Math.round(one * 100000) / 100000) == (Math.round(other * 100000) / 100000);
    if (key === "updated_at") return true;
  }

  get sort_index(): number {
    return this.isFreight && this.cardinality ? this.cardinality[0]*-1 : +this.created_at;
  }

  get isConsigned(): boolean {
    return ConsignedStatus.includes(this.status);
  }
  set isConsigned(value: boolean) {
    if (value && this.status != PackageStatuses.shipped) this.status = PackageStatuses.consigned;
  }

  get isPalletizable(): boolean {
    return (this.arrive_packing_list.haveShipment && this.isConsigned && !this.pallet_id) ||
      (this.haveShipment && this.isConsigned && !this.pallet_id) ||
      (!this.arrive_packing_list.haveShipment && !this.pallet_id) ||
      (!this.haveShipment && !this.pallet_id);
  }

  get isPacked(): boolean {
    return this.status == PackageStatuses.shipped;
  }

  get shipping_category(): string {
    if (this.pallet_id && this.pallet.container_type_id)
      return 'loose_packages';
    if (this.isFreight)
      return 'freight_packages';
    else
      return 'parcel_packages';
  }

  get propWeight(): number {
    return this.isFreight ? this.arrive_packing_list.bundleWeight : this.weight;
  }

  set pallet(value: Pallet) {
    this._pallet = value;
    this.pallet_id = value && value.id;
  }

  get pallet(): Pallet {
    return this._pallet;
  }

  get isPalletized(): boolean {
    return this.status == PackageStatuses.palletized;
  }
  set isPalletized(value: boolean) {
    if (value)
      this.status = PackageStatuses.palletized;
    else {
      if (this.arrive_packing_list.shipment_id)
        this.status = PackageStatuses.consigned;
      else {
        if (this.isValid)
          this.status = PackageStatuses.processed;
        else
          this.status = PackageStatuses.received;
      }
      this.pallet = null;
    }
  }

  get isPrePalletized(): boolean {
    return this.status == PackageStatuses.palletized && (!this.pallet || this.pallet.id < 0 || this.pallet.consolidation);
  }

  get isShipped(): boolean{
    return this.status == PackageStatuses.shipped;
  }

  set isShipped(value: boolean) {
    this.status = value ? PackageStatuses.shipped : PackageStatuses.consigned;
  }

  get packages(): Package[] {
    return [this];
  }

  get arrival_open(): boolean {
    return this.arrive_packing_list.arrival.open;
  }

  get arrival_id(): number {
    return this.arrive_packing_list ? this.arrive_packing_list.arrival_id : 0;
  }

  get arrival(): Arrival {
    return this.arrive_packing_list && this.arrive_packing_list.arrival;
  }

  get client_id(): number {
    return this.arrive_packing_list.client_id;
  }

  palletizable(good_category_id: number) : boolean {
    return this.good_category_id == good_category_id &&
      !this.pallet_id &&
      this.isPalletized;
  }

  compare(trk: string): boolean {
    return this.trk == trk || this.simple_trk == trk || this.code == trk;
  }

  get allow_update(): boolean {
    return !this.dense &&
      !(this.arrive_packing_list && this.arrive_packing_list.client_id) &&
      !this.isValid;
  }

  get isPicked(): boolean {
    return PickedStatus.includes(this.status);
  }

  get inStock(): boolean {
    return OnStockStatus.includes(this.status);
  }

  get haveShipment(): boolean {
    return !!this.shipment_id ||
      !!this.shipment ||
      !!this.arrive_packing_list.shipment ||
      !!this.arrive_packing_list.shipment_id;
  }

  get canOrderToShipment(): boolean {
  	  return OnStockStatus.includes(this.status) && !this.shipment_id;
  }

  belongToShipment(id: number): boolean {
    return (this.shipment_id && this.shipment_id == id) ||
      (this.shipment && this.shipment.id == id) ||
      (this.arrive_packing_list && this.arrive_packing_list.shipment_id && this.arrive_packing_list.shipment_id == id);
  }

  get _shipment_id(): number {
    return this.shipment_id || (this.shipment && this.shipment.id);
  }

  get part_numbers_dic(): {[id: number]: PartNumber} {
    if (this.part_number) {
      this.part_number.quantity = this.quantity;
      return {[this.part_number.id]: this.part_number};
    } else
      return {};
  }

  unlocate() {
    this.location_id = undefined;
    this.location_tag = undefined;
    this.status = PackageStatuses.processed;
  }

  locate(storageLocation: IStorageLocation){
    this.location_id = storageLocation.location_id;
    this.location_tag = storageLocation.location_tag;
    this.status = PackageStatuses.stored;
  }

  set last_print(value: Date) {
    this._last_print = value;
  }

  get last_print(): Date {
    return this._last_print;
  }

  get alredyPrinted(): boolean {
    return !!this._last_print;
  }

  get canShip(): boolean {
  	  return this.isParcel || (this.isFreight && this.isPicked);
  }

}
