import { Component, OnInit, Input, OnChanges, SimpleChanges, EventEmitter, Output } from '@angular/core';
import { CatalogsService, Country, Address, stateJson, cityJson, CountryJson } from 'wms-lib';
import * as actions from 'wms-lib';
import * as _ from 'lodash';
import { Store } from '@ngxs/store';

@Component({
    selector: 'app-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss']
})
export class AddressFormComponent implements OnInit, OnChanges {
    @Input() address: Address = new Address({});
    @Output() addressChange = new EventEmitter<Address>();
    countries: { [id: string]: Country } = {};
    country: CountryJson;

    states: { [id: string]: stateJson } = {};
    cities: { [id: string]: cityJson } = {};
    addresses: { [id: string]: Address } = {};

    constructor(protected store: Store, private catalogsService: CatalogsService) {
        this.store.dispatch(new actions.GetCatalogAction<Country>({name_catalog: 'countries', live: false}));
        this.store.select(state => state.catalogsState.countries).subscribe((countries: { [id: string]: Country }) => {
            this.countries = countries;
            this.setCountry();
        });
    }
    ngOnChanges(changes: SimpleChanges): void {
        if (changes.address) {
            if(changes.address.currentValue){
                this.address = changes.address.currentValue || new Address({})
                this.setCountry();
            } else {
                this.address = new Address({});
            }
        }
    }

    setCountry(){
        if(this.address.country_id && _.size(this.countries) > 0){
            let key = _.get(this.countries[this.address.country_id], 'key', '');
            if(key){
                this.country = actions.countriesJson.getCountries()[key];
                this.states = this.country.states;
                if(this.address.state){
                    let state = _(this.states).filter({ state_code: this.address.state }).value()[0];
                    if(state && state.cities && _.size(state.cities) > 0){
                        this.cities = state.cities; 
                    } else {
                        this.cities = {};
                    }
                }
            } 
        } else {
            this.country = null;
        }
    }

    ngOnInit() {
    }

    onSubmit() {
        this.addressChange.emit(this.address);
    }

    changeState(state: any) {
        if (typeof state === 'string') {
            this.address.state = state;
        } else {
            this.address.state = state.state_code;
        }
        this.setCountry();
        this.address.city = '';
    }

    changeCity(city: any) {
        if (typeof city === 'string') {
            this.address.city = city;
        } else {
            this.address.city = city.name
        }
    }

    loadStates(countryId: string) {
        this.address.state = '';
        this.address.city = '';
        this.setCountry();

    }

}
