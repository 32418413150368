import { Component } from '@angular/core';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { Client, Shipment, SetWorkingShipmentAction } from 'wms-lib';
import { map } from 'rxjs/operators';
import { StateListenerComponent } from '../../../../shared/components/scan-listener/state-listener.component';

@Component({
    selector: 'app-shipment-builder',
    templateUrl: './shipment-builder.component.html',
    styleUrls: ['./shipment-builder.component.scss']
})
export class ShipmentBuilderComponent extends StateListenerComponent {
    usePartNumber$: Observable<boolean>;
    shipment?: Shipment;
    activeTab: number = 0;
    constructor(private store: Store) {
        super();
        this.usePartNumber$ = this.store.select((state) => state.catalogsState.active_client).pipe(
            map((u: Client) => u && u.part_number)
        );
        this.addSub(this.store.select(state => state.shipmentsState.shipment_template).subscribe((ship) => {
            this.shipment = ship;
            if (this.shipment && !this.canShipByPart && this.activeTab == 0)
                this.activeTab = 1;
        }))
    }

    resetShipment() {
        if (this.shipment) this.store.dispatch(new SetWorkingShipmentAction(this.shipment.id))
    }

    get canShipByPart() {
        return this.shipment &&
            (this.shipment.isNew || this.shipment.isByPart);
    }

    get canShipByReceipt() {
        return this.shipment &&
            (this.shipment.isNew || !this.shipment.isByPart);
    }
}


