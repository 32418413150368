import { Component, OnInit, Input, OnChanges, SimpleChanges, Output, EventEmitter } from '@angular/core';
import { CatalogsService, Client, Country, Forward, NotificationService } from 'wms-lib';
import * as actions from 'wms-lib';
import * as _ from 'lodash';
import { Store } from '@ngxs/store';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-forwards',
    templateUrl: './forward.component.html',
    styleUrls: ['./forward.component.scss']
})
export class ForwardComponent implements OnInit, OnChanges {
    @Input() client?: Client;
    @Output() selectedForward = new EventEmitter<Forward>();
    items: { [id: string]: Forward } = {};
    forward: Forward;
    editedForwards: { [id: string]: Forward } = {};
    forwards_import: any;
    importModalShow: boolean = false;
    importing: boolean = false;
    countries: { [id: string]: Country };
    _forwards: Forward[] = [];

    constructor(protected store: Store,private catalogsService: CatalogsService, protected notificationService: NotificationService) {
        this.store.dispatch(new actions.LoadForwardsAction());
        this.store.select(state => state.exposState.forwards).subscribe((forwards) => {
            this.items = _.cloneDeep(forwards) || {};
            this._forwards = _(this.items).filter((item) => item.client_id === this.client?.id).orderBy(['name'], ['asc']).value();
            this.importing = false;
            this.importModalShow = false;
            if(this.forward){
                this.forward = this.items[this.forward.id];
                this.onRowSelect({data: this.forward});
            }
        });
        this.store.select(state => state.catalogsState.countries).subscribe((countries) => {
            this.countries = countries;
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.client) {
            this.client = changes.client.currentValue;
        }
    }

    ngOnInit() {
    }

    addNew() {
        this._forwards = this._forwards || [];
        this.onRowUnselect(null);
        let c = this.newItem({name:'', client_id: this.client?.id});
    	this._forwards.unshift(c);
    }

    delete(item: Forward) {
        if (item.isNew){
            delete this.items[item.id];
        } else {
            this.notificationService.showFeedback(
                'Are you sure you want to delete ' + item.name + '?',
                'Confirm',
                false,
                null,
                () => {
                    this.store.dispatch(new actions.DeleteForwardAction({id : item.id}));
                    this.clearNew();
                }
            );
        }
    }

    onRowEditInit(item: Forward) {
        this.editedForwards[item.id] = _.cloneDeep(item);
    } 

    onRowEditSave(item: Forward) {
        const forward = this.editedForwards[item.id];
        this.store.dispatch(new actions.SaveForwardAction({forward: forward}));
        this.clearNew();
    }

    onRowSelect(event: any) {
        if(this.forward && !this.forward.isNew){
            this.selectedForward.emit(event.data);
        } else {
            this.forward = null;
            this.selectedForward.emit(null);
        }
    }

    onRowUnselect(event: any) {
        this.forward = null;
        this.selectedForward.emit(null);
    }

    clearNew() {
        this.items = _.pickBy(this.items, (item) => !item.isNew);
    }

    newItem(data: any): Forward {
        return new Forward(data);
    }

    loadXls(event: any) {
        const target: DataTransfer = <DataTransfer>(event.target);
        if (target.files.length !== 1) {
            throw new Error('Cannot use multiple files');
        }
        const reader: FileReader = new FileReader();
        reader.readAsBinaryString(target.files[0]);
        reader.onload = (e: any) => {
            const binarystr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);
            event.target.value = null;
            this.forwards_import= _(data).map((d)=>{
                let address = {
                    address1: d['Address 1'],
                    address2: d['Address 2'],
                    address3: d['Address 3'],
                    city: d['City'],
                    state: d['State'],
                    zip: d['Zip'],
                    country: d['Country'],
                    phone: d['Phone'],
                    email: d['Email'],
                    contact_name: d['Contact Name'],
                    country_id: this.searchContryByName(d['Country'])?.id
                };
                let forward = {
                    name: d['Name'],
                    client_id: this.client.id,
                    address: address
                };
                return this.newItem(forward);
            }).value();
            this.importModalShow = true;
        };
    }

    executeImport() {
        this.importing = true;
        this.store.dispatch(new actions.ImportForwardsAction({forwards: this.forwards_import}));
    }

    searchContryByName(name: string): Country {
        name = _.deburr(name).toLowerCase();
        return _.find(this.countries, (country) => _.deburr(country.name).toLowerCase() == name);
    }

}
