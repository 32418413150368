import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import * as store_actions from 'wms-lib';
import { Store } from '@ngxs/store';
import { Router } from '@angular/router';
import { ShipmentGeneralFormComponent } from './shipment-general-form/shipment-general-form.component';

@Component({
  selector: 'app-home',
  templateUrl: './shipment.component.html',
  styleUrls: ['./shipment.component.scss']
})
export class ShipmentComponent implements OnInit {
  mode: string = 'parts';
  saving: boolean = false;
  modeOptions = [
    {icon: 'fas fa-cogs', title: 'Use tool for part numbers', value: 'parts'},
    {icon: 'fas fa-boxes', title: 'Use tool for receipt', value: 'receipts'}
  ];

  constructor(private store: Store,
              private router: Router,
              private activated_route: ActivatedRoute) { }

  ngOnInit() {
    this.activated_route.params.subscribe((params: any) =>
      this.store.dispatch(new store_actions.SetWorkingShipmentAction(params.id)));
  }

  cancel() {
    this.router.navigate(['shipments', 'orders']);
  }

  save(shipmentGf: ShipmentGeneralFormComponent) {
    this.saving = true;
    shipmentGf.save();
  }
}
