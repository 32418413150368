import { Component, OnInit } from '@angular/core';
import {DialogService} from 'primeng/dynamicdialog';
import {DynamicDialogRef} from 'primeng/dynamicdialog';
import {DynamicDialogConfig} from 'primeng/dynamicdialog';
import { AuthenticationService } from 'wms-lib';
import { interval } from 'rxjs';
import { takeWhile, timeInterval } from 'rxjs/operators';
import { StateListenerComponent } from '../../components/scan-listener/state-listener.component';
import { Router } from '@angular/router';

@Component({
  selector: 'app-refresh-session',
  templateUrl: './refresh-session.component.html',
  styleUrls: ['./refresh-session.component.scss'],
  providers: [DialogService]
})
export class RefreshSessionComponent extends StateListenerComponent implements OnInit {
  countDown: number;
  idle: boolean = false;
  constructor(public authenticationService: AuthenticationService, public ref: DynamicDialogRef, public config: DynamicDialogConfig, private router: Router) {
    super();
    if (config && config.data) {
      if (config.data.countdown) {
        this.addSub(interval(1000).pipe(
          timeInterval(),
          takeWhile(i => i.value < config.data.countdown)
        ).subscribe(
          i => this.countDown = config.data.countdown - i.value,
          (error: any) => console.log(error),
          () => this.signout()
        ));
      }
      this.idle = config.data.idle || false;
    }
  }

  ngOnInit() {
  }

  refresh() {
    this.authenticationService.refresh();
    this.ref.close();
  }

  signout(){
    this.authenticationService.LogOut();
    this.router.navigate(['/','login'])
    this.ref.close();
  }

}
