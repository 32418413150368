import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Catalog, PackingsFilter, Package, Resume, ArrivePackingList, Shipment } from 'wms-lib';
import * as _ from 'lodash';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-receipts-resume',
  templateUrl: './receipts-resume.component.html',
  styleUrls: ['./receipts-resume.component.scss']
})
export class ReceiptsResumeComponent implements OnInit, OnChanges {
  @Input() filter: PackingsFilter;
  @Input() pls: ArrivePackingList[];
  @Input() shipment: Shipment;
  bundle_types$: Observable<{[id: number]: Catalog }>;
  resume: Resume;

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.bundle_types$ = this.store.select(state => state.catalogsState.bundle_types);
  }

  ngOnChanges(event: any): void {
    if (event.pls) {
      this.resume = new Resume(_(this.pls).
        filter((apl: ArrivePackingList) => !this.filter || apl.client_id == this.filter.client_id).
        map((apl: ArrivePackingList) => _.values(apl.packages)).
        flatten().
        groupBy("bundle_type_id").
        map((g: Package[], k: string) => ({
          bundle_type_id: k,
          qty_ready_to_ship: g.length,
          qty_selected: _.sumBy(g, (p: Package) => this.isSelected(p.arrive_packing_list) ? 1 : 0),
          weight_ready_to_ship: _(g).map((p: Package) => p.propWeight).sum(),
          weight_selected: _.sumBy(g, (p: Package) => this.isSelected(p.arrive_packing_list) ? p.propWeight : 0)
        })).value());
    }
  }

  isSelected(pl: ArrivePackingList) {
    return this.shipment && this.shipment.arrive_packing_lists[pl.id];
  }
}
