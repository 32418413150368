import { Component } from '@angular/core';
import { CatalogLogoComponent } from "../../base-components/catalog-logo.component";
import { Carrier, AuthenticationService } from "wms-lib";
import { Store } from '@ngxs/store';
import { NotificationService } from 'wms-lib';
import * as _ from 'lodash';

@Component({
    selector: 'app-carriers',
    templateUrl: './carriers.component.html',
    styleUrls: ['./carriers.component.sass']
})
export class CarriersComponent extends CatalogLogoComponent<Carrier> {
    name_catalog: string = "carriers";

    constructor(store: Store,
                notificationService: NotificationService,
                protected authenticationService : AuthenticationService
               ) { super(store, notificationService); }

    ngOnInit(): void {
        super.ngOnInit();
    }

    get types(): string[] {
        return _.uniq(_.map(this.items,'type').concat(['ForwardCarrier']));
    }

    newItem(data: any) {
        return new Carrier(data);
    }

    get canDelete(): boolean {
        return this.authenticationService.currentUserValue.isAdmin;
    }

    get canCreate(): boolean {
    	return this.authenticationService.currentUserValue.isAdmin;
	}
}
