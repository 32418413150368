import { Catalog } from "./catalog";

export class ContainerType extends Catalog {
    validationKey: string = "container_type";
    weight: number;
    default: string;

    constructor(data:any) {
        super(data);
        if(this.weight) this.weight = +this.weight;
    }

    protected get saveProp(): string[] {
        return  super.saveProp.concat(["weight","default"]);
    }

    get isDefault(): boolean {
        return this.default == 't';
    }

    set isDefault(value: boolean) {
        this.default = value ? 't' : 'f';
    }
}
