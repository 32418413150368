import { Validable, IShareable } from "./common";
import { ArrayNotEmpty, ValidateNested, IsNotEmpty } from 'class-validator';

export class Tracking extends Validable implements IShareable {
    validationKey: string = "tracking";
    comment: string;
    created_at: Date;
    client_name: string;
    trackeable_type: string;
    share_html: string;
    share_text: string;
    subject: string;
    error_message: string;
    validate_trks: boolean;
    @ArrayNotEmpty()
    @ValidateNested()
    tracking_details: TrackingDetail[];
    get quantity(): number {
        return this.tracking_details.length;
    }
    get sort_field(): number {
        return +this.created_at*-1;
    }
    get saveProp(): string[] {
        return super.saveProp.concat(["comment","validate_trks", "tracking_details_attributes"]);
    }
    get tracking_details_attributes(): any[] {
        return this.tracking_details;
    }

    get isNew(): boolean {
    	return this.id < 0;
    }

    constructor(data?: any) {
        super(data);
        this.id = this.id || +(new Date())*-1;
        this.created_at = this.created_at || new Date();
        this.tracking_details = this.tracking_details || [];
        this.comment = this.comment || "";
        this.error_message = this.error_message || "";
        if (data && data.tracking_details)
            this.tracking_details = data.tracking_details.map((d: any) => new TrackingDetail(d));
    }

  addTrk(trks: string[], format: RegExp): boolean {
        let result = false;
        trks.forEach((trk: string) => {
            if (!this.tracking_details.find(f => f.trk.toUpperCase() == trk.toUpperCase()) &&
              format.exec(trk.toUpperCase())) {
                result = true;
                this.tracking_details.unshift(new TrackingDetail({trk: trk.toUpperCase()}));
            }
        });
        return result;
    }

	get filename(): string {
		return `tracking_${this.id}.xlsx`;
	}

	get as_csv(): string {
		let data =  this.tracking_details_to_export.map((d: any) => `"${d.client_name}";"${d.type}";"'${d.trk}";"${d.comment}";"${d.found_at}";"${d.processed_date}";"${d.receipt_id}";"${d.receipt_date}"`).join("\n");
		return `"client_name";"type";"trk";"comment";"found_at";"processed_date";"receipt_id";"receipt_date"\n${data}`;
	}

	get tracking_details_to_export(): any[] {
		return this.tracking_details.map((d: TrackingDetail) => ({
      client_name: this.client_name,
      type: this.trackeable_type,
      trk: d.trk,
      comment: d.comment,
      found_at: d.found_at ? d.found_at.toISOString() : '',
      processed_date: d.processed_date ? d.processed_date.toISOString() : '',
      receipt_id: d.receipt_id,
      receipt_date: d.receipt_date ? d.receipt_date.toISOString() : ''
    })).concat({
      client_name: '',
      type: 'Total Bundles',
      trk: ''+this.tracking_details.length,
      comment: '',
      found_at: '',
      processed_date: '',
      receipt_id: '',
      receipt_date: ''
    });
	}
}


export class TrackingDetail extends Validable {
    validationKey: string = "tracking_detail";

    @IsNotEmpty()
    trk: string;
    trk_dense: string;
    comment: string;
    _destroy: boolean;
    found_at: Date | null;
    processed_date: Date;
    receipt_id: string;
	  receipt_date: Date;


    client_name: string;

    get saveProp(): string[] {
        if (this._destroy && this.id > 0)
            return ['id', '_destroy'];
        else
            return super.saveProp.concat(["trk", "comment", "found_at", "trk_dense"]);
    }

    constructor(data?: any) {
        super(data);
        this.trk = this.trk || "";
        this.trk_dense = this.trk_dense || "";
        this.comment = this.comment || "";
		this.found_at = this.found_at || null;
        this._destroy = false;
    }
}
