import * as _ from 'lodash';
import { Bindable } from './common';
import { PlatformService } from '../platform-services';

export class TimezoneJson extends Bindable {
    zoneName: string;
    gmtOffset: number;
    gmtOffsetName: string;
    abbreviation: string;
    tzName: string;

    constructor(data?: any) {
        super(data);
    }

}

export class stateJson extends Bindable {
    id: number;
    name: string;
    state_code: string;
    latitude: string;
    longitude: string;
    type: string;
    cities: { [id: string]: cityJson} = {};

    constructor(data: any) {
        super(data);
        if (data['cities']) this.buildCities(data['cities']);
    }

    buildCities(cities: any[]) {
        this.cities = {};
        for(let c of cities) {
          this.cities[c.id] = new cityJson({...c});
        }
    }

}

export class cityJson extends Bindable {
    id: number;
    name: string;
    latitude: string;
    longitude: string;

    constructor(data?: any) {
        super(data);
    }
}

export class CountryJson extends Bindable {
    id: number;
    name: string;
    iso3: string;
    iso2: string;
    numeric_code: string;
    phone_code: string;
    capital: string;
    currency: string;
    currency_name: string;
    currency_symbol: string;
    tld: string;
    native: string;
    region: string;
    region_id: string;
    subregion: string;
    subregion_id: string;
    nationality: string;
    timezones: TimezoneJson[] = [];
    translations: any;
    latitude: string;
    longitude: string;
    emoji: string;
    emojiU: string;
    states: { [id: string]: stateJson } = {};

    constructor(data: any) {
        super(data);
        if (data['timezones']) this.buildTimezones(data['timezones']);
        if (data['states']) this.buildStates(data['states']);
    }

    buildStates(states: any[]) {
        this.states = {};
        for(let s of states) {
          this.states[s.state_code] = new stateJson({...s});
        }
    }

    buildTimezones(timezones: any[]) {
        this.timezones = timezones.map((timezone: any) => new TimezoneJson(timezone));
    }

}


export const countriesJson = {

    test() {
      return _(PlatformService.getLocationsCatalog()).map((country: any) => new CountryJson(country)).keyBy('iso2').value();
    },

    getCountries() {
      return _(PlatformService.getLocationsCatalog()).map((country: any) => new CountryJson(country)).keyBy('iso2').value();
    },

    getStates(countryId: string) {
        return _(this.getCountries()).filter({ iso2: countryId }).map('states').flatten().keyBy('state_code').value();
    },
}
