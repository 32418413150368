import { Component, OnInit } from '@angular/core';
import { CatalogsState, Client, GoodCategory, PartNumber, Unit } from 'wms-lib';
import { CatalogComponent } from '../../base-components/catalog.component';
import * as actions from 'wms-lib';
import * as _ from 'lodash';
import { combineLatest } from 'rxjs';
import * as XLSX from 'xlsx';

@Component({
    selector: 'app-part-numbers',
    templateUrl: './part-numbers.component.html',
    styleUrls: ['../catalogs.component.sass']
})
export class PartNumbersComponent extends CatalogComponent<PartNumber> {
    name_catalog = 'part_numbers';
    clients: {[id: number]: Client};
    good_categories: {[id: number]: GoodCategory};
    units: {[id: number]: Unit};
    good_category_id: number;
    importing: boolean = false;
    importModalShow: boolean = false;
    active_client: Client;
    part_numbers_import: any;
    loading: boolean = true;

    ngOnInit(): void {
        this.store.dispatch(new actions.GetCatalogAction<Client>({name_catalog: 'clients', live: false}));
        this.store.select((state) => state.catalogsState['clients']).subscribe((clients: {[id: number]: Client}) => this.clients = clients);
        this.store.select(state => state.catalogsState.units).subscribe(data => this.units = data);
        this.store.select(CatalogsState.getPartNumbersByActiveClient()).subscribe((parts: any)=> {
            this.items = parts || {};
            this.loading = parts ? false : true;
            this.importing = false;
            this.importModalShow = false;
        });

        combineLatest(
            this.store.select(state => state.catalogsState.good_categories),
            this.store.select((state) => state.catalogsState.active_client) 
        ).subscribe(([good_categories, client]) => {
            this.active_client = client;
            this.good_categories = _(good_categories).filter((f: GoodCategory) => (client && f.client_id == client.id) || f.client_id == null).keyBy("id").value();
        });
    }

    addNew(_type?: string) {
        let c = this.newItem({name: '', description: '', client_id: this.active_client.id, product_type: null, unit_id: null, category_id: null});
        this.items[c.id] = c;
        this.items = {...this.items};
    }

    newItem(data: any) {
        return new  PartNumber(data);
    }

    save(item: PartNumber) {
        this.store.dispatch(new actions.SaveGroupCatalogAction<PartNumber>({catalog: item, name_catalog: this.name_catalog}));
    }

    deletePartNumber(item: PartNumber) {
        this.notificationService.showFeedback(
            'Are you sure you want to delete ' + item.name + '?',
            'Confirm',
            false,
            null,
            () => this.store.dispatch(new actions.DeleteGroupCatalogAction<PartNumber>({catalog: item, name_catalog: this.name_catalog}))
        );
    }

    loadXls(event: any) {
        const target: DataTransfer = <DataTransfer>(event.target);
        if (target.files.length !== 1) {
            throw new Error('Cannot use multiple files');
        }
        const reader: FileReader = new FileReader();
        reader.readAsBinaryString(target.files[0]);
        reader.onload = (e: any) => {
            const binarystr: string = e.target.result;
            const wb: XLSX.WorkBook = XLSX.read(binarystr, { type: 'binary' });
            const wsname: string = wb.SheetNames[0];
            const ws: XLSX.WorkSheet = wb.Sheets[wsname];
            const data = XLSX.utils.sheet_to_json(ws);
            event.target.value = null;
            this.part_numbers_import= _(data).map((d)=>
                this.newItem({name: d['Numero de Parte'], description: d['Descripcion Español'], client_id: this.active_client.id, product_type: d['Tipo de Parte'], category_id: null, unit_name: d['Unidad Medida']})
            ).value();
            this.importModalShow = true;
        };
    }

    executeImport () {
        let defaultCategory = _.findKey(this.good_categories, 'isDefault');

        if(!this.good_category_id && defaultCategory){
            this.good_category_id = defaultCategory
        }

        let options = {
            good_category_id: this.good_category_id,
            client_id: this.active_client.id
        }
        this.importing = true;
        this.import(this.part_numbers_import,options);
    }
}

