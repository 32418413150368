import { ArrivePackingList, Shipment, Package, GoodCategory, RemoteAsset, Pallet } from "../../models";
import { PackingsFilter } from "../../view-models";

export class AddPackingListToShipmentAction {
  public static readonly type = '[Shipments] Add Packing List to shipment';
  constructor(public payload: ArrivePackingList){}
}

export class AddPackingListsToShipmentAction {
  public static readonly type = '[Shipments] Add Packing Lists to shipment';
  constructor(public payload: ArrivePackingList[]){}
}

export class AddPackageToShipmentAction {
  public static readonly type = '[Shipments] Add Package to shipment';
  constructor(public payload: Package){}
}

export class AddPartToShipmentAction {
  public static readonly type = '[Shipments] Add Part to shipment';
  constructor(public payload: Package){}
}

export class SaveShipmentAction {
  public static readonly type = '[Shipments] Save shipment action';
  constructor(public payload: { shipment: Shipment, keep_working?: boolean}){}
}

export class DeleteShipmentAction {
  public static readonly type = '[Shipments] Delete shipment action';
  constructor(public payload: Shipment){}
}

export class AddOrDeletePackageFromShipmentAction {
  public static readonly type = '[Shipments] Add or delete package from shipment';
  constructor(public payload: { pkg: Package}){}
}

export class GetOpenShipmentsAction {
  public static readonly type = '[Shipments] Get Open Shipments';
  constructor() { }
}

export class GetOpenAndClosedShipmentsAction {
  public static readonly type = '[Shipments] Get Open and Closed Shipments';
  constructor(public payload: { user_id: number }) { }
}

export class GetPagedShipmentsAction {
  public static readonly type = '[Shipments] Get Paged Shipments';
  constructor(public payload: { client_id: number, page: number, append: boolean }) { }
}

export class GetPackingsAction {
  public static readonly type = '[Clients] Get Packing For Client';
  constructor(public payload: PackingsFilter ) { }
}

export class GetGoodsCategoriesAction {
  public static readonly type = '[Clients] Get Good Categories';
  constructor() { }
}

export class UpdatePackingCategoryAction {
  public static readonly type = '[Clients] Update Packing Category';
  constructor(public payload: { pl: ArrivePackingList, old_cat: number, flush: boolean }) { }
}

export class UpdatePackingCategoryInBatchAction {
  public static readonly type = '[Clients] Update Packing Category in batch';
  constructor(public payload: ArrivePackingList[]) { }
}

export class UpdateShipmentPackageAction {
  public static readonly type = '[Shipments] Update Package';
  constructor(public payload: Package) { }
}

export class SetWorkingShipmentAction {
  public static readonly type = '[Clients] Set working shipment';
  constructor(public payload: number) { }
}

export class SetShipmentPalletIdAction {
  public static readonly type = '[Clients] Set pallet id to pacakges';
  constructor(public payload: {shipment: Shipment, good_category: GoodCategory}) { }
}

export class UnPalletAction {
  public static readonly type = '[Clients] Un pallet';
  constructor(public payload: Pallet | Package) { }
}

export class UpdateImageAction {
  public static readonly type = '[Clients] Update image to shipment';
  constructor(public payload: RemoteAsset | RemoteAsset[]) { }
}

export class ClearShipmentsStateAction {
  public static readonly type = '[Clients] Clear shipments state';
}

export class ReloadShipmentsPageAction {
  public static readonly type = '[Shipments] Reload shipments page';
  constructor(public payload: number) { }
}

export class LoadImagesAction {
  public static readonly type = '[Shipments] Load the images of a shipment';
  constructor(public payload: number) { }
}

export class LoadPackingListAction {
  public static readonly type = '[Shipments] Load Packing List';
  constructor(public payload: Package){}
}

export class ReLoadPackingListAction {
  public static readonly type = '[Shipments] ReLoad Packing List';
  constructor(public payload: ArrivePackingList){}
}

export type shipmentsActions = AddPackingListToShipmentAction |
  SaveShipmentAction |
  AddOrDeletePackageFromShipmentAction |
  GetOpenShipmentsAction |
  GetPagedShipmentsAction |
  GetPackingsAction |
  GetGoodsCategoriesAction |
  UpdatePackingCategoryAction |
  UpdateShipmentPackageAction |
  SetWorkingShipmentAction |
  SetShipmentPalletIdAction |
  UpdateImageAction |
  ClearShipmentsStateAction |
  UpdatePackingCategoryInBatchAction |
  ReloadShipmentsPageAction |
  UnPalletAction |
  LoadImagesAction |
  AddPackageToShipmentAction |
  AddPartToShipmentAction |
  LoadPackingListAction |
  ReLoadPackingListAction
;
