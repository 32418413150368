import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { Package, PackingsFilter, Resume, Shipment, PartNumber } from 'wms-lib';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';

@Component({
    selector: 'app-parts-resume',
    templateUrl: './parts-resume.component.html',
    styleUrls: ['./parts-resume.component.scss']
})
export class PartsResumeComponent implements OnInit, OnChanges {
    @Input() filter: PackingsFilter;
    @Input() inventory: PartNumber[];
    @Input() shipment: Shipment;
    resume: Resume;
    totalPackages: number;
    constructor(protected store: Store) {
        this.resume = new Resume([]);
    }

    ngOnInit(): void { }

    ngOnChanges(): void {
        if (this.shipment)
            this.resume = new Resume(
                _(this.shipment.packages).
                    values().
                    groupBy("part_number_id").
                    map((g: Package[], k: string) => ({
                        part_number_id: k,
                        qty_selected: g.length,
                        name: _.first(g).part_number.name,
                        description: _.first(g).part_number.description,
                        unit_name: _.first(g).part_number.unit_name,
                        quantity: _.sumBy(g, (p: Package) => p.quantity),
                    })).value()
            );
        if (this.inventory)
            this.totalPackages = _(this.inventory).sumBy((p: PartNumber) => p.packages.length );
    }

}
