import {Component, OnInit} from '@angular/core';
import { AppMainComponent } from '../../app.main.component';
import { AuthenticationService, Client, User } from 'wms-lib';
import { DomSanitizer } from '@angular/platform-browser';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {
  active_client: Client;
  model: any[];
  user: User;

  constructor(public app: AppMainComponent, private authenticationService: AuthenticationService, private sanitizer: DomSanitizer, protected store: Store,) {}

  ngOnInit() {
    this.user = this.authenticationService.currentUserValue;
    this.store.select(state => state.catalogsState.active_client).subscribe(client => {
      this.active_client = client;
      this.model = this.filterItemsByUser(this.options);
    } );
  }

  filterItemsByUser(items: any[]): any[] {
    let result = [];
    items = _.filter(items,'view');
    items.forEach((item: any) => {
      if (item.items) {
        let submenus = this.filterItemsByUser(item.items)
        if (submenus.length > 0)
          result.push({label: item.label, icon: item.icon, items: submenus})
      } else if (this.user.access.includes((item.routerLink && item.routerLink[0]) || item.report) || (item.routerLink && item.routerLink[0] == ''))
        result.push(item)
    });
    return result;
  }

  private get options(): any[] {
    return [
      {label: 'Dashboard', icon: 'fa-tachometer-alt', routerLink: [''], view: true},
      {label: 'Receipts', icon: 'fa-receipt', routerLink: ['receipts'], view: true},
      {
        label: 'Catalogs', icon: 'list', view: true,
        items: [
          {label: 'Users', icon: 'fa-users', routerLink: ['users'], view: true},
          {label: 'Warehouses', icon: 'fa-warehouse', routerLink: ['warehouses'], view: true},
          {label: 'Bundle Types', icon: 'fa-box', routerLink: ['bundle-types'], view: true},
          {label: 'Carriers', icon: 'fa-truck', routerLink: ['carriers'], view: true},
          {label: 'Clients', icon: 'fa-user-tie', routerLink: ['clients'], view: true},
          {label: 'Container Types', icon: 'fa-box-open', routerLink: ['container-types'], view: true},
          {label: 'Damage Types', icon: 'fa-exclamation-triangle', routerLink: ['damage-types'], view: true},
          {label: 'Good Categories', icon: 'fa-random', routerLink: ['good-categories'], view: true},
          {label: 'Good Types', icon: 'fa-arrows-alt', routerLink: ['good-types'], view: true},
          {label: 'Printers', icon: 'fa-print', routerLink: ['printers'], view: true},
          {label: 'Priorities', icon: 'fa-exclamation', routerLink: ['priorities'], view: true},
          {label: 'Conveyance Types', icon: 'fa-truck-loading', routerLink: ['conveyance-types'], view: true},
          {label: 'Locations', icon: 'fa-thumbtack', routerLink: ['locations'], view: true},
          {label: 'Units', icon: 'fa-th', routerLink: ['units'], view: this.active_client?.part_number},
          {label: 'Part Numbers', icon: 'fa-cogs', routerLink: ['part-numbers'], view: this.active_client?.part_number},
          {label: 'Forwards', icon: 'fa-building', routerLink: ['forwards'], view: this.active_client?.expos},
          {label: 'Classifications', icon: 'fa-object-group', routerLink: ['classifications'], view: true},
          {label: 'Commodities', icon: 'fa-cubes', routerLink: ['commodities'], view: true},
        ]
      },
      {label: 'Parcel', icon: 'fa-box', routerLink: ['receivings', 'ParcelArrival'], view: true},
      {label: 'Freight', icon: 'fa-shipping-fast', routerLink: ['receivings', 'FreightArrival'], view: true},
      {label: 'Categorization', icon: 'fa-object-group', routerLink: ['categorize-packages'], view: true},
      {label: 'Shipment Orders', icon: 'fa-dolly', routerLink: ['shipments', 'orders'], view: true},
      {label: 'Shipments', icon: 'fa-dolly', routerLink: ['shipments'], view: true},
      {label: 'Tracker', icon: 'fa-thumbtack', routerLink: ['tracker'], view: true},
      {label: 'Cyclic Inventory', icon: 'fa-sync-alt', routerLink: ['inventory', 'cyclics'], view: this.user?.isSupervisor},
      {label: 'Services', icon: 'fa-headset', routerLink: ['help-desk', 'tickets'], view: true},
      {
        label: 'KPIs', icon: 'list', view: this.user?.isSupervisor,
        items: [
          {label: 'Parcels', icon: 'fa-box', routerLink: ['kpis', 'parcels'], view: true},
          {label: 'Shipments', icon: 'fa-dolly', routerLink: ['kpis', 'shipments'], view: true},
          {label: 'Tickets', icon: 'fa-headset', routerLink: ['kpis', 'tickets'], view: true},
        ]
      },
      {label: 'ASN', icon: 'fa-share-square', routerLink: ['exports', 'asns'], view: this.active_client?.expos},
      {label: 'Deliveries Exports', icon: 'fa-retweet', routerLink: ['exports', 'deliveries'], view: (this.active_client?.expos && !this.user?.isOnlyClient)},
    ];
  }


}
