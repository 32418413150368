import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { ReceivingRoutingModule } from './receiving-routing.module';
import { SharedModule } from '../shared/shared.module';

import { CarrierSelectionDialogComponent } from './components/carrier-selection-dialog/carrier-selection-dialog.component';
import { PoSelectionDialogComponent } from './components/po-selection-dialog/po-selection-dialog.component';
import { ConciliatingComponent } from './components/conciliating/conciliating.component';
import { ArrivalComponent } from './components/arrival/arrival.component';
import { ArrivalsComponent } from './components/arrivals/arrivals.component';
import { DamagesComponent } from './components/damages/damages.component';
import { HomeComponent } from './components/home/home.component';
import { ArrivePackingListComponent } from './components/arrive-packing-list/arrive-packing-list.component';
import { ArrivePackingListsComponent } from './components/arrive-packing-lists/arrive-packing-lists.component';
import { ArrivalPackageComponent } from './components/arrival-pacakge/arrival-package.component';
import { PoSelectionDialogBaseComponent } from './components/po-selection-dialog/po-selection-dialog.component.base';
import { CarrierSelectionDialogBaseComponent } from './components/carrier-selection-dialog/carrier-selection-dialog.component.base';

import { PalletizeComponent } from './components/palletize/palletize.component';
import { PartNumbersDialogListComponent } from './components/arrive-packing-lists/part-numbers-dialog-list/part-numbers-dialog-list.component';
import { PalletDialogFormComponent } from './components/arrive-packing-list/pallets-dialog/pallets-dialog-form.component';
import { PalletDialogListComponent } from './components/arrive-packing-list/pallets-dialog/pallets-dialog-list.component';

@NgModule({
    declarations: [
        ConciliatingComponent,
        ArrivalComponent,
        ArrivalsComponent,
        DamagesComponent,
        HomeComponent,
        CarrierSelectionDialogBaseComponent,
        CarrierSelectionDialogComponent,
        PoSelectionDialogBaseComponent,
        PoSelectionDialogComponent,
        ArrivePackingListComponent,
        ArrivePackingListsComponent,
        ArrivalPackageComponent,
        PalletizeComponent,
        PartNumbersDialogListComponent,
        PalletDialogFormComponent,
        PalletDialogListComponent
    ],
    imports: [
        CommonModule,
        ReceivingRoutingModule,
        SharedModule.forRoot()
    ],
    exports: [],
    entryComponents: [CarrierSelectionDialogComponent, PoSelectionDialogComponent]
})
export class ReceivingModule { }
