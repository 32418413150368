import { Component, OnInit, ChangeDetectorRef, Directive } from '@angular/core';
import { Store } from '@ngxs/store';
import { Carrier } from 'wms-lib';
import * as _ from 'lodash';
import { Observable } from 'rxjs';

@Directive({selector: '[carrierSelectionDialogBase]'})
export class CarrierSelectionDialogBaseComponent implements OnInit {
    carriers$: Observable<{[id: number]: Carrier }>;
    params_context: any = {}; 
    constructor(protected store:Store,
                protected readonly change_detector_ref: ChangeDetectorRef
               ) { 
    }

    ngOnInit() {
        this.carriers$ = this.store.select(state => state.catalogsState.carriers);
        setTimeout(()=> this.change_detector_ref.detectChanges(),0);
    }

    get carrier_type(): string {
        return this.params_context.carrier_type;
    }

}
