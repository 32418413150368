import {  Directive, OnInit } from "@angular/core";
import { Store } from "@ngxs/store";
import { AuthenticationService, Catalog } from "wms-lib";

@Directive({selector: 'appTicketListBase'})
export class TicketListBaseComponent implements OnInit {

    public statuses: {[id: string]: Catalog}

    constructor(protected store: Store, 
                protected authenticationService: AuthenticationService) {
    }

    ngOnInit() {
        this.store.select(state => state.catalogsState.ticket_statuses).subscribe(data => {
            this.statuses = data;
        });    
    }
    
}