import { Ticket } from "../../models";

export class SaveTicketAction {
    public static readonly type = '[HelpDesk] Save ticket action';
    constructor(public payload: { ticket: Ticket}){}
}

export class GetTicketsAction {
    public static readonly type = '[HelpDesk] Get Tickets';
    constructor(public payload: { status_key: string, 
                                  client_id: number, 
                                  page: number
                                  clear_loaded_pages?: boolean}) {}
}

export class SetActiveTicketAction {
    public static readonly type = '[HelpDesk] Set active ticket';
    constructor(public payload: Ticket) {}
}

export class DeleteTicketAction {
    public static readonly type = '[HelpDesk] Delete ticket';
    constructor(public payload: Ticket) {}
}


export class ClearHelpDeskStateAction {
    public static readonly type = '[HelpDesk] Clear state action';
}

export class GetTicketAction {
    public static readonly type = '[HelpDesk] Get ticket action';
    constructor(public payload: number){}
}

export class ResetTicketSaveStatus {
    public static readonly type = '[HelpDesk] Reset ticket save status';
}

export class GetCompanyUsersAction {
    public static readonly type = '[HelpDesk] Get company users';
    constructor() {}
}

export class GetAllTodayTicketsAction {
    public static readonly type = '[HelpDesk] Get all today tickets';
    constructor() {}
}

export class SetTicketUpdate {
    public static readonly type = '[HelpDesk] Set ticket update';
    constructor(public payload: Ticket) {}
}

export type helpDeskActions = SaveTicketAction | 
    GetTicketsAction |
    SetActiveTicketAction |
    DeleteTicketAction |
    ClearHelpDeskStateAction |
    GetTicketAction |
    ResetTicketSaveStatus |
    GetCompanyUsersAction;
