import * as _ from 'lodash';
import { Observable, Subject, BehaviorSubject, combineLatest } from 'rxjs';
import { Client, Shipment, Ticket, User } from '../models';
import { LoginStateService } from '../services/login-state.service';
import { map } from 'rxjs/operators';
import { NgZone } from '@angular/core';

export class ChannelsServiceBase extends LoginStateService {
  protected channels_subs: {[id:string]: any} = {};
  protected channels: { [id: string]: Subject<any> } = {};
  protected api_server: string;
  protected cable:any;
  channels_connected: { [id: string]: BehaviorSubject<boolean> } = {};

  constructor(protected env: any, protected ng_zone: NgZone) {
    super();
    this.api_server = env.apiUrl;
  }

  closeChannelByName(name:string) {
    if (this.channels_subs[name]) {
      this.channels_subs[name].unsubscribe();
      delete this.channels_subs[name];
    }
  }

  closeChannelsByCriteria(criteria: string) {
    _.keys(this.channels_subs).forEach((key:string) => {
      if (key.startsWith(criteria)) {
        this.closeChannelByName(key);
      }
    });
  }

  channelExists(channel_subscription_name: string): boolean {
    if (this.channels_subs[channel_subscription_name]) {
      return true;
    }
    return false;
  }


  openUserClientNotificationChannels(user: User, callback: (data: Ticket | Shipment) => void) {
    let clients = user.clients || [];
    clients.forEach((c: Client) => {
			if (!this.channelExists(`notifications_by_client_${c.id}`)) {
        global.log("Channel", "Opening NotificationsByClientChannel for " + c.name)
				this.openChannel(
					{ channel: 'NotificationsByClientChannel', client_id: c.id },
					`notifications_by_client_${c.id}`
				).subscribe(callback);
			}
		});
  }

  get serverUrl(): string {
    return this.api_server + "/";
  }

  openChannel(options: any, subscription_name: string = null): Observable<any> {
    let sub_name = subscription_name || _(options).values().join("_");
    this.channels[sub_name] = new Subject<any>();
    this.channels_connected[sub_name] = this.channels_connected[sub_name] || new BehaviorSubject<boolean>(false);
    if (!this.channelExists(sub_name))
      this.channels_subs[sub_name] = this.cable.subscriptions.create(options, {
        connected: () => {
          this.channels_connected[sub_name].next(true);
          global.log("Channel", "Connected to " + options.channel);
        },
        disconnected: () => {
          this.channels_connected[sub_name].next(false);
          global.log("Channel", "Disconnected from " + options.channel);
        },
        received: (data: any) => this.ng_zone.run(() => this.channels[sub_name].next(data))
      });
    return this.channels[sub_name];
  }

  get allConnected(): Observable<boolean> {
    return combineLatest(_.values(this.channels_connected)).pipe(
      map((status: any[]) => status.every(Boolean) )
    )
  }
}
