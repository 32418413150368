import { Component} from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthenticationService, Catalog, SaveTicketAction, Ticket, TicketStatuses} from 'wms-lib';
import { DialogService } from 'primeng/dynamicdialog';
import { TicketFormComponent } from '../ticket-form/ticket-form.component';
import { TicketListBaseComponent } from './ticket-list.base.component';
import { ActivatedRoute, Params } from '@angular/router';


@Component({
    selector: 'app-ticket-list',
    templateUrl: './ticket-list.component.html',
    styleUrls: ['./ticket-list.component.scss'],
    providers: [DialogService]
})
export class TicketListComponent extends TicketListBaseComponent{


    private _ticket_being_drag: Ticket;
    constructor(store: Store,
                private dialogService: DialogService,
                private route: ActivatedRoute, 
                authenticationService: AuthenticationService) { 
        super(store,authenticationService);
    }

    ngOnInit() {
        super.ngOnInit();
        this.route.queryParams.subscribe((params:Params) => {
            if (params['ticket_id'] && !Number.isNaN(+params['ticket_id'])) {
                this.openTicketEdition(+params['ticket_id']);
            }
        });
    }

    openTicketEdition(ticket_id: number) {
        this.dialogService.open(TicketFormComponent,{ width: '70%', data: { id: ticket_id}});
    }

    new() {

        this.dialogService.open(TicketFormComponent, 
                                {header: 'New Ticket', 
                                 width: '40%',
                                 data: {
                                     id: 0
                                 }
                                });
    }

    statusClass(s: Catalog) {
        if (s.key === TicketStatuses.open) return 'fa-lock-open';
        if (s.key === TicketStatuses.closed) return 'fa-lock';
        if (s.key === TicketStatuses.working) return 'fa-cogs';
    }

    drop(event,status:Catalog){
        if (this._ticket_being_drag) {
            const newStatus = status;
            const oldStatus = new Catalog(this._ticket_being_drag.ticket_status);
            if (oldStatus.key !== newStatus.key) {
                this._ticket_being_drag.status_id = newStatus.id;
                this.store.dispatch(new SaveTicketAction({ticket: this._ticket_being_drag}));
            }
        }
    }

    dragging(event) {
        this._ticket_being_drag = event.ticket;
    }
}
