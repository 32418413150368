import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ReceivingModule } from './receiving/receiving.module';
import { TopbarComponent } from './layouts/topbar/topbar.component';
import { SharedModule } from './shared/shared.module';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppInlineProfileComponent } from './layouts/profile/app.profile.component';
import { AppMenuComponent } from './layouts/menu/app.menu.component';
import { AppMenuitemComponent } from './layouts/menu-item/app.menuitem.component';
import { NgxsModule } from '@ngxs/store';
import { JwtModule } from '@auth0/angular-jwt';
import { LocalStorage } from 'wms-lib';
import { environment } from '../environments/environment';
import { WmsLibModule } from 'wms-lib';
import { ConfirmationService } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { FormsModule } from '@angular/forms';
import { DialogService } from 'primeng/dynamicdialog';
import {MenuModule} from 'primeng/menu';
import {MenuItem} from 'primeng/api';

import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { registerSchema } from 'class-validator';
import { AppMainComponent } from './app.main.component';
import { HomeComponent } from './home/home.component';
import { ClientModule } from './clients/client.module';

registerSchema(require('./models/_package.json').validationSchema);
registerSchema(require('./models/arrive-packing-list.json').validationSchema);
registerSchema(require('./models/location.json').validationSchema);

const LOCAL_MODULES = [
    NgbModule,
    BrowserAnimationsModule,
    BrowserModule,
    AppRoutingModule,
    ReceivingModule,
    FormsModule,
    JwtModule.forRoot({
        config:{
            tokenGetter: utoken,
            allowedDomains: [environment.apiUrl],
            skipWhenExpired: true
        }
    }),
    NgxsModule.forRoot([]),
    SharedModule.forRoot(),
    WmsLibModule.forRoot(environment),
    ClientModule.forRoot()
];
@NgModule({
    declarations: [
        AppMenuComponent,
        AppMenuitemComponent,
        AppInlineProfileComponent,
        AppComponent,
        AppMainComponent,
        TopbarComponent,
        AppInlineProfileComponent,
        HomeComponent
    ],
    imports: LOCAL_MODULES,
    providers: [
      MessageService,
      ConfirmationService,
      DialogService
    ],
    exports: [WmsLibModule, SharedModule, ClientModule],
    bootstrap: [AppComponent]
})
export class AppModule { }

export function utoken(){
    const t = LocalStorage.getString('auth_token');
    return t;
}
