import { Bindable } from "./common";

export class AplListDetail extends Bindable {
  arrive_packing_list_id: number;
  receipt_id: string;
  carrier_name: string;
  processed_date: Date;
  client_name: string;
  good_type_name: string;
}
