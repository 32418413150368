import { Injectable, Inject, NgZone } from "@angular/core";
import { GenericService } from "./generic.service";
import * as _ from 'lodash';
import { Shipment, Pallet } from "../models";
import { BehaviorSubject, Observable } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { NotificationService } from "../platform-services/notification.service";

const PO_SUGGESTIONS_LIST_URL = 'packages/pos';
const SHIPMENTS_URL = 'shipments';
const PALLETIZATION_URL = 'pallets';
const SHIPMENTS_RESUME_URL = 'shipments/resume';
const IMAGES_URL = 'images';
const ACTIVE_CHANNEL = 'ShipmentsChannel';

@Injectable({
  providedIn: 'root'
})
export class ShipmentsService extends GenericService {
  private activeShipment: BehaviorSubject<Shipment>;
  private kpiShipments: BehaviorSubject<{[id: number]: Shipment}>;

  constructor(@Inject('env') env: any, http: HttpClient, ng_zone: NgZone, notificationService: NotificationService) {
    super(env, http, ng_zone, notificationService);
  }

  getActiveShipment(id: number): Observable<Shipment> {
    global.log("Service", "Get active shipment");
    if (!this.activeShipment)
      this.activeShipment = new BehaviorSubject<Shipment>(null);

    this.getShipment(id).subscribe((shipment) => {
      this.activeShipment.next(shipment);
    });
    if(!this.isPhone) {
      this.createShipmentChannel(id);
    }
    return this.activeShipment;
  }

  private createShipmentChannel(id: number) {
    this.closeChannelsByCriteria(ACTIVE_CHANNEL + "_");
    this.openChannel({channel: ACTIVE_CHANNEL, id: id}).subscribe((data: any) => {
      if(data) this.activeShipment.next(new Shipment(data));
    });
  }

  private createKpiShipmentsChannel(user_id: number) {
    this.closeChannelByName("kpi_shipments_" + user_id);
    this.openChannel({channel: ACTIVE_CHANNEL, user_id: user_id}, "kpi_shipments_" + user_id).subscribe((data: any[]) => {
      if(data) this.kpiShipments.next(this.buildShipments(data));
    });
  }

  getPoSuggestionsList(query: string): Observable<string[]> {
    return this.http.get<string[]>(this.serverUrl + PO_SUGGESTIONS_LIST_URL + "/" + query, this.httpOptions).pipe(
      catchError(this.handleError<string[]>('RetrievingPoSuggestionList',[]))
    );
  }

  saveOrUpdate(shipment:Shipment): Observable<Shipment> {
    if (shipment.id && shipment.id > 0) {
      return this.update(shipment);
    } else {
      return this.save(shipment);
    }

  }

  palletize(pallet: Pallet): Observable<Pallet> {
    return this.http.post(this.serverUrl + PALLETIZATION_URL, {pallet: pallet.toSaveData()}, this.httpOptions).pipe(
      map((data: any) => { let p = new Pallet({...data, ...{packages: pallet.packages}}); return p; }),
      catchError(this.handleError<Pallet>(" Palletize", pallet))
    );
  }

  unpalletize(pallet: Pallet): Observable<Pallet | null> {
    return this.http.delete(this.serverUrl + PALLETIZATION_URL + "/" + pallet.id, this.httpOptions).pipe(
      map(() => null),
      catchError(this.handleError<Pallet>(" Unpalletize", pallet))
    );
  }

  save(shipment: Shipment): Observable<Shipment> {
    global.log("Service",`Saving shipments`);
    return this.http.post(this.serverUrl + SHIPMENTS_URL, shipment.toSaveData(), this.httpOptions).pipe(
      map((data: any)=> {
        return new Shipment(data);
      }),
      catchError(this.handleError<Shipment>(" SavingShipment",shipment))
    );
  }

  update(shipment: Shipment): Observable<Shipment> {
    global.log("Service",`Updating shipment ${shipment.id}`);
    return this.http.put(this.serverUrl + SHIPMENTS_URL + "/" + shipment.id, {shipment: shipment.toSaveData()}, this.httpOptions).pipe(
      map(()=> shipment),
      catchError(this.handleError<Shipment>("UpdatingShipment",shipment))
    );
  }

  delete(shipment: Shipment): Observable<boolean> {
    global.log("Service",`Delete shipments`);
    return this.http.delete(this.serverUrl + SHIPMENTS_URL + "/" + shipment.id, this.httpOptions).pipe(
      map(()=> true),
      catchError(this.handleError<boolean>(" Deleting Shipment",false))
    );
  }

  getOpenShipments(): Observable<{[id: number]: Shipment}> {
    global.log("Service",`Get open shipments`);
    return this.http.get<any[]>(this.serverUrl + SHIPMENTS_URL + "?open=true", this.httpOptions).pipe(
      map((data:any) =>  this.buildShipments(data)),
      catchError(this.handleError<{[id:number]:Shipment}>(' GettingShipments',{}))
    );
  }

  getOpenAndClosedShipments(user_id: number): Observable<{[id: number]: Shipment}> {
    global.log("Service",`Get open and closed shipments`);
    if (!this.kpiShipments) {
      this.kpiShipments = new BehaviorSubject<Shipment[]>(null);
    }
    this.http.get<any[]>(this.serverUrl + SHIPMENTS_URL + "?kpi=true", this.httpOptions).pipe(
      map((data:any) => this.buildShipments(data)),
      catchError(this.handleError<{[id:number]:Shipment}>(' GettingShipments',{}))
    ).subscribe((result: {[id: number]: Shipment}) => this.kpiShipments.next(result));
    this.createKpiShipmentsChannel(user_id);
    return this.kpiShipments;
  }

  getPagedShipments(client_id: number, page: number): Observable<{totalRecords: number, ships: {[id: number]: Shipment}}> {
    global.log("Service",`Get page ${page} of shipments`);
    return this.http.get<any[]>(this.serverUrl + SHIPMENTS_URL + "?open=false&client_id=" + client_id,{
      params: { 'page': page.toString()},
      headers: this.httpOptions.headers,
      observe: 'response'
    }).pipe(
      map((response:any) => ({
        totalRecords: +response.headers.get("totalrecords"),
        ships: this.buildShipments(response.body)
      })),
      catchError(this.handleError<{totalRecords: number, ships: {[id: number]: Shipment}}>(' GettingShipments',null))
    );
  }

  getShipment(id: number): Observable<Shipment> {
    global.log("Service",`Get shipment`);
    return this.http.get<any[]>(this.serverUrl + SHIPMENTS_URL + `/${id}`, this.httpOptions).pipe(
      map((data:any) =>  new Shipment(data)),
      catchError(this.handleError<Shipment>(' GettingShipment',null))
    );
  }

  private buildShipments(data: any[]): {[id: number]: Shipment} {
    let tmp = _(data).map((m: any) => new Shipment(m)).keyBy('id').value();
    return tmp;
  }

  getSummary(): Observable<any> {
    return this.http.get(this.serverUrl + SHIPMENTS_RESUME_URL, this.httpOptions).pipe(
      catchError(this.handleError(' get shipments resume', {}))
    );
  }

  getShipmentImages(id: number): Observable<any> {
    return this.http.get(this.serverUrl + `${SHIPMENTS_URL}/${id}/${IMAGES_URL}`, this.httpOptions).pipe(
      catchError(this.handleError(' get shipments resume', {}))
    );
  }
}
