import { Injectable, Inject, NgZone } from '@angular/core';
import { Subject } from 'rxjs';
import { CodeParserService } from './code-parse.service';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import { HttpClient } from "@angular/common/http";
import { NotificationService } from '../platform-services/notification.service';


export enum DD_TYPES {
  default = 'default',
  simple = 'simple',
  dense = 'dense',
  location = 'location',
  dock = 'dock',
  package = 'package',
  packing = 'packing',
  pallet = 'pallet',
  domestic = 'domestic',
  asn_serial_part='asn_serial_part',
  delivery_order = 'delivery_order',
  part_number = 'part_number',
  part_number_quantity = 'part_number_quantity',
}

@Injectable({
  providedIn: 'root'
})
export class CodebarReaderService extends CodeParserService {
  dispatchers: { [id: string]: Subject<string> } = {};
  private _type: string;

  constructor(@Inject('env') env: any, http: HttpClient, store: Store, ng_zone: NgZone,notificationService: NotificationService) {
    super(env, http, store, ng_zone, notificationService);
    Object.values(DD_TYPES).forEach((k: string) => this.dispatchers[k] = new Subject<string>());
    this.scannedText.subscribe((text: string) => {
      if (text && text.length > 0){
        let disp = this.inferDispatcher(text);
        (this.dispatchers[disp] || this.dispatchers[DD_TYPES.default]).next(this.cleanPrefix(disp, text));
      }
    })
    this.scannedTextFiltred.subscribe((payload: { text: string, filter: string[] }) => {
      if (payload.text && payload.text.length > 0){
        let disp = this.inferDispatcher(payload.text, payload.filter);
        (this.dispatchers[disp] || this.dispatchers[DD_TYPES.default]).next(this.cleanPrefix(disp, payload.text));
      }
    })
  }

  setOperation(type_operation: string) {
    this._type = type_operation;
    if (this._type && !this.dispatchers[this._type])
      this.dispatchers[this._type] = new Subject<string>();
  }

  private cleanPrefix(dd_type: string, text: string) {
    let m = this.codes[dd_type] && this.codes[dd_type].exec(text);
    return m && m.groups && m.groups["prefix"] && m.groups["value"] ? m.groups["value"] : text;
  }

  private inferDispatcher(val: string, filter: string[] = []): string {
    return this._type ||
      Object.values(DD_TYPES)
        .filter((k: string) => !filter.includes(k))
        .find((k: string) => this.codes[k] && this.codes[k].test(val)) ||
      DD_TYPES.default;
  }
}
