import { Client } from "./client";
import { Bindable } from "./common";
import { Address } from "./address";

const SAVE_PROPS = ['name','client_id','address_id','address'];

export class Forward extends Bindable {
    id: number;
    name: string;
    client_id: number;
    address_id: number;
    client: Client;
    address: Address;

    constructor(data: any) {
        super(data);
        this.id = this.id || (+(new Date())) * -1;
        if (this.client) {
            this.client = new Client(this.client);
        }
        if (this.address) {
            this.address = new Address(this.address);
        }
    }

    get isNew():boolean {
        return this.id.toString().startsWith("-");
    }

    get isValid():boolean {
        return this.name && this.name.length > 0;
    }

    get saveProp(): string[] {
        return super.saveProp.concat(SAVE_PROPS);
    }
}