import { Component, OnInit, Input, ViewEncapsulation } from '@angular/core';
import { StateListenerComponent } from '../../../shared/components/scan-listener/state-listener.component';
import { Store } from '@ngxs/store';
import { Location, CatalogsState, Client } from 'wms-lib';

@Component({
    selector: 'app-location-card-list',
    templateUrl: './location-card-list.component.html',
    styleUrls: ['./location-card-list.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class LocationCardListComponent extends StateListenerComponent implements OnInit {
    @Input() type: string;
    locations: Location[];
    clients: {[id: number]: Client};
    allocated = {
        1: {4: [1,2]},
        2: {1: [1],2: [1],3: [1],4: [1],5: [1],6: [1]},
        4: {2: [2],5: [1,3],6: [1,3],7: [1,3], 8: [3],9: [1]},
        6: {2: [1],3: [1],4: [1],5: [1]}
    }
    levelColors = {1: "#E63946", 2: "#1D3557", 3: "#A8DADC", 4: "#457B9D", 5: "#F1FAEE", 6: "#F4F1DE", 7: "#E07A5F", 8: "#3D405B", 9: "#81B29A", 10: "#F2CC8F"}
    toStore = {
        "AD-20-4": {0: {1: [1], 2: [1], 3: [1], 4: [1], 5: [1], 6: [1], 7: [1], 8: [1]} },
        "ZE-20-3": {5: {1: [1], 2: [1], 3: [1], 4: [1], 5: [1], 6: [1], 7: [1], 8: [1], 9: [1], 10: [1]} }
    };
    currenReciptId: string;
    constructor(private store: Store) { super(); }

    ngOnInit() {
        this.addSub(this.store.select(CatalogsState.locationByType(this.type)).
            subscribe((locs: Location[]) => this.locations = locs ));
        this.addSub(this.store.select(state => state.catalogsState.clients).
            subscribe((clients: { [id: number]: Client }) => this.clients = clients ));
    }

    getClient(id: number) {
        return this.clients[id];
    }

    getBackgroundColor(l: number, c: number, v: number) {
        if (this.currenReciptId &&
            this.currenReciptId != "" &&
            this.toStore[this.currenReciptId] &&
            this.toStore[this.currenReciptId][l] &&
            this.toStore[this.currenReciptId][l][c] &&
            this.toStore[this.currenReciptId][l][c].includes(v)){
            return "#86F2FF";
        }
        else
            return this.allocated[l] && this.allocated[l][c] && this.allocated[l][c].includes(v) ? this.levelColors[v] : "";
    }


}
