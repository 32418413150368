import { Inject, Injectable, NgZone } from '@angular/core';
import { createConsumer } from '@rails/actioncable';
import { ChannelsServiceBase } from './channels.service-base';


@Injectable({
  providedIn: 'root'
})
export class ChannelsService extends ChannelsServiceBase {
  constructor(@Inject('env') env: any, ng_zone: NgZone){
    super(env, ng_zone);
    this.cable = createConsumer(this.serverUrl + 'cable')
  }
}
