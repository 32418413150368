import { OnInit, Input, ViewChildren, QueryList, ViewContainerRef, ViewChild, ElementRef, NgZone, Directive, SimpleChanges, OnChanges } from '@angular/core';
import { ArrivePackingList, Client, Package, CodebarReaderService, CatalogsState, AuthenticationService, NotificationService } from 'wms-lib';
import * as _ from 'lodash';
import { Store } from '@ngxs/store';
import * as actions from 'wms-lib';
import { PackageBaseComponent } from './package.component.base';
import { ActivatedRoute } from '@angular/router';
import { ScanListenerComponent } from '../scan-listener/scan-listener.component';

@Directive({selector: '[packingListBase]'})
export class PackingListBaseComponent extends ScanListenerComponent implements OnInit, OnChanges {
  @Input() compact = true;
  @Input() pl: ArrivePackingList;
  @ViewChildren('packages') packages: QueryList<PackageBaseComponent>;
  @ViewChild('card', { static: false, read: ViewContainerRef }) card: any;
  @ViewChild('chkPl', { static: false}) chkPackingList: ElementRef;
  clients: { [id: number]: Client } = [];
  selected: boolean;
  //Undocumented selected packages from this and other packing lists
  usp: number[];
  protected _expanded: boolean;
  protected el: ElementRef;
  good_types: Map<number, string> = new Map([]);

  constructor(protected store:Store,
              protected activatedRoute: ActivatedRoute,
              protected codebar_service: CodebarReaderService,
              protected ng_zone: NgZone,
              protected authenticationService : AuthenticationService,
              protected notificationService: NotificationService
             ) {
    super(codebar_service, ng_zone);

  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.pl && changes.pl.currentValue) {
      this.pl = changes.pl.currentValue;
    }
    if (this.pl && this.pl.id && (changes.pl.previousValue !== changes.pl.currentValue || changes.pl.previousValue === null)) {
      this.addSub(this.store.select((state) => state.arrivalsState.expanded_packings).subscribe((expanded_ids: number[]) => {
        this.setExpanded(this.pl && expanded_ids.includes(this.pl.id));
      }));
    }
  }

  ngOnInit() {
    this.addSub(this.store.select((state) => state.catalogsState.clients)
      .subscribe((c: { [id: number]: Client }) => this.clients = c));

    this.addSub(this.store.select((state) => state.arrivalsState.working_packing_id).subscribe((wpid: number) => {
      this.selected = this.pl && this.pl.id === wpid;
      if (this.el) {
        if (this.selected)
          this.el.nativeElement.classList.add('selected-pl');
        else
          this.el.nativeElement.classList.remove('selected-pl');
      }
    }));

    this.addSub(this.store.select((state) => state.arrivalsState.expanded_packings).subscribe((expanded_ids: number[]) => {
      this.setExpanded(this.pl && expanded_ids.includes(this.pl.id));
    }));

    this.addSub(this.store.select((state) => state.arrivalsState.undoc_sel_pkgs).subscribe((usp: number[]) => {
      this.usp = usp;
    }));

    this.addSub(this.store.select(CatalogsState.getMapCatalog('good_types')).subscribe((c: Map<number, string>) => {
      if (c && c.size > 0){
        if (this.pl && !this.pl.good_type_id) this.pl.good_type_id = c.keys().next().value;
        this.good_types = c;
      }
    }));
  }

  protected setExpanded(value: boolean) {
    this._expanded = value;
  }

  //@Input()
  set expanded(value: boolean) {
    if (this.pl) {
      this.store.dispatch(new actions.ToggleExpandedPackageAction({ id: this.pl.id, expanded: value, single: false }));
    }
  }
  get expanded(): boolean {
    return this._expanded;
  }

  get show_client(): boolean {
    return !!this.pl.client_id;
  }

  get receiptIdOrCarrierPackingId() {
    let sub = '';
    if (this.pl.receipt_id)
      sub = this.pl.receipt_id;
    else if (this.pl && this.pl.carrier_packing_id && this.pl.carrier_packing_id !== 'NA')
      sub =  this.pl.carrier_packing_id;
    return sub;
  }

  get undoc_label(): string {
    return this.pl && this.pl.client_id ?
      this.getClientName(this.pl.client_id) : 'Undocumented';
  }

  get documented() {
    return !!this.pl.document;
  }

  get expander(): string {
    return (this.expanded ? String.fromCharCode(0xf0dd) : String.fromCharCode(0xf0da));
  }

  get package_count(): string {
    return this.pl.total_packages ?
      `${this.pl.count}/${this.pl.total_packages}` :
      '' + this.pl.count;
  }

  get selectedCarrierPackagesIds(): string[] {
    return this.packages ? this.packages.filter((f: PackageBaseComponent) => f.selected).map((m: PackageBaseComponent) => m.package.carrier_packing_id) : [];
  }

  get selectedPackagesIds(): number[] {
    return this.packages ? this.packages.filter((f: PackageBaseComponent) => f.selected).map((m: PackageBaseComponent) => m.package.id) : [];
  }

  get allPackagesIds(): number[]{
    return this.packages ?this.packages.map((p:PackageBaseComponent) => p.package.id) : [];
  }

  get all_packages_selected(): boolean{
    let pkIds = this.allPackagesIds;
    return _.isEqual(_.intersection(pkIds,this.usp), pkIds);
  }

  get otherSelectedPackages(): number[] {
    return _.difference(this.usp, this.selectedPackagesIds);
  }

  getClientNameForTitle(id: number): string {
    return this.getClientName(id);
  }

  getClientName(id:number): string{
    return id ? ((this.clients && this.clients[id] && this.clients[id].name) || 'Loading...') : '';
  }

  getClientLogo(id: number): string {
    return (this.clients && this.clients[id] && this.clients[id].logo_url) || '';
  }

  onSelectClient(client: Client){
    if (client) {
      this.pl.client = client;
      if (this.pl.client_id)
        this.save();
    }
  }

  select() {
    if (!this.selected)
      this.store.dispatch(new actions.SetWorkingPackingAction({packing_id: this.pl.id, attach_doc: true}));
  }

  select_all(value:boolean = true) {
    _(this.pl.packages).each((p: Package) => this.store.dispatch(new actions.ToggleUndocPkgAction({ package: p, selected: value})));
  }

  unselect(chk?: any) {
    if (this.selected && !(chk || {checked: false}).checked)
      this.store.dispatch(new actions.SetWorkingPackingAction({packing_id: null, attach_doc: true}));
  }

  toggleDetails() {
    this.expanded = !this.expanded;
    if (!this.expanded) this.unselect();
  }

  protected get isPrimary(): boolean {
    return this.activatedRoute.outlet == "primary";
  }

  move_to_this() {
    this.pl.pull(this.usp);
    this.store
      .dispatch(new actions.UpdateArriveAction({
        arrival: this.pl.arrival,
        package: null}));
    this.store.dispatch(new actions.ToggleUndocPkgAction({ package: null, selected: false }));
  }

  save() {
    if(this.pl._backup && this.pl._count != this.pl._backup._count)
      this.pl.updateCounter();
    let upl: ArrivePackingList = null;
    if(this.pl.generic) {
      upl = this.pl.convert(this.selectedPackagesIds);
      this.store.dispatch(new actions.ToggleUndocPkgAction({ package: null, selected: false}));
    }
    else
      upl = this.pl;
    if (!upl.isFreight && upl.weight > 0) _(upl.packages).each((p: Package) => p.weight = upl.weight );
    this.store.dispatch(new actions.UpdateArriveAction({arrival: upl.arrival, package: null}) );
  }

  get canPrint(): boolean {
    return this.authenticationService.currentUserValue.canReprint || _(this.pl.packages).values().some((p: Package) => !p.alredyPrinted);
  }

  print() {
    let cloned_packages = _.cloneDeep(this.pl.packages);
    try {
      if(this.canPrint){
        _(this.pl.packages).each((p: Package) => p.last_print = new Date());
        this.codebar_service.sendToPrinter(_(this.pl.packages).sortBy('numerator').map((p: Package) => p.zpl).value());
        this.pl.arrival.printed_packages = true;
        this.store.dispatch(new actions.UpdateArriveAction({arrival: this.pl.arrival, package: null}) );
      }
    } catch (e) {
      this.pl.arrival.printed_packages = false;
      this.pl.packages = cloned_packages;
      this.notificationService.toast(e.name + ': ' + e.message);
    }
  }

  get canChangeClient(): boolean {
    return this.canEdit && (!this.pl.generic || (this.pl.generic && this.selectedPackagesIds.length > 0));
  }

  get canEdit(): boolean {
    // This also covers the case when the case when the arrival is closed
    // in theory if the arrival is closed all the pl's need to be valids
    //return !this.pl.isValid || this.authenticationService.currentUserValue.isSupervisor;
    return true;
  }

  get canSetCreationDate(): boolean {
    return this.authenticationService.currentUserValue.isAdmin;
  }

  get canViewHistory(): boolean {
    return this.authenticationService.currentUserValue.isSupervisor
  }
}
