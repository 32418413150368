import { Component } from '@angular/core';
import { applyMixins, Attachment, NotificationService, User, CatalogsService } from 'wms-lib';
import * as _ from 'lodash';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { TicketFormBaseComponent } from './ticket-form.base.component';
import { CanTakeAttachment } from '../../../../shared/mixins/can-take-picture';
import { ActivatedRoute } from '@angular/router';
@Component({
    selector: 'app-ticket-form',
    templateUrl: './ticket-form.component.html',
    styleUrls: ['./ticket-form.component.scss']  
})
export class TicketFormComponent extends TicketFormBaseComponent {
    selectedImage: Subject<any> = new Subject<any>();
    takeAttachmentImage: (image: File) => Promise<void>;
    selectedAttachment: Subject<Attachment> = new Subject<Attachment>();
    filtered_users: User[];
    @Select((state: any) => state.helpDeskState.saved) saved$: Observable<boolean>;

    constructor(store: Store, 
                private ref: DynamicDialogRef,
                private dialogConfig: DynamicDialogConfig,
                route: ActivatedRoute,
                notificationService: NotificationService, 
                searchService: CatalogsService) { 
        super(store,route,notificationService,searchService);
        this.ref.onClose.subscribe(() => this.clearTicket()); 
    }

    ngOnInit() {
        super.ngOnInit();

        // This is when the component is loaded in a ngPrime dialog
        if (this.dialogConfig.data && this.dialogConfig.data.id > 0) {
            this.requestTicket(this.dialogConfig.data.id);
        }
    }

    fileChange(event: any) {
        if (!(event.target.files.length > 0)) return;
        this.ticket.appendAttachmentFromFile(event.target.files[0]);
    }

    findUsers(event: any) {
        this.filtered_users = this._users.filter(u => u.username.includes(event.query));
    }


    save() {
        this.persist().subscribe(() => {
            this.ref.close();
        });
    }

    cancel() {
        this.ref.close();
    }
}

applyMixins(TicketFormComponent, [CanTakeAttachment]);
