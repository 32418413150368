import { OnDestroy, NgZone, HostListener, Directive } from '@angular/core';
import { Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { Document, CodebarReaderService, Arrival, NotificationService } from 'wms-lib';
import { ActivatedRoute } from '@angular/router';
import { ScanListenerComponent } from '../../../shared/components/scan-listener/scan-listener.component';

const IMG_DATAURL_REG = /^data:(?<type>\w+)\/(?<ext>\w+);base64,(?<content>.*)$/i;

@Directive({selector: '[documentsCarrouselBase]'})
export class DocumentsCarrouselBaseComponent extends ScanListenerComponent implements OnDestroy {
  documents$: Observable<{ [name: string]: Document }> = new Observable<{[name: string]: Document}>();
  protected selected_doc_subscription: Subscription[] = [new Subscription(), new Subscription()];
  current_arrival: Arrival;
  uploading: boolean = false;

  constructor(
    protected store: Store,
    protected activatedRoute: ActivatedRoute,
    protected codebar_service: CodebarReaderService,
    protected ng_zone: NgZone,
    public notificationService: NotificationService
  ) {
    super(codebar_service,ng_zone);
  }

  @HostListener('unloaded')
  ngOnDestroy() {
    super.ngOnDestroy();
    global.log("Component",`Destroying carousel`)
  }

  goToTabletDocumentViewer(){
    throw new Error('This method is abstract');
  }

  goToPhoneDocumentViewer(){
    throw new Error('This method is abstract');
  }


  protected get isPrimary(): boolean {
    return this.activatedRoute.outlet === 'primary';
  }

  addDocument(event: any, service: (doc: FormData) => Observable<boolean>, checksize: boolean = false) {
    this.uploading = true;
    let reader = new FileReader();
    reader.onloadend = ((pe: ProgressEvent) => {
      const formData: FormData = new FormData();
      const urlData = IMG_DATAURL_REG.exec((pe.target as any).result);
      if (event.srcElement.files && event.srcElement.files[0].size > 1048576 && checksize) {
        this.notificationService.toast(`The file size must be less than 1MB`);
		    this.uploading = false;
		    return;
	  }
      if (urlData.groups["ext"].toLowerCase() == "pdf") {
        formData.append("document",
                        this.dataURLtoBlob(urlData.groups["content"], `${urlData.groups["type"]}/${urlData.groups["ext"]}`),
                        event.srcElement.files[0].name);
        service(formData).subscribe(() => this.uploading = false );
      }
    }).bind(this);
    reader.readAsDataURL(event.srcElement.files[0]);
  }

  private dataURLtoBlob(data: string, mime: string) {
    let bstr = atob(data), n = bstr.length, u8arr = new Uint8Array(n);
    while(n--) u8arr[n] = bstr.charCodeAt(n);
    return new Blob([u8arr], {type:mime});
  }
}
