import {AppMainComponent} from '../../app.main.component';
import {Component, OnInit, NgZone} from '@angular/core';
import {trigger, state, transition, style, animate} from '@angular/animations';
import { AuthenticationService, User } from 'wms-lib';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import * as actions from 'wms-lib';

@Component({
    selector: 'app-inline-profile',
    templateUrl: './app.profile.component.html',
    animations: [
        trigger('menu', [
            state('hidden', style({
                height: '0px'
            })),
            state('visible', style({
                height: '*'
            })),
            transition('visible => hidden', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)')),
            transition('hidden => visible', animate('400ms cubic-bezier(0.86, 0, 0.07, 1)'))
        ])
    ]
})
export class AppInlineProfileComponent implements OnInit {
    user: User;
    active: boolean;

    constructor(public app: AppMainComponent, 
                private authenticationService : AuthenticationService,
                private ng_zone: NgZone, 
                private router: Router,
                private store: Store
               ) {}


    ngOnInit() {
        this.authenticationService.currentUser.subscribe((user: User) => {
            this.user = user;
            if (this.user && this.user.clients && this.user.clients.length > 0)
                this.store.dispatch(new actions.SetActiveClientAction({client: this.user.clients[0]}));
        });
    }

    onClick(event) {
        this.active = !this.active;
        event.preventDefault();
    }

    logOut(){
        this.authenticationService.LogOut();
        this.router.navigate(['login']).then(() => this.ng_zone.run(() => location.reload()));
    }

    setActiveClient(event: any) {
        this.store.dispatch(new actions.SetActiveClientAction({client: event.value}));
    }
}
