import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { PartNumber, Arrival, ArrivePackingList, Package } from 'wms-lib';
import * as _ from 'lodash';
// import * as FileSaver from 'file-saver';


@Component({
  selector: 'app-part-number-table-resume',
  templateUrl: './part-number-table-resume.component.html',
  styleUrls: ['./part-number-table-resume.component.scss']
})
export class PartNumberTableResumeComponent implements OnInit, OnChanges {
	@Input() arrival: Arrival = null;
	@Input() apl: ArrivePackingList = null;
	index: number = 0;
	parts: { receipt_id: string, part_number: PartNumber, quantity: number, bundles: number }[] = [];
	cols = [
		{ field: 'receipt_id', header: 'Receipt ID' },
		{ field: 'part_number.name', header: 'Name' },
		{ field: 'quantity', header: 'Quantity' },
		{ field: 'bundles', header: 'Bundles' }
	];

	constructor() { }

	ngOnInit() { }

	ngOnChanges() {
		if (!this.arrival) return;
		this.parts = [];
		_.forEach(this.packages_grouped, (packages, key) => {
			const [group_key, receipt_id] = key.split('|');
			if (!_.first(packages).part_number) return;
			const part_number = _.first(packages).part_number;
			this.parts.push({
				receipt_id,
				part_number,
				quantity: _.sumBy(packages, 'quantity'),
				bundles: _.size(packages)
			});
		});
		this.parts = _.orderBy(this.parts, ['receipt_id', 'part_number.name', 'quantity'], ['asc', 'asc', 'desc']);
	}

	handleChange(e: any) {
		this.index = e.index;
		this.ngOnChanges();
	}

	get packages(): Package[] {
		return this.apl && this.apl.id ? this.apl.packages_a : this.arrival.all_packages_with_part_number;
	}

	get packages_grouped(): { [id: string]: Package[] } {
		const group = this.index == 1 ? 'id' : 'part_number_id';
		return _.groupBy(this.packages, (p) => `${p[group]}|${p.receipt_id}`);
	}

  exportExcel() {
    import("xlsx").then(xlsx => {
      const worksheet = xlsx.utils.json_to_sheet(this.partsAsJson(this.parts));
      const workbook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, { bookType: 'xlsx', type: 'array' });
      this.saveAsExcelFile(excelBuffer, "parts_resume");
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import("file-saver").then(FileSaver => {
      let EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE
      });
      FileSaver.saveAs(data, fileName + '_export_' + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  partsAsJson(parts: any[]){
    return parts.map((p: any) => ({
      receipt_id: p.receipt_id, part_number: p.part_number.name, quantity: p.quantity, bundles: p.bundles
    }));
  }
}
