import { Input, ViewChild, ElementRef, OnInit, NgZone, Directive } from '@angular/core';
import { InventoryFilter, IPackable, ArrivePackingList, PackingListGroupTypes, Client } from 'wms-lib';
import { Store } from '@ngxs/store';
import { ScanListenerComponent } from '../../../shared/components/scan-listener/scan-listener.component'
import { CodebarReaderService, DD_TYPES, Package, AuthenticationService } from 'wms-lib';
import * as store_actions from 'wms-lib';

@Directive({selector: '[pacakgesBase]'})
export class PackingListsBaseComponent extends ScanListenerComponent implements OnInit  {
  @Input() arrival: IPackable;
  @Input() compact: boolean = true;
  @Input() display_valids: boolean;
  @Input() display_deflected: boolean;
  @ViewChild('list', { static: false })
  list: ElementRef;
  maxListSize: number = 10;
  filter: InventoryFilter;
  wpid: number;
  lastRequest: Date = new Date();
  active_client: Client;
  constructor(protected _store: Store,
              protected codebar_service: CodebarReaderService,
              ng_zone: NgZone,
              protected authenticationService: AuthenticationService
             ) {
    super(codebar_service, ng_zone);
  }

  ngOnInit() {
    this.addSub(this._store.select(state => state.arrivalsState.working_package_id).
      subscribe((wpid: number) => {
        if (this.arrival) {
          const p = this.arrival.findPackage(wpid);
          if (p && p.arrive_packing_list_id) {
            this.wpid = p.arrive_packing_list_id;
          }
        }
      }));

    this.subscribe_scanner({
      [DD_TYPES.simple]: this.SelectPackage,
      [DD_TYPES.dense]: this.SelectPackage,
    });

    this._store.select(state => state.catalogsState.active_client).subscribe(client => {
      this.active_client = client;
    } );
  }

  private SelectPackage(code: string) {
    const vp = new Package(this.codebar_service.parseCode(code));
    const p = this.arrival.findPackage(vp.trk);
    if (p) {
      this._store.dispatch(new store_actions.SetWorkingPackageAction({ package: p }));
      this.SetPackingGroupTabIndex(p.arrive_packing_list);
    }
  }

  private SetPackingGroupTabIndex(pl: ArrivePackingList) {
    let index = PackingListGroupTypes.active;

    if (pl.isValid) {
      index = PackingListGroupTypes.completed;
    }

    if (pl.deflected) {
      index = PackingListGroupTypes.deflected;
    }

    this._store.dispatch(new store_actions.SetNavInstructionAction({value: index, field: 'packings_tab_id' }));
  }

  get canBeClosedOrOpended(): boolean {
    return !!this.arrival;// && (this.arrival.can_be_closed || (!this.arrival.open && this.authenticationService.currentUserValue.isSupervisor));
  }

  get canSetCreationDate(): boolean {
    return this.authenticationService.currentUserValue.isAdmin;
  }
}
