import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ObjToOptions'
})
export class ObjectToSelectOptionsPipe implements PipeTransform {

  transform(value: any, args?: any): any {
      const options =  Object.keys(value).map(k => ({ label: args && args.label ? value[k][args.label] : value[k], value: args && args.value ? value[k][args.value] : value[k] }));
      if (args && args.null_option) {
        options.unshift({ label: args.null_option, value: null });
      }
      return options;
  }

}

