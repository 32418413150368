import { Directive, OnInit, ViewChild, NgZone, ElementRef } from '@angular/core';
import { Arrival, ArrivePackingList } from 'wms-lib';
import { Store } from '@ngxs/store';
import * as actions from 'wms-lib';
import { ActivatedRoute } from '@angular/router';
import * as _ from 'lodash';
import { ScanListenerComponent } from '../../../shared/components/scan-listener/scan-listener.component';
import { ArrivePackingListsComponent } from '../arrive-packing-lists/arrive-packing-lists.component';
import { CodebarReaderService, DD_TYPES, NotificationService, Carrier } from 'wms-lib';

@Directive({selector: '[appConciliating]'})
export class ConciliatingBaseComponent extends ScanListenerComponent implements OnInit {
    open_arrival: Arrival;
    @ViewChild('pl', { static: false }) plComponent: ArrivePackingListsComponent;
    @ViewChild('trk', { static: false }) trk: ElementRef;
    docks: string[] = [];
    carriers: {[id: number]: Carrier} = {};
    isTrkFocused: boolean = false;

    constructor(protected store: Store,
                protected activated_route: ActivatedRoute,
                protected codebar_service: CodebarReaderService,
                public notification_service: NotificationService,
                protected ng_zone: NgZone) {
        super(codebar_service, ng_zone);
    }

    ngOnInit() {
        this.loadDocumentsCarousel();

        this.onActivate = () => {
            this.loadResouces();
        };
        this.loadResouces();
    }

	onTrkFocus(focus: boolean){
    	this.isTrkFocused = focus;
    }

    loadDocumentsCarousel(){
        this.store.dispatch(new actions.GetDocumentsAction());
    }

    protected setDock(dock: string) {
        this.open_arrival.dock = dock;
    }

    protected checkFocusAndTryAddPacking(code: string){
		if(this.isTrkFocused){
			this.tryAddPacking(code);
		}
	}

    protected tryAddPacking(code: string) {
        if (this.open_arrival.require_dock)
            this.notification_service.toast('RequireDock');
        else {
            let pl = new ArrivePackingList(code);
            if (this.open_arrival.carrier_id)
                this.addPacking(pl);
            else
                this.createArrival(pl);
        }
    }

    onInputReturn(text: string) {
        if (this.open_arrival.dock)
            this.tryAddPacking(text)
        else
            this.setDock(text);
        this.trk.nativeElement.text = "";
        this.trk.nativeElement.focus();
    }

    private async addPacking(pl: ArrivePackingList) {
        this.open_arrival.addPackingList(pl);
        this.store.dispatch(new  actions.UpdateArriveAction({arrival: this.open_arrival, package: null}));
    }


    private async createArrival(pl: ArrivePackingList) {
        let id = await this.getCarrierId();
        this.open_arrival.carrier_id = id;
        if (this.open_arrival.carrier_id) { //TODO: Pasar a una validacion a nivel modelo (isValid)
            this.open_arrival.addPackingList(pl);
            this.store.dispatch(new  actions.UpdateArriveAction({arrival: this.open_arrival, package: null}));
        }
    }

    async promptCarrier() {
        let id = await this.getCarrierId();
        if (id) {
            this.open_arrival.carrier_id = id;
            this.store.dispatch(new  actions.UpdateArriveAction({arrival: this.open_arrival, package: null}));
        }
    }

    protected async getCarrierId(): Promise<number> {
        throw new Error('This method is abstract');
    }

    protected get isPrimary(): boolean {
        return this.activated_route.outlet == "primary";
    }

    get hintText(): string {
        return this.open_arrival.dock ? "trk" : "dock";
    }

    get placeHolderText(): string {
        return this.open_arrival.require_dock ? "Dock" : "Tracking number";
    }

    get type(): string{
        return this.open_arrival.isFreight ? "Freight" : "Parcel";
    }

    loadResouces() {
        this.open_arrival = null;
        this.activated_route.params.subscribe((params: any) => {
            if (!!+params.load || this.isPrimary){
                this.addSub(this.store.select(state => state.arrivalsState.open_arrival).
                    subscribe((a: Arrival) => {
                        this.open_arrival = a;
                        if (this.open_arrival && this.open_arrival.isFreight) {
                            this.subscribe_scanner({
                                [DD_TYPES.dock]: this.setDock,
								[DD_TYPES.default]: this.checkFocusAndTryAddPacking
                            });
                            this.addSub(this.codebar_service.codes$.subscribe((codes: {[id: string]: RegExp}) => {
                                if (codes && codes["dock"]){
                                    let prefix = codes["dock"].toString()[2];
                                    let max = +(codes["dock"].toString()[10]+codes["dock"].toString()[14]);
                                    this.docks = _.range(1,max+1).map((v: number) => prefix+v);
                                }
                            }));
                        }
                    } ));
            }
            else
                this.open_arrival = null;
        });
        this.addSub(this.store.select((state) => state.catalogsState.carriers).subscribe((carriers: {[id: number]: Carrier}) => {
            this.carriers = carriers;
        }));
    }

}
