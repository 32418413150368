import { Component, OnInit } from '@angular/core';
import { Client, NotificationService, Catalog, Asn } from 'wms-lib';
import * as actions from 'wms-lib';
import * as _ from 'lodash';
import { Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';

@Component({
    selector: 'app-asns',
    templateUrl: './asns.component.html',
    styleUrls: ['./asns.component.scss']
})
export class AsnsComponent implements OnInit {
    client_id: number = 0;
    clients: { [id: number]: Client } = {};

    constructor(private router: Router, protected store: Store, protected notificationService: NotificationService) {
        this.store.dispatch(new actions.LoadForwardsAction());
    }

    ngOnInit() {
    }
}
