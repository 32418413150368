import { ArrivePackingList } from "./arrive-packing-list";
import { RemoteAsset } from "./remote-asset";
import { DeliveryOrder } from "./delivery-order";

export class Document extends RemoteAsset {
  name: string;
  snapshot: string;
  arrive_packing_list: ArrivePackingList;
  delivery_order: DeliveryOrder;
  displayAssingDialog: boolean = false;

  get saveProp(): string[] {
    return ["base64", "filename"];
  }

  append(doc: Document) {
    this.base64 = doc.base64;
    this.filename = doc.filename;
    this.stored = false;
    return this;
  }
}
