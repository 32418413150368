import { OnInit, NgZone, Input, Output, EventEmitter, ViewChild, ElementRef, AfterViewInit, Directive } from '@angular/core';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';
import { applyMixins, NotificationService, CodebarReaderService, ModalDialogService, ArrivalsState, AuthenticationService } from 'wms-lib';
import * as store_actions from 'wms-lib';
import { PackageBaseComponent } from './package.component.base';
import { PurchaseOrderModalService } from '../../../services/purchase-order/purchase-order-modal.service';
import { Patterns } from '../../validators/patterns'
import { Subject } from 'rxjs';
import { CanTakeDamageReport } from '../../mixins/can-take-picture';
import { Router } from '@angular/router';

@Directive({})
export class PackageComponent extends PackageBaseComponent implements OnInit, AfterViewInit, CanTakeDamageReport{
    @Output() selectAll: EventEmitter<any> = new EventEmitter<any>();
    @Input()all_packages_selected: boolean;
    clonedPackage: any = null;
    PATTERNS =  Patterns;
    protected po_modal_service: PurchaseOrderModalService;
    
    @ViewChild('inputFile', { static: false }) damage_element: ElementRef;
    selectedImage: Subject<any> = new Subject<any>();    
    onChangeImage: () => Promise<void>;

    constructor(
        protected store: Store,
        public notification_service: NotificationService,
        protected codebar_service: CodebarReaderService,
        protected ng_zone: NgZone,
        protected modal_service: ModalDialogService,
        protected _router: Router,
        protected authenticationService : AuthenticationService
    ) {
        super(store,notification_service,codebar_service,ng_zone,authenticationService)
    }

    ngAfterViewInit(){
        if (this.damage_element)
            this.damage_element.nativeElement.addEventListener("change", this.onChangeImage.bind(this));
    }

    routeToDamageReport(){
        this._router.navigate(["/receivings", "damages", this.package.id]);
    }

    initiEditorPO() {
        this.po_modal_service = new PurchaseOrderModalService(this.modal_service);
        this.po_modal_service.selected_pos = this.package.po_list || [];   
        this.po_modal_service.package = this.package;

        this.po_modal_service.onPOUpdated.subscribe((value:void) => {
            this._propertyUpdated = true;
            this.validateAndSave();
        });

        this.store
            .select(ArrivalsState.posFromActiveArrival)
            .pipe(map((fn) => fn()))
            .subscribe((pos) => {
                if (this.po_modal_service != null) {
                    this.po_modal_service.suggestions = pos;
                }
            });     
        this.po_modal_service.displayCustomDialog();        
    }

    trackByIndex = (index: number): number => {
        return index;
    };

    delete() {
        this.store
        .dispatch(new store_actions.MarkPackageToBeDeletedAction({ pkg: this.package }))
        .subscribe(() => {
            if(confirm('Are you sure want to delete the selected package?'))
                this.commitPackageDelete();
            else
                this.restorePackageDeleted();
        });
    }
}

applyMixins(PackageComponent, [CanTakeDamageReport]);
