import { Injectable, Inject, NgZone } from '@angular/core';
import { Observable, of, Subject } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import * as _ from 'lodash';
import { PlatformService } from '../platform-services/platform.service';
import { NotificationService } from '../platform-services/notification.service';


@Injectable({
  providedIn: 'root'
})
export class GenericService extends PlatformService {
  protected workingUploads: { [id: string]: { callback: Subject<string>, id: number } } = {};
  protected readonly httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json',
      'Accept': 'application/json'
    })
  };

  protected readonly httpMultipartOptions = {
    headers: new HttpHeaders({
      'Accept': 'application/json'
    })
  };

  constructor(@Inject('env') protected env: any,
              protected http: HttpClient,
              protected ng_zone: NgZone,
              notificationService: NotificationService) {
    super(env,ng_zone,notificationService);
  }



  handleError<T>(operation: any, result?: T, rollback: boolean = false): (error: any) => Observable<T> {
    return (response: any): Observable<T> => {
      let message = `It was not possible: ${operation}`;
      global.log('Service',message);
      global.log('Response',response);
      if (response.error && response.error.errors) {
        if (response.error.errors && response.error.errors.base && response.error.errors.base.length > 0){
          message += `, ${response.error.errors.base.join(', ')}`;
        }
        if (result && typeof result === 'object') {
          result['errors'] = response.error.errors || response.error || response.message;
          message += `, ${response.message}`;
        } else {
          _(response.error.errors).forEach((v:string[], k: string) => message += `, ${k}: ${v.join()}`)
        }
      } if (response.error && response.error.error_message) {
        message += `, ${response.error.error_message}`;
        result['errors'] = response.error.error_message;
      }

      this.notificationService.toast(message, true);
      if (rollback) {
        try { (result as any).rollback(); }
        catch {}
      }
      return of(result as T);
    };
  }

  handleSnapError<T>(operation: any, result?: T): (error: any) => T {
    return (error: any): T => {
      global.log("Error", error.message);
      this.notificationService.toast(`It was not possible: ${operation}`, true);
      if (result && typeof result === 'object') {
        result['error'] = operation;
      }
      return result;
    };
  }
}
