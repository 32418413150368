import { Component, OnInit, Input } from '@angular/core';
import { PackingsFilter, ArrivePackingList, Catalog, GoodCategory, PartNumber, CatalogsState, Client } from 'wms-lib';
import * as _ from 'lodash';
import { combineLatest, Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-receipts-filter',
  templateUrl: './receipts-filter.component.html',
  styleUrls: ['./receipts-filter.component.scss']
})
export class ReceiptsFilterComponent implements OnInit {
  good_categories: {[id: number]: Catalog };
  @Input() filter: PackingsFilter;
  @Input() pls: ArrivePackingList[];
  parts$: Observable<PartNumber[]>;
  client: Client;

  constructor(private store: Store) {}

  ngOnInit(): void {
    combineLatest(
      this.store.select(state => state.catalogsState.good_categories),
      this.store.select((state) => state.catalogsState.active_client)
    ).subscribe(([good_categories, client]) => {
      this.good_categories = _(good_categories).filter((f: GoodCategory) => (client && f.client_id == client.id) || f.client_id == null).keyBy("id").value();
      this.client = client;
    });
    this.parts$ = this.store.select(CatalogsState.getPartNumbersByActiveClient()).pipe(
      map((parts: {[id:number]: PartNumber}) => parts ? _.map(parts, (pn: PartNumber) => ({label: pn.name, value: pn})) : parts)
    );
  }

  get po_list(): string[] {
    return _(this.pls).
      map((apl: ArrivePackingList) => apl.packages_po_list).
      flatten().
      map((po: string) => ({label: po, value: po})).
      value();
  }
}
