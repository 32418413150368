import { Pipe } from '@angular/core';
import * as _ from 'lodash';
import { SortByPipeBase, SortByLocationTagBase } from './sort-by.pipe.base';


@Pipe({
    name: 'sortBy'
})
export class SortByPipe extends SortByPipeBase {
    
}

@Pipe({
	name: 'sortByLocationTag'
})
export class SortByLocationTagPipe extends SortByLocationTagBase {
	
}
