import { Asn } from "./asn";
import { Bundle } from "./bundle";
import { Bindable } from "./common";
import * as _ from 'lodash';
import { Document } from './document';
import * as moment from 'moment';
import { RemoteAsset } from "./remote-asset";
import { Client } from "./client";
import { Address } from "./address";

const SAVE_PROPS = ['asn_id','address_id','priority_id','order_number','purchase_order','customer_order','instructions','_destroy','freight_account', 'bundles_attributes','images', 'pop_id', 'pops','carrier_id','trk'];

export class DeliveryOrder extends Bindable {
  id: number;
  asn_id: number;
  address_id: number;
  priority_id: number;
  order_number: string;
  purchase_order: string;
  customer_order: string;
  invoice: string;
  itn: string;
  dgd: string;
  shipping: string;
  instructions: string;
  bundles: { [key: string]: Bundle } = {};
  _destroy: boolean = false;
  asn: Asn;
  freight_account: string;
  invoice_url: string;
  itn_url: string;
  dgd_url: string;
  shipping_url: string;
  documents: Document[] = [];
  images: RemoteAsset[] = [];
  damages: RemoteAsset[] = [];
  address: Address;
  ready_to_ship?: boolean;
  status?: string;
  pop: Document;
  carrier_id: number;
  trk: string;
  was_received?: boolean;
  images_count: number;
  damages_count: number;
  shipped_at: Date;

  constructor(data: any) {
    super(data);
    if (data.asn && data.asn.constructor.name == "Object") this.asn = new Asn(data.asn);
    if(data.address) this.address = new Address(data.address);
    this.id = this.id || (+(new Date())) * -1;
    if(_.size(_.values(data.bundles)) > 0) {
      this.buildBundles(data.bundles || []);
    }

    if(_.size(_.values(data.documents)) > 0) {
      this.buildDocuments(data.documents || []);
    }
    if(_.size(_.values(data.images)) > 0) {
      this.setImages(data.images || []);
    }
    if(_.size(_.values(data.damages)) > 0) {
	  this.setDamages(data.damages || []);
	}
    if (data.pop) this.setupPop(data.pop);
  }

  private setupPop(pop: any) {
    this.pop = new Document(_.merge(pop, {delivery_order: this}));
  }

  buildBundles(bundles: any[]) {
    this.bundles = {};
    for(let b of bundles) {
      this.bundles[b.id] = new Bundle({...b, ...{delivery_order: this}});
    }
  }

  buildDocuments(documents: any[]) {
    this.documents = [];
    for(let d of documents) {
      this.documents.push(new Document(d));
    }
  }

  addOrUpdateBundle(bundle: Bundle) {
    let bundles = { ...this.bundles };
    bundle.delivery_order_id = this.id;
    bundles[bundle.id] = bundle;
    this.bundles = bundles;
  }

  deleteBundle(id: number) {
    if(id < 0) {
      delete this.bundles[id];
    } else {
      this.bundles[id]._destroy = true;
    }
    this.bundles = { ...this.bundles };
  }


  appendAttachment(a: RemoteAsset) {
    this.images.push(a);
  }

  setImages(data: any[]) {
    this.images = (data || []).map( d => d instanceof RemoteAsset ? d : new RemoteAsset(d));
  }

  setDamages(data: any[]) {
  	  this.damages = (data || []).map( d => d instanceof RemoteAsset ? d : new RemoteAsset(d));
  }

  get bundles_attributes(): Bundle[] {
    return Object.values(this.bundles);
  }

  get saveProp(): string[] {
    let p = [];
    if (this.id && this.id > 0)
      p = super.saveProp.concat(this.pop && !this.pop.stored ? SAVE_PROPS.concat(['pop']) : SAVE_PROPS);
    else
      p = super.saveProp.concat(SAVE_PROPS);

    if(!!this.parseDataUrl(this.invoice_url)) p.push("invoice_url");
    if(!!this.parseDataUrl(this.itn_url)) p.push("itn_url");
    if(!!this.parseDataUrl(this.dgd_url)) p.push("dgd_url");
    if(!!this.parseDataUrl(this.shipping_url)) p.push("shipping_url");
    return p;
  }

  get isValid(): boolean {
    return this._destroy || (!!this.order_number && !!this.purchase_order && !!this.customer_order && !!this.instructions && this.address_id > 0 && this.priority_id > 0 && !!this.freight_account && this.carrier_id > 0);
  }

  get isComplete(): boolean {
    if(this._destroy) return true;
    let deletedBundles = _.filter(this.bundles, (b: Bundle) => b._destroy);
    if(_.size(this.bundles) == 0 || _.size(deletedBundles) == _.size(this.bundles)) return false;
    return (_.every(this.bundles, (b: Bundle) => b.isComplete) && this.isValid);
  }

  get hasFilesAttached(): boolean {
    return !!this.parseDataUrl(this.invoice_url) || !!this.parseDataUrl(this.itn_url) || !!this.parseDataUrl(this.dgd_url) || !!this.parseDataUrl(this.shipping_url);
  }

  get total_bundles(): number {
    return _.size(_.filter(this.bundles, (b: Bundle) => !b._destroy));
  }

  get total_parts(): number {
    return _.reduce(this.bundles, (sum: number, b: Bundle) => sum + b.total_parts, 0);
  }

  get color(): string {
    return this.priority_id == 35 ? '#D79696' : this.priority_id == 34 ? '#F5F9B5' : '';
  }

  get colorPriority(): string {
    return this.priority_id == 35 ? '#D79696' : this.priority_id == 34 ? '#F5F9B5' : '';
  }

  get colorSituation(): string {
    return this.ready_to_ship ? '#d1fbeb':  this.was_received ? '#8bc5f0' : '';
  }

  get info(): string{
    return this.total_bundles + ' bundle' + (this.total_bundles > 1 ? 's' : '') + ', ' + this.total_parts + ' part' + (this.total_parts > 1 ? 's' : '');
  }

  get order(): number {
    return moment(this.created_at).unix() + (( this.priority_id == 35 ? 3 : this.priority_id == 34 ? 2 : 1) * 1000000000);
  }

  get canTakePhotoForDocument(): boolean {
    return !this._destroy && _.every(this.bundles, (b: Bundle) => b.isProcessed);
  }

  get client(): Client {
    return this.asn.client;
  }

  get pop_id(): number {
    return this.pop && this.pop.id;
  }

  get canPreviewAttachment(): { [key: string]: boolean } {
    return {
      invoice: !!this.invoice_url && !this.parseDataUrl(this.invoice_url),
      itn: !!this.itn_url && !this.parseDataUrl(this.itn_url),
      dgd: !!this.dgd_url && !this.parseDataUrl(this.dgd_url),
      shipping: !!this.shipping_url && !this.parseDataUrl(this.shipping_url)
    }
  }

  generateFormData(mainKey='delivery') {
    let data = super.toSaveData();
    let formData = new FormData();

    let iterateArray = (key: string, array: any[]) => {
        array.forEach((item: any, index: number) => {
            for(let itemKey in item) {
                if(item[itemKey] !== undefined){
                    if(Array.isArray(item[itemKey])) {
                        iterateArray(`${key}[${index}][${itemKey}]`, item[itemKey]);
                    } else {
                        appendData(`${key}[${index}][${itemKey}]`, item[itemKey]);
                    }
                }
            }
        });
    };

    let appendData = (key: string, value: any) => {
        if(!!this.parseDataUrl(value)) {
            let url = this.parseDataUrl(value);
            let replaced = key.replace('_url', '');
            formData.append(`${mainKey}[${replaced || key}]`, this.dataURLtoBlob(url.groups["content"], `${url.groups["type"]}/${url.groups["ext"]}`), `${key}.${url.groups["ext"]}`);
        } else {
          if(value !== undefined){
            formData.append(`${mainKey}[${key}]`, value);
          }
        }
    }

    for (const key in data) {
        if(Array.isArray(data[key])) {
            iterateArray(key, data[key]);
        } else {
            appendData(key, data[key]);
        }
    }
    return formData;
  }


  toSaveDataForm() {
    return this.hasFilesAttached ? this.generateFormData() : super.toSaveData()
  }

}
