import { Catalog } from "./catalog";
import { Validable } from "./common";

export class TicketStatusLog extends Validable {
    validationKey = "ticket_status_log";
    active: boolean;
    ticket_id: number;
    ticket_status_id: number;

    ticket_status: Catalog;

    constructor(data) {
        super(data);
        this.setup_defaults();
    }


    private setup_defaults() {
        if (this.ticket_status && !(this.ticket_status instanceof Catalog)) {
            this.ticket_status = new Catalog(this.ticket_status);
        }
    }
}