import { Component, OnInit } from '@angular/core';
import { PackingsFilter } from 'wms-lib';
import { combineLatest } from 'rxjs';
import { Store } from '@ngxs/store';
import { Shipment, PartNumber, ShipmentsState, Package, OnStockStatus } from 'wms-lib';
import * as _ from 'lodash';
import { StateListenerComponent } from '../../../../shared/components/scan-listener/state-listener.component';
import { map, tap } from 'rxjs/operators';

@Component({
    selector: 'app-shipment-builder-by-parts',
    templateUrl: './shipment-builder-by-parts.component.html',
    styleUrls: ['./shipment-builder-by-parts.component.sass']
})
export class ShipmentBuilderByPartsComponent extends StateListenerComponent implements OnInit {
    inventory: PartNumber[];
    shipment: Shipment;
    filter: PackingsFilter;

    constructor(private store: Store) {
        super();
    }

    ngOnInit(): void {
        this.filter = new PackingsFilter();
        this.filter.categories = [];
        this.addSub(combineLatest(
            this.store.select(ShipmentsState.getPartNumbers()),
            this.store.select(state => state.shipmentsState.shipment_template)
        ).pipe(
            tap(([_, ship]: [{[id: number]: PartNumber}, Shipment]) => this.shipment = ship),
            map(([stock, ship]: [{[id: number]: PartNumber}, Shipment]) => {
                return stock && ship ?
                    PartNumber.mergePartNumbers(stock,PartNumber.fromPackages(_.values(ship.packages))) :
                    null;
            })
        ).subscribe((parts: PartNumber[]) => {
            this.inventory = _(parts).filter((pn: PartNumber) =>
                !!pn.id &&
                pn.packages.some((p: Package) =>
                    OnStockStatus.includes(p.status) ||
                    !!this.shipment.packages[p.id])).
                value();
        }));
    }
}
