import { Component, OnInit, Input } from '@angular/core';
import { DamageReport, Catalog } from 'wms-lib';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';

@Component({
    selector: 'app-damage-report',
    templateUrl: './damage-report.component.html',
    styleUrls: ['./damage-report.component.scss']
})
export class DamageReportComponent implements OnInit {
    @Input() damage_report: DamageReport;
    damage_types$: Observable<{ [id: number]: Catalog }> = new Observable<{ [id: number]: Catalog }>();
    constructor(private store: Store) { }

    ngOnInit() {
        this.damage_types$ = this.store.select(state => state.catalogsState.damage_types);
    }

}
