import { HttpClient } from "@angular/common/http";
import { Inject, Injectable, NgZone } from "@angular/core";
import { TicketPagedList, Ticket, User } from "../models";
import { GenericService } from "./generic.service";
import * as _ from 'lodash';
import { catchError, map } from "rxjs/operators";
import { Observable, BehaviorSubject } from "rxjs";
import { NotificationService } from "../platform-services/notification.service";

const TICKETS_URL = 'tickets';
const TICKETS_RESUME_URL = 'tickets/resume';
const COMPANY_USERS_URL = 'users';

@Injectable({providedIn: 'root'})
export class TicketsService  extends GenericService {
    tickets_stream: BehaviorSubject<TicketPagedList | Ticket | {} | number>;

    constructor(@Inject('env') env: any, http: HttpClient, ng_zone: NgZone, notificationService: NotificationService) {
        super(env, http, ng_zone, notificationService);
    }

    getPagedTicketsFromApi(status:string, client_id:number, page:number, direction: string = null): Observable<TicketPagedList | {}> {
        const parts = _.compact([TICKETS_URL, status, client_id > 0 ? client_id : null, page, direction]);
        return this.http.get(this.serverUrl + parts.join("/"), this.httpOptions)
            .pipe(
                map(response => new TicketPagedList({...response, client_id: client_id, status: status})),
                catchError(this.handleError(' Getting Paged Tickets',{}))
            );
    }

    getPagedTickets(status: string, client_id: number, page:number): Observable<TicketPagedList | {}> {
        return this.getPagedTicketsFromApi(status, client_id,page).pipe(
            map(data => { this.tickets_stream.next(data); return data; }));
    }

    setLiveStream(client_id: number | string) {
        if (!this.tickets_stream) this.tickets_stream = new BehaviorSubject<TicketPagedList | Ticket | number | {}>({});
        this.openLiveChannel(client_id);
        return this.tickets_stream;
    }

    resetLiveStream() {
        if (this.tickets_stream) {
            this.tickets_stream.complete();
            this.tickets_stream = new BehaviorSubject<TicketPagedList | Ticket | number | {}>({});
        }
    }

    private openLiveChannel(client_id: number | string) {
        if (!this.channels_subs[`tickets_client_${client_id}`]) {
            this.closeChannelsByCriteria('tickets_client');
            this.openChannel({channel: 'TicketsChannel', client_id: client_id},`tickets_client_${client_id}`)
                .subscribe((response:any) => {
                    if (response) {
                        if (response['page']) {
                            this.tickets_stream.next(new TicketPagedList(response));
                        } else if(response['id']){
                            this.tickets_stream.next(new Ticket(response));
                        }else if (response['_deleted']){
                            this.tickets_stream.next(response);
                        }
                    } 
                });
        }
    }

    getTicket(id:number): Observable<Ticket> {
        return this.http.get(this.serverUrl + TICKETS_URL + `/show/${id}`, this.httpOptions).pipe(
            map(response => new Ticket(response)),
            catchError(this.handleError("getting ticket", null))
        );
    }

    getCompanyUsers() {
        return this.http.get(this.serverUrl + COMPANY_USERS_URL, this.httpOptions).pipe(
            map(response => _(response).map(u => new User(u)).keyBy((e: User)=> e.id).value()),
            catchError(this.handleError("getting company users", { }))
        );
    }

    delete(ticket: Ticket) {
        return this.http.delete(this.serverUrl + TICKETS_URL + "/" + ticket.id.toString(),
                                this.httpOptions).pipe(
                                    map(() => {
                                        if (this.tickets_stream) this.tickets_stream.next(ticket.id);
                                        return ticket;
                                    }),
                                    catchError(this.handleError<Ticket>('RemovingTicket',ticket))
                                );
    }

    save(model: Ticket) {
        const data = model.toSaveData();
        const options = data instanceof FormData ? this.httpMultipartOptions : this.httpOptions;
        return (model.id > 0 ?
            this.http.put(this.serverUrl + TICKETS_URL + "/" + model.id,data,options) :
            this.http.post(this.serverUrl +  TICKETS_URL, data, options)).
            pipe(
                map(response => new Ticket(response)),
                catchError(this.handleError(' saving ticket', model))
            );
    }

    getSummary(): Observable<any> {
        return this.http.get(this.serverUrl + TICKETS_RESUME_URL, this.httpOptions).pipe(
            catchError(this.handleError(' get tickets resume', {}))
        );
    }

    getTodayTicketsFromApi(): Observable< {[client_id: number]: { [status: string]: TicketPagedList } } | {}> {
        const parts = _.compact([TICKETS_URL, 'today']);
        return this.http.get(this.serverUrl + parts.join("/"), this.httpOptions)
        .pipe(
            map((response: {[status: string]: Ticket[]}) => {
                let res: {[client_id: number]: { [status: string]: TicketPagedList } } = {};
                _(response).forEach((tickets: Ticket[], status: string) => {
                    let groupByClient = _.groupBy(tickets, (ticket: Ticket) => ticket.client_id);
                    _.forEach(groupByClient, (tickets: Ticket[], client_id: string) => {
                        if (!res[Number(client_id)]) {
                            res[Number(client_id)] = {};
                        }
                        res[Number(client_id)][status] = new TicketPagedList({
                            items: tickets,
                            client_id: Number(client_id),
                            status: status,
                            total_items: tickets.length,
                            page: 1,
                            page_size: tickets.length
                        });
                    }
                )});
                return res;
            }), catchError(this.handleError(' Getting Today Tickets',[]))
        );
    }

    getTodayTickets(): Observable< {[client_id: number]: { [status: string]: TicketPagedList } } | {}> {
        return this.getTodayTicketsFromApi().pipe(
            map(data => { return data; }));
    }

}
