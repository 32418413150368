import { Component, OnInit } from '@angular/core';
import {DynamicDialogConfig, DynamicDialogRef} from 'primeng/dynamicdialog';
import { Ticket } from 'wms-lib';

@Component({
  selector: 'app-ticket-details',
  templateUrl: './ticket-details.component.html',
  styleUrls: ['./ticket-details.component.scss']
})
export class TicketDetailsComponent implements OnInit {

  ticket: Ticket;

  attachment_columns = [
    { field: 'name', header: 'Filename' },
    { field: 'created_at', header: 'Created At' },
    { field: 'src', header: 'Options' },
  ];

  constructor(private ref: DynamicDialogRef, 
              private config: DynamicDialogConfig) { 
                
     this.ticket = this.config.data.ticket
  }

  ngOnInit() {

  }

}
