import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ClientHomeComponent } from './components/home/home.component';
import { ShipmentBuilderComponent } from './components/shipments/shipment-builder/shipment-builder.component';
import { ShipmentReviewComponent } from './components/shipments/shipment-review/shipment-review.component';
import { ReceiptsComponent } from './components/receipts/receipts.component';
import { ReceiptsPackingListsComponent } from './components/receipts/receipts-packing-lists/receipts-packing-lists.component';
import { ReceiptsPackingListComponent } from './components/receipts/receipts-packing-list/receipts-packing-list.component';
import { ReceiptsPackageComponent } from './components/receipts/receipts-package/receipts-package.component';
import { ShipmentPackingListComponent } from './components/shipments/shipment-packing-list/shipment-packing-list.component';
import { ShipmentGeneralFormComponent } from './components/shipments/shipment-general-form/shipment-general-form.component';
import { ShipmentComponent } from './components/shipments/shipment.component';
import { ClientsRoutingModule } from './clients-routing.module';
import { SharedModule } from '../shared/shared.module';
import { GaugeChartModule } from 'angular-gauge-chart';
import { ReceiptsFilterComponent } from './components/shipments/receipts-filter/receipts-filter.component';
import { PartsFilterComponent } from './components/shipments/parts-filter/parts-filter.component';
import { PartsResumeComponent } from './components/shipments/parts-resume/parts-resume.component';
import { ReceiptsResumeComponent } from './components/shipments/receipts-resume/receipts-resume.component';
import { ShipmentBuilderByReceiptsComponent } from './components/shipments/shipment-builder-by-receipts/shipment-builder-by-receipts.component';
import { ShipmentBuilderByPartsComponent } from './components/shipments/shipment-builder-by-parts/shipment-builder-by-parts.component';
import { ReceiptsByPartComponent } from './components/receipts/receipts-by-part/receipts-by-part.component';

@NgModule({
    declarations: [
        ClientHomeComponent,
        ReceiptsComponent,
        ReceiptsPackingListsComponent,
        ReceiptsPackingListComponent,
        ReceiptsPackageComponent,
        ShipmentComponent,
        ShipmentBuilderComponent,
        ShipmentReviewComponent,
        ShipmentGeneralFormComponent,
        ShipmentPackingListComponent,
        ReceiptsFilterComponent,
        PartsFilterComponent,
        PartsResumeComponent,
        ReceiptsResumeComponent,
        ShipmentBuilderByReceiptsComponent,
        ShipmentBuilderByPartsComponent,
        ReceiptsByPartComponent
    ],
    exports: [
        ClientHomeComponent,
        ReceiptsComponent,
        ReceiptsPackingListsComponent,
        ReceiptsPackingListComponent,
        ReceiptsPackageComponent,
        ShipmentComponent,
        ShipmentBuilderComponent,
        ShipmentReviewComponent,
        ShipmentGeneralFormComponent
    ],
    imports: [
        CommonModule,
        ClientsRoutingModule,
        SharedModule.forRoot(),
        GaugeChartModule
    ]
})
export class ClientModule {
    static forRoot() {
        return {
            ngModule: ClientModule
        };
    }
}
