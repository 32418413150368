import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngxs/store';
import { Observable, Subject } from 'rxjs';
import { applyMixins, Damage, CodebarReaderService, Package } from 'wms-lib';
import { CanTakeDamage } from '../../../shared/mixins/can-take-picture';
import { PackageDamagesComponent } from './package-damages.component';


@Component({
  selector: 'app-damages',
  templateUrl: './damages.component.html',
  styleUrls: ['./damages.component.scss']
})
export class DamagesComponent extends PackageDamagesComponent implements OnInit, AfterViewInit, CanTakeDamage {
  @ViewChild('inputFile', { static: false }) damage_element: ElementRef;
  selectedImage: Subject<any> = new Subject<any>();
  onChangeImage: () => Promise<void>;
  package: Package;
  package$: Observable<Package>;


  constructor(
    protected _route: ActivatedRoute,
    protected store: Store,
    protected codebar_service: CodebarReaderService
  ) {
    super(_route, store, codebar_service);
  }

  ngAfterViewInit() {
    this.damage_element.nativeElement.addEventListener("change", this.onChangeImage.bind(this));
  }

  ngOnInit(): void {
    super.ngOnInit();
  }

 

  get active_damages(): Damage[] {
    return this.package.damage_report.damages.filter((d: Damage) => !d._destroy);
  }
}
applyMixins(DamagesComponent, [CanTakeDamage]);
