import { Catalog } from "./catalog";
import { Package } from "./package";
import * as _ from 'lodash';

export class PartNumber extends Catalog {
    validationKey: string = "part-number";
    id: number;
    private _name: string;
    client_id: number;
    description: string;
    product_type: string;
    unit: any;
    unit_id: number;
    unit_name: string;
    category_id: number;
    quantity?: number; //Temp property used when assign to package
    packages?: Package[];
    message?: string; //Temp property used when import
    part_number_id: number;
    _destroy: boolean = false;

    static fromPackages(packages: Package[]): {[id: number]: PartNumber} {
        let pns = _(packages).
            groupBy('part_number_id').
            mapValues((pkgs: Package[]) => {
                let pn = new PartNumber({...pkgs[0].part_number});
                pn.packages = pkgs;
                return pn;
            }).
            value();
        return pns;
    }

    static mergePartNumbers(a: {[id: number]: PartNumber}, b: {[id: number]: PartNumber}): PartNumber[] {
        let result = _.mergeWith(a,b,(t: any,s: any) => {
            return _.mergeWith(t || new PartNumber({}),s, (tt: any, ss: any, kk: string) => {
                if (kk == "packages") return _.values(_.merge(_.keyBy(tt, 'id'),_.keyBy(ss, 'id')));
            })
        });
        return _(result).values().uniqBy('id').value();
    }

    constructor(data:any) {
        super(data);
        this.id = this.id || (+(new Date())) * -1;
        if (data.quantity) this.quantity = +this.quantity;
        if (data.packages) this.packages = data.packages.map((p: any) => new Package(p));
    }

    get name(): string {
        return this._name;
    }
    set name(value: string) {
        this._name = value;
    }

    get have_stock(): boolean {
        return this.stock_packages && this.stock_packages.length > 0;
    }

    get stock_packages(): Package[] {
        return this.packages.filter((p: Package) => p.inStock )
    }

    get totalQuantity(): number {
    	return _.sumBy(this.packages, 'quantity');
    }

    protected get saveProp(): string[] {
        if (this._destroy)
            return ["id", "_destroy"];
        if (this.part_number_id && this.quantity){
            let p = ["part_number_id", "quantity"]
            if (this.id > 0) p.push("id");
            return p;
        }
        return  ["client_id","name","description","product_type","unit_id","good_category_id","unit_name"];
    }

    get search_tag(): string {
        return (this.name || '').toLowerCase() + ' ' + (this.description || '').toLowerCase();
    }
}
