import { Routes } from '@angular/router';
import { AsnsComponent } from './components/asns/asns.component';
import { AsnDetailComponent } from './components/asns/asn-detail/asn-detail.component';
import { DeliveryComponent } from './components/delivery/delivery.component';
import { PopsCarrouselComponent } from '../shared/components/documents-carrousel/pops-carrousel.component';
import { PopViewerComponent } from '../shared/components/document-viewer/pop-viewer.component';

export const componentDeclarations: any[] = [
];

export const providerDeclarations: any[] = [
];

export const routes: Routes = [
    { path: 'asns', component: AsnsComponent },
    { path: 'asns/:id', component: AsnDetailComponent},
    {
        path: '',
        redirectTo: 'asns',
        pathMatch: 'full'
    },
    {
      path: 'deliveries',
      component: DeliveryComponent,
      children: [
        {
          path: '',
          component: PopsCarrouselComponent,
          outlet: 'documents',
          pathMatch: 'full'
        },
        {
          path: '',
          component: PopViewerComponent,
          outlet: 'viewer'
        }
      ]
    }
];
