import { Validable } from "./common";
import { User } from "./user";

const SAVE_PROPS = ['comment', 'created_by_id'];

export class TicketComment extends Validable {
    validationKey = "ticket_comment";

    comment: string;
    created_by_id:number;
    created_by: User;

    constructor(data) {
        super(data);
    }
    

    get saveProp(): string[] {
        return super.saveProp.concat(SAVE_PROPS);
    }
}