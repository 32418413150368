import { Directive, Input } from '@angular/core';
import { DeviceInfo, Document, AuthenticationService } from 'wms-lib';
import { Store } from '@ngxs/store';
import { ActivatedRoute } from '@angular/router';
import { StateListenerComponent } from '../scan-listener/state-listener.component';

@Directive({selector: '[appDocViewerBaseComponent]'})
export class DocumentViewerBaseComponent extends  StateListenerComponent {
    @Input() read_only = false;
    @Input() document: Document;
    @Input() only_file = false;

    constructor(protected store: Store, protected route: ActivatedRoute,
                protected authenticationService : AuthenticationService) {
        super();
    }

    goToDocuments() {
        if (DeviceInfo.isTablet) {
            this.goToTabletCarousel();
        } else {
            this.goToPacks();
        }
    }

    goToTabletCarousel() {
        throw new Error('This method is abstract');
    }

    goToPacks() {
        throw new Error('This method is abstract');
    }

    protected get isPrimary(): boolean {
        return this.route.outlet === 'primary';
    }


    get canEdit(): boolean {
        return !this.read_only;// && (!this.document.arrive_packing_list.isValid || this.authenticationService.currentUserValue.isSupervisor);
    }

}
