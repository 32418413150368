import { Injectable } from "@angular/core";
import { Action, State, StateContext } from "@ngxs/store";
import { TicketsService } from "../../services/tickets.service";
import { AuthenticationService } from "../../services/authentication.service";
import { TicketPagedList } from "../../models";
import { Ticket, User } from "../../models";
import * as actions from '../actions';
import * as _ from 'lodash';
import { Subscription } from "rxjs";

export interface IHelpDeskState {
    tickets: {[client_id:number]:{[status:string]: TicketPagedList}},
    users: {[id:string]: User},
    company_users: {[id:string]: User}
    saving: boolean,
    saved: boolean
    active_ticket: Ticket;
}

const defaultStateValue =
    {
        tickets: {},
        users: {},
        company_users: {},
        saving: false,
        saved: false,
        active_ticket: null
    };



@State<IHelpDeskState>({
    name: 'helpDeskState',
    defaults:  {...defaultStateValue}
})
@Injectable()
export class HelpDeskState {
    private live_subscriptions: {[name:string]: Subscription} = {};
    private current_user: User;

    constructor(private ticketsService: TicketsService, private authenticationService : AuthenticationService) {
    	this.current_user = this.authenticationService.currentUserValue;
    }


    @Action(actions.GetTicketsAction)
    getTickets(ctx: StateContext<IHelpDeskState>, {payload}: actions.GetTicketsAction) {
        global.log("State",`Get Tickets ${payload.status_key} client: ${payload.client_id}`);
        const stream_name = payload.client_id.toString();

        this.closeTicketLiveStreamSubsWhenClientChanges(payload.client_id);

        if (!this.live_subscriptions[stream_name]) { // Create only a subcription per component that  retrieves the ticket list
            this.live_subscriptions[stream_name] = this.ticketsService.setLiveStream(payload.client_id).subscribe(data => {
                this.updateTicketState(ctx,data,payload.clear_loaded_pages);
            });
        }
        return this.ticketsService.getPagedTickets(payload.status_key,payload.client_id,payload.page);
    }

    private closeTicketLiveStreamSubsWhenClientChanges(request_client_id :number) {
        _.keys(this.live_subscriptions).forEach(sub_name => {
            if (!sub_name.startsWith(request_client_id.toString()) && this.live_subscriptions[sub_name]) {
                this.ticketsService.resetLiveStream(); // To avoid last emited value when a client change
                this.live_subscriptions[sub_name].unsubscribe();
                delete this.live_subscriptions[sub_name];
            }
        });
    }


    private updateTicketState(ctx: StateContext<IHelpDeskState>,
                              data: TicketPagedList | Ticket | any,
                              clear_loaded_pages?:boolean) {
        if ((data instanceof TicketPagedList)) {
            this.updateWhenPagedListIsGiven(ctx,data,clear_loaded_pages);
        }
        else if (data instanceof Ticket) {
            this.updateWhenASingleTickedCreatedUpdated(ctx,data);
        } else if (data['_deleted'] === true){
            this.updateWhenATicketIsDeleted(ctx,data);
        }
    }

    private updateWhenPagedListIsGiven(ctx: StateContext<IHelpDeskState>,
                                       data: TicketPagedList,
                                       clear_loaded_pages?:boolean) {
        let tks =  {...ctx.getState().tickets};
        data.all_loaded = _.size(data.items) <= 0 ? true: false;

        if (!clear_loaded_pages) {
            const mergeData = _.merge((tks[data.client_id] && tks[data.client_id][data.status])
                ? tks[data.client_id][data.status].items : {},
                                      data.items);
            data.items = {...mergeData};
        }
        tks[data.client_id] = _.merge(tks[data.client_id] || {[data.status]: {}}, {[data.status]: data});
        tks[data.client_id] = {...tks[data.client_id]};
        ctx.patchState({ tickets: tks});
    }

	private canAssignTicket(ticket: Ticket): boolean {
		if(this.current_user.isMaterialist || this.current_user.isAdmin) {
			const client_id = _.get(ticket, 'client_id', null);
			return this.current_user.client_ids.includes(client_id);
		} else if(this.current_user.isSupervisor) {
			const created_by = _.get(ticket, 'created_by_id', null);
			return this.current_user.warehouse_id == created_by.warehouse_id;
		} else {
			const created_by = _.get(ticket, 'created_by_id', null);
			const assign_to = _.get(ticket, 'assign_to_id', null);
			return this.current_user.id == created_by || this.current_user.id == assign_to;
		}
	}

    private updateWhenASingleTickedCreatedUpdated(ctx: StateContext<IHelpDeskState>, data: Ticket) {
		let tickets =  ctx.getState().tickets;
		if (this.canAssignTicket(data)) {
			tickets[data.client_id] = tickets[data.client_id] || {};
			tickets[0] = tickets[0] || {};

			tickets[data.client_id][data.status_key] = tickets[data.client_id][data.status_key] || new TicketPagedList({
				client_id: data.client_id,
				status: data.status_key,
				items: {}
			});        
			tickets[data.client_id][data.status_key].items[data.id] = data;
			tickets[data.client_id][data.status_key] = new TicketPagedList(tickets[data.client_id][data.status_key] || {});

			tickets[0][data.status_key] = tickets[0][data.status_key] || new TicketPagedList({
				client_id: 0,
				status: data.status_key,
				items: {}
			});

			tickets[0][data.status_key].items[data.id] = data;
			tickets[0][data.status_key] = new TicketPagedList(tickets[0][data.status_key] || {});

			_.keys(tickets[data.client_id]).forEach(k => {
				if (k !== data.status_key ){
					if (tickets[data.client_id][k].items[data.id]) {
						delete tickets[data.client_id][k].items[data.id];
						tickets[data.client_id][k].items = {...tickets[data.client_id][k].items};
					}
				}
			});

			_.keys(tickets[0]).forEach(k => {
				if (k !== data.status_key ){
					if (tickets[0][k].items[data.id]) {
						delete tickets[0][k].items[data.id];
						tickets[0][k].items = {...tickets[0][k].items};
					}
				}
			});

			tickets[data.client_id] = {...tickets[data.client_id]};
			tickets[0] = {...tickets[0]};
		}
		ctx.patchState({ tickets :{...tickets}});
    }

    private updateWhenATicketIsDeleted(ctx: StateContext<IHelpDeskState>, data:any) {
        let tickets =  ctx.getState().tickets;
        if (
            (tickets[data.client_id] && tickets[data.client_id][data.status_key] && tickets[data.client_id][data.status_key].items[data.ticket_id])||
            (tickets[0] && tickets[0][data.status_key] && tickets[0][data.status_key].items[data.ticket_id])
        ) {
            if(tickets[data.client_id] && tickets[data.client_id][data.status_key]){
                delete tickets[data.client_id][data.status_key].items[data.ticket_id];
                tickets[data.client_id][data.status_key] = new TicketPagedList(tickets[data.client_id][data.status_key]);
                tickets[data.client_id] = {...tickets[data.client_id]};
            }
            if(tickets[0] && tickets[0][data.status_key]){
                delete tickets[0][data.status_key].items[data.ticket_id];
                tickets[0][data.status_key] = new TicketPagedList(tickets[0][data.status_key]);
                tickets[0] = {...tickets[0]};
            }
            ctx.patchState({ tickets :{...tickets}});
        }
    }

    @Action(actions.SaveTicketAction)
    saveTicket(ctx: StateContext<IHelpDeskState>,{payload}: actions.SaveTicketAction) {
        global.log("State",`Save Ticket `)

        ctx.patchState({saved: false,saving: false});

        this.ticketsService.save(payload.ticket).subscribe(ticket => {
            if (_.size(ticket['errors']) <= 0) {
                ctx.patchState({ 
                    saved: true,
                    saving: false,
                    active_ticket: ticket
                });
            } else {
                ctx.patchState({saved: false,saving: false});
            }
        });
    }

    @Action(actions.ResetTicketSaveStatus)
    resetSaveStatus(ctx: StateContext<IHelpDeskState>) {
        ctx.patchState({saved: false,saving: false});
    }

    @Action(actions.SetActiveTicketAction)
    setActiveTicket(ctx: StateContext<IHelpDeskState>, {payload}: actions.SetActiveTicketAction) {
        ctx.patchState({active_ticket: payload});
    }

    @Action(actions.DeleteTicketAction)
    deleteTicket(ctx: StateContext<IHelpDeskState>, {payload}: actions.DeleteTicketAction){
        this.ticketsService.delete(payload).subscribe(ticket => {
            if (!(_.size(ticket['errors']) > 0)) {
                let tickets =  ctx.getState().tickets;
                ctx.patchState({tickets: {...tickets}});
            }
        });
    }

    @Action(actions.GetTicketAction)
    getTicket(ctx: StateContext<IHelpDeskState>, {payload}: actions.GetTicketAction){
        this.ticketsService.getTicket(payload).subscribe(ticket => {
            if (ticket && _.size(ticket['errors']) == 0) {
                ctx.patchState({active_ticket: ticket});
            } else {
                ctx.patchState({active_ticket: null});
            }
        });
    }

    @Action(actions.GetCompanyUsersAction)
    getCompanyUsers(ctx: StateContext<IHelpDeskState>) {
        global.log("State",`Get Company Users `)
        this.ticketsService.getCompanyUsers().subscribe(data => {
            ctx.patchState({company_users: data});
        });
    }


    @Action(actions.ClearHelpDeskStateAction)
    clearState(ctx: StateContext<IHelpDeskState>){ 
        global.log("State",`Clear help desk state`);
        ctx.setState({...defaultStateValue});
    }

    @Action(actions.GetAllTodayTicketsAction)
    getAllTodayTickets(ctx: StateContext<IHelpDeskState>, {}: actions.GetAllTodayTicketsAction) {
        global.log("State",`Get All today Tickets`);
        ctx.patchState({tickets: null});
        return this.ticketsService.getTodayTickets().subscribe((tickets: {[client_id: number]: { [status: string]: TicketPagedList } }) => {
            const current_data = _.merge({}, tickets);
            ctx.patchState({tickets: current_data});
        });
    }

    @Action(actions.SetTicketUpdate)
    setTicketUpdate(ctx: StateContext<IHelpDeskState>, {payload}: actions.SetTicketUpdate) {
        this.updateTicketState(ctx,payload, false);
    }
}
