import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from 'wms-lib';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

@Component({
  selector: 'app-update-password',
  templateUrl: './update-password.component.html',
  styleUrls: ['./update-password.component.scss']
})
export class UpdatePasswordComponent implements OnInit {
  loading: boolean = false;

  constructor(private authService: AuthenticationService, private router: Router) { }

  ngOnInit() {
    this.loading = false;
  }

  updateFormState(form: NgForm) {
    if (form.controls["new_password"].value != form.controls["password_confirmation"].value)
      form.controls['password_confirmation'].setErrors({'incorrect': true});
    if (form.controls["password"].value == form.controls["new_password"].value)
      form.controls['new_password'].setErrors({'incorrect': true});
  }

  updatePassword(form: NgForm) {
    this.loading = true;
    this.updateFormState(form);
    if (form.valid) {
      this.authService.UpdatePassword(form.value.password, form.value.new_password, form.value.password_confirmation)
        .subscribe((success: boolean) => {
          this.loading = false;
          if (success) this.router.navigate(['/']);
        });
    }
  }
}
