import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HomeComponent } from './components/home/home.component';
import { LocationCardListComponent } from './components/location-card-list/location-card-list.component';
import { RoutingModule } from './routing.module';
import { SharedModule } from '../shared/shared.module';
import { LocationCardComponent } from './components/location-card/location-card.component';
import { LocationCardDetailComponent } from './components/location-card-detail/location-card-detail.component';
import { InventoryPackageComponent } from './components/inventory-package/inventory-package.component';
import { InventoryPackingListComponent } from './components/inventory-packing-list/inventory-packing-list.component';
import { InventoryPackingListsComponent } from './components/inventory-packing-lists/inventory-packing-lists.component'
import { InventoryCyclicComponent } from './components/inventory-cyclic/inventory-cyclic.component';
import { InventoryCyclicTagsComponent } from './components/inventory-cyclic/inventory-cyclic-tags/inventory-cyclic-tags.component';



@NgModule({
    declarations: [
        HomeComponent,
        LocationCardListComponent,
        LocationCardComponent,
        LocationCardDetailComponent,
        InventoryPackageComponent,
        InventoryPackingListComponent,
        InventoryPackingListsComponent,
        InventoryCyclicComponent,
        InventoryCyclicTagsComponent
    ],
    imports: [
        RoutingModule,
        SharedModule.forRoot(),
        CommonModule
    ],
    exports: []
})
export class InventoryModule { }
