import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import * as actions from 'wms-lib';
import { Observable } from 'rxjs';
import { User } from 'wms-lib';

@Component({
    selector: 'app-users',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {
    users$: Observable<User[]>;
    expandedRows: number[] = [];
    constructor(private store: Store) { }

    ngOnInit() {
        this.store.dispatch(new actions.GetUsersAction());
        this.store.dispatch(new actions.GetRolesAction());
        this.users$ = this.store.select((state) => state.catalogsState.users);
    }

    addNew() {
        let u = new User({id: (+(new Date()))*-1});
        this.store.dispatch(new actions.AddNewUserAction(u));
        this.expandedRows = [u.id];
    }

    close(remove: User) {
        this.expandedRows = [];
        if (remove)
            this.store.dispatch(new actions.DeleteNewUserAction(remove));
    }
}
