import { Bindable } from "./common";
import { Damage } from "./damage";
import * as _ from 'lodash';
import { Package } from "./package";
import { Bundle } from "./bundle";

export class DamageReport extends Bindable {
  id:number;
  damages: Damage[];

  private _package: Package;
  package_id: number;

  private _bundle: Bundle;
  bundle_id: number;

  damage_type_id: number;
  report_number:string;
  comments:string;


  constructor(data:any){
    super(data);
    this.id = this.id || (+new Date())*-1;
    this.damages = this.damages || [];
    this.comments = this.comments || ""
    this.report_number = this.report_number || ""

    this.damages = this.damages.map( d => {
      let dm = d instanceof Damage ? d : new Damage(d);
      dm.damage_report = this;
      dm.damage_report_id = this.id;
      return dm;
    });
  }

  get bundle(): Bundle {
    return this._bundle;
  }
  set bundle(value: Bundle) {
    if (value)
      this.bundle_id = value.id;
    this._bundle = value;
  }

  get package(): Package {
    return this._package;
  }
  set package(value: Package) {
    if (value)
      this.package_id = value.id;
    this._package = value;
  }

  addDamage(damage: Damage) {
    damage.damage_report = this;
    damage.damage_report_id = this.id;
    this.damages = [damage,...this.damages];
  }

  get saveProp(): string[] {
    return super.saveProp.concat(["package_id", "bundle_id", "report_number", "comments", "damages", "damage_type_id"]);
  }

  get filters(): any {
    return {
      "damages": (d: Damage) => !d.stored || d._destroy
    };
  }

  get arrival_id(): number {
    return this.package.arrival_id;
  }

  get arrive_packing_list_id(): number {
    return this.package.arrive_packing_list_id;
  }

  get damage_loaded(): boolean {
    return this.damages && this.damages.length > 0;
  }
}
