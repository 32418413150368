import { Component, OnInit, Input, ViewChild, OnChanges} from '@angular/core';
import { Store } from '@ngxs/store';
import * as _ from 'lodash';
import { ArrivePackingList, CatalogsState, GoodCategory, Package, PartNumber, Unit } from 'wms-lib';
import { Table } from 'primeng/table';


@Component({
    selector: 'part-numbers-dialog-list',
    templateUrl: './part-numbers-dialog-list.component.html',
    styleUrls: ['../../../../shared/components/base-components/packing-lists.component.scss']
})
export class PartNumbersDialogListComponent implements OnInit, OnChanges {
    @ViewChild('dt',{static: false}) partNumberTable: Table;
    @Input() ptap?: Package | ArrivePackingList = undefined;
    parts: {[id: number]: PartNumber};
    good_categories: {[id: number]: GoodCategory};
    units: {[id: number]: Unit};
    partNumberFilter: string = '';
    constructor(private store: Store) {}

    ngOnInit(): void {
        this.store.select(state => state.catalogsState.good_categories).subscribe(data => this.good_categories = data);
        this.store.select(state => state.catalogsState.units).subscribe(data => this.units = data);
    }

    ngOnChanges(changes: any) {
        if(this.ptap)
            this.assignDialog();
    }

    assignPartNumber(pkg: PartNumber = null) {
        if (this.ptap instanceof Package && pkg) 
            this.ptap.part_number = pkg;
        if (this.ptap instanceof ArrivePackingList)
            this.ptap.updatePartNumbers(_(this.parts).filter((p: PartNumber) => p.quantity > 0).value());
        this.ptap.updateCallback();
        this.ptap = undefined;
    }

    assignPartNumberToAll(pkg: PartNumber) {
        if (this.isPackage && pkg) {
            (this.ptap as Package).arrive_packing_list.packages_a.forEach((p: Package) => {
                p.part_number = pkg;
                p.quantity = (this.ptap as Package).quantity;
            });
            this.ptap.updateCallback();
            this.ptap = undefined;
        }
        this.ptap = undefined;
    }

    assignDialog() {
        this.store.select(CatalogsState.getPartNumbersByClient(this.ptap.client_id)).subscribe((data)=>{
            let ids = this.ptap.part_numbers_dic;
            this.parts = _(data).mapValues((s: PartNumber)=>{
                s.quantity = !!ids[s.id] ? ids[s.id].quantity : undefined;
                return s;
              }).value();
        });
        if(this.ptap instanceof Package && (this.ptap as Package).part_number) {
            let part:any = (this.ptap as Package).part_number;
            this.partNumberFilter = part?.name || '';
            setTimeout(()=>{this.partNumberTable.filterGlobal(part?.name,'contains');},1000)
        }
        if(this.ptap instanceof ArrivePackingList && ((this.ptap as ArrivePackingList).part_numbers || []).length > 0) {
            this.partNumberFilter = ((this.ptap as ArrivePackingList).part_numbers[0] || {}).name || '';
            setTimeout(()=> {
                this.partNumberTable.filterGlobal(
                    (this.ptap as ArrivePackingList).part_numbers.map((pn: PartNumber) => pn.name),
                    'in');
            },500)
		}
    }

    get isPackage(): boolean {
        return this.ptap instanceof Package;
    }

    get isApl(): boolean {
        return this.ptap instanceof ArrivePackingList;
    }

}
