import { Package, Arrival, DamageReport, Document, Pallet, ArrivePackingList } from '../../models';

export class GetActiveArrivalsAction {
  public static readonly type = '[Warehouse] Get Active Arrival';
  constructor(public payload: string) { }
}

export class GetPagedArrivalsAction {
  public static readonly type = '[Warehouse] Get Paged Arrivals';
  constructor(public payload: { type: string, page: number, search?: string }) { }
}

export class GetArrivalAction {
  public static readonly type = '[Warehouse] Get Arrival';
  constructor(public payload: { id: number , type: string }) { }
}

export class GetArrivePackingListAction {
  public static readonly type = '[Warehouse] Get Arrive Packing List';
  constructor(public payload: { arrival: Arrival }) { }
}

export class MarkPackageToBeDeletedAction {
  public static readonly type = '[Warehouse] Mark Package To Be Deleted';
  constructor(public payload: { pkg: Package }) { }
}

export class MarkPackageToNotBeDeletedAction {
  public static readonly type = '[Warehouse] Mark Package To Be Not Deleted';
  constructor(public payload: { pkg: Package }) { }
}

export class UpdateArriveAction {
  public static readonly type = '[Warehouse] Update arrive';
  constructor(public payload: { arrival: Arrival, package: Package }) { }
}

export class UpdateDamageReportAction {
  public static readonly type = '[Warehouse] Update Damage Report';
  constructor(public payload: DamageReport) { }
}

export class GetDocumentsAction {
  public static readonly type = '[Warehouse] Get Documents';
}

export class DeleteDocumentsAction {
  public static readonly type = '[Warehouse] Delete Documents';
  constructor(public payload: number) { }
}

export class AssignDocumentToWorkingPackingAction {
  public static readonly type = '[Warehouse] Set Selected Document';
  constructor(public payload: { document?: Document | any , keep_working_packing_id?: boolean} = { keep_working_packing_id: false }) { }
}

export class SetWorkingPackingAction {
  public static readonly type = '[Warehouse] Set working packing';
  constructor(public payload: { packing_id: number, attach_doc: boolean }) { }
}

export class SetWorkingPackageAction {
  public static readonly type = '[Warehouse] Set working package';
  constructor(public payload: { package: Package }) { }
}

export class SetWorkingPackageForPartNumberAction {
	public static readonly type = '[Warehouse] Set working package for part number';
	constructor(public payload: { package: Package }) { }
}

export class ToggleUndocPkgAction {
  public static readonly type = '[Warehouse] Toggle unodcumented pacakge';
  constructor(public payload: { package: Package, selected: boolean }) { }
}

export class ToggleExpandedPackageAction {
  public static readonly type = '[Warehouse] Toggle expanded package';
  constructor(public payload: { id: number, expanded: boolean, single: boolean }) { }
}

export class SetWorkingDocumentAction {
  public static readonly type = '[Warehouse] Set working document';
  constructor(public payload: { document: Document | any }) { }
}

export class SetNavInstructionAction {
  public static readonly type = '[Warehouse] Set navigation instruction';
  constructor(public payload: { value?: number, field: 'last_completed_packing_list_id' | 'packings_tab_id' }) { }
}

export class ClearArrivalsStateAction {
  public static readonly type = '[Warehouse] Clear arrivals state value';
}

export class ClearArrivalStateAction {
  public static readonly type = '[Warehouse] Clear arrivals state value';
}

export class DeleteArrivalAction {
  public static readonly type = '[Warehouse] Delete arrival';
  constructor(public payload: number) { }
}

export class UpdateRecivingPackageAction {
  public static readonly type = '[Warehouse] Update Package';
  constructor(public payload: Package) { }
}

export class CreatePalletAction {
  public static readonly type = '[Warehouse] Set pallet id to pacakges';
  constructor(public payload: {packages: Package[], container_type_id: number, consolidation: boolean | undefined}) { }
}

export class CreateUnPalletAction {
  public static readonly type = '[Warehouse] Unpallet';
  constructor(public payload: Package | Pallet) { }
}

export class ReAssignReceiptAction {
  public static readonly type = '[Warehouse] Reassign Pallet';
  constructor(public payload: {packages: Package[], receipt_id: string}) { }
}

export class AddPalletToPackageAction {
  public static readonly type = '[Warehouse] Add Pallet to packages';
  constructor(public payload: {arrival_package_id: number, quantity: number, container_type_id: number  }) { }
}

export class MoveArrivalPackingsAction {
  public static readonly type = '[Warehouse] Move Arrival Packings to another arrival';
  constructor(public payload: { to: number, trk: string }) { }
}

export class ToggleBatchModeStateAction {
  public static readonly type = '[Warehouse] Toggle batch mode';
}

export type arrivalActions =
  GetActiveArrivalsAction |
  GetPagedArrivalsAction |
  GetArrivalAction |
  GetArrivePackingListAction |
  MarkPackageToBeDeletedAction |
  MarkPackageToNotBeDeletedAction |
  UpdateArriveAction |
  UpdateDamageReportAction |
  GetDocumentsAction |
  AssignDocumentToWorkingPackingAction |
  SetWorkingPackingAction |
  SetWorkingPackageAction |
  ToggleUndocPkgAction |
  ToggleExpandedPackageAction |
  SetWorkingDocumentAction |
  SetNavInstructionAction |
  DeleteDocumentsAction |
  ClearArrivalsStateAction |
  ClearArrivalStateAction |
  DeleteArrivalAction |
  UpdateRecivingPackageAction |
  CreatePalletAction |
  CreateUnPalletAction |
  ReAssignReceiptAction |
  ToggleBatchModeStateAction
;
