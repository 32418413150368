import { Component, OnInit } from '@angular/core';
import { Location, CatalogsState } from 'wms-lib';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
    locations: Location[];
    constructor(private store: Store) { }
    
    ngOnInit() {
        this.store.select(CatalogsState.locationByType('RacK')).subscribe((locs: Location[]) => this.locations = locs );
    }

}
