import { Injectable, Inject, NgZone } from '@angular/core';
import { ArrivePackingList, Document, User } from '../models';
import { Observable, Subject, BehaviorSubject } from 'rxjs';
import { catchError } from "rxjs/operators";
import * as _ from 'lodash';
import { GenericService } from './generic.service';
import { AuthenticationService } from './authentication.service';
import { HttpClient } from "@angular/common/http";
import { NotificationService } from '../platform-services/notification.service';

const USERS_PATH = "users";
const DOCUMENTS_PATH = "documents";
const DOCUMENTS_LIST_PATH = `${USERS_PATH}/${DOCUMENTS_PATH}`;
const DOCUMENTS_CHANNEL = "DocumentsChannel";

@Injectable({
    providedIn: 'root'
})
export class ConciliationService extends GenericService {
    public selected_packing_list: Subject<ArrivePackingList> = new Subject<null>();
    private _documents: BehaviorSubject<{ [name: number]: Document }>;

    constructor(private authenticationService: AuthenticationService,
                @Inject('env') env: any, http: HttpClient,
                ng_zone: NgZone,
                notificationService: NotificationService) {
        super(env, http, ng_zone,notificationService);
    }

    getDocuments(): Observable<{ [name: string]: Document }> {
        if (!this._documents) {
            this._documents = new BehaviorSubject<{ [name: number]: Document }>({});
            this.setDocumentsSnap();
        }
        return this._documents;
    }

    deleteDocuments(id: number) {
        this.http.delete(this.serverUrl + `${USERS_PATH}/${id}/${DOCUMENTS_PATH}`, this.httpOptions).pipe(
            catchError(this.handleSnapError<any[]>('Get documents', []))
        ).subscribe(() => global.log("Service",`Document delete request completed`));
    }

    setDocumentsSnap() {
        global.log("Service",`Get available documents`);
        this.http.get<any[]>(this.serverUrl + DOCUMENTS_LIST_PATH, this.httpOptions).pipe(
            catchError(this.handleSnapError<any[]>('Get documents', []))
        ).subscribe((data: any) => this.buildDocuments(data));
        this.authenticationService.currentUser.subscribe((user: User) => {
            if (user && user.id){
                global.log("Service",`Setup channel for user id: ${user.id}`);
                this.openChannel({channel: DOCUMENTS_CHANNEL, user_id: user.id }).
                    subscribe((data: any) => {
                        if (data)
                            this.buildDocuments(data)
                    });
            }
        });
    }

    buildDocuments(data: any[]) {
        global.log("Service",`Snapshot of documents received: ${data.length}`);
        this._documents.next(_(data).map((m) => new Document(m)).keyBy("id").value());
    }
}
