import { Component } from '@angular/core';
import { Arrival, Carrier, AuthenticationService, User } from 'wms-lib';
import * as actions from 'wms-lib';
import * as _ from 'lodash';
import { Observable, combineLatest } from 'rxjs';
import { AppMainComponent } from '../../../../../../src/app/app.main.component';
import { Store } from '@ngxs/store';
import * as moment from 'moment';
import { KpisComponent } from '../kpis.component';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-kpis-parcel',
  templateUrl: './kpis-parcel.component.html',
  styleUrls: ['./kpis-parcel.component.scss']
})
export class KpisParcelComponent extends KpisComponent {
  items$: Observable<Arrival[]>;
  normal$: Arrival;
  kpiViewData$: Observable<ParcelKpiViewData>;
  loading = false;
  type = 'ParcelArrival';

  constructor(protected app: AppMainComponent, protected store: Store, protected authenticationService: AuthenticationService) {
    super(app, store);
    this.store.dispatch(new actions.GetActiveArrivalsAction(this.type));
    this.store.dispatch(new actions.GetPagedArrivalsAction({type: this.type, page: 1 }));
    this.kpiViewData$ = combineLatest(
      this.store.select((state) => state.arrivalsState.arrivals),
      this.authenticationService.currentUser
    ).pipe(
      map(([items, user]: [{[id: number]: Arrival}, User]) => {
        return new ParcelKpiViewData(
          this.filterTodayArrivals(_.values(items), user.warehouse_id),
          this.store.select(state => state.catalogsState.carriers)
        )
      })
    )
  }

  filterTodayArrivals(arrivals: Arrival[], warehouse_id: number): Arrival[]{
    return _(arrivals).
      filter((e: Arrival) => e.warehouse_id === warehouse_id).
      filter((e: Arrival) => moment(e.created_at).isSame(moment(), 'day')).
      orderBy(['created_at'], ['desc']).
      value();
  }
}

class ParcelKpiViewData {
  current_arrivals: Arrival[];
  carrier_id: number;
  deflected: number = 0;
  regular: number = 0;
  total: number = 0;
  created_at: Date;
  last_arrival: Arrival; 

  constructor(public arrivals: Arrival[], public carriers$: Observable<Carrier[]>){
    if (arrivals && arrivals.length>0){
      [this.carrier_id, this.current_arrivals] = _(this.arrivals).
        groupBy('carrier_id').
        map((a: Arrival[], carrier_id: string) => [+carrier_id, a]).
        orderBy((o: Arrival[]) => o[1][0].created_at, ['desc']).
        first();

      _(this.current_arrivals).each((arrival: Arrival) => {
        this.deflected += arrival.deflected_counter;
        this.regular += arrival.regular_counter;
        this.total = this.deflected + this.regular;
        this.created_at = !this.created_at || arrival.created_at < this.created_at ? arrival.created_at : this.created_at;
        this.last_arrival = !this.last_arrival || arrival.created_at > this.last_arrival.created_at ? arrival : this.last_arrival;
      })
    }
  }

  get currentDate(): Date {
    return new Date();
  }

  get diference(): string{
    return moment(this.created_at).fromNow();
  }

  get diferenceFromStartToEnd(): string{
    if(this.last_arrival){  
      return this.rowDiff(this.last_arrival);
    }
    return '';
  }

  getCarrierIcon$(carrier_id: number = null): Observable<string>{
    return this.carriers$.pipe(map((carriers: Carrier[]) => !!carriers ? carriers[carrier_id || this.carrier_id]?.logo_url : null));
  }

  getCarrierName$(carrier_id: number = null): Observable<string>{
    return this.carriers$.pipe(map((carriers: Carrier[]) => !!carriers ? carriers[carrier_id || this.carrier_id]?.name : null));
  }

  get totalDeflected() {
    return _.sumBy(this.arrivals, (a:Arrival)=>a.deflected_counter);
  }

  get totalRegular() {
    return _.sumBy(this.arrivals, (a:Arrival)=>a.regular_counter);
  }

  get totalCounter() {
    return _.sumBy(this.arrivals, (a:Arrival)=>a.counter);
  }

  get totalCompleted() {
    return _.sumBy(this.arrivals, (a:Arrival)=>a.completed_counter);
  }

  get totalPending() {
    return _.sumBy(this.arrivals, (a:Arrival)=>a.counter-a.completed_counter);
  }

  closedDate(carrier_id: number): Date {
    return this.arrivals.find((a: Arrival) => a.carrier_id==carrier_id && !!a.receiving_end)?.receiving_end;
  }

  rowDiff(a: Arrival): string {
    return moment.duration(
      moment(a.created_at).diff(moment(this.closedDate(a.carrier_id)||new Date()))
    ).humanize();
  }
}
