import { PipeTransform } from "@angular/core";
import * as _ from 'lodash';

const BIG = 8640000000000000;

export class SortByPipeBase implements PipeTransform {
    transform(value: any, sort_by: string = null, selected: string = null, direction: 'asc' | 'desc' = 'desc'): any {
        return sort_by ? this.sort(_.values(value), sort_by, selected, direction) : _.values(value);
    }

    sort(data: any[], sort_by: string, selected: string,direction: 'asc'| 'desc' = 'desc'): any[] {
         const res = selected ?
            data.sort((a: any, b: any) => this.comparator((a.id == selected ? BIG : a[sort_by]), (b.id == selected ? BIG : b[sort_by]))) :
            data.sort((a: any, b: any) => this.comparator(a[sort_by], b[sort_by]));
        return direction === 'asc' ? res.reverse() : res;
    }

    comparator(a: any, b: any): number {
        if (typeof(a) == "string")
            return a > b ? -1 : (a < b ? 1 : 0);
        else
            return b - a;
    }
}

export class SortByLocationTagBase extends SortByPipeBase implements PipeTransform {

  transform(value: any[], sort_by: string = 'location_tag', selected: string = null, direction: 'asc' | 'desc' = 'desc'): any[] {
    return sort_by ? this.sort(_.values(value), sort_by, selected, direction) : _.values(value);
  }

  sort(data: any[], sort_by: string, selected: string, direction: 'asc' | 'desc' = 'desc'): any[] {
    const res = selected ?
      data.sort((a: any, b: any) => this.comparator((a.id === selected ? BIG : a[sort_by]), (b.id === selected ? BIG : b[sort_by]))) :
      data.sort((a: any, b: any) => this.compareLocationTags(a[sort_by], b[sort_by]));
    return direction === 'asc' ? res.reverse() : res;
  }

  compareLocationTags(a: string, b: string): number {
    const [aPart1, aPart2, aPart3] = this.parseLocationTag(a);
    const [bPart1, bPart2, bPart3] = this.parseLocationTag(b);

    if (aPart1 < bPart1) return -1;
    if (aPart1 > bPart1) return 1;

    if (aPart2 < bPart2) return -1;
    if (aPart2 > bPart2) return 1;

    if (aPart3 < bPart3) return -1;
    if (aPart3 > bPart3) return 1;

    return 0;
  }

  private parseLocationTag(tag: string): [string, number, number] {
	  if (!tag) {
		  return ['', 0, 0];
	  }
    const [part1, part2, part3] = tag.split('-');
    return [part1, parseInt(part2, 10), parseInt(part3, 10)];
  }
}
