import { Inject, Injectable, NgZone } from '@angular/core';
import { Observable, BehaviorSubject, of, timer, Subscription } from 'rxjs';
import { User, SettingsEnum } from '../models';
import { LocalStorage } from '../helpers';
import { GenericService } from './generic.service';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { Store } from '@ngxs/store';
import * as actions from '../ngxs-store/actions';
import * as _ from 'lodash';
import * as moment from 'moment';
import { Router } from '@angular/router';

import {
	ClearArrivalsStateAction,
	ClearHelpDeskStateAction,
	ClearInventoryStateAction,
	ClearNotificationsStateAction,
	ClearShipmentsStateAction,
	SetActiveClientAction
} from '../ngxs-store/actions';
import { NotificationService } from '../platform-services/notification.service';
import { fromEvent, Subject } from "rxjs";

const LOGIN_PATH = 'login';
const LOGOUT_PATH = 'logout';
const REFRESH_PATH = 'refresh';
const REFRESH_COUNTDOWN = 30 * 1000; //30 segundos
const TOTP_QR_PATH = 'users/totp_qr';
const UPDATE_PASSWORD_PATH = 'update_password';

@Injectable({
	providedIn: 'root'
})
export class AuthenticationService extends GenericService {
	private currentUserSubject: BehaviorSubject<User> = new BehaviorSubject<User>(null);
	public currentUser: Observable<User>;
  public refreshToken$: BehaviorSubject<number> = new BehaviorSubject<number>(null);
  private subTimer: Subscription;
  public idle$: Subject<boolean> = new Subject();

  private isIdle: boolean = false;
  private idleAfterSeconds: number;
  private countDown: any;
  private activitySubs: Subscription[];

	constructor(@Inject('env') env: any,
				      http: HttpClient,
				      ng_zone: NgZone,
				      private store: Store,
				      notificationService: NotificationService,
              private router: Router) {
		super(env, http, ng_zone,notificationService);
		this.currentUser = this.currentUserSubject.asObservable();
    this.currentUser.subscribe((u: User) => {
      this.setupSessionTimer(u);
    });

		this.initUser(
			LocalStorage.getString('currentUser'),
			env.default_user,
			!!+LocalStorage.getString(SettingsEnum.DEFLECTED)
		).subscribe((u: User) => {
      this.currentUserSubject.next(u)
    });
		global.log('Service', `Init state user ${this.currentUserValue ? this.currentUserValue.username : 'NA'}`);
	}

	initUser(userString: string, credentials: any, deflected: boolean): Observable<User> {
		if (userString) {
			let data: any = JSON.parse(userString);
			data.deflected = deflected;
			return of(new User(data));
		}
		if (credentials) return this.LogIn(credentials.username, credentials.password);
		return of(null);
	}

	get currentUserValue(): User {
		return this.currentUserSubject.value;
	}

	setDeflected(value: boolean) {
		if (this.currentUserSubject.value) {
			let cu = this.currentUserSubject.value;
			cu.deflected = value;
			this.currentUserSubject.next(cu);
		}
	}

	LogIn(username: string, password: string, code?: string, totp_session?: string, mfa?: string) {
		const options = { ...this.httpOptions };
		options['observe'] = 'response';

		return this.http
			.post<any>(this.serverUrl + LOGIN_PATH, { user: { username: username, password: password, code: code, totp_session: totp_session, mfa: mfa } }, options).pipe(
				map((response) => {
					if (response.body['id']) {
						const usr = this.setUser(response);
            if (usr.session_timeout > 0) this.setupSessionTimer(usr);
            if (usr.activity_timeout > 0) this.setupActivityTimer(usr);
            this.store.dispatch(new actions.LoadCatalogsAction());
						return usr;
					} else if (response.body['totp_session']) {
            return new User({...response.body});
          }
					return null;
				}),
				catchError(this.handleError<any>('Authenticate user', null))
			);
	}


  refresh() {
    this.http.put<any>(this.serverUrl + REFRESH_PATH, this.httpOptions).subscribe((token: any) => {
      if (token.length >= 2 && token[0] && token[0].length > 1 && token[1] && token[1].created_at) {
        LocalStorage.saveString('auth_token',token[0]);
        let usr = this.currentUserValue;
        usr.session_created_at = moment(token[1].created_at).toDate()
        LocalStorage.saveString('currentUser', JSON.stringify({...usr}));
        this.currentUserSubject.next(usr);
      }
    });
  }

  setupSessionTimer(usr: User) {
    if (usr && usr.session_timeout > 0) {
      if (this.subTimer) this.subTimer.unsubscribe();
      this.subTimer = timer(usr.ms_for_session_to_expire - REFRESH_COUNTDOWN).subscribe(() => {
        this.refreshToken$.next(_.floor(usr.ms_for_session_to_expire / 1000));
      });
    }
  }

  setupActivityTimer(usr: User) {
    this.idleAfterSeconds = (60*usr.activity_timeout)-10;
    if (typeof document != 'undefined'){
      this.activitySubs = [
        fromEvent(document, 'mousemove').subscribe(() => this.onInteraction()),
        fromEvent(document, 'touchstart').subscribe(() => this.onInteraction()),
        fromEvent(document, 'keydown').subscribe(() => this.onInteraction())
      ]
    }
  }

  stopActivityTimer() {
    this.idleAfterSeconds = null;
    this.isIdle = false;
    this.idle$.next(false);
    clearTimeout(this.countDown);
    if (this.activitySubs)
      this.activitySubs.forEach((s: Subscription) => s.unsubscribe);
  }

  onInteraction() {
    // Is idle and interacting, emit Wake
    if (this.isIdle) {
      this.isIdle = false;
      this.idle$.next(this.isIdle);
    }

    // User interaction, reset start-idle-timer
    clearTimeout(this.countDown);
    if (this.idleAfterSeconds) {
      this.countDown = setTimeout(() => {
        // Countdown done without interaction - emit Idle
        this.isIdle = true;
        this.idle$.next(this.isIdle);
      }, this.idleAfterSeconds * 1_000)
    }
  }

  setUser(response: any): User {
    let data = {...response.body};
    data.session_created_at = new Date();
    LocalStorage.saveString('currentUser', JSON.stringify(data));
		LocalStorage.saveString(
			'auth_token',
			response.headers.get('Authorization').replace(/[Bb]earer/, '').trim()
		);
		const usr = new User(data);
		usr.deflected = !!+LocalStorage.getString(SettingsEnum.DEFLECTED);
		this.currentUserSubject.next(usr);
		global.log(
			'Service',
			`Login success for: ${this.currentUserValue ? this.currentUserValue.username : 'NA'}`
		);
    return usr;
  }

  UpdatePassword(password: string, new_password: string, password_confirmation: string): Observable<boolean> {
		return this.http.put<any>(this.serverUrl + UPDATE_PASSWORD_PATH, { user: {
      current_password: password,
      password: new_password,
      password_confirmation: password_confirmation
		}}, this.httpOptions).pipe(
      map(() => true),
			catchError(this.handleError<any>('Update password', false))
		);
	}


	LogOut() {
		LocalStorage.remove('currentUser');
		LocalStorage.remove('auth_token');
	  this.cleanUpCookies();
		this.currentUserSubject.next(new User({}));
    this.clearStates();
    this.refreshToken$.next(null);
    this.stopActivityTimer();
		this.http.delete(this.serverUrl + LOGOUT_PATH).subscribe(() => global.log('Service', 'Logout'));
	}

	testLogin() {
		return this.http.get(this.serverUrl + 'auth-test', this.httpOptions);
	}

  getTOTP_QR(): Observable<string> {
    return this.http.
      get(this.serverUrl + TOTP_QR_PATH, {responseType: 'blob'}).pipe(
        map((blob: any)=>URL.createObjectURL(blob)),
        catchError((response: any) => {
          if (response.status == 401) {
            this.LogOut();
            this.router.navigate(['/']);
          }
          return "";
        })
      );
  }

	clearStates() {
		this.clearHelpDeskState();
		this.clearCatalogsState();
		this.clearArrivalsState();
		this.clearNotificationsState();
		this.clearShipmentsState();
		this.clearInventoryState();
	}

  setNextUser(user: User) {
    this.currentUserSubject.next(user);
  }

	private clearHelpDeskState() {
		this.store.dispatch(new ClearHelpDeskStateAction());
	}
	private clearInventoryState() {
		this.store.dispatch(new ClearInventoryStateAction());
	}
	private clearCatalogsState() {
		this.store.dispatch(new SetActiveClientAction({ client: null }));
	}

	private clearNotificationsState() {
		this.store.dispatch(new ClearNotificationsStateAction());
	}

	private clearShipmentsState() {
		this.store.dispatch(new ClearShipmentsStateAction());
	}

	private clearArrivalsState() {
		this.store.dispatch(new ClearArrivalsStateAction());
	}
}
