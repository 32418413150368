import { NgModule } from '@angular/core';
import { RouterModule } from "@angular/router";
import { routes } from './shipments.common';

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [ RouterModule]
})
export class ShipmentsRoutingModule {
}
