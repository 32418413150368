import { GenericService } from './generic.service';
import { Injectable, Inject, NgZone } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import * as _ from 'lodash';
import { ArrivePackingList, Package, CyclicalInventory, Tag } from '../models';
import { HttpClient } from '@angular/common/http';
import { NotificationService } from '../platform-services/notification.service';

const LOCATIONS_PATH = 'locations';
const FIND_PATH = 'inventory/find';
const MOVE_PATH = 'inventory/move';
const RESUME_PATH = 'inventory/resume';
const INVENTORY_CHANNEL = 'InventoryChannel';
const CYCLICAL_INVENTORIES_PATH = 'inventory/cyclical_inventories';

@Injectable({
  providedIn: 'root'
})
export class InventoryService extends GenericService {
  private _packings: {[location_id: number]: BehaviorSubject<{[id: number]: ArrivePackingList}>} = {};

  constructor(@Inject('env') env: any, http: HttpClient, ng_zone: NgZone,notificationService: NotificationService) {
    super(env, http, ng_zone,notificationService);
  }

  setPackingsSnap(location_id: number){
    this._packings[location_id] = new BehaviorSubject<{[id: number]: ArrivePackingList}>({});
    global.log("Service",`Get inventory of location ${location_id}`);
    this.http.get<any[]>(this.serverUrl + LOCATIONS_PATH + '/' + location_id + '/inventory', this.httpOptions).pipe(
      catchError(this.handleSnapError<any[]>('Get inventory', []))
    ).subscribe((data: any) => this.buildPackings(location_id, data));

    this.openChannel({channel: INVENTORY_CHANNEL, location_id: location_id}).subscribe((data: any) => {
      if (data) this.buildPackings(location_id, JSON.parse(data));
    });
  }

  getLocationInventory(location_id: number): Observable<{[id: number]: ArrivePackingList}>{
    this.setPackingsSnap(location_id);
    return this._packings[location_id];
  }

  find(id: number | string, apl: boolean = false): Observable<Package | ArrivePackingList> {
    return this.http.get<any>(this.serverUrl + FIND_PATH + `/${id}${apl ? '/apl' : ''}`, this.httpOptions).pipe(
      map((data: any) => typeof(id) == "string" || apl ? new ArrivePackingList(data) : new Package(data)),
      catchError(this.handleError<Package | ArrivePackingList>(' getting packing list by recipt id',
                                                               typeof(id) == "string" || apl ? new ArrivePackingList({receipt_id: id}) : new Package({id: id})))
    );
  }

  move(id: number | string, location_tag: string): Observable<boolean> {
    let path = this.serverUrl + MOVE_PATH + `/${id}`;
    if (!!location_tag) path += `/${location_tag}`;
    return this.http.put<any>(path, this.httpOptions).pipe(
      map(() => true ),
      catchError(this.handleError<boolean>(' moving recipt id', false))
    );
  }


  private buildPackings(location_id: number, data: any[]) {
    const packings = _(data).map(pl => new ArrivePackingList(pl)).keyBy('id').value();
    this._packings[location_id].next(packings || {});
  }

  getSummary(): Observable<any> {
    return this.http.get(this.serverUrl + RESUME_PATH, this.httpOptions).pipe(
      catchError(this.handleError(' get inventory resume', {}))
    );
  }


getCyclicalInventories(query: {[key: string]: any} = {}): Observable<{totalRecords: number, data: CyclicalInventory[]}> {
  global.log("Service",`Get cyclical inventories with query ${JSON.stringify(query)}`);
  
  return this.http.get<{totalRecords: number, data: CyclicalInventory[]}>(this.serverUrl + CYCLICAL_INVENTORIES_PATH, {
    params: _.mapValues(query, v => v.toString()),
    headers: this.httpOptions.headers,
    observe: 'response'
  }).pipe(
    map((data: any) => {
      let totalRecords = +data.headers.get('totalrecords');
      return {totalRecords: totalRecords, data: _.map(data.body, d => new CyclicalInventory(d)) as CyclicalInventory[]};
    }),
    catchError(this.handleError<{totalRecords: number, data: CyclicalInventory[]}>(' getting cyclical inventories', {totalRecords: 0, data: []}))
  );
}

  saveCyclicalInventory(cyclical: CyclicalInventory): Observable<CyclicalInventory> {
  	  let data = cyclical.toSaveData();
  	  return ((cyclical.id && cyclical.id > 0) ? this.http.put<any>(this.serverUrl + CYCLICAL_INVENTORIES_PATH + '/' + cyclical.id, data, this.httpOptions) :
  	  		  this.http.post<any>(this.serverUrl + CYCLICAL_INVENTORIES_PATH, data, this.httpOptions)).pipe(
  	  map((data: any) => {
  	  	  this.notificationService.toast('Cyclical inventory saved successfully', true, {severity: 'success', summary: 'Success'});
  	  	  return new CyclicalInventory(data)
  	  }
		 ),
		 catchError(this.handleError<CyclicalInventory>(' saving cyclical inventory', new CyclicalInventory({})))
	  );
  }

  toogleActiveCyclicalInventory(cyclical: CyclicalInventory): Observable<CyclicalInventory> {
  	  let data = cyclical.toSaveData();
  	  return this.http.put<any>(this.serverUrl + CYCLICAL_INVENTORIES_PATH + '/' + cyclical.id + '/toggle', data, this.httpOptions).pipe(
  	  	  map((data: any) => {
  	  	  	  this.notificationService.toast('Cyclical inventory saved successfully', true, {severity: 'success', summary: 'Success'});
  	  	  	  return new CyclicalInventory(data);
  	  	  }),
  	  	  catchError(this.handleError<CyclicalInventory>(' saving cyclical inventory', new CyclicalInventory({})))
  	  );
  }

  deleteCyclicalInventory(id: number): Observable<boolean> {
  	  return this.http.delete(this.serverUrl + CYCLICAL_INVENTORIES_PATH + '/' + id, this.httpOptions).pipe(
  	  	  map(() => {
  	  	  	  this.notificationService.toast('Cyclical inventory deleted successfully', true, {severity: 'success', summary: 'Success'});
  	  	  	  return true;
  	  	  }),
  	  	  catchError(this.handleError<boolean>(' deleting cyclical inventory', false))
  	  );
  }

  getCyclicalInventoryTags(id: number): Observable<Tag[]> {
  	  return this.http.get<any>(this.serverUrl + CYCLICAL_INVENTORIES_PATH + '/' + id + '/tags', this.httpOptions).pipe(
  	  	  map((data: any) => {
  	  	  	  return data.map((t: any) => new Tag(t));
  	  	  }),
  	  	  catchError(this.handleError<Tag[]>(' getting cyclical inventory tags', []))
  	  );
  }

  loadCyclicalInventory(id: number): Observable<CyclicalInventory> {
  	  return this.http.get<any>(this.serverUrl + CYCLICAL_INVENTORIES_PATH + '/' + id, this.httpOptions).pipe(
  	  	  map((data: any) => {
  	  	  	  return new CyclicalInventory(data);
  	  	  }),
  	  	  catchError(this.handleError<CyclicalInventory>(' loading cyclical inventory', new CyclicalInventory({})))
  	  );
  }

  openCloseCyclicalInventory(cyclical: CyclicalInventory, open: boolean): Observable<CyclicalInventory> {
  	  let action = open ? 'open' : 'close';
  	  return this.http.put<any>(this.serverUrl + CYCLICAL_INVENTORIES_PATH + '/' + cyclical.id + '/' + action, {}, this.httpOptions).pipe(
  	  	  map((data: any) => {
  	  	  	  this.notificationService.toast(`Cyclical inventory ${action} successfully`, true, {severity: 'success', summary: 'Success'});
  	  	  	  return new CyclicalInventory(data);
  	  	  }),
  	  	  catchError(this.handleError<CyclicalInventory>(`${action} cyclical inventory`, new CyclicalInventory({})))
  	  );
  }

}
