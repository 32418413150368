import { Directive } from '@angular/core';
import * as _ from 'lodash';

@Directive({selector: '[poSelectionDialogBase]'})
export class PoSelectionDialogBaseComponent {
    protected _new: string;
    protected params: any;

    constructor() {
    }

    get options(): string[] {
        if (this._new && !this.params.context.suggestions.includes(this._new) && !this.selected.includes(this._new)) 
            return this.params.context.suggestions.concat(this._new);
        else
            return this.params.context.suggestions;
    }

    get selected(): string[] {
        throw new Error('This method is abstract');
    }

    
}
