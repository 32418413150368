import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list-detail-package',
  templateUrl: './list-detail-package.component.html',
  styleUrls: ['./list-detail-package.component.scss']
})
export class ListDetailPackageComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
