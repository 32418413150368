import { Routes } from '@angular/router';
import { PicklistComponent } from './components/picklist/picklist.component';
import { ShipmentsComponent } from '../shared/components/shipments/shipments.component';
import { ShipmentComponent } from '../clients/components/shipments/shipment.component';

export const componentDeclarations: any[] = [
];

export const providerDeclarations: any[] = [
];

export const routes: Routes = [
    { path: '', component: ShipmentsComponent, data: {open: false}},
    { path: 'orders', component: ShipmentsComponent, data: {open: true}},
    { path: 'picklist', component: PicklistComponent },
    { path: ':id', component: ShipmentComponent }
];
