import { Catalog } from "./catalog";

export class Printer extends Catalog {
    public static SELECTED: Printer = null;
    validationKey: string = 'printer';
    id: number;
    private _name: string;
    ip: string;
    port: string;
    brand: string;
    model: string;

    get name(): string {
        return this._name;
    }
    set name(value: string) {
        this._name = value;
    }
    
    get port_number(): number {
        return +this.port;
    }

    protected get saveProp(): string[] {
        return  ["name", "ip", "port", "brand", "model"];
    }

    get http_url(): string {
        return `http://${Printer.SELECTED.ip}`;
    }

    get http_url_post(): string {
        return `${this.http_url}/pstprnt`;
    }

    get http_url_ping(): string {
        return `${this.http_url}/index.html`;
    }
}
