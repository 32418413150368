import * as _ from 'lodash';
import { Bindable } from './common';
import { Client } from './client';
import { User } from './user';
import { Warehouse } from './warehouse';
import { Package, PackageStatuses, OnStockStatus } from "./package";
import { Location } from './location';
import { Tag } from './tag';

const SAVE_PROPS = ['size', 'client_id', 'location_id', 'tags_attributes', 'package_type', 'package_statuses', 'warehouse_id', 'active', 'milk_run', 'closed_by_id', 'closed_at'];

export class CyclicalInventory extends Bindable {
	size: number;
	client_id: number;
	location_id: number;
	client: Client;
	location: Location;
	tags: Tag[];
	_package_type: string;
	package_statuses: string[];
	_total_tags: number;
	warehouse_id: number;
	warehouse: Warehouse;
	active: boolean;
	milk_run: boolean | null;
	closed_by_id: number;
	closed_by: User;
	closed_at: Date;
	created_at: Date;
	updated_at: Date;
	isEditing: boolean = false;

	constructor(data: any) {
		super(data);
        this.id = this.id || (+(new Date())) * -1;
		if (data.client) this.client = new Client(data.client);
		if (data.location) this.location = new Location(data.location);
		if (data.tags) this.tags = data.tags.map((t: any) => new Tag(t));
		if (data.warehouse) this.warehouse = new Warehouse(data.warehouse);
		if (data.closed_by) this.closed_by = new User(data.closed_by);
		this.size = this.size || 0;
		this._package_type = this._package_type || null;
		this.package_statuses = _.size(this.package_statuses) ? this.package_statuses : OnStockStatus;
		this.client_id = this.client_id || (this.client ? this.client.id : null);
		this.location_id = this.location_id || (this.location ? this.location.id : null);
		this.active = (typeof this.active === 'boolean') ? this.active : true;
		this.milk_run = (typeof this.milk_run === 'boolean') ? this.milk_run : null;
		this.closed_by_id = this.closed_by_id || null;
		this.closed_at = this.closed_at || null;
	}

	get isNew(): boolean {
		return this.id.toString().startsWith('-');
	}

	get size_as_percentage(): number {
		return Math.round(this.size * 100) || 0;
	}

	get saveProp(): string[] {
		return super.saveProp.concat(SAVE_PROPS);
	}

	set total_tags(value: number) {
		this._total_tags = value;
	}

	get total_tags(): number {
		return this._total_tags || _.size(this.tags);
	}

	get percentage(): number {
		return Math.round(this.size * 100);
	}

	set percentage(value: number) {
		this.size = value / 100;
	}

	get packages(): Package[] {
		return _(this.tags).map('package').value();
	}

	get package_type(): string {
		return this._package_type;
	}

	set package_type(value: string) {
		this._package_type = value;
		this.milk_run = ((typeof this.milk_run === 'boolean') && (!value || value == 'FreightArrival') ) ? this.milk_run : null;
	}

	get tags_attributes(): any[] {
		return this.tags;
	}

	get package_statuses_names(): string {
		const inverted = _.invert(PackageStatuses);
		return this.package_statuses.map((v: string) => inverted[v]).join(', ');
	}

	get package_statuses_names_list(): string[] {
		const inverted = _.invert(PackageStatuses);
		return this.package_statuses.map((v: string) => inverted[v]);
	}

	get isParcel(): boolean {
		return this.package_type == 'ParcelArrival';
	}

	get isFreight(): boolean {
		return this.package_type == 'FreightArrival';
	}

	get isParcelAndFreight(): boolean {
		return !this.package_type;
	}

	get canMilkRun(): boolean {
		return this.isFreight || this.isParcelAndFreight;
	}

	get milk_run_label(): string {
		return typeof this.milk_run === 'boolean' ? (this.milk_run ? 'Only Milk Run' : 'Exclude Milk Run') : 'Include Milk Run';
	}

	get isOpen(): boolean {
		return !this.closed_by_id;
	}

	get package_type_label(): string {
		return (this.isParcel ? 'Parcel' : this.isFreight ? 'Freight' : 'Parcel and Freight') + ' Arrival';
	}

	get canEdit(): boolean {
		return !this.closed_by_id && this.active;
	}

    findPackage(id: string | number): Package {
        return this.packages.find((x: Package) => typeof(id) === 'string' ? x.trk === id || id.includes(x.simple_trk) : x.id === id);
    }

    findTagByPackageId(id: number | string): Tag {
		return this.tags.find((t: Tag) => typeof(id) === 'string' ? t.package.trk === id || id.includes(t.package.simple_trk) : t.package.id === id);
	}



}
