import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { AuthenticationService, SetActiveClientAction } from 'wms-lib';
import { ClientChangerBaseComponent } from './client-changer-base.component';

@Component({
  selector: 'app-client-changer',
  templateUrl: './client-changer.component.html',
  styleUrls: ['./client-changer.component.scss']
})
export class ClientChangerComponent extends ClientChangerBaseComponent implements OnInit {

  constructor(store: Store,authService:AuthenticationService) { 
    super(store,authService);
  }

  ngOnInit() {
    super.ngOnInit();
  }

  onClientChange(event) {
    this.store.dispatch(new SetActiveClientAction({client: event.value}));
  }

}
